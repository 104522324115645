import React from "react";
import styled from "styled-components";

import {
  DOCS_WRITES_MERGE_WRITES,
  DOCS_WRITES_RELATED_AND_NESTED_WRITES,
  DOCS_WRITES_PROGRAMMATIC_WRITES,
  DOCS_WRITES_PROGRAMMATIC_NESTED_WRITES,
  DOCS_WRITES_TEMPLATES_AND_CONDITIONAL_FIELDS,
} from "../../../../router/RouterUtils";
import DeprecatedH3 from "../../../../deprecated/DeprecatedH3";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";
import { Button } from "@merge-api/merge-javascript-shared";
import { ChevronRight } from "lucide-react";

const Container = styled.div`
  @media (min-width: 1330px) {
    margin: 32px auto;
    max-width: 75%;
    padding-bottom: 64px;
  }
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 24px;
`;

const ShadedArea = styled.div`
  border-radius: 10px;
  width: 100%;
  display: block;
  background: #eff6ff;
  padding: 16px 20px;
`;

const Header = styled(DeprecatedH3)`
  font-size: 24px;
  line-height: 36px;
`;

const Subtitle = styled(DeprecatedH6)`
  font-size: 14px;
  line-height: 24px;
`;

const MergeWrites = () => {
  return (
    <Container>
      <Card>
        <Header className="mb-1">Writing to Merge</Header>
        <Subtitle className="mb-6 gray font-normal">Push data to integrated third-parties</Subtitle>

        <DeprecatedH3>Basic writes</DeprecatedH3>
        <p className="mb-9">
          Here, we introduce universal concepts that allow you to execute writes for{" "}
          <strong>most</strong> — but not all — third-party platforms and Linked Accounts.
        </p>
        <ShadedArea className="mb-6">
          <DeprecatedH4>Writing to Merge</DeprecatedH4>
          <p className="small">
            Make POST requests to Merge to write data to third-party platforms
          </p>
          <Button
            rightIcon={<ChevronRight size={12} />}
            size="sm"
            onClick={() => window.open(DOCS_WRITES_MERGE_WRITES, "_blank")}
          >
            View docs
          </Button>
        </ShadedArea>
        <ShadedArea>
          <DeprecatedH4>Related and nested writes</DeprecatedH4>
          <p className="small">
            Write data to third-party platforms with relations to existing and new Common Model
            instances
          </p>
          <Button
            size="sm"
            rightIcon={<ChevronRight size={12} />}
            onClick={() => window.open(DOCS_WRITES_RELATED_AND_NESTED_WRITES, "_blank")}
          >
            View docs
          </Button>
        </ShadedArea>
        <DeprecatedH3 className="mt-9">Advanced writes</DeprecatedH3>
        <p>Writes are unified across all of our third-party platforms.</p>
        <p>
          However, some platforms require an additional piece of Merge&apos;s advanced functionality
          to support them.
        </p>
        <p className="mb-9">
          Here, we introduce programmatic concepts that make heavy use of an endpoint we call{" "}
          <code>/meta</code> to handle writes for <strong>all</strong> third-party platforms and
          Linked Accounts.
        </p>
        <ShadedArea className="mb-6">
          <DeprecatedH4>
            Programmatic writes with <code className="bg-transparent">/meta</code>
          </DeprecatedH4>
          <p className="small">
            Use <code className="bg-transparent">/meta</code> to programmatically form and validate
            POST requests to write standardized or integration-specific / Linked Account-specific
            model fields
          </p>
          <Button
            size="sm"
            rightIcon={<ChevronRight size={12} />}
            onClick={() => window.open(DOCS_WRITES_PROGRAMMATIC_WRITES, "_blank")}
          >
            View docs
          </Button>
        </ShadedArea>
        <ShadedArea className="mb-6">
          <DeprecatedH4>
            Programmatic nested writes with <code className="bg-transparent">/meta</code>
          </DeprecatedH4>
          <p className="small">
            Use <code className="bg-transparent">/meta</code> to create related Common Model
            instances (through nesting) with standardized or integration-specific / Linked
            Account-specific fields
          </p>
          <Button
            size="sm"
            rightIcon={<ChevronRight size={12} />}
            onClick={() => window.open(DOCS_WRITES_PROGRAMMATIC_NESTED_WRITES, "_blank")}
          >
            View docs
          </Button>
        </ShadedArea>
        <ShadedArea>
          <DeprecatedH4>Templates and conditional fields</DeprecatedH4>
          <p className="small">
            Use <code className="bg-transparent">/meta</code> to determine which specific model
            fields are available based on your user&apos;s input in another field
          </p>
          <Button
            size="sm"
            rightIcon={<ChevronRight size={12} />}
            onClick={() => window.open(DOCS_WRITES_TEMPLATES_AND_CONDITIONAL_FIELDS, "_blank")}
          >
            View docs
          </Button>
        </ShadedArea>
      </Card>
    </Container>
  );
};

export default MergeWrites;
