import React from "react";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
} from "../../../../../../../../models/Entities";
import { Select } from "@merge-api/merge-javascript-shared";
import { getFilteredModelOptions } from "../helpers";
import startCase from "lodash/startCase";

type CommonModelSelectProps = {
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  linkedAccountConditions: Partial<LinkedAccountCondition>[];
  onLinkedAccountConditionChange: (LinkedAccountCondition: Partial<LinkedAccountCondition>) => void;
};

const CommonModelSelect = ({
  linkedAccountCondition,
  linkedAccountConditionMeta,
  linkedAccountConditions,
  onLinkedAccountConditionChange,
}: CommonModelSelectProps) => {
  // derived state
  const commonModelOptions = getFilteredModelOptions(
    linkedAccountCondition,
    linkedAccountConditionMeta,
    linkedAccountConditions,
  );

  // event handlers
  const onCommonModelChange = (_: any, commonModel: string | null) => {
    onLinkedAccountConditionChange({
      ...linkedAccountCondition,
      common_model: commonModel || undefined,

      // reset other fields
      normalized_key_name: undefined,
      operator: undefined,
      value: undefined,
    });
  };

  return (
    <Select
      className="flex flex-1 h-full py-[3px]"
      noOptionsText="No filters available"
      shadowHidden
      options={commonModelOptions}
      clearable={false}
      value={linkedAccountCondition.common_model}
      getOptionLabel={(option) => startCase(option)}
      onChange={onCommonModelChange}
    />
  );
};

export default CommonModelSelect;
