import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Dialog, Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import type { Props as RowProps } from "../../IntegrationSettingsConfigParameterRow";
import CredentialChips from "./CredentialChips";
import PartnershipConfigurationForm from "./PartnershipConfigurationForm";
import styled from "styled-components";
import find from "lodash/find";
import { OrganizationIntegrationSettings } from "../../../../../models/Entities";
import usePartnershipModal from "./hooks/usePartnershipModal";
import { ConfigFieldMapValue } from "./hooks/usePartnershipModal";
import { Key } from "lucide-react";
import {
  NoMergeCredentialsText,
  MergeProvidedCredentialsText,
  CredentialChoices,
} from "./constants";
import { PartnershipType } from "../../../../../models/Entities";

type FadeScrollProps = {
  showFade: boolean;
};
const FadeScroll = styled.div<FadeScrollProps>`
  mask-image: ${({ showFade }: FadeScrollProps) =>
    showFade ? "linear-gradient(to bottom, black calc(100% - 37px), transparent 100%)" : ""};
`;
type PartnershipCredentialModalProps = {
  onModalClose: () => void;
  configStatusFields: Array<RowProps["fieldConfigStatus"]>;
  isAlreadyCompleted: boolean;
  organizationIntegrationSettingsID: string;
  setOrganizationIntegrationSettings: Dispatch<SetStateAction<OrganizationIntegrationSettings>>;
  hasMergeCreds: boolean;
  partnershipType: string;
};
const PartnershipCredentialModal = ({
  onModalClose,
  configStatusFields,
  isAlreadyCompleted,
  organizationIntegrationSettingsID,
  setOrganizationIntegrationSettings,
  hasMergeCreds,
  partnershipType,
}: PartnershipCredentialModalProps) => {
  const {
    configFieldMap,
    setConfigFieldMap,
    height,
    selectedCredentialType,
    setSelectedCredentialType,
    deleteCredentialsLoading,
    deletePartnerCredentials,
    savePartnerCredentials,
    updatePartnershipCredentialsLoading: savePartnerCredentialsLoading,
  } = usePartnershipModal({
    organizationIntegrationSettingsID: organizationIntegrationSettingsID,
    configStatusFields: configStatusFields,
    onModalClose: onModalClose,
    setOrganizationIntegrationSettings: setOrganizationIntegrationSettings,
    hasMergeCreds: hasMergeCreds,
    partnershipType: partnershipType,
  });

  const missingRequiredField = find(configFieldMap, (configField: ConfigFieldMapValue) => {
    return (
      (configField.isOptional == false &&
        !configField.isComplete &&
        (configField.value == null || configField.value == "")) ||
      (configField.isModified && (configField.value == null || configField.value == ""))
    );
  });

  const initialCredsType =
    partnershipType == PartnershipType.ORGANIZATION_PARTNERSHIP
      ? CredentialChoices.CUSTOMER
      : CredentialChoices.MERGE;
  const needToSaveChanges =
    find(configFieldMap, (configFieldMap: ConfigFieldMapValue) => {
      return configFieldMap.isModified == true;
    }) || initialCredsType !== selectedCredentialType;

  const handleChipSelect = (value: string | number | boolean | null) => {
    if (typeof value == "string" || value == null) {
      setSelectedCredentialType(value);
    }
  };
  return (
    <Dialog
      preTitleIcon={<Key size={16} stroke="black" />}
      title="Credentials"
      onClose={() => {
        onModalClose();
      }}
      variant="md"
      open
      footerButtonsHidden
    >
      <div>
        <FadeScroll
          showFade={selectedCredentialType == CredentialChoices.CUSTOMER}
          className={`${height} mt-[-4px] transition-all ease duration-300 max-h-[304px] overflow-y-auto`}
        >
          <div
            className={`flex  flex-col gap-4 ${
              selectedCredentialType == CredentialChoices.CUSTOMER ? "pb-[40px]" : "pb-6"
            }`}
          >
            <div>
              {hasMergeCreds ? <>{MergeProvidedCredentialsText}</> : <>{NoMergeCredentialsText}</>}
            </div>
            {hasMergeCreds && (
              <CredentialChips
                selectedCredentialType={selectedCredentialType}
                onChipSelect={handleChipSelect}
              />
            )}
            <div
              className={`transition duration-700 ease flex flex-col gap-4 ${
                selectedCredentialType == CredentialChoices.CUSTOMER ? "" : "opacity-0 h-[0px]"
              }`}
            >
              {selectedCredentialType == CredentialChoices.CUSTOMER && (
                <PartnershipConfigurationForm
                  configStatusFields={configStatusFields}
                  configFieldMap={configFieldMap}
                  setConfigFieldMap={setConfigFieldMap}
                />
              )}
            </div>
          </div>
        </FadeScroll>
        <div className="w-100 mt-auto">
          <div className="flex items-center justify-between">
            <div className="flex w-50">
              {isAlreadyCompleted ? (
                <Button
                  variant={ButtonVariant.TextBlack}
                  className="border-none flex-grow-1 text-red-40"
                  loading={deleteCredentialsLoading}
                  onClick={() => {
                    deletePartnerCredentials();
                  }}
                >
                  Remove credentials
                </Button>
              ) : (
                <Button
                  variant={ButtonVariant.TextBlack}
                  className="border-none flex-grow-1"
                  onClick={() => {
                    onModalClose();
                  }}
                >
                  Close
                </Button>
              )}
            </div>
            <div className="w-50 flex">
              <Button
                disabled={
                  (missingRequiredField !== undefined &&
                    selectedCredentialType == CredentialChoices.CUSTOMER) ||
                  (isAlreadyCompleted && !needToSaveChanges)
                }
                className="ml-4 flex-grow-1"
                loading={savePartnerCredentialsLoading}
                onClick={() => {
                  savePartnerCredentials();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PartnershipCredentialModal;
