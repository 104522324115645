import React, { useState } from "react";
import { Spinner } from "@merge-api/merge-javascript-shared";
import PortalPageHeaderWrapper from "../../../portal/PortalPageHeaderWrapper";
import {
  CONFIGURATION_INTEGRATIONS_ENDPOINT_USAGE_PATH,
  CONFIGURATION_INTEGRATIONS_PATH,
} from "../../../../router/RouterUtils";
import EndpointUsageFilters from "./components/EndpointUsageFilters/EndpointUsageFilters";
import EndpointUsageTable from "./components/EndpointUsageTable/EndpointUsageTable";
import useEndpointUsageData from "./hooks/useEndpointUsageData";
import TableLegend from "./components/TableLegend/TableLegend";
import isUndefined from "lodash/isUndefined";
import { Redirect } from "react-router-dom";

type IntegrationEndpointUsagePageProps = {
  isEndpointUsageEnabled: boolean | undefined;
};

const IntegrationEndpointUsagePage = ({
  isEndpointUsageEnabled,
}: IntegrationEndpointUsagePageProps) => {
  // hooks
  const {
    selectedCategory,
    setSelectedCategory,
    selectedIntegrations,
    setSelectedIntegrations,
    selectedEndpoints,
    setSelectedEndpoints,
    endpointUsageLoading,
    endpointsAndIntegrationsLoading,
    endpointUsageData,
    integrations,
    endpoints,
  } = useEndpointUsageData();

  if (isUndefined(isEndpointUsageEnabled)) {
    return <Spinner />;
  }

  if (isEndpointUsageEnabled == false) {
    return <Redirect to={CONFIGURATION_INTEGRATIONS_PATH} />;
  }

  return (
    <PortalPageHeaderWrapper
      title="Integrations"
      subtitle="Manage the integrations you make available to your users"
      subtabs={[
        { label: "Overview", destination: CONFIGURATION_INTEGRATIONS_PATH + "/hris" },
        { label: "Endpoint usage", destination: CONFIGURATION_INTEGRATIONS_ENDPOINT_USAGE_PATH },
      ]}
    >
      <p>
        See which endpoints are supported for each integration and compare usage across platforms.
        Ratings are dynamically updated based on usage across Merge. Low usage endpoints are
        regarded as less battle-tested.
      </p>
      {/* Filters */}
      <EndpointUsageFilters
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        selectedIntegrations={selectedIntegrations}
        setSelectedIntegrations={setSelectedIntegrations}
        selectedEndpoints={selectedEndpoints}
        setSelectedEndpoints={setSelectedEndpoints}
        integrations={integrations}
        endpoints={endpoints}
        loading={endpointsAndIntegrationsLoading}
      />

      {/* Legend */}
      <TableLegend className="my-6" />

      {/* Table */}
      <div className="pb-6">
        {/* Table */}
        <EndpointUsageTable
          endpointUsageData={endpointUsageData}
          loading={endpointUsageLoading || endpointsAndIntegrationsLoading}
          selectedCategory={selectedCategory}
        />
      </div>
    </PortalPageHeaderWrapper>
  );
};

export default IntegrationEndpointUsagePage;
