import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import {
  DataWarehouseImportConfigList,
  Destination,
  LinkedAccount,
  LinkedAccountStatus,
  PauseReason,
} from "../../../../../../models/Entities";
import { HTTPMethod } from "@merge-api/merge-javascript-shared";
import { Alert } from "@merge-api/merge-javascript-shared";
import { fetchWithAuth } from "../../../../../../api-client/APIClient";
import DataWarehouseSyncHistoryTable from "./DataWarehouseSyncHistoryTable";
import { usePaginatedRequest } from "../../../../../shared-components/hooks/usePaginatedRequest";

export interface Props {
  linkedAccount: LinkedAccount;
}

const DataWarehouseSyncHistory = ({ linkedAccount }: Props) => {
  // fetch hooks
  const [connectionImportConfigData, setConnectionImportConfigData] = useState<
    DataWarehouseImportConfigList | undefined
  >(undefined);
  const [destinations, setDestinations] = useState<Destination[]>([]);

  const fetchConnectionImportConfigData = () => {
    fetchWithAuth({
      path: `datawarehouse/v1/import/connections/${linkedAccount?.sync_connection_id}/config`,
      method: HTTPMethod.GET,
      onResponse: (data: DataWarehouseImportConfigList) => {
        setConnectionImportConfigData(data);
      },
    });

    fetchWithAuth({
      path: `/integrations/destinations`,
      method: HTTPMethod.GET,
      onResponse: (data: any) => {
        setDestinations(data);
      },
    });
  };

  useEffect(fetchConnectionImportConfigData, []);
  return (
    <>
      <div className="flex flex-col">
        <div>
          {linkedAccount?.pause_reason === PauseReason.PRICING_PLAN_LIMIT ? (
            <>
              {linkedAccount?.status === LinkedAccountStatus.INCOMPLETE && (
                <Alert showWarningIcon color="red">
                  Syncing for this account has been paused. Please upgrade your plan to continue
                  syncing.
                </Alert>
              )}
              {linkedAccount?.status === LinkedAccountStatus.COMPLETE && (
                <>
                  <Alert showWarningIcon color="red" className="mb-5">
                    This account is linked but not syncing. Please upgrade your plan to continue
                    syncing.
                  </Alert>
                </>
              )}
            </>
          ) : (
            <DataWarehouseSyncHistoryTable
              linkedAccount={linkedAccount}
              connectionImportConfigData={connectionImportConfigData}
              destinations={destinations}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DataWarehouseSyncHistory;
