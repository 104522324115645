import React from "react";
import { AuditLogEvent } from "../../../../../models/Entities";
import { UserCell, RoleCell, EventDescriptionCell, IPAddressCell, DateCell } from "./components";

type AuditLogsTableRowProps = {
  auditLogEvent: AuditLogEvent;
};

const AuditLogsTableRow = ({ auditLogEvent }: AuditLogsTableRowProps) => {
  const { user_name, user_email, role, event_description, ip_address, created_at, event_type } =
    auditLogEvent;

  return (
    <tr>
      <UserCell name={user_name} email={user_email} />
      <RoleCell role={role} />
      <EventDescriptionCell eventDescription={event_description} eventType={event_type} />
      <IPAddressCell ipAddress={ip_address} />
      <DateCell date={created_at} />
    </tr>
  );
};

export default AuditLogsTableRow;
