import { useEffect, useMemo, useState } from "react";
import { ConditionPreset } from "../../../../models/Entities";
import isUndefined from "lodash/isUndefined";
import isEqual from "lodash/isEqual";
import { useLazyRequest } from "../../../shared-components/hooks/useLazyRequest";
import { APICategory, HTTPMethod } from "@merge-api/merge-javascript-shared";
import { useParams } from "react-router-dom";
import size from "lodash/size";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";

type UsePublishConditionPresetChangesProps = {
  remoteConditionPresets?: ConditionPreset[];
  conditionPresets?: Partial<ConditionPreset>[];
  refetch: () => void;
};

/**
 * Hook for publishing changes to condition presets. Makes a series of POST, PATCH, and DELETE endpoint calls for each change.
 */
const usePublishConditionPresetChanges = ({
  remoteConditionPresets,
  conditionPresets,
  refetch,
}: UsePublishConditionPresetChangesProps) => {
  // state
  const [added, setAdded] = useState<Partial<ConditionPreset>[]>([]);
  const [changed, setChanged] = useState<ConditionPreset[]>([]);
  const [deleted, setDeleted] = useState<ConditionPreset[]>([]);

  // hooks
  const { category } = useParams<{ category: APICategory }>();
  const [addConditionPreset, { loading: addConditionLoading }] = useLazyRequest<ConditionPreset>({
    path: "integrations/selective-sync/condition-presets",
    method: HTTPMethod.POST,
  });
  const [changeConditionPreset, { loading: changeConditionLoading }] =
    useLazyRequest<ConditionPreset>({
      method: HTTPMethod.PATCH,
    });
  const [deleteConditionPreset, { loading: deleteConditionLoading }] = useLazyRequest({
    method: HTTPMethod.DELETE,
  });

  // derived state
  // we automatically add an empty object as the first condition preset,
  // in the event of an empty state this means we want excluded
  // that empty object when calculating if there are any changes
  const hasChanges = useMemo(() => {
    if (
      !size(remoteConditionPresets) && // no remote condition presets
      size(conditionPresets) === 1 && // only one condition preset (the one we added)
      isObject(added[0]) && // which is an object
      isEmpty(added[0]) // and is empty
    ) {
      return false;
    }

    return size(added) + size(changed) + size(deleted) > 0;
  }, [added, changed, conditionPresets, deleted, remoteConditionPresets]);

  // event handlers
  const parseChanges = () => {
    if (!remoteConditionPresets || !conditionPresets) return;

    // parse deletes
    // (remoteConditionPresets that do not exist in conditionPresets)
    const deleted = remoteConditionPresets.filter((remoteConditionPreset) => {
      return conditionPresets.findIndex(({ id }) => remoteConditionPreset.id === id) === -1;
    });

    // parse added
    // (conditionPresets w/o id)
    const added = conditionPresets.filter(({ id }) => isUndefined(id));

    // parse changed
    // (conditionPresets w/ id that do not equal remoteConditionPreset)
    const changed = conditionPresets.filter((conditionPreset) => {
      const remoteConditionPreset = remoteConditionPresets.find(
        ({ id }) => id === conditionPreset.id,
      );

      // if both are user configured, we do not consider it a change
      // (remote has operator as null whereas local would have it as undefined, hense why below isEqual does not work)
      const areBothUserConfigured =
        conditionPreset.is_end_user_configured && remoteConditionPreset?.is_end_user_configured;

      return (
        remoteConditionPreset &&
        !areBothUserConfigured &&
        !isEqual(conditionPreset, remoteConditionPreset)
      );
    }) as ConditionPreset[]; // type assert b/c if changed, we know it should have all condition preset values

    setAdded(added);
    setChanged(changed);
    setDeleted(deleted);
  };

  /**
   * Get all changes, and send an api request for each change
   */
  const publishChanges = async () => {
    await Promise.all([
      // added
      ...added.map(({ common_model, normalized_key_name, value, operator }) =>
        addConditionPreset({
          body: {
            common_model,
            category,
            normalized_key_name,
            value: value || null,
            operator: operator || null,
          },
        }),
      ),

      // changed
      ...changed.map((conditionPreset) => {
        const { common_model, normalized_key_name, value, operator } = conditionPreset;

        return changeConditionPreset({
          path: `integrations/selective-sync/condition-presets/${conditionPreset.id}`,
          body: {
            common_model,
            category,
            normalized_key_name,
            value: value || null,
            operator: operator || null,
          },
        });
      }),

      // deleted
      ...deleted.map((conditionPreset) =>
        deleteConditionPreset({
          path: `integrations/selective-sync/condition-presets/${conditionPreset.id}`,
        }),
      ),
    ]);

    // refetch conditionPresets and conditionPresetMeta after changes
    refetch();
  };

  // effects
  // parse changes on every change
  useEffect(parseChanges, [conditionPresets, remoteConditionPresets]);

  return {
    publishChanges,
    changes: {
      added,
      changed,
      deleted,
    },
    hasChanges,
    publishing: addConditionLoading || changeConditionLoading || deleteConditionLoading,
  };
};

export default usePublishConditionPresetChanges;
