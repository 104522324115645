import React from "react";
import { Calculator, Webhook, Boxes, Database, Filter, Shuffle } from "lucide-react";
import {
  CONFIGURATION_LINK_PATH,
  CONFIGURATION_WEBHOOKS_PATH,
  CONFIGURATION_FIELD_MAPPINGS_PATH,
  CONFIGURATION_CUSTOM_OBJECTS_PATH,
  CONFIGURATION_DESTINATIONS_PATH,
  CONFIGURATION_SELECTIVE_SYNC_CONDITION_PRESETS_PATH,
} from "../../router/RouterUtils";
import useAppContext from "../context/useAppContext";
import useProductRestrictions from "../shared-components/hooks/useProductRestrictions";
import PortalPageHeaderWrapper from "../portal/PortalPageHeaderWrapper";
import { MergeFlagFeature, useMergeFlag } from "../shared-components/hooks/useMergeFlag";
import compact from "lodash/compact";

type Props = { children: JSX.Element | JSX.Element[] };

const ConfigurationPageWrapper = ({ children }: Props) => {
  const { productRestrictions } = useProductRestrictions();
  const { isUserPrivileged, user } = useAppContext();
  const { enabled: dataWarehouseEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_ENABLE_DATA_WAREHOUSE,
    organizationId: user.organization.id,
  });

  const { enabled: selectiveSyncEnabled } = useMergeFlag({
    feature: MergeFlagFeature.ENABLE_SELECTIVE_SYNC,
    organizationId: user.organization.id,
  });

  return (
    <PortalPageHeaderWrapper
      title="Advanced"
      subtabs={compact([
        {
          label: "Webhooks",
          destination: CONFIGURATION_WEBHOOKS_PATH,
        },
        selectiveSyncEnabled && {
          label: "Selective Sync",
          destination: CONFIGURATION_SELECTIVE_SYNC_CONDITION_PRESETS_PATH,
        },
        {
          label: "Merge Link",
          destination: CONFIGURATION_LINK_PATH,
        },
        isUserPrivileged && {
          label: "Field Mapping",
          destination: CONFIGURATION_FIELD_MAPPINGS_PATH,
        },
        isUserPrivileged &&
          productRestrictions?.are_custom_objects_enabled && {
            label: "Custom Objects",
            destination: CONFIGURATION_CUSTOM_OBJECTS_PATH,
          },
        dataWarehouseEnabled && {
          label: "Destinations",
          destination: CONFIGURATION_DESTINATIONS_PATH,
        },
      ])}
    >
      {children}
    </PortalPageHeaderWrapper>
  );
};

export default ConfigurationPageWrapper;
