import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { useMergeLink } from "@mergeapi/react-merge-link";
import React, { useState } from "react";
import { ReactComponent as MergeLink } from "../../../../../src/components/get-started/src/merge-link.svg";
import styled from "styled-components";
import useAppContext from "../../../context/useAppContext";
import { fetchCurrentUser } from "../../../../api-client/APIClient";

const StyledMergeLink = styled(MergeLink)`
  height: 12px;
  width: 12px;
`;

interface Props {
  /**
   * Called when a new account is linked via the linking flow.
   */
  linkToken: string;
  tenantConfig: {} | { tenantConfig: { apiBaseURL: string } };
  onNewLinkedAccount: (wasSuccess: boolean) => void;
  accountLimitReached?: boolean;
  freeAccountGated?: boolean;
}

const ProdMergeLinkActionButton = ({
  linkToken,
  tenantConfig,
  onNewLinkedAccount,
  freeAccountGated,
  accountLimitReached,
}: Props) => {
  const [wasSuccess, setWasSuccess] = useState(false);
  const { user, setUser } = useAppContext();
  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess: () => {
      setWasSuccess(true);
    },
    onExit: () => onNewLinkedAccount(wasSuccess),
    ...tenantConfig,
  });

  return (
    <Button
      size="sm"
      variant={ButtonVariant.TertiaryWhite}
      onClick={() => {
        setWasSuccess(false);
        open();
      }}
      disabled={accountLimitReached === true ? true : !isReady}
      leftIcon={<StyledMergeLink />}
    >
      Create test Linked Account
    </Button>
  );
};

export default ProdMergeLinkActionButton;
