import React, { useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { updateOrganizationIntegrationSettings } from "../../../api-client/organization/OrganizationIntegrationSettingsAPIClient";
import { OrganizationIntegrationSettings, PartnershipType } from "../../../models/Entities";
import type { Props as RowProps } from "./IntegrationSettingsConfigParameterRow";
import IntegrationSettingsConfigParameterRow from "./IntegrationSettingsConfigParameterRow";
import IntegrationSettingsSectionContainer from "./IntegrationSettingsSectionContainer";
import { SmallHeader, VerticallyCenteredCol } from "./IntegrationSyncFrequencySection";
import { Checkbox } from "@merge-api/merge-javascript-shared";

type Props = Pick<
  RowProps,
  "integrationName" | "modifyField" | "organizationIntegrationSettingsId"
> & {
  /**
   * These are the fields for the config parameters to include in this section
   */
  configStatusFields: Array<RowProps["fieldConfigStatus"]> | undefined;

  /**
   * If the user has the option to partner directly with the integration and allow
   * using integration credentials in Merge Link
   */
  isPartnershipCheckboxVisible: boolean;

  /**
   * The current value for the partnership checkbox, if it's visible.
   */
  isCurrentlyPartnered: boolean;

  /**
   * Something happens when we toggle the integration - this dictates it!
   */
  onIntegrationToggle: (data: OrganizationIntegrationSettings) => void;
};

const PartnershipText = styled.span`
  color: var(--gray60);
  font-size: 13px;
  line-height: 18px;
`;

/**
 * Houses all the config parameters if they exist
 */
const IntegrationSettingsConfigParametersSection = ({
  configStatusFields,
  integrationName,
  organizationIntegrationSettingsId,
  isPartnershipCheckboxVisible,
  isCurrentlyPartnered,
  onIntegrationToggle,
  modifyField,
}: Props) => {
  const statusIcon = useMemo(() => {
    // Incomplete status shows up when the config params are required and not filled out (currently required for non-partnership integrations)
    const isIncomplete =
      !isPartnershipCheckboxVisible &&
      (configStatusFields?.some((fieldConfigStatus) => !fieldConfigStatus.completed) ?? false);
    return <i className={`ml-2 fe fe-${isIncomplete ? "alert-circle red" : "check green"}`} />;
  }, [configStatusFields, isPartnershipCheckboxVisible]);

  // Calls backend and updates with the new partnership available status
  const updatePartnershipStatus = useCallback(
    (isEnabled: boolean) => {
      updateOrganizationIntegrationSettings({
        settingsId: organizationIntegrationSettingsId,
        body: {
          partnership_type: isEnabled
            ? PartnershipType.ORGANIZATION_PARTNERSHIP
            : PartnershipType.NONE,
        },
        onUpdate: onIntegrationToggle,
      });
    },
    [updateOrganizationIntegrationSettings, onIntegrationToggle, organizationIntegrationSettingsId],
  );

  if (!isPartnershipCheckboxVisible && (!configStatusFields || configStatusFields.length === 0)) {
    // Nothing visible if we're not in partnership mode and have no config params
    return null;
  }

  // Creates a row for each of the fields if there is one
  const configurationParameterRows = configStatusFields?.map((fieldConfigStatus, index) => {
    const isFirstRow = index === 0;
    return (
      <IntegrationSettingsConfigParameterRow
        key={fieldConfigStatus.field_name}
        fieldConfigStatus={fieldConfigStatus}
        isFirstRow={isFirstRow}
        integrationName={integrationName}
        modifyField={modifyField}
        organizationIntegrationSettingsId={organizationIntegrationSettingsId}
      />
    );
  });

  const nonPartnershipHeader = !isPartnershipCheckboxVisible && configStatusFields && (
    <SmallHeader className="font-semibold mb-0">
      <i className="mr-3 font-medium fe fe-box" />
      {configStatusFields.length} configuration parameter
      {configStatusFields.length > 1 ? "s " : " "}
      {statusIcon}
    </SmallHeader>
  );

  const checkbox = isPartnershipCheckboxVisible && (
    <Checkbox
      label={
        <PartnershipText className="font-semibold mb-0 ml-1">
          I&apos;m a <strong className="black">{integrationName} partner</strong> and want my users
          to authenticate using {integrationName} credentials in Merge Link
        </PartnershipText>
      }
      onChange={() => updatePartnershipStatus(!isCurrentlyPartnered)}
      checked={isCurrentlyPartnered}
    />
  );

  // Only show config parameters if there's not an available partnership, or if there is one and it's enabled
  const showsConfigParameters =
    !isPartnershipCheckboxVisible || (isPartnershipCheckboxVisible && isCurrentlyPartnered);

  return (
    <>
      <IntegrationSettingsSectionContainer
        id={`collapse-${organizationIntegrationSettingsId}-config-parameters`}
      >
        <VerticallyCenteredCol sm={12} style={{ justifyContent: "flex-start" }}>
          {nonPartnershipHeader || checkbox}
        </VerticallyCenteredCol>
      </IntegrationSettingsSectionContainer>
      {showsConfigParameters && configurationParameterRows}
    </>
  );
};

export default IntegrationSettingsConfigParametersSection;
