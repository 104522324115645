import uniqBy from "lodash/uniqBy";
import filter from "lodash/filter";
import {
  ConditionPreset,
  ConditionPresetMeta,
  ConditionPresetMetaOperator,
  ConditionType,
  USER_CONFIGURED_OPERATOR,
} from "../../../../models/Entities";
import { USER_CONFIGURED_OPERATOR_OPTION } from "./components/OperatorSelect";
import { DATE_KEY_TYPES } from "../constants";

/**
 * Get field options for a given condition preset by cross referencing its common model with meta.
 */
const _getFieldOptions = (
  conditionPreset: Partial<ConditionPreset>,
  conditionPresetMeta: ConditionPresetMeta,
) => {
  const fieldOptions =
    (conditionPreset.common_model && conditionPresetMeta[conditionPreset.common_model]) || [];

  return fieldOptions;
};

/**
 * Get field options for a given condition preset, and filters out any fields that already exist in other rows
 * to prevent creating duplicate filters.
 */
export const getFilteredFieldOptions = (
  conditionPreset: Partial<ConditionPreset>,
  conditionPresetMeta: ConditionPresetMeta,
  conditionPresets: Partial<ConditionPreset>[],
) => {
  const fieldOptions = _getFieldOptions(conditionPreset, conditionPresetMeta);

  return fieldOptions.filter(({ normalized_key_name }) => {
    const isFieldValue = normalized_key_name == conditionPreset.normalized_key_name;

    const isNotUsed = conditionPresets.every((l) => normalized_key_name !== l.normalized_key_name);

    return isFieldValue || isNotUsed;
  });
};

/**
 * Get list of available common models. Only returns models with at least one filtered field option.
 */
export const getFilteredModelOptions = (
  conditionPreset: Partial<ConditionPreset>,
  conditionPresetMeta: ConditionPresetMeta,
  conditionPresets: Partial<ConditionPreset>[],
) => {
  const commonModelOptions = Object.keys(conditionPresetMeta);

  return commonModelOptions.filter((commonModel) => {
    const isModelValue = commonModel === conditionPreset.common_model;

    const isNotUsed = !!getFilteredFieldOptions(
      { common_model: commonModel },
      conditionPresetMeta,
      conditionPresets,
    ).length;

    return isModelValue || isNotUsed;
  });
};

/**
 * Get the field value for a given condition preset by cross referencing with the available field options.
 */
export const getFieldValue = (
  conditionPreset: Partial<ConditionPreset>,
  conditionPresetMeta: ConditionPresetMeta,
) => {
  const fieldOptions = _getFieldOptions(conditionPreset, conditionPresetMeta);

  const fieldValue = fieldOptions.find(
    ({ normalized_key_name }) => normalized_key_name == conditionPreset.normalized_key_name,
  );

  return fieldValue;
};

/**
 * Get operator options for a given condition preset by cross referencing with meta
 */
export const getOperatorOptions = (
  conditionPreset: Partial<ConditionPreset>,
  conditionPresetMeta: ConditionPresetMeta,
) => {
  const fieldValue = getFieldValue(conditionPreset, conditionPresetMeta);

  // For now, we only support condition preset operators for a date condition type.
  // This is b/c other values are difficult to normalize across integrations.
  return [
    ...filter(
      fieldValue?.operators,
      ({ key_type }) => key_type === ConditionType.DATE || key_type === ConditionType.DATE_TIME,
    ),
    USER_CONFIGURED_OPERATOR_OPTION,
  ];
};

/**
 * Get operator value from a given condition preset by cross referencing with operator options
 */
export const getOperatorValue = (
  conditionPreset: Partial<ConditionPreset>,
  conditionPresetMeta: ConditionPresetMeta,
) => {
  const operatorOptions = getOperatorOptions(conditionPreset, conditionPresetMeta);

  const operatorValue = operatorOptions.find((option) => {
    // user configured has no actual operator in db, but we replace it with one to render on client
    if (conditionPreset.is_end_user_configured) {
      return option.operator === USER_CONFIGURED_OPERATOR;
    }
    return option.operator === conditionPreset.operator;
  });

  return operatorValue;
};

/**
 * Returns true if operator value key_type is date or date_time,
 * Both of which are treated as date on frontend.
 */
export const getOperatorValueIsDate = (operatorValue: ConditionPresetMetaOperator | undefined) => {
  if (!operatorValue || !operatorValue.key_type) return false;
  return DATE_KEY_TYPES.includes(operatorValue.key_type);
};

/**
 * Get all supported integrations for a given conditionPreset. Cross references meta w/ a given condition preset.
 */
export const getSupportedIntegrations = (
  conditionPreset: Partial<ConditionPreset>,
  conditionPresetMeta: ConditionPresetMeta,
) => {
  const fieldValue = getFieldValue(conditionPreset, conditionPresetMeta);
  const operatorValue = getOperatorValue(conditionPreset, conditionPresetMeta);

  // combine supported_integrations for each operator, removing duplicates
  const allOperatorIntegrations = uniqBy(
    fieldValue?.operators.flatMap(({ supported_integrations }) => supported_integrations),
    "id",
  );

  // For USER_CONFIGURED, we show all supported integrations for ALL operators
  // otherwise we just return the supported_integrations tied to the specific operator chosen
  const supportedIntegrations =
    operatorValue?.operator == USER_CONFIGURED_OPERATOR
      ? allOperatorIntegrations
      : operatorValue?.supported_integrations || [];

  return supportedIntegrations;
};
