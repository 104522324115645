import React from "react";
import { DashboardOnboardingStatus } from "../get-started/DashboardOnboardingWrapper";

interface GetStartedTaskCompleteCounterProps {
  dashboardOnboardingStatus: DashboardOnboardingStatus | null;
  isDefaultOffScopesEnabled: boolean | undefined;
}

const GetStartedTaskCompleteCounter = ({
  dashboardOnboardingStatus,
  isDefaultOffScopesEnabled,
}: GetStartedTaskCompleteCounterProps) => {
  const dashboardOnboardingStatuses = [
    dashboardOnboardingStatus?.has_made_request,
    dashboardOnboardingStatus?.has_successfully_linked_account,
    dashboardOnboardingStatus?.has_pulled_common_model_data,
    dashboardOnboardingStatus?.has_opened_production_link,
  ];

  if (isDefaultOffScopesEnabled) {
    dashboardOnboardingStatuses.push(dashboardOnboardingStatus?.has_toggled_scopes);
  }

  return (
    <span>
      <text className="text-sm font-medium text-gray-60">
        {dashboardOnboardingStatuses.filter((value) => value).length} /{" "}
        {dashboardOnboardingStatuses.length}
      </text>
    </span>
  );
};

export default GetStartedTaskCompleteCounter;
