import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";
import cx from "classnames";
import { useForm } from "react-hook-form";
import { fetchWithoutAuth, FormErrorData, hasAuthToken } from "../../../api-client/APIClient";
import SpinnerButton from "../../shared-components/SpinnerButton";
import { showErrorToast } from "../../shared-components/Toasts";
import useTimeout from "../../shared-components/hooks/useTimeout";
import {
  LANDING_PAGE_PATH,
  LOGIN_PATH,
  useQuery,
  navigateToLoginPage,
} from "../../../router/RouterUtils";
import MergeLogo from "../../../assets/svg/logos/merge/logo-large.svg";
import { useMediaQuery } from "react-responsive";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { SEC_PASSWORD_RESET_AUTO_REDIRECT } from "../../../constants";
import DeprecatedH1 from "../../../deprecated/DeprecatedH1";

const Title = styled(DeprecatedH1)`
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
`;

interface FormData {
  new_password: string;
}

function ResetPasswordScreen() {
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });

  const query = useQuery();
  const email = query.get("email");
  const token = query.get("token");

  const history = useHistory();

  const [isErrorShown, setIsErrorShown] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetComplete, setResetComplete] = useState(false);
  const { register, handleSubmit, setError, errors } = useForm();

  const resetPassword = (data: FormData) => {
    setIsLoading(true);

    const userInfo = {
      email,
      password: data.new_password,
      email_token: token,
    };

    fetchWithoutAuth({
      path: "/users/password/reset",
      method: "POST",
      body: userInfo,
      onResponse: (_) => {
        setIsLoading(false);
        setResetComplete(true);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((data: FormErrorData) => {
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                setIsErrorShown(data[field_name][0]);
                continue;
              }
              setError(field_name, { message: data[field_name][0] });
            }
          });
        }
        showErrorToast("Failed to reset password. Please try again.");
        setIsLoading(false);
      },
    });
  };

  useTimeout(
    () => {
      navigateToLoginPage(history);
    },
    resetComplete ? SEC_PASSWORD_RESET_AUTO_REDIRECT : null,
  );

  return (
    <>
      {hasAuthToken() ? <Redirect to="/" /> : null}
      <div className="ResetPassword">
        <div
          className={cx(
            "ForgotPassword-header d-flex align-items-center",
            isDesktopOrTablet ? "justify-content-between" : "justify-content-center",
          )}
        >
          <a href={LANDING_PAGE_PATH}>
            <img src={MergeLogo} />
          </a>
          {isDesktopOrTablet && !resetComplete && <Link to={LOGIN_PATH}>Back to login</Link>}
        </div>

        <div className="ResetPassword-form d-flex flex-column align-items-center justify-content-center">
          <div className="px-6 col-12 col-md-5 col-xl-4 my-9">
            <Title className="text-center mb-9">Reset password</Title>

            <p
              className="mb-9 text-center"
              style={{ whiteSpace: "pre-line" }}
              hidden={!resetComplete}
            >
              {"Your password was successfully reset." +
                "\n\n" +
                `Redirecting to login screen in ${SEC_PASSWORD_RESET_AUTO_REDIRECT}...`}
            </p>

            <Form onSubmit={handleSubmit(resetPassword)} hidden={resetComplete}>
              {isErrorShown && <Alert variant="primary">{isErrorShown}</Alert>}
              <Form.Group>
                <Form.Label>New password</Form.Label>
                <Form.Control
                  name="new_password"
                  type="password"
                  placeholder="New password"
                  className={cx({ "is-invalid": errors.new_password })}
                  ref={register({
                    required: true,
                    minLength: 8,
                    maxLength: 100,
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid password at least 8 characters in length
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="text-center">
                <SpinnerButton
                  text="Reset password"
                  isLoading={isLoading}
                  className="primaryAction btn btn-primary btn-block"
                />
              </Form.Group>
              {!isDesktopOrTablet && (
                <Row>
                  <Col className="text-center">
                    <Link to={LOGIN_PATH}>Back to login</Link>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
          <div
            className="px-6 col-12 col-md-5 col-xl-4 my-9"
            hidden={!resetComplete}
            style={{ paddingTop: "20vh" }}
          >
            <p className="mb-9 text-center">
              <Link to={LOGIN_PATH}>Skip to login screen</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPasswordScreen;
