import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import { conditionPresetOperatorMap } from "./OperatorSelect";
import { USER_CONFIGURED_OPERATOR } from "../../../../../models/Entities";

const UserConfiguredPlaceholder = () => {
  return (
    <div className="text-gray-40 flex flex-1">
      <Text className="text-gray-40 flex flex-1 pl-3">
        {conditionPresetOperatorMap[USER_CONFIGURED_OPERATOR].title}
      </Text>
    </div>
  );
};

export default UserConfiguredPlaceholder;
