import { Card, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FieldMappingTarget,
  FieldMappingCommonModelConfiguration,
} from "../../../../models/Entities";
import React, { useState } from "react";
import { Globe, Plus } from "lucide-react";
import ConfigurationFieldMappingCardRow from "./ConfigurationFieldMappingCardRow";
import {
  addNewFieldMappingTarget,
  patchFieldMappingConfiguration,
} from "./utils/FieldMappingUtils";
import ConfigurationFieldMappingCardEditSection from "./ConfigurationFieldMappingCardEditSection";
import styled from "styled-components";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../router/RouterUtils";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import { Link } from "react-router-dom";
import { APICategory, Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import useDeprecatedCommonModel from "../../../../hooks/useDeprecatedCommonModel";
import { DeprecationStage } from "../../../../constants";

const TooltipTextV2 = styled.b`
  color: #939aa5;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const RemoteDataDisabledTextContainer = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
`;
export const RemoteDataDisabledText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
`;

export const LongLine = styled.hr`
  margin-left: -24px;
  margin-right: -24px;
`;

type Props = {
  category: APICategory;
  commonModelID: string;
  fieldMappingTargets: FieldMappingTarget[];
  commonModelsToMappingConfig?: FieldMappingCommonModelConfiguration;
  updateFieldMappingConfigurations: (
    updatedConfiguration: FieldMappingCommonModelConfiguration,
  ) => void;
  updateFieldMappingTargets: (updatedFieldMappingTarget: FieldMappingTarget) => void;
  refreshFieldMappingsAndConfigurations: () => void;
  setIsLoading: (isLoading: boolean) => void;
  setFieldMappingTargetToDelete: (customMappingTarget: FieldMappingTarget) => void;
  setHasError: (hasError: boolean) => void;
};

const ConfigurationFieldMappingCard = ({
  category,
  commonModelID,
  fieldMappingTargets,
  commonModelsToMappingConfig,
  updateFieldMappingConfigurations,
  updateFieldMappingTargets,
  refreshFieldMappingsAndConfigurations,
  setIsLoading,
  setFieldMappingTargetToDelete,
  setHasError,
}: Props) => {
  const [isAddingNewFieldMappingTarget, setIsAddingNewFieldMappingTarget] = useState(false);
  const commonModelName = commonModelID.split(".")[1];

  // hooks
  const { deprecationStage, showDeprecatedSunsetItems } = useDeprecatedCommonModel({
    category,
    commonModel: commonModelName,
  });

  const addFieldMappingTarget = (fieldKey: string, fieldDescription: string) => {
    setIsLoading(true);
    addNewFieldMappingTarget({
      body: {
        common_model_id: commonModelID,
        field_key: fieldKey,
        field_description: fieldDescription,
      },
      category,
      onResponse: () => {
        setIsAddingNewFieldMappingTarget(false);
        refreshFieldMappingsAndConfigurations();
      },
      onError: () => {
        setHasError(true);
        setIsAddingNewFieldMappingTarget(false);
      },
    });
  };

  const toggleCustomMappingConfigUserCreatedCustomFields = (
    config: FieldMappingCommonModelConfiguration,
    new_allow_user_created_field_mapping_targets: string,
  ) => {
    patchFieldMappingConfiguration({
      body: {
        configuration_id: config.id,
        allow_user_created_field_mapping_targets:
          new_allow_user_created_field_mapping_targets === "enabled",
      },
      category,
      onResponse: (response: FieldMappingCommonModelConfiguration) => {
        updateFieldMappingConfigurations(response);
      },
      onError: () => {
        setHasError(true);
      },
    });
  };

  const alphabeticalSort = (a: FieldMappingTarget, b: FieldMappingTarget) =>
    a.field_key.localeCompare(b.field_key);

  const allTargetNames: string[] = fieldMappingTargets.map((target) => target.field_key);

  if (deprecationStage === DeprecationStage.SUNSET && !showDeprecatedSunsetItems) {
    return null;
  }

  return (
    <Card>
      <div className="h-2 bg-orange-20 rounded-t-lg"></div>
      <Card.Body className="py-5 pt-3 pr-0 pl-0">
        <div className="d-flex justify-content-between align-items-center pl-6 pr-6 pb-5 border-b border-gray-10">
          <div className="d-flex">
            <Globe size={16} className="text-orange-50" />
            <DeprecatedH4 className="mb-1 ml-2">{commonModelName}</DeprecatedH4>
          </div>
          <div>
            <Col className="d-flex align-items-center pr-1.5">
              <TooltipTextV2 className="mr-1 mb-0 d-inline-block">
                End user-created target fields
              </TooltipTextV2>
              <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 0 }}
                overlay={
                  <Tooltip id="api-requests-include-outbound-tooltip">
                    Allow your users to create Linked Account-specific custom fields in Link
                  </Tooltip>
                }
              >
                <i className="text-right fe fe-info text-gray-50 mr-1" />
              </OverlayTrigger>
              {commonModelsToMappingConfig && (
                <span className="ml-auto field-mapping-toggle">
                  <Form.Check
                    type="switch"
                    id={`field-mapping-toggle-for` + commonModelName}
                    checked={commonModelsToMappingConfig.allow_user_created_field_mapping_targets}
                    className="small"
                    onClick={() => {
                      toggleCustomMappingConfigUserCreatedCustomFields(
                        commonModelsToMappingConfig,
                        commonModelsToMappingConfig.allow_user_created_field_mapping_targets
                          ? "disabled"
                          : "enabled",
                      );
                    }}
                  />
                </span>
              )}
            </Col>
          </div>
        </div>
        {fieldMappingTargets.sort(alphabeticalSort).map((customMappingTarget) => (
          <ConfigurationFieldMappingCardRow
            key={customMappingTarget.id}
            fieldMappingTarget={customMappingTarget}
            refreshFieldMappingsAndConfigurations={refreshFieldMappingsAndConfigurations}
            setFieldMappingTargetToDelete={setFieldMappingTargetToDelete}
            updateFieldMappingTargets={updateFieldMappingTargets}
            setHasError={setHasError}
            remoteDataEnabled={commonModelsToMappingConfig?.has_remote_data_enabled || false}
          />
        ))}
        {commonModelsToMappingConfig?.has_remote_data_enabled ? (
          <div className="pl-6 pt-5 pr-6">
            {!isAddingNewFieldMappingTarget && (
              <Button
                variant={ButtonVariant.TertiaryWhite}
                size="sm"
                leftIcon={<Plus size={12} />}
                onClick={() => setIsAddingNewFieldMappingTarget(true)}
                className="mt-1"
              >
                Target Field
              </Button>
            )}
            {isAddingNewFieldMappingTarget && (
              <ConfigurationFieldMappingCardEditSection
                isCreatingNewField
                allTargetNames={allTargetNames}
                onSave={addFieldMappingTarget}
                onCancel={() => setIsAddingNewFieldMappingTarget(false)}
              />
            )}
          </div>
        ) : (
          <div className="pl-6 pr-6 pt-5">
            <RemoteDataDisabledTextContainer>
              <RemoteDataDisabledText>
                <>
                  <Link
                    to={CONFIGURATION_COMMON_MODELS_PATH + `/${category}` + `/#${commonModelName}`}
                  >
                    Enable Remote Data
                  </Link>
                  {"  "}
                  to create target fields and mappings for this Common Model.
                </>
              </RemoteDataDisabledText>
            </RemoteDataDisabledTextContainer>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ConfigurationFieldMappingCard;
