import React, { useState } from "react";
import { fetchCurrentUser, toggleRedactUnmappedData } from "../../../api-client/APIClient";
import useAppContext from "../../context/useAppContext";
import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";

type Props = {
  isRedactionCurrentlyEnabledForOrg: boolean;
  organizationId: string;
  isModalOpen: boolean;
  onHide: () => void;
  onConfirmResponse: () => void;
};

const RedactUnmappedDataModal = ({
  isRedactionCurrentlyEnabledForOrg,
  organizationId,
  isModalOpen,
  onHide,
  onConfirmResponse,
}: Props) => {
  const { setUser } = useAppContext();
  const [isToggling, setIsToggling] = useState(false);

  const _onConfirmResponse = () => {
    // Once the setting is toggled, we need to refetch the user to get the updated redaction status.
    // Else, the data in the AppContext's user will go stale until the user is refetched (e.g. via a page refresh).
    fetchCurrentUser(setUser);

    setIsToggling(false);
    onConfirmResponse();
  };

  const _onConfirmError = () => {
    setIsToggling(false);
    onConfirmResponse();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={onHide}
      onPrimaryButtonClick={() => {
        setIsToggling(true);
        toggleRedactUnmappedData(
          !isRedactionCurrentlyEnabledForOrg,
          organizationId,
          undefined,
          _onConfirmResponse,
          _onConfirmError,
        );
      }}
      primaryButtonVariant={ButtonVariant.DangerFilled}
      primaryButtonText="Confirm"
      primaryButtonLoading={isToggling}
      onSecondaryButtonClick={onHide}
      secondaryButtonText="Cancel"
      title={
        isRedactionCurrentlyEnabledForOrg
          ? "Disable redacting unmapped data"
          : "Enable redacting unmapped data"
      }
      variant="md"
    >
      This will apply to all existing and new Linked Accounts. Do you wish to continue?
    </Dialog>
  );
};

export default RedactUnmappedDataModal;
