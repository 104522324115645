import React from "react";
import { Col, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { FieldMappingTarget } from "../../../../models/Entities";
import { ReactComponent as StarSVG } from "../../../../styles/css/img/star.svg";
import { ReactComponent as StarfilledSVG } from "../../../../styles/css/img/starfilled.svg";
import { patchFieldMappingTarget } from "./utils/FieldMappingUtils";
import { Link } from "react-router-dom";
import { getFieldMappingSettingsPathForId } from "../../../../router/RouterUtils";
import { EllipsesToggle } from "../../../shared-components/MergeToggles";
import { isFeatureEnabled } from "../../../../router/ComponentForFeature";
import useAppContext from "../../../context/useAppContext";
import { INTEGRATION_WIDE_FIELD_MAPPING_ENABLED_FLAG } from "../../../../constants";
import { NoFieldMappingTableText } from "./ConfigurationFieldMappingLinkedAccountTable";

type Props = {
  fieldMappingTarget: FieldMappingTarget;
  updateFieldMappingTargets: (updatedFieldMappingTarget: FieldMappingTarget) => void;
  refreshFieldMappingsAndConfigurations: () => void;
  setFieldMappingTargetToDelete: (fieldMappingTarget: FieldMappingTarget) => void;
  setHasError: (hasError: boolean) => void;
  remoteDataEnabled: boolean;
};

const EnabledStarToggle = styled(StarfilledSVG)`
  margin-bottom: 4px;
`;

const DisabledStarToggle = styled(StarSVG)`
  margin-bottom: 4px;
`;

const ChevronRight = styled(Col)`
  text-align: right !important;
`;

const MinHeightDiv = styled.div`
  min-height: 46px;
`;

const ConfigurationFieldMappingCardRow = ({
  fieldMappingTarget,
  updateFieldMappingTargets,
  setFieldMappingTargetToDelete,
  setHasError,
  remoteDataEnabled,
}: Props) => {
  const { field_key, field_description, weight, id } = fieldMappingTarget;
  const { user } = useAppContext();
  const isIntegrationsWideFieldMappingsEnabled = isFeatureEnabled(
    INTEGRATION_WIDE_FIELD_MAPPING_ENABLED_FLAG,
    user,
  );
  const toggleFieldMappingConfigIsMappableInLink = (
    fieldMappingTarget: FieldMappingTarget,
    new_is_mappable_setting: boolean,
    new_weight: number,
  ) => {
    patchFieldMappingTarget({
      body: {
        is_mappable_in_link: new_is_mappable_setting,
        weight: new_weight,
      },
      fieldMappingTargetId: fieldMappingTarget.id,
      onResponse: (response: FieldMappingTarget) => {
        updateFieldMappingTargets(response);
      },
      onError: () => {
        setHasError(true);
      },
    });
  };
  const isClickable = isIntegrationsWideFieldMappingsEnabled && remoteDataEnabled;
  const ClickableRow = (props: { isClickable: boolean; children: React.ReactNode }) => {
    return (
      <>
        {props.isClickable ? (
          <Link to={getFieldMappingSettingsPathForId(id)} className="text-gray-50 ">
            {props.children}
          </Link>
        ) : (
          props.children
        )}
      </>
    );
  };

  return (
    <ClickableRow isClickable={isClickable}>
      <MinHeightDiv className="d-flex align-items-center justify-content-between p-6 pt-5 pb-5 border-b border-gray-10 hover:bg-[#f9fbfd]">
        <Col className="col-7 pl-0">
          <div className="configuration-fieldmappings-key-text-v2 d-flex align-items-center">
            {field_key}
            {!isIntegrationsWideFieldMappingsEnabled && (
              <div className="star-toggle-container ml-1">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 0 }}
                  overlay={
                    <Tooltip id="api-requests-include-outbound-tooltip">
                      Starred fields will appear first in Link
                    </Tooltip>
                  }
                >
                  {weight > 0 ? (
                    <EnabledStarToggle
                      onClick={() => {
                        toggleFieldMappingConfigIsMappableInLink(
                          fieldMappingTarget,
                          fieldMappingTarget.is_mappable_in_link,
                          0,
                        );
                      }}
                    />
                  ) : (
                    <DisabledStarToggle
                      onClick={() => {
                        toggleFieldMappingConfigIsMappableInLink(
                          fieldMappingTarget,
                          fieldMappingTarget.is_mappable_in_link,
                          1,
                        );
                      }}
                    />
                  )}
                </OverlayTrigger>
              </div>
            )}
          </div>
          <div className="configuration-fieldmappings-description-text-v2">{field_description}</div>
        </Col>
        <ChevronRight className="ml-auto d-flex justify-content-end items-center pr-0">
          <div className="mr-6">
            {fieldMappingTarget.total_int_wide_field_mappings > 0 ? (
              <div className="text-black text-[10px] font-semibold">
                {fieldMappingTarget.total_int_wide_field_mappings === 1
                  ? `${fieldMappingTarget.total_int_wide_field_mappings} Field Mapping`
                  : `${fieldMappingTarget.total_int_wide_field_mappings} Field Mappings`}{" "}
              </div>
            ) : (
              <NoFieldMappingTableText> 0 Field Mappings</NoFieldMappingTableText>
            )}
          </div>
          <div className="p-0">
            {isClickable ? (
              <span className="fe fe-chevron-right text-gray-60 text-[12px]"></span>
            ) : (
              <Dropdown>
                <Dropdown.Toggle as={EllipsesToggle} id="dropdown-custom-components">
                  <i className="fe fe-more-horizontal black" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-ellipses dropdown-toggle">
                  <Dropdown.Item
                    className="ellipses-dropdown-item dropdown-item"
                    onSelect={() => setFieldMappingTargetToDelete(fieldMappingTarget)}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </ChevronRight>
      </MinHeightDiv>
    </ClickableRow>
  );
};

export default ConfigurationFieldMappingCardRow;
