import React from "react";
import clsx from "clsx";
import { useRequest } from "../../../../../shared-components/hooks/useRequest";
import { UnreleasedAPICategory, Text, Spinner } from "@merge-api/merge-javascript-shared";
import { SyncConnectionIntegration } from "../../../../../../models/Entities";
import IntegrationsList from "./components/IntegrationsList";
import { MergeLinkNavButtons } from "../../../../../common/MergeLinkDialog/components";

type IntegrationStepProps = {
  className?: string;
  onClose: () => void;
  onIntegrationSelect: (integration: SyncConnectionIntegration) => void;
};

const IntegrationStep = ({ className, onClose, onIntegrationSelect }: IntegrationStepProps) => {
  const { data: integrations, loading } = useRequest<SyncConnectionIntegration[]>({
    path: `integrations/sync-connection-integrations/${UnreleasedAPICategory.datawarehouse}`,
    errorText: "Failed to load integrations! Please try again.",
  });

  return (
    <div className={clsx("flex flex-col h-full mt-5", className)}>
      {/* X Button */}
      <MergeLinkNavButtons onClose={onClose} />

      {/* Header */}
      <Text variant="h3" className="mt-3 px-5">
        Select integration
      </Text>

      {/* Integerations */}
      {loading || !integrations ? (
        <div className="flex justify-center items-center h-full">
          <Spinner size={8} />
        </div>
      ) : (
        <IntegrationsList integrations={integrations} onIntegrationSelect={onIntegrationSelect} />
      )}
    </div>
  );
};

export default IntegrationStep;
