import React from "react";
import { ConditionPreset, ConditionPresetMeta } from "../../../../../models/Entities";
import { Select } from "@merge-api/merge-javascript-shared";
import { getFilteredModelOptions } from "../helpers";
import startCase from "lodash/startCase";

type CommonModelSelectProps = {
  conditionPreset: Partial<ConditionPreset>;
  conditionPresetMeta: ConditionPresetMeta;
  conditionPresets: Partial<ConditionPreset>[];
  onConditionPresetChange: (ConditionPreset: Partial<ConditionPreset>) => void;
};

const CommonModelSelect = ({
  conditionPreset,
  conditionPresetMeta,
  conditionPresets,
  onConditionPresetChange,
}: CommonModelSelectProps) => {
  // derived state
  const commonModelOptions = getFilteredModelOptions(
    conditionPreset,
    conditionPresetMeta,
    conditionPresets,
  );

  // event handlers
  const onCommonModelChange = (_: any, commonModel: string | null) => {
    onConditionPresetChange({
      ...conditionPreset,
      common_model: commonModel || undefined,

      // reset other fields
      normalized_key_name: undefined,
      operator: undefined,
      value: undefined,
      is_end_user_configured: false,
    });
  };

  return (
    <Select
      className="flex flex-1 h-full py-[3px]"
      placeholder="Select model..."
      noOptionsText="No filters available"
      shadowHidden
      options={commonModelOptions}
      getOptionLabel={(option) => startCase(option)}
      clearable={false}
      value={conditionPreset.common_model}
      onChange={onCommonModelChange}
    />
  );
};

export default CommonModelSelect;
