import React from "react";
import { FieldMappingCreationAndEditDict } from "../../../../../models/Entities";
import { Tooltip, Badge, capitalizeFirstLetter } from "@merge-api/merge-javascript-shared";
import { DropdownItem } from "./FieldMappingInstanceCard";

type RemoteFieldOptionProps = {
  fieldMapping: FieldMappingCreationAndEditDict;
};
const RemoteFieldOption = ({ fieldMapping }: RemoteFieldOptionProps) => {
  let fieldMappingExample = fieldMapping.value ?? null;

  if (fieldMappingExample !== null) {
    if (typeof fieldMappingExample === "object") {
      fieldMappingExample = JSON.stringify(fieldMappingExample);
    } else {
      fieldMappingExample = String(fieldMappingExample);
    }
  }
  const coveragePercentage =
    fieldMapping?.percentage_availability == 0 ? 1 : fieldMapping?.percentage_availability;

  return (
    <DropdownItem
      key={fieldMappingExample}
      className="d-flex flex-column justify-content-between align-content-center dropdown-menu-options max-w-full"
    >
      <div>
        <div>
          <div className="flex items-center">
            <div className="text-gray-50 text-sm min-w-[58px]">Field:</div>
            <div className="flex items-end ml-3 max-w-full truncate ">
              <Tooltip title={fieldMapping.display_name} className="truncate">
                <div className=" !text-black text-ellipsis truncate">
                  {fieldMapping.display_name}
                </div>
              </Tooltip>
              <div className="text-sm text-gray-50 ml-2">
                {capitalizeFirstLetter(fieldMapping.format || fieldMapping.type)}
              </div>
              {fieldMapping?.commonModelName && (
                <div className="ml-2">
                  <Badge size="sm" color="gray">
                    {fieldMapping.commonModelName}
                  </Badge>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-1.5">
          <div className="flex items-center">
            <div className="text-gray-50 text-sm min-w-[58px]">Example:</div>
            {fieldMappingExample ? (
              <Tooltip title={fieldMappingExample} className="truncate">
                <div className="ml-3 text-gray-50 truncate">{fieldMappingExample}</div>
              </Tooltip>
            ) : (
              <div className="ml-3">
                <Badge color="blue" size="sm">
                  N/A
                </Badge>
              </div>
            )}
          </div>
        </div>
        <div className="mt-1.5">
          <div className="flex items-center">
            <div className="text-gray-50 text-sm w-[58px]">Coverage:</div>
            <div className="ml-3">
              <Badge color="blue" size="sm">
                {coveragePercentage ? `${coveragePercentage}%` : "N/A"}
              </Badge>
            </div>
          </div>
        </div>
      </div>
    </DropdownItem>
  );
};

export default RemoteFieldOption;
