import React from "react";
import DestinationPageData from "./DestinationPageData";
import DestinationPageHeader from "./DestinationPageHeader";
import useAppContext from "../../context/useAppContext";
import { useMergeFlag, MergeFlagFeature } from "../../shared-components/hooks/useMergeFlag";
import { useHistory } from "react-router-dom";
import { CONFIGURATION_WEBHOOKS_EMITTERS_PATH } from "../../../router/RouterUtils";

const ConfigurationDestinations = () => {
  // hooks
  const { user } = useAppContext();
  const history = useHistory();
  const { enabled: dataWarehouseEnabled, hasInitialized } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_ENABLE_DATA_WAREHOUSE,
    organizationId: user.organization.id,
  });

  if (!dataWarehouseEnabled && hasInitialized) {
    history.replace(CONFIGURATION_WEBHOOKS_EMITTERS_PATH);
  }

  return (
    <div className="flex flex-col">
      <DestinationPageHeader />
      <DestinationPageData />
    </div>
  );
};

export default ConfigurationDestinations;
