import React from "react";

type Props = {
  originField: string;
  originType: string;
  remoteEndpointMethod: string;
  remoteEndpointPath: string;
};

const FieldMappingDropdownChild = ({
  originField,
  originType,
  remoteEndpointMethod,
  remoteEndpointPath,
}: Props) => {
  return (
    <div className="flex flex-col w-full px-3 py-2 gap-2 hover:bg-gray0 justify-center break-words whitespace-normal break-all">
      <div className="flex flex-col gap-3">
        <div className="text-black font-mono text-[14px] font-medium leading-[20px] break-all">
          {originField}
        </div>
        <div className="text-gray-60 font-mono text-[12px] font-medium leading-[16px] mt-0.5">
          {originType}
        </div>
      </div>
      <div className="flex flex-col gap-1.5">
        <div className="text-gray-60 font-mono font-inter text-[10px] font-semibold leading-[22px]">
          {remoteEndpointMethod}
        </div>
        <div className="text-gray-60 font-mono font-inter text-[12px] font-normal leading-[22px] truncate">
          {remoteEndpointPath}
        </div>
      </div>
    </div>
  );
};

export default FieldMappingDropdownChild;
