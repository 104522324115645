import React from "react";
import MergeTable from "../../../shared-components/MergeTable";
import TableSkeletonLoader from "../../../integrations-management/shared/TableSkeletonLoader";

const AuditLogsLoadingStateTable = () => {
  return (
    <>
      <MergeTable
        hasMorePaddingOnFirstElement
        hover={false}
        header={
          <>
            <th scope="col">User</th>
            <th scope="col">Role</th>
            <th scope="col">Event description</th>
            <th scope="col">IP address</th>
            <th scope="col">Date</th>
          </>
        }
        content={<TableSkeletonLoader cols={5} rows={8} variant="static" size="large" />}
      />
    </>
  );
};

export default AuditLogsLoadingStateTable;
