import React, { useEffect, useState } from "react";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
} from "../../../../../../../../models/Entities";
import { TextField } from "@merge-api/merge-javascript-shared";
import { getOperatorValue, getOperatorValueIsDate } from "../helpers";
import { useDebouncedCallback } from "use-debounce";
import isUndefined from "lodash/isUndefined";
import { LIST_CONDITION_OPERATORS } from "../../../../../../../configuration/selective-sync/constants";

type ValueTextFieldProps = {
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  onLinkedAccountConditionChange: (LinkedAccountCondition: Partial<LinkedAccountCondition>) => void;
};

const ValueTextField = ({
  linkedAccountCondition,
  onLinkedAccountConditionChange,
  linkedAccountConditionMeta,
}: ValueTextFieldProps) => {
  // state
  const [value, setValue] = useState(linkedAccountCondition.value ?? undefined);

  // derived state
  const operatorValue = getOperatorValue(linkedAccountCondition, linkedAccountConditionMeta);
  const isDate = getOperatorValueIsDate(operatorValue);
  const isList = operatorValue && LIST_CONDITION_OPERATORS.includes(operatorValue.operator);

  // effects
  // update value when updated from parent component
  useEffect(() => {
    if (isUndefined(linkedAccountCondition.value)) {
      setValue(""); // set as empty string to clear date
    }
  }, [linkedAccountCondition.value]);

  const debouncedOnLinkedAccountConditionChange = useDebouncedCallback((value) => {
    onLinkedAccountConditionChange({
      ...linkedAccountCondition,
      value,
    });
  }, 350);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedOnLinkedAccountConditionChange(e.target.value);
  };

  return (
    <TextField
      variant="inlined-rounded"
      placeholder={isList ? "Enter comma-separated list..." : "Enter value..."}
      className="flex flex-1 w-full h-full py-[3px]"
      inputWrapperClassName="w-full"
      {...(isDate && { type: "date" })}
      value={value}
      onChange={onChange}
    />
  );
};

export default ValueTextField;
