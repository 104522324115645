import React, { Dispatch, SetStateAction } from "react";
import useAppContext from "../../../../context/useAppContext";
import { Typeahead } from "@merge-api/merge-javascript-shared";
import {
  FieldMappingOptionsResponse,
  FieldMappingCreationAndEditDict,
  FieldMappingCommonModelConfiguration,
} from "../../../../../models/Entities";
import { createFieldMappingOptionsForAllModels } from "../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";
import RemoteFieldOption from "./RemoteFieldOption";
type RemoteFieldSearchBarProps = {
  linkedAccountFieldMappingOptions: FieldMappingOptionsResponse | undefined;
  setRemoteFieldSelected: Dispatch<SetStateAction<FieldMappingCreationAndEditDict | null>>;
  remoteFieldSelected: FieldMappingCreationAndEditDict | null;
  fieldMappingConfigurations?: Array<FieldMappingCommonModelConfiguration> | null;
};

const RemoteFieldSearchBar = ({
  linkedAccountFieldMappingOptions,
  setRemoteFieldSelected,
  remoteFieldSelected,
  fieldMappingConfigurations,
}: RemoteFieldSearchBarProps) => {
  const availableFieldMappings =
    linkedAccountFieldMappingOptions?.remote_field_options || undefined;

  const isLoading = availableFieldMappings == undefined || fieldMappingConfigurations == null;

  return (
    <div className="flex-1">
      <Typeahead
        value={remoteFieldSelected}
        options={
          !isLoading
            ? createFieldMappingOptionsForAllModels(
                availableFieldMappings!,
                fieldMappingConfigurations!,
              )
            : []
        }
        loading={isLoading}
        onChange={(_, selectedRemoteField: any) => {
          if (selectedRemoteField) {
            setRemoteFieldSelected(selectedRemoteField);
          } else {
            setRemoteFieldSelected(null);
          }
        }}
        placeholder={isLoading ? "Loading remote fields..." : "Search remote fields..."}
        loadingText="Loading remote fields..."
        getOptionLabel={(option: any) => {
          return option?.display_name ?? "";
        }}
        renderOption={(fieldMapping: any) => {
          return <RemoteFieldOption fieldMapping={fieldMapping} />;
        }}
        multiple={false}
        filterOptions={(options: any, { inputValue }: any) => {
          return options.filter((option: FieldMappingCreationAndEditDict) => {
            const inputValueToLowerCase = inputValue.toLowerCase();
            const commonModelName = option.commonModelName.toLowerCase();
            const remoteField = option.display_name.toLowerCase();
            return (
              remoteField.includes(inputValueToLowerCase) ||
              commonModelName.startsWith(inputValueToLowerCase)
            );
          });
        }}
      />
    </div>
  );
};

export default RemoteFieldSearchBar;
