import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { UserMinus } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";
import useAppContext from "../../../../../../context/useAppContext";

interface LinkedAccountDeletedWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: Set<SelectedWebhookType>;
  onUnselectingWebhookType: (selectedWebhookType: SelectedWebhookType) => void;
}

function LinkedAccountDeletedWebhook(props: LinkedAccountDeletedWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType, onUnselectingWebhookType } = props;

  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Linked Account deleted"
        description="Receive an alert when a Linked Account is deleted"
        icon={<UserMinus size={16} />}
        selectedWebhookType={selectedWebhookType}
        onClick={() => {
          if (
            selectedWebhookType
              ? selectedWebhookType.has(SelectedWebhookType.LINKED_ACCOUNT_DELETED)
              : false
          ) {
            onUnselectingWebhookType(SelectedWebhookType.LINKED_ACCOUNT_DELETED);
          } else {
            onSelectedWebhookTypeChange(SelectedWebhookType.LINKED_ACCOUNT_DELETED);
          }
        }}
        selected={
          selectedWebhookType
            ? selectedWebhookType.has(SelectedWebhookType.LINKED_ACCOUNT_DELETED)
            : false
        }
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default LinkedAccountDeletedWebhook;
