import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

import { PageMode } from "../enums";
import { SectionHeaderWrapper, TitleHeading } from "../../../../shared-components/MergeLayouts";
import {
  DOCS_WEBHOOKS_MERGE_TO_YOU_PATH,
  navigateToWebhooksConfigurationPage,
  openInNewTab,
} from "../../../../../router/RouterUtils";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { BookOpen } from "lucide-react";
import MoreHorizontalPopover from "../../../../shared-components/MoreHorizontalPopover";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import { showSuccessToast } from "../../../../shared-components/Toasts";
import { useHistory } from "react-router-dom";

interface AddEditWebhooksPageHeaderProps {
  mode: PageMode;
  webhookID: string;
}

function AddEditWebhooksPageHeader(props: AddEditWebhooksPageHeaderProps) {
  const { mode, webhookID } = props;
  const history = useHistory();

  const deleteWebhook = (webhookID: string) => {
    fetchWithAuth({
      path: "/integrations/webhooks/" + webhookID,
      method: "DELETE",
      onResponse: () => {
        showSuccessToast("Successfully deleted webhook!");
        navigateToWebhooksConfigurationPage(history);
      },
    });
  };

  return (
    <SectionHeaderWrapper
      title={
        mode === PageMode.EDIT ? "Edit webhook from Merge to you" : "Add webhook from Merge to you"
      }
      titleHeading={TitleHeading.H1}
      subtitle="Configure your webhook to send a POST request to the designated URL"
      headerRightHandContent={
        mode === PageMode.EDIT ? (
          <MoreHorizontalPopover
            menuOptions={
              <Dropdown.Item
                className="hover:bg-gray-0 text-sm text-red-50 !font-normal"
                onSelect={() => {
                  deleteWebhook(webhookID);
                }}
              >
                Delete webhook
              </Dropdown.Item>
            }
          />
        ) : (
          <Button
            size="sm"
            variant={ButtonVariant.TertiaryWhite}
            onClick={() => openInNewTab(DOCS_WEBHOOKS_MERGE_TO_YOU_PATH)}
            leftIcon={<BookOpen size={12} />}
          >
            Webhook docs
          </Button>
        )
      }
    />
  );
}

export default AddEditWebhooksPageHeader;
