import React from "react";

type MappingCardInstanceSubtitleProps = {
  exampleValue?: string | null;
  jmesPath?: string | null;
  remoteEndpointMethod: string | null;
  remoteEndpointPath: string;
};
const MappingCardInstanceSubtitle = ({
  exampleValue,
  jmesPath,
  remoteEndpointMethod,
  remoteEndpointPath,
}: MappingCardInstanceSubtitleProps) => {
  return exampleValue !== undefined ? (
    <div className="flex ">
      <div className="text-gray-50 text-sm">Value:</div>
      <div className="overflow-wrap-anywhere ml-1.5 text-black text-sm">
        {JSON.stringify(exampleValue)}
      </div>
    </div>
  ) : jmesPath ? (
    <div className="flex ">
      <div className="text-gray-50 text-sm">Advanced:</div>
      <div className="overflow-wrap-anywhere ml-1.5 text-black text-sm">{jmesPath}</div>
    </div>
  ) : (
    <p className="text-gray-60 truncate max-w-[300px] overflow-wrap-anywhere small mb-0">
      {remoteEndpointMethod && (
        <span className="mb-0 mr-3 text-gray-50 text-[10px] font-semibold">
          {remoteEndpointMethod}
        </span>
      )}
      <span className="text-gray-50 text-[12px]">{remoteEndpointPath}</span>
    </p>
  );
};

export default MappingCardInstanceSubtitle;
