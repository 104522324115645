import React from "react";
import { Col, Row } from "react-bootstrap";
import giftcard from "../../styles/css/img/giftcard.png";
import styled from "styled-components";
import MergeText, { TextType } from "../shared-components/MergeText";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";

const ReferralCard = styled.div`
  max-width: 816px;
  background: linear-gradient(305.29deg, #c6d6ff 12.94%, #dce5ff 51.86%, #f4f7ff 81.64%);
  border-radius: 8px;
`;

const ImageFrame = styled.div`
  opacity: 0.75;
  margin: 0.375rem;
  @media (min-width: 992px) {
    margin-left: 2.25rem;
  }
`;

function Referrals() {
  return (
    <>
      <ReferralCard className="d-flex flex-row align-items-center justify-content-center p-9">
        <Row>
          <Col xs={12} lg={6} className="d-flex align-items-center">
            <ImageFrame>
              <img src={giftcard} width="100%" />
            </ImageFrame>
          </Col>
          <Col xs={12} lg={6} className="px-9">
            <div className="d-flex flex-column align-items-start ml-3">
              <MergeText isBold fontSize="28px" lineHeight="32px" className="mt-2 mb-2">
                Earn a
              </MergeText>
              <MergeText isBold fontSize="74px" lineHeight="80px" className="mt-2 mb-2">
                $500
              </MergeText>
              <MergeText isBold fontSize="28px" lineHeight="32px" className="mt-2 mb-2">
                Amazon gift card
              </MergeText>
              <MergeText fontSize="15px" lineHeight="24px" className="my-9">
                Refer a friend to Merge and get $500 via Amazon gift card!
              </MergeText>
              <Button variant={ButtonVariant.TertiaryWhite} size="sm">
                Refer a friend
              </Button>
            </div>
          </Col>
        </Row>
      </ReferralCard>
      <MergeText className="pt-2" type={TextType.MUTED}>
        Gift card is rewarded upon your referral’s first invoice payment
      </MergeText>
    </>
  );
}

export default Referrals;
