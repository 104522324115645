import React from "react";
import { Row } from "react-bootstrap";
import LeftSideBar from "../helpers/LeftSideBar";
import LeftSideBarSpacing, { OffsetCol, PaddingLessCol } from "../helpers/LeftSideBarSpacing";
import TileStep, { TitleText } from "../helpers/TileStep";
import { ReactComponent as Union } from "../../src/Union.svg";
import { ReactComponent as SelectOneSVG } from "../../src/select-one-svg.svg";
import { ReactComponent as SelectOneSingle } from "../../src/SelectOneSingle.svg";
import { ReactComponent as SelectOneSingleLine } from "../../src/longline.svg";
import styled from "styled-components";
import SelectOneStep from "../helpers/SelectOneStep";
import { Cpu, Globe, Key } from "lucide-react";
import { palette } from "../../../../styles/theme";
import LiveWaitingIndicator from "../../../shared-components/LiveWaitingIndicator";
import useDashboardOnboardingStatus from "../../utils/useDashboardOnboardingStatus";
import {
  API_KEYS_PATH,
  DOCS_AUTHENTICATION_PATH,
  DOCS_SDK_PATH,
  PROD_LINKED_ACCOUNTS_PATH,
} from "../../../../router/RouterUtils";
import TileOption from "../helpers/TileOption";

const StyledSelectOneSingle = styled(SelectOneSingle)`
  grid-area: selectonesingle;
  display: none;
  @media (max-width: 1000px) {
    display: grid;
  }
`;

const StyledSelectOneSingleLine = styled(SelectOneSingleLine)`
  grid-area: selectoneline;
  display: none;
  @media (max-width: 1000px) {
    position: relative;
    display: grid;
    left: 40px;
  }
`;

const SelectOneContainer = styled.div`
  display: grid;
  grid-area: container;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 22px;
  justify-content: space-evenly;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const StyledSelectOne = styled(SelectOneSVG)`
  position: relative;
  margin-top: 8px;
  margin-bottom: -10px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "container";
  @media (max-width: 1000px) {
    grid-template-columns: 1fr minmax(1px, 4fr);
    grid-template-areas:
      "selectonesingle ..."
      "selectoneline container";
  }
`;

const StyledCPU = styled(Cpu)`
  color: ${palette.blue};
`;

const StyledGlobe = styled(Globe)`
  color: ${palette.blue};
`;

const StyledKey = styled(Key)`
  color: ${palette.indigo};
`;

const StyledCol = styled(PaddingLessCol)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledP = styled.p`
  margin-bottom: 9px;
`;

const RelativeContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 13px;
`;

const AbsoluteDiv = styled.div`
  position: absolute;
  top: 30px;
`;

const StyledZap = styled.img`
  position: absolute;
`;

const MarginDiv = styled.div`
  margin-bottom: 60px;
`;

const ZapImage = require("../../src/zap.png");

const IntegrateSyncedDataSection = () => {
  const dashboardOnboardingStatus = useDashboardOnboardingStatus();
  return (
    <>
      <OffsetCol>
        <Row>
          <PaddingLessCol>
            <TitleText>Integrate synced data</TitleText>
          </PaddingLessCol>
        </Row>
        <Row>
          <LeftSideBar stepNumber="1" isNormalStep />
          <TileStep
            img={<Union height={48.43} width={74.85} />}
            title="Connect backend to Unified API"
            subtitle={
              <>Integrate our Unified API with your backend to push and pull end user data.</>
            }
            stepNumber={1}
          />
        </Row>
        <Row>
          <LeftSideBarSpacing />
          <StyledCol>
            <StyledSelectOne />
            &nbsp;
          </StyledCol>
        </Row>
        <Row>
          <LeftSideBarSpacing />
          <PaddingLessCol>
            <GridWrapper>
              <StyledSelectOneSingle />
              <StyledSelectOneSingleLine />
              <SelectOneContainer>
                <SelectOneStep
                  img={<StyledCPU size={16} />}
                  title="Direct API requests"
                  subtitle={
                    <>
                      Make your own <strong>HTTPS requests</strong> to Merge's Unified API to push
                      and pull data from your backend.
                    </>
                  }
                  buttonName="API reference"
                  docsURL={DOCS_AUTHENTICATION_PATH}
                />
                <SelectOneStep
                  img={<StyledGlobe size={16} />}
                  title="SDKs"
                  subtitle={
                    <>
                      Use our <strong>SDKs</strong> to connect to our Unified API quickly in an
                      opinionated way.
                    </>
                  }
                  buttonName="View SDKs"
                  docsURL={DOCS_SDK_PATH}
                />
              </SelectOneContainer>
            </GridWrapper>
          </PaddingLessCol>
        </Row>
        <Row>
          <LeftSideBarSpacing />
          <PaddingLessCol>&nbsp;</PaddingLessCol>
        </Row>
        <Row>
          <LeftSideBarSpacing />
          <PaddingLessCol>
            <TileOption
              img={<StyledKey size={16} />}
              title="API keys and authentication"
              subtitle1={
                <StyledP>
                  To <strong>interact</strong> with Merge’s API from your production environment,
                  use your{" "}
                  <a href={API_KEYS_PATH} target="_blank" rel="noreferrer">
                    {" "}
                    production access key
                  </a>
                  .
                </StyledP>
              }
              subtitle2={
                <StyledP>
                  To <strong>authenticate</strong> your API requests to Merge, use your end users'
                  Account Token. You can find the Account Token in the{" "}
                  <a href={PROD_LINKED_ACCOUNTS_PATH} target="_blank" rel="noreferrer">
                    {" "}
                    Linked Accounts
                  </a>{" "}
                  Dashboard.
                </StyledP>
              }
              subtitle3={
                <StyledP>
                  Learn more in our{" "}
                  <a
                    href="https://docs.merge.dev/basics/authentication/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Authentication
                  </a>{" "}
                  guide.
                </StyledP>
              }
            />
          </PaddingLessCol>
        </Row>
        <Row>
          <LeftSideBarSpacing />
          <PaddingLessCol>&nbsp;</PaddingLessCol>
        </Row>
        <Row>
          <LeftSideBarSpacing />
          <PaddingLessCol>&nbsp;</PaddingLessCol>
        </Row>
        <Row>
          <LeftSideBar stepNumber="2" isNormalStep={false} />
          <TileStep
            img={
              <RelativeContainer>
                <StyledZap src={ZapImage} height={100} />
                <AbsoluteDiv>
                  {dashboardOnboardingStatus?.has_made_request ? (
                    <LiveWaitingIndicator color="green" />
                  ) : (
                    <LiveWaitingIndicator color="red" />
                  )}
                </AbsoluteDiv>
              </RelativeContainer>
            }
            title="Test run"
            subtitle={
              <>
                <StyledP>
                  Once you've connected your backend, make any successful HTTP request to the
                  Unified API.
                </StyledP>
                We'll authenticate your API key and check this off your list!
              </>
            }
            stepNumber={2}
          />
        </Row>
      </OffsetCol>
      <MarginDiv />
    </>
  );
};

export default IntegrateSyncedDataSection;
