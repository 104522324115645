import React, { useState } from "react";
import { Alert, ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import { publishWhiteLabelGuideChanges } from "../../../../api-client/APIClient";
import { WhiteLabelGuide } from "../pages/ConfigurationWhiteLabelGuideSettingsPage";
import Dropzone from "../../../shared-components/Dropzone";
import { TextField } from "@merge-api/merge-javascript-shared";

const IS_ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]*$/;

type WhiteLabelGuideModalProps = {
  whiteLabelGuide: WhiteLabelGuide;
  handleWhiteLabelGuideChanges: (whiteLabelGuide: WhiteLabelGuide) => void;
  handleHasSavedWhiteLabelGuide: (handleHasSavedWhiteLabelGuide: boolean) => void;
  isReupload: boolean;
  isModalOpen: boolean;
  onHide: () => void;
};

const WhiteLabelGuideModal = ({
  whiteLabelGuide,
  handleWhiteLabelGuideChanges,
  handleHasSavedWhiteLabelGuide,
  isReupload,
  isModalOpen,
  onHide,
}: WhiteLabelGuideModalProps) => {
  // STATE
  const [error, setError] = useState<boolean>(false);

  // HANDLERS
  const handleLogoUpload = (files: Array<File>) => {
    const logoFile = files[0];
    handleWhiteLabelGuideChanges({
      subdomain: whiteLabelGuide?.subdomain ?? "",
      square_logo: logoFile,
      active: true,
    });
  };

  const handleSubdomain = (subdomain: string) => {
    setError(!IS_ALPHANUMERIC_REGEX.test(subdomain));
    handleWhiteLabelGuideChanges({
      subdomain,
      square_logo: whiteLabelGuide?.square_logo ?? null,
      active: true,
    });
  };

  return isReupload ? (
    <Dialog
      open={isModalOpen}
      onClose={onHide}
      variant="md"
      title="Reupload logo"
      primaryButtonText="Save"
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      onPrimaryButtonClick={() => {
        publishWhiteLabelGuideChanges(whiteLabelGuide, handleHasSavedWhiteLabelGuide);
      }}
    >
      <div className="flex flex-col gap-4">
        <div>
          <div className="font-semibold">Upload a square logo</div>
          <div className="text-gray-60 text-sm mb-1">
            Recommended 1:1 ratio and 1MB max file size
          </div>
          <Dropzone upload={handleLogoUpload} />
        </div>
      </div>
    </Dialog>
  ) : (
    <Dialog
      open={isModalOpen}
      variant="md"
      title="Create white label guide"
      primaryButtonText="Set up"
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      onClose={onHide}
      onPrimaryButtonClick={() => {
        publishWhiteLabelGuideChanges(whiteLabelGuide, handleHasSavedWhiteLabelGuide);
      }}
      primaryButtonDisabled={error}
    >
      <div className="flex flex-col gap-4">
        <>
          Automatically point URLs shown to your user during authentication in Merge Link to
          white-labeled version with your logo and preferred subdomain. Any customized links saved
          in Configuration will still be used.
        </>
        <div>
          <div className="font-semibold">Upload a square logo</div>
          <div className="text-gray-60 text-sm mb-1">
            Recommended 1:1 ratio and 1MB max file size
          </div>
          <Dropzone upload={handleLogoUpload} />
        </div>
        <div className="font-semibold">Preferred subdomain</div>
        <TextField
          variant="shadowed"
          placeholder="your-subdomain"
          error={error}
          errorText="Only alphanumeric characters are allowed"
          prefix="https://"
          prefixVariant="url"
          postfix={
            <div className="flex bg-gray-0 text-gray-90 rounded-br-md rounded-tr-md h-full items-center pl-2 pr-2">
              .integrations.guide
            </div>
          }
          onChange={(e) => handleSubdomain(e.target.value)}
        />
        <Alert showWarningIcon color="yellow" size="sm">
          Subdomain can take up to 24 hours to create and cannot be changed once set up
        </Alert>
      </div>
    </Dialog>
  );
};

export default WhiteLabelGuideModal;
