import React from "react";
import { SectionHeaderWrapper } from "../../shared-components/MergeLayouts";

const DestinationPageHeader = () => {
  return (
    <SectionHeaderWrapper
      className="mb-6"
      title="Destinations"
      subtitle={
        <div className="flex flex-row justify-between items-center flex-wrap gap-y-2">
          Your data stores that Merge can write data into. This is currently only available for the
          Data Warehouse category.
        </div>
      }
    />
  );
};

export default DestinationPageHeader;
