import { LinkedAccount } from "../../../../models/Entities";
import React from "react";
import MetadataPair from "../../../integrations-management/shared/MetadataPair";
import DottedOutlineTextCard from "../../../shared-components/DottedOutlineTextCard";
import { Key } from "lucide-react";
import { palette } from "../../../../styles/theme";
import styled from "styled-components";
import { DATE_FORMAT } from "../../../../constants";
import { CommonModelBadge } from "../../../shared-components/MergeBadges";
import { displayNameForAPICategory } from "../../../../models/Helpers";

export interface Props {
  /**
   * The account to display info for if it exists
   */
  linkedAccount: LinkedAccount | null;
}

const HalfSection = styled.div`
  width: 49%;
  padding: 0px 0px 0px 0px;
`;

const Section = styled.div`
  width: 100%;
  padding: 16px 0px 0px 0px;
`;

const APIExplorerMetadata = ({ linkedAccount }: Props) => {
  if (!linkedAccount) {
    return null;
  }

  const integration = linkedAccount.integration && linkedAccount.category && (
    <HalfSection>
      <MetadataPair
        label="Integration"
        data={
          <div className="flex flex-row">
            <span className="mr-1">{linkedAccount.integration.name}</span>
            <>
              {linkedAccount.category && (
                <CommonModelBadge
                  commonModel={displayNameForAPICategory(linkedAccount.category)}
                  color="var(--gray-gray-70, #50545B)"
                  background="var(--lm-gray-10, #EAEEF3)"
                />
              )}
            </>
          </div>
        }
      />
    </HalfSection>
  );

  const linkedDate = linkedAccount.completed_at && (
    <HalfSection>
      <MetadataPair
        label="Linked"
        data={new Date(linkedAccount.completed_at).toLocaleDateString(undefined, DATE_FORMAT)}
      />
    </HalfSection>
  );

  const accountToken = linkedAccount.account_token && (
    <Section>
      <MetadataPair
        label="Account token"
        labelHoverText="Use account tokens to interact with Merge's API"
        labelIcon={<Key size="12px" style={{ color: palette.gray }} />}
        data={
          <DottedOutlineTextCard
            className="mb-2 mt-3 flex flex-1"
            text={linkedAccount.account_token}
            isSecret
            isEmbedded
          />
        }
      />
    </Section>
  );

  return (
    <>
      <div className="flex justify-between items-center">
        {integration} {linkedDate}
      </div>

      <div className="flex flex-row items-center">{accountToken}</div>
    </>
  );
};

export default APIExplorerMetadata;
