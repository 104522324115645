import React from "react";
import { ChevronRight } from "lucide-react";
import ClickableContainer from "../../../../../../shared-components/ClickableContainer";
import LinkedAccountSettingToggle, {
  LinkedAccountSettingToggleProps,
} from "../../LinkedAccountSettingToggle";
import { Badge, Text } from "@merge-api/merge-javascript-shared";
import isUndefined from "lodash/isUndefined";

interface ConfigurationWidgetCardProps {
  cardTitle: string;
  cardSubtitle?: JSX.Element | string;
  onClick?: () => void;
  includeRightChevron?: boolean;
  badgeChildren?: string | number | JSX.Element;
  linkedAccountSettingToggleProps?: LinkedAccountSettingToggleProps;
  includeCardDivider?: boolean;
}

const ConfigurationWidgetCard = ({
  cardTitle,
  cardSubtitle,
  onClick,
  includeRightChevron,
  badgeChildren,
  linkedAccountSettingToggleProps,
  includeCardDivider,
}: ConfigurationWidgetCardProps) => {
  const content = (
    <div className="flex flex-col bg-white shadow-sm rounded-md py-2 px-3">
      <div className="flex flex-row items-center justify-between">
        <div className="flex items-center">
          <Text variant="title-sm">{cardTitle}</Text>
          {includeRightChevron && <ChevronRight className="ml-1" size={12} />}
        </div>
        {!isUndefined(badgeChildren) && <Badge color="gray">{badgeChildren}</Badge>}
      </div>
      {linkedAccountSettingToggleProps && (
        <div className="pr-4">
          <LinkedAccountSettingToggle {...linkedAccountSettingToggleProps} />
        </div>
      )}
      {includeCardDivider && <hr className="border-gray-10 -ml-4 -mr-3 my-2" />}
      {cardSubtitle && <div className="text-gray-50 text-xs font-normal">{cardSubtitle}</div>}
    </div>
  );

  return onClick ? <ClickableContainer onClick={onClick}>{content}</ClickableContainer> : content;
};

export default ConfigurationWidgetCard;
