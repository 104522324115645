import React from "react";
import ConfigurationSettingsPage from "../helpers/ConfigurationSettingsPage";
import ConfigurationSelectiveSyncCard from "../cards/ConfigurationSelectiveSyncCard";
import CustomizationViewPublishButtons from "../helpers/CustomizationViewPublishButtons";

const ConfigurationFeaturesSettingsPage = () => {
  return (
    <ConfigurationSettingsPage
      title="Features"
      subtitle="Customize what screens and features appear for your users in Merge Link"
      subtitleRightContent={<CustomizationViewPublishButtons />}
    >
      <>
        <ConfigurationSelectiveSyncCard />
      </>
    </ConfigurationSettingsPage>
  );
};

export default ConfigurationFeaturesSettingsPage;
