import { Dialog } from "@merge-api/merge-javascript-shared";
import React from "react";
type SaveOverrideModalProps = {
  open: boolean;
  text: string;
  title?: string;
  saveButtonText?: string;
  onHide: () => void;
  onConfirm: () => void;
};
const SaveOverrideModal = ({
  open,
  text,
  title,
  saveButtonText,
  onHide,
  onConfirm,
}: SaveOverrideModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => onHide()}
      onPrimaryButtonClick={() => onConfirm()}
      primaryButtonText={saveButtonText || "Override field"}
      onSecondaryButtonClick={() => onHide()}
      secondaryButtonText="Cancel"
      title={title || "Override Common Model field"}
      variant="md"
    >
      <p>{text}</p>
    </Dialog>
  );
};
export default SaveOverrideModal;
