import React from "react";
import { Dropdown, Row, Col, ListGroup, Button, Badge } from "react-bootstrap";
import classNames from "classnames";
import styled from "styled-components";

import { fetchWithAuth } from "../../../api-client/APIClient";
import { showSuccessToast, showErrorToast } from "../../shared-components/Toasts";
import useAppContext from "../../context/useAppContext";
import { UserAvatar } from "../../shared-components/MergeAvatars";
import { User, UserType } from "../../../models/Entities";
import { SmallTextMutedParagraph } from "../../shared-components/MergeText";
import { displayNameForUserType } from "../../../models/Helpers";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";
import { EllipsesToggle } from "../../shared-components/MergeToggles";

const Text = styled.div`
  font-size: 0.8125rem;
  font-weight: 600;
  font-family: inherit;
  padding-left: 0.5rem;
`;

interface OrganizationTeamMemberProps {
  user: User;
  onRefetchTeamMembers: () => void;
}

function OrganizationTeamMember({ user, onRefetchTeamMembers }: OrganizationTeamMemberProps) {
  const { user: meUser, isUserPrivileged: isMeUserPrivileged, setUser } = useAppContext();

  const resetMfaForUser = (user: User) => {
    fetchWithAuth({
      path: `/users/totp/disable/${user.id}`,
      method: "POST",
      body: JSON.parse(JSON.stringify(user)),
      onResponse: () => {
        showSuccessToast(`Successfully reset ${user.name}'s two-factor authentication!`);
        onRefetchTeamMembers();
      },
    });
  };

  const setUserAccountActive = (user: User, isActive: boolean) => {
    const cloneUser = JSON.parse(JSON.stringify(user));
    cloneUser.is_active = isActive;

    fetchWithAuth({
      path: `/organizations/users/${user.id}`,
      method: "PATCH",
      body: cloneUser,
      onResponse: (data) => {
        onRefetchTeamMembers();
        showSuccessToast(
          `Successfully ${isActive ? "reactivated" : "disabled"} ${user.name}'s account!`,
        );

        if (user.email === meUser.email) {
          setUser(data.user);
        }
      },
      onError: () => {
        showErrorToast(
          `Failed to ${isActive ? "reactivate" : "deactivate"} ${user.name}'s account!`,
        );
      },
    });
  };

  const changePermissions = (user: User, type: UserType) => {
    const cloneUser = JSON.parse(JSON.stringify(user));
    cloneUser.type = type;

    fetchWithAuth({
      path: `/organizations/users/${user.id}`,
      method: "PATCH",
      body: cloneUser,
      onResponse: (data) => {
        onRefetchTeamMembers();
        showSuccessToast(`Updated ${user.name}'s permissions to ${displayNameForUserType(type)}!`);

        if (user.email === meUser.email) {
          setUser(data.user);
        }
      },
      onError: () => {
        showErrorToast(`Failed to update ${user.name}'s permissions.`);
      },
    });
  };

  return (
    <ListGroup.Item className="px-6" key={user.id}>
      <Row className="align-items-center">
        <Col className="col-1">
          <UserAvatar user={user} />
        </Col>
        <Col className="col-4 ml-n2">
          <DeprecatedH4 className="mb-1">
            {user.name}
            {!user.is_active && (
              <Badge variant="light" className={classNames(user.name && "ml-2")}>
                Disabled
              </Badge>
            )}
          </DeprecatedH4>
          <SmallTextMutedParagraph className="small text-gray-50 mb-0">
            {user.email}
          </SmallTextMutedParagraph>
        </Col>
        <Col className="col-3">
          {meUser.email != user.email && isMeUserPrivileged ? (
            <div className="dropdown">
              {user.is_active ? (
                <Dropdown>
                  <Dropdown.Toggle variant="white" className="custom-dropdown-button" size="sm">
                    {displayNameForUserType(user.type)}
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right" className="custom-dropdown-menu">
                    <Dropdown.Item
                      className="custom-dropdown-option"
                      onSelect={() => changePermissions(user, UserType.admin_with_billing)}
                    >
                      {displayNameForUserType(UserType.admin_with_billing)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="custom-dropdown-option"
                      onSelect={() => changePermissions(user, UserType.admin_without_billing)}
                    >
                      {displayNameForUserType(UserType.admin_without_billing)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="custom-dropdown-option"
                      onSelect={() => changePermissions(user, UserType.member)}
                    >
                      {displayNameForUserType(UserType.member)}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Button variant="light" size="sm" disabled>
                  Disabled
                </Button>
              )}
            </div>
          ) : (
            <Text>{displayNameForUserType(user.type)}</Text>
          )}
        </Col>
        <Col className="col-3">
          {isMeUserPrivileged && (
            <>
              {user.is_two_factor_enabled ? (
                <Badge variant="soft-success" className="ml-2">
                  Enabled
                </Badge>
              ) : (
                <Badge variant="soft-warning" className="ml-2">
                  Disabled
                </Badge>
              )}
            </>
          )}
        </Col>
        <Col className="col-1">
          {meUser.email != user.email && isMeUserPrivileged && (
            <Dropdown className="float-right">
              <Dropdown.Toggle as={EllipsesToggle} id="dropdown-custom-components">
                <i className="fe fe-more-vertical black" />
              </Dropdown.Toggle>
              <Dropdown.Menu align="right" className="dropdown-ellipses dropdown-toggle">
                {user.is_two_factor_enabled && (
                  <Dropdown.Item
                    className="ellipses-dropdown-item dropdown-item"
                    onSelect={() => {
                      resetMfaForUser(user);
                    }}
                  >
                    Reset 2FA
                  </Dropdown.Item>
                )}
                {user.is_active ? (
                  <Dropdown.Item
                    className="ellipses-dropdown-item dropdown-item"
                    onSelect={() => setUserAccountActive(user, false)}
                  >
                    Disable account
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    className="ellipses-dropdown-item dropdown-item"
                    onSelect={() => setUserAccountActive(user, true)}
                  >
                    Reactivate account
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

export default OrganizationTeamMember;
