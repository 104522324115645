import React from "react";
import clsx from "clsx";
import { Button, ButtonVariant, InputCombo, Text } from "@merge-api/merge-javascript-shared";
import { CommonModelSelect, FieldSelect, OperatorSelect, ValueTextField } from "./components";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
} from "../../../../../../../models/Entities";
import { Trash2 } from "lucide-react";
import compact from "lodash/compact";

type LinkedAccountConditionInputComboProps = {
  className?: string;
  integrationName: string;
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  errorText: string | undefined;
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  linkedAccountConditions: Partial<LinkedAccountCondition>[];
  onLinkedAccountConditionChange: (linkedAccount: Partial<LinkedAccountCondition>) => void;
  onLinkedAccountConditionDelete: () => void;
  isFirst: boolean;
};

const LinkedAccountConditionInputCombo = ({
  className,
  integrationName,
  linkedAccountCondition,
  errorText,
  linkedAccountConditionMeta,
  linkedAccountConditions,
  onLinkedAccountConditionChange,
  onLinkedAccountConditionDelete,
  isFirst,
}: LinkedAccountConditionInputComboProps) => {
  // instead of disabling fields, we hide them, and replace them with empty space
  const optionalSelectFields = compact([
    linkedAccountCondition.common_model && (
      <FieldSelect
        key="field"
        integrationName={integrationName}
        linkedAccountCondition={linkedAccountCondition}
        linkedAccountConditionMeta={linkedAccountConditionMeta}
        linkedAccountConditions={linkedAccountConditions}
        onLinkedAccountConditionChange={onLinkedAccountConditionChange}
      />
    ),
    linkedAccountCondition.normalized_key_name && (
      <OperatorSelect
        key="operator"
        linkedAccountCondition={linkedAccountCondition}
        linkedAccountConditionMeta={linkedAccountConditionMeta}
        onLinkedAccountConditionChange={onLinkedAccountConditionChange}
      />
    ),
    linkedAccountCondition.operator && (
      <ValueTextField
        key="value"
        linkedAccountCondition={linkedAccountCondition}
        linkedAccountConditionMeta={linkedAccountConditionMeta}
        onLinkedAccountConditionChange={onLinkedAccountConditionChange}
      />
    ),
  ]);

  return (
    <div className={clsx("flex flex-col", className)}>
      <div className="flex flex-row items-center">
        <Text className="min-w-[57px]">{isFirst ? "where" : "and"}</Text>
        <InputCombo
          fullWidth
          error={!!errorText}
          errorText={errorText}
          inputs={compact([
            <CommonModelSelect
              key="commonModel"
              linkedAccountCondition={linkedAccountCondition}
              linkedAccountConditionMeta={linkedAccountConditionMeta}
              linkedAccountConditions={linkedAccountConditions}
              onLinkedAccountConditionChange={onLinkedAccountConditionChange}
            />,

            ...optionalSelectFields,
            // add blank space equal to number of hidden inputs
            optionalSelectFields.length < 3 && (
              <div className="flex" style={{ flex: 3 - optionalSelectFields.length }} />
            ),

            <Button
              size="sm"
              variant={ButtonVariant.IconShadowHidden}
              key="trash"
              className="mr-1"
              onClick={onLinkedAccountConditionDelete}
            >
              <Trash2 size={16} />
            </Button>,
          ])}
        />
      </div>
    </div>
  );
};

export default LinkedAccountConditionInputCombo;
