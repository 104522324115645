import React from "react";
import { Info } from "lucide-react";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
  LinkedAccountConditionMetaField,
} from "../../../../../../../../models/Entities";
import { Select, Text } from "@merge-api/merge-javascript-shared";
import { getFilteredFieldOptions, getFieldValue } from "../helpers";
import { snakeCaseToSentenceCase } from "../../../../../../../../utils/casing";

type FieldSelectProps = {
  integrationName: string;
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  linkedAccountConditions: Partial<LinkedAccountCondition>[];
  onLinkedAccountConditionChange: (LinkedAccountCondition: Partial<LinkedAccountCondition>) => void;
};

const FieldSelect = ({
  integrationName,
  linkedAccountCondition,
  linkedAccountConditionMeta,
  linkedAccountConditions,
  onLinkedAccountConditionChange,
}: FieldSelectProps) => {
  // derived state
  const fieldOptions = getFilteredFieldOptions(
    linkedAccountCondition,
    linkedAccountConditionMeta,
    linkedAccountConditions,
  );
  const fieldValue = getFieldValue(linkedAccountCondition, linkedAccountConditionMeta);

  // event handlers
  const onFieldChange = (_: any, fieldOption: LinkedAccountConditionMetaField | null) => {
    onLinkedAccountConditionChange({
      ...linkedAccountCondition,
      normalized_key_name: fieldOption?.normalized_key_name || undefined,

      // reset other fields
      condition_schema_id: undefined,
      operator: undefined,
      value: undefined,
    });
  };

  return (
    <Select
      className="flex flex-1 h-full py-[3px]"
      shadowHidden
      placeholder="Select field..."
      options={fieldOptions}
      clearable={false}
      getOptionLabel={(option) => snakeCaseToSentenceCase(option.normalized_key_name)}
      renderOption={(option) => (
        <div className="flex flex-row items-center">
          <Text className="text-gray-90 mr-2">
            {snakeCaseToSentenceCase(option.normalized_key_name)}
          </Text>
          <Text variant="caption" className="text-gray-60">
            {option.remote_key_name}
          </Text>
        </div>
      )}
      renderValue={(option) => <>{snakeCaseToSentenceCase(option.normalized_key_name)}</>}
      value={fieldValue}
      onChange={onFieldChange}
      footer={
        <div className="border-t-gray-20 border-t bg-gray-0 text-gray-70 py-2.5 px-3 flex items-center -mb-2">
          <Info size={12} className="mr-1.5" />{" "}
          <Text variant="sm">{integrationName} fields in small text</Text>
        </div>
      }
    />
  );
};

export default FieldSelect;
