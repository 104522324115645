import React from "react";
import styled from "styled-components";
import SyncingDataGraphic from "../../../../assets/img/syncing-data.png";
import { DOCS_WEBHOOKS_PATH } from "../../../../router/RouterUtils";
import DeprecatedH3 from "../../../../deprecated/DeprecatedH3";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";

const Container = styled.div`
  @media (min-width: 1330px) {
    margin: 32px auto;
    max-width: 75%;
    padding-bottom: 64px;
  }
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 24px;
`;

// Create space between list item markers and content - was too tight
const FormattedListItem = styled.li`
  > * {
    position: relative;
    left: 8px;
  }
`;

const SyncingDataImg = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 8px auto 24px;
`;

const Header = styled(DeprecatedH3)`
  font-size: 24px;
  line-height: 36px;
`;

const Subtitle = styled(DeprecatedH6)`
  font-size: 14px;
  line-height: 24px;
`;

const SyncingData = () => {
  return (
    <Container>
      <Card>
        <Header className="mb-1">Syncing data</Header>
        <Subtitle className="mb-6 gray font-normal">Sync user data effectively with Merge</Subtitle>
        <p className="mt-6">There are a few methods involved in syncing data with Merge:</p>
        <p>
          <ol>
            <FormattedListItem className="mb-3">Polling</FormattedListItem>
            <FormattedListItem>Webhooks</FormattedListItem>
          </ol>
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SyncingDataImg src={SyncingDataGraphic} alt="Syncing Merge data" />
        </div>
        <p className="my-6">Merge recommends syncing data in the following way:</p>
        <ol>
          <FormattedListItem className="mb-9">
            <p>
              <b>Create a set of functions in your backend that are responsible for syncing data</b>
            </p>
            <p>
              These functions should utilize the <code>modified_after</code> timestamp filter that
              is available on all Merge list endpoints.
            </p>

            <p>
              This critical filter enables you to only pull data that has been changed (or created)
              since your last sync.
            </p>

            <p>
              For example, you can ask for <code>modified_after=2021-03-30T20:44:18.662942Z</code>,
              and only pull items that are new / different.
            </p>
            <p className="mb-6">
              To do this, you need to store the time you last began a fetch to Merge&apos;s API for
              the linked account and endpoint.
            </p>

            <aside>
              <p>
                <b>Important:</b>
              </p>
              <p className="mb-0">
                Use the time the fetch began since data can change during a fetch. Pick up those
                changes during the next sync.
              </p>
            </aside>
          </FormattedListItem>

          <FormattedListItem className="mb-9">
            <p>
              <b>Sync periodically (aka polling)</b>
            </p>
            <p>
              Configure your backend to call your sync functions periodically, such as every 24
              hours.
            </p>
          </FormattedListItem>

          <FormattedListItem className="mb-9">
            <p>
              <b>Sync when Merge emits a "Sync Notification" webhook</b>
            </p>

            <p>Merge offers a variety of webhooks for real-time events.</p>

            <p>
              To be alerted to when Merge may have data updates, configure Merge to notify you via
              webhook when Merge has completed syncing with a third-party platform for a linked
              account.
            </p>

            <p>
              Whenever you receive a <b>"Sync Notification" webhook</b>, initiate the logic you
              configured in Step 1.
            </p>

            <p>
              We recommend using a combination of our &quot;First Sync Notification&quot; and
              &quot;Any Sync Notification&quot; webhooks to help you manage your sync activities at
              scale.
            </p>

            <p>
              Learn how to configure Merge webhooks in our{" "}
              <a href={DOCS_WEBHOOKS_PATH}>webhook docs</a>.
            </p>

            <aside className="mt-6 mb-6">
              <p>
                <b>I see Merge offers "Changed Data" webhooks. Can I use those?</b>
              </p>
              <p className="mb-0">
                Merge offers webhooks that deliver updated data when individual data instances are
                updated, but depending on the amount of user data, this will not scale well.
              </p>
            </aside>

            <aside>
              <p>
                <b>
                  Make sure to implement polling and don&apos;t rely entirely on notification
                  webhooks.
                </b>
              </p>

              <p>
                They can fail for a variety of reasons (such as downtime on your end or failed
                processing).
              </p>

              <p className="mb-0">
                Merge does attempt to redeliver multiple times using exponential backoff, but we
                still recommend calling your sync functions on a periodic cadence of around once
                every 24 hours.
              </p>
            </aside>
          </FormattedListItem>
        </ol>
      </Card>
    </Container>
  );
};

export default SyncingData;
