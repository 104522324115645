import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { CONFIGURATION_ADVANCED_FIELD_MAPPINGS_TARGET_FIELDS_PATH } from "../../../../../../router/RouterUtils";
import { AvailableFieldMappingIntegration } from "../../../../../../models/Entities";
import {
  getFieldMappingTarget,
  getAvailableIntegrationsForFieldMapping,
} from "../../utils/FieldMappingUtils";
import { showErrorToast } from "../../../../../shared-components/Toasts";
import { FieldMappingTarget } from "../../../../../../models/Entities";
import IntegrationFieldMappingsHeader from "../../IntegrationFieldMappingsHeader";
import { Button, Card } from "@merge-api/merge-javascript-shared";
import FieldMappingLinkConfigurationOptions from "../../FieldMappingLinkConfigurationOptions";
import FieldMappingByIntegrationTable from "../../FieldMappingByIntegrationTable";
import EmptyStateWrapper from "../../../../../shared-components/EmptyStateWrapper";
type Props = {
  fieldMappingTargetId: string;
};
const IntegrationWideFieldMappingSettings = () => {
  const { fieldMappingTargetId } = useParams<Props>();
  const [targetFieldInfo, setTargetFieldInfo] = useState<FieldMappingTarget | null>(null);
  const [avaialbleIntegrations, setAvailableIntegrations] = useState<
    AvailableFieldMappingIntegration[] | null
  >(null);

  useEffect(() => {
    if (fieldMappingTargetId) {
      const onSuccess = (data: FieldMappingTarget) => {
        setTargetFieldInfo(data);
      };

      const onFailure = () => {
        showErrorToast("Unable to fetch target field information");
      };

      getFieldMappingTarget({
        fieldMappingTargetId: fieldMappingTargetId,
        onResponse: onSuccess,
        onError: onFailure,
      });
    }
  }, [fieldMappingTargetId]);

  const getAvailableintegrationsForFieldMappingTarget = () => {
    const onSuccess = (response: { integrations: AvailableFieldMappingIntegration[] }) => {
      setAvailableIntegrations(response.integrations);
    };
    const onFailure = () => {
      showErrorToast("Unable to fetch integration info for target field ");
    };

    getAvailableIntegrationsForFieldMapping({
      fieldMappingTargetId: fieldMappingTargetId,
      onResponse: onSuccess,
      onError: onFailure,
    });
  };

  useEffect(() => {
    if (fieldMappingTargetId) {
      getAvailableintegrationsForFieldMappingTarget();
    }
  }, [fieldMappingTargetId]);
  return (
    <div className="mb-6">
      <Link
        to={CONFIGURATION_ADVANCED_FIELD_MAPPINGS_TARGET_FIELDS_PATH}
        className="font-semibold mb-[-20px]"
      >
        <div className="d-flex items-center">
          <ArrowLeft size={14} />
          <span className="ml-1">Back to target fields</span>
        </div>
      </Link>
      {targetFieldInfo && avaialbleIntegrations ? (
        <div className="flex flex-column">
          <div className="mt-6">
            <IntegrationFieldMappingsHeader
              commonModel={targetFieldInfo.common_model_name}
              fieldMappingTargetId={targetFieldInfo.id}
              title={targetFieldInfo.field_key}
              category={targetFieldInfo.category}
              description={targetFieldInfo.field_description}
              refreshFieldMappingsAndConfigurations={() => {}}
            />
          </div>
          <Card className="mt-6 pt-5 px-6" size="lg">
            <FieldMappingLinkConfigurationOptions
              fieldMappingTarget={targetFieldInfo}
              refreshFieldMappingsAndConfigurations={() => {}}
              isDisabled={!targetFieldInfo.is_remote_data_enabled}
            />
          </Card>
          <Card className="mt-9 px-6 pt-5" size="lg">
            <FieldMappingByIntegrationTable
              availableIntegrations={avaialbleIntegrations}
              fieldMappingTargetId={targetFieldInfo.id}
              fieldMappingTargetCommonModelName={targetFieldInfo.common_model_name}
              fieldMappingTargetCategory={targetFieldInfo.category}
              fieldMappingTargetFieldKey={targetFieldInfo.field_key}
              refreshFieldMappings={getAvailableintegrationsForFieldMappingTarget}
              isRemoteDataEnabled={targetFieldInfo.is_remote_data_enabled}
            />
          </Card>
        </div>
      ) : (
        <EmptyStateWrapper isSpinner />
      )}
    </div>
  );
};

export default IntegrationWideFieldMappingSettings;
