import React from "react";
import ClickableContainer from "../../../shared-components/ClickableContainer";
import useProductRestrictions from "../../../shared-components/hooks/useProductRestrictions";
import { Alert } from "@merge-api/merge-javascript-shared";
import { ChevronRight } from "lucide-react";

/**
 * Creates a note about launch only saving data going back 30 days for non-admins.
 */
const NonAdminNote = () => {
  const { productRestrictions } = useProductRestrictions();
  const log_retention_days = productRestrictions ? productRestrictions.log_retention_days : 30;
  return (
    <Alert color="gray" className="mb-9">
      <div className="flex w-full flex-row items-center justify-between">
        <span>
          Your <strong>Launch</strong> plan only allows you to search logs going back{" "}
          {log_retention_days} days. For search longer than {log_retention_days} days, upgrade to
          our <strong>Professional</strong> or <strong>Enterprise</strong> plans.
        </span>

        <ClickableContainer>
          <span className="flex align-middle font-semibold">
            Speak to our team
            <div>
              <ChevronRight className="ml-2" size={14} />
            </div>
          </span>
        </ClickableContainer>
      </div>
    </Alert>
  );
};

export default NonAdminNote;
