import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import CopyComponentWithIcon from "../../../../../../shared-components/CopyComponentWithIcon";

type SyncConnectionIdProps = {
  syncConnectionId?: string;
};

const SyncConnectionId = ({ syncConnectionId }: SyncConnectionIdProps) => {
  return (
    <>
      <Text variant="title-sm" className="text-slate-90">
        Connection ID
      </Text>
      <div className="flex justify-between items-center px-2 py-[3px] rounded border-[0.5px] border-gray-20 bg-gray-10">
        <Text
          variant="sm-mono"
          className="block text-ellipsis overflow-hidden whitespace-nowrap mr-2"
        >
          {syncConnectionId}
        </Text>
        <CopyComponentWithIcon text={syncConnectionId} />
      </div>
    </>
  );
};

export default SyncConnectionId;
