import React from "react";
import { ArrowUpRight } from "lucide-react";
import { FieldMappingOptions } from "../../../../../../models/Entities";
import { createFieldMappingOptions } from "../../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";
import { deleteLinkedAccountFieldMapping } from "../../../../../../api-client/APIClient";
import { ArrowLeft } from "lucide-react";
import { Typeahead, Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { LinkedAccount } from "../../../../../../models/Entities";
import { showSuccessToast } from "../../../../../shared-components/Toasts";
import { CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH } from "../../../../../../router/RouterUtils";
import RemoteFieldOption from "../RemoteFieldOption";
import EditJmesPathModal from "../modal/field-mappings/EditJmesPathModal";
import useUnmappedTargetCard from "./useUnmappedTargetCard";
type UnmappedTargetCardProps = {
  linkedAccount: LinkedAccount;
  fieldKey: string;
  fieldDescription: string;
  fieldMappingOptions: FieldMappingOptions;
  commonModel: string;
  fieldMappingTargetId: string;
};
const UnmappedTargetCard = ({
  linkedAccount,
  fieldKey,
  fieldDescription,
  fieldMappingOptions,
  commonModel,
  fieldMappingTargetId,
}: UnmappedTargetCardProps) => {
  const createdfieldMappingOptions = createFieldMappingOptions(fieldMappingOptions, commonModel);
  const {
    createdFieldMappingInstanceId,
    originFieldForAdvancedMapping,
    showAdvancedMappingButton,
    showAdvancedFieldMappingModal,
    setCreatedFieldMappingInstanceId,
    setOriginFieldForAdvancedMapping,
    setShowAdvancedMappingButton,
    setShowAdvancedFieldMappingModal,
    isAdvancedMappingEnabled,
    saveLinkedAccountMapping,
    editCreatedFieldMapping,
    jmesPath,
    setJmesPath,
  } = useUnmappedTargetCard();

  return (
    <>
      {showAdvancedFieldMappingModal && originFieldForAdvancedMapping && (
        <EditJmesPathModal
          currentJmesPath={jmesPath}
          jsonData={originFieldForAdvancedMapping.value}
          remoteFieldDisplayName={originFieldForAdvancedMapping.display_name}
          onModalClose={() => {
            setShowAdvancedFieldMappingModal(false);
          }}
          onUpdate={(jmesPath: string) => {
            if (createdFieldMappingInstanceId) {
              editCreatedFieldMapping(originFieldForAdvancedMapping, jmesPath);
            } else {
              saveLinkedAccountMapping(
                originFieldForAdvancedMapping,
                linkedAccount,
                fieldKey,
                fieldDescription,
                commonModel,
                jmesPath,
              );
            }
            setJmesPath(jmesPath);
            setShowAdvancedMappingButton(false);
            setShowAdvancedFieldMappingModal(false);
          }}
        />
      )}
      <div className="grid grid-cols-11">
        <div className="col-start-1 col-end-6 rounded-lg border border-gray-20 py-4 px-5 flex flex-col">
          <div className="flex flex-grow-1 mb-[6px]">
            <div className="text-base font-semibold font-mono">{fieldKey}</div>
            <a
              href={`${CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}/${fieldMappingTargetId}/settings`}
              className="ml-auto text-xs text-blue-40 font-inter"
            >
              View
            </a>
          </div>
          {fieldDescription && (
            <div className="text-sm text-gray-50 leading-[18px]">{fieldDescription}</div>
          )}
        </div>
        <div className="col-start-6 col-end-7 flex justify-center items-center">
          <ArrowLeft />
        </div>
        {createdfieldMappingOptions.length > 0 ? (
          <div className="col-start-7 col-end-12 rounded-lg border border-gray-20 py-4 px-5 ">
            <Typeahead
              className="w-100"
              options={createdfieldMappingOptions}
              placeholder="Search Fields..."
              multiple={false}
              getOptionLabel={(option: any) => {
                return option?.display_name ?? "";
              }}
              blurOnSelect
              renderOption={(fieldMapping: any) => {
                return <RemoteFieldOption fieldMapping={fieldMapping} />;
              }}
              onChange={(_, selectedRemoteField: any) => {
                const showAdvancedFieldMappingsForm =
                  selectedRemoteField &&
                  ((selectedRemoteField.type.includes("list") &&
                    selectedRemoteField.value?.length !== 0) ||
                    selectedRemoteField.type == "dict") &&
                  isAdvancedMappingEnabled;

                setJmesPath(null);
                if (showAdvancedFieldMappingsForm) {
                  setOriginFieldForAdvancedMapping(selectedRemoteField);
                  setShowAdvancedMappingButton(true);
                  return;
                }
                if (selectedRemoteField) {
                  if (createdFieldMappingInstanceId == null) {
                    saveLinkedAccountMapping(
                      selectedRemoteField,
                      linkedAccount,
                      fieldKey,
                      fieldDescription,
                      commonModel,
                      null,
                    );
                  } else {
                    editCreatedFieldMapping(selectedRemoteField, null);
                  }
                  // create field mapping instance
                } else {
                  if (createdFieldMappingInstanceId) {
                    deleteLinkedAccountFieldMapping(createdFieldMappingInstanceId, () => {
                      showSuccessToast("Successfully deleted field mapping");
                      setCreatedFieldMappingInstanceId(null);
                    });
                  }
                  setShowAdvancedMappingButton(false);
                }
              }}
            />
            {showAdvancedMappingButton && (
              <Button
                size="md"
                variant={ButtonVariant.SecondaryBlue}
                onClick={() => {
                  setShowAdvancedFieldMappingModal(true);
                }}
                className="mt-4 ml-auto"
                rightIcon={<ArrowUpRight size={12} />}
              >
                Advanced Mapping
              </Button>
            )}
            {jmesPath && (
              <div className="flex items-center py-2 px-3  justify-between bg-blue-0 rounded-md text-sm mt-4  ">
                <div className="overflow-wrap-anywhere">{jmesPath}</div>
                <div
                  className="text-blue-40  font-semibold hover:cursor-pointer min-w-[75px]"
                  onClick={() => {
                    setShowAdvancedFieldMappingModal(true);
                  }}
                >
                  <span className="mr-1">Advanced</span>
                  <span>
                    <ArrowUpRight size={12} />
                  </span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="col-start-7 col-end-12 flex items-center justify-center py-4 px-5 text-base bg-[#f5f8ff] text-[#697387] rounded-lg">
            No remote fields currently available
          </div>
        )}
      </div>
    </>
  );
};
export default UnmappedTargetCard;
