import React, { useState } from "react";

import { AuthEmailForm, AuthLayout, OnEmailFormSubmitFunction } from "../AuthLayouts";
import { showErrorToast } from "../../shared-components/Toasts";
import { fetchWithoutAuth, FormErrorData } from "../../../api-client/APIClient";

const SendSsoUrlScreen = () => {
  const [sendSsoUrlSuccess, setSendSsoUrlSuccess] = useState(false);
  const handleSubmit: OnEmailFormSubmitFunction = (
    data,
    setEmailErrorMessage,
    setEmailFormIsLoading,
  ) => {
    setEmailFormIsLoading(true);
    fetchWithoutAuth({
      path: "/users/sso/saml/send-url",
      method: "POST",
      body: { email: data.email },
      onResponse: () => {
        setEmailFormIsLoading(false);
        setSendSsoUrlSuccess(true);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((data: FormErrorData) => {
            if (data.non_field_errors) {
              setEmailErrorMessage(data.non_field_errors[0]);
            } else if (data.email) {
              setEmailErrorMessage(data.email[0]);
            }
            showErrorToast("Failed to send SSO login url.");
            setEmailFormIsLoading(false);
          });
        } else {
          showErrorToast("Something went wrong, please check your connection and try again.");
          setEmailFormIsLoading(false);
        }
      },
    });
  };
  const title = "Sign in with SSO";
  const finePrint = (
    <>
      If you want to enable SSO for your organization, please reach out to your customer success
      manager or contact support@merge.dev.
    </>
  );
  if (!sendSsoUrlSuccess) {
    return (
      <AuthEmailForm
        title={title}
        description={
          <p>
            If you have a Merge account, we'll email you a link to sign in with your SSO provider
          </p>
        }
        onEmailFormSubmit={handleSubmit}
        finePrint={finePrint}
      />
    );
  }
  return (
    <AuthLayout
      title={title}
      description={
        <p>
          If you have a Merge account and your organization uses SSO, then a SSO link has been sent
          to your email address
        </p>
      }
      finePrint={finePrint}
    />
  );
};

export default SendSsoUrlScreen;
