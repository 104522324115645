import React, { useMemo } from "react";
import clsx from "clsx";

import { renderEndpoint } from "../../../helpers";
import { ROW_HEADER_WIDTH } from "../EndpointUsageTable";
import { APICategory, Badge } from "@merge-api/merge-javascript-shared";
import { ADDITIONAL_ENDPOINT_INFO_MAP } from "../../../../../../../constants";

type EndpointUsageTableRowHeaderProps = {
  endpoint: string;
  selectedCategory: APICategory;
};

const EndpointUsageTableRowHeader = ({
  endpoint,
  selectedCategory,
}: EndpointUsageTableRowHeaderProps) => {
  const isBeta = useMemo(() => {
    let isBeta = false;

    const [_, path] = endpoint.split(" ");

    const additionalInfo = ADDITIONAL_ENDPOINT_INFO_MAP[path.slice(1)];

    if (additionalInfo && additionalInfo.category === selectedCategory) {
      isBeta = !!additionalInfo.isBeta;
    }

    return isBeta;
  }, [endpoint, selectedCategory]);

  return (
    <div className="sticky left-0">
      <td
        className={clsx(
          ROW_HEADER_WIDTH,
          "bg-white z-10 py-3 px-4 border-r-[0.5px] border-solid border-gray-20 overflow-visible shadow-[4px_0_6px_-2px_rgba(0,0,0,0.12)]",
        )}
      >
        {renderEndpoint(endpoint)}
        {isBeta && (
          <Badge color="blue" className="ml-2">
            BETA
          </Badge>
        )}
      </td>
    </div>
  );
};

export default EndpointUsageTableRowHeader;
