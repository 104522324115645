import React from "react";
import { navigateToLinkedAccountDetailWebhooksPathForId } from "../../../../../../../router/RouterUtils";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import { useHistory } from "react-router-dom";

type WebhookReceiversWidgetCardProps = {
  activeWebhookReceiversCount: number | undefined;
  linkedAccountId: string;
};

const WebhookReceiversWidgetCard = ({
  activeWebhookReceiversCount,
  linkedAccountId,
}: WebhookReceiversWidgetCardProps) => {
  const history = useHistory();

  return (
    <ConfigurationWidgetCard
      cardTitle="Webhooks"
      includeRightChevron
      onClick={() => navigateToLinkedAccountDetailWebhooksPathForId(history, linkedAccountId)}
      badgeChildren={activeWebhookReceiversCount}
    />
  );
};

export default WebhookReceiversWidgetCard;
