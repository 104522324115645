import React, { useCallback, useEffect, useRef, useState, useContext } from "react";
import { Redirect, Route, useHistory, Switch, useLocation } from "react-router-dom";
import { Location } from "history";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import useAppContext from "../components/context/useAppContext";
// THIRD PARTIES
import * as Sentry from "@sentry/react";

// UTILITIES
import {
  fetchCurrentUser,
  fetchWithAuth,
  fetchWithoutAuth,
  getAuthToken,
  hasAuthToken,
} from "../api-client/APIClient";
import { APICategory, Integration, INVOICE_FE_DISABLED_STATUSES } from "../models/Entities";
import INTEGRATION_CATEGORY_LIST from "../models/Helpers";

import {
  getIntegrationConfigurationPathForIndividualCategory,
  API_TESTER_PATH,
  API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH,
  BILLING_PATH,
  ADD_PAYMENT_METHOD_PATH,
  CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_ADD_PATH,
  CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH,
  CONFIGURATION_INTEGRATIONS_PATH,
  CONFIGURATION_COMMON_MODELS_PATH,
  CONFIGURATION_PATH,
  DASHBOARD_PATH,
  ONBOARDING_PATH,
  ISSUES_PATH,
  LINKED_ACCOUNTS_PATH,
  LOGIN_PATH,
  LOGIN_WITH_SSO_PATH,
  LOGS_PATH,
  NOTIFICATIONS_SETTINGS_PATH,
  ORGANIZATION_PATH,
  AUDIT_TRAIL_PATH,
  PASSWORD_RESET_PATH,
  PASSWORD_SEND_RESET_PATH,
  PROFILE_PATH,
  REFERRALS_PATH,
  SAML_NO_ACCOUNT_FOUND,
  SAML_RELAY_PATH,
  SECURITY_PATH,
  SIGNUP_PATH,
  useQuery,
  API_KEYS_PATH,
  SETTINGS_PATHS,
  CONFIGURATION_INTEGRATIONS_ENDPOINT_USAGE_PATH,
} from "./RouterUtils";

// CONTEXT
import { UserWithBillingPlan } from "../components/context/AppContext";
import AppContextProvider from "../components/context/AppContextProvider";

// WRAPPERS
import LeftNavigationBar from "../components/portal/LeftNavigationBar";
import PortalPageContainer from "../components/portal/PortalPageContainer";

// AUTH
import SignUpScreen from "../components/authentication/signup/SignUpScreen";
import Login from "../components/authentication/login/Login";
import ResetPasswordScreen from "../components/authentication/login/ResetPasswordScreen";
import SendResetPasswordScreen from "../components/authentication/login/SendResetPasswordScreen";
import SendSsoUrlScreen from "../components/authentication/login/SendSsoUrlScreen";
import SamlNoAccountFound from "../components/authentication/saml/SamlNoAccountFound";
import SamlRedirectScreen from "../components/authentication/saml/SamlRedirectScreen";

// ONBOARDING
import DashboardOnboarding from "../components/get-started/DashboardOnboarding";

// DASHBOARD
import Dashboard from "../components/dashboard/Dashboard";

// ADVANCED
import IntegrationsManagementIndividualIssuePageV2 from "../components/integrations-management/issues/detail-page/IntegrationsManagementIndividualIssuePageV2";
import IntegrationsManagementIssuesPage from "../components/integrations-management/issues/IntegrationsManagementIssuesPage";
import LinkedAccountsTab from "../components/integrations-management/linked-accounts/LinkedAccountsTab";
import ConfigurationIntegrationsPageWrapper from "../components/configuration/integrations/ConfigurationIntegrationsPageWrapper";
import ConfigurationIntegrationsPage from "../components/configuration/integrations/ConfigurationIntegrationsPage";
import ConfigurationCustomIntegrations from "../components/configuration/integrations/custom-integrations/ConfigurationCustomIntegrations";
import ConfigurationEditCustomIntegrations from "../components/configuration/integrations/custom-integrations/ConfigurationEditCustomIntegrations";
import ConfigurationCommonModelsFullPage from "../components/configuration/common-models/ConfigurationCommonModelsFullPage";
import ConfigurationTab from "../components/configuration/ConfigurationTab";

// DEVELOP
import APIKeysPage from "../components/integrations-management/api-keys/APIKeysPage";

// ACCOUNT SETTINGS
import BillingPage from "../components/settings/billing/BillingPage";
import AddPaymentMethod from "../components/settings/billing/AddPaymentMethod";
import NotificationSettingsPage from "../components/settings/notifications/NotificationSettingsPage";
import OrganizationPage from "../components/settings/organization/OrganizationPage";
import Profile from "../components/settings/profile/Profile";
import Referrals from "../components/settings/Referrals";
import SecurityPage from "../components/settings/security/SecurityPage";
import ErrorFallback from "../components/shared-components/ErrorFallback/ErrorFallback";

// COMPONENTS
import AddPaymentMethodModal from "../components/settings/billing/components/AddPaymentMethodModal";
import UpdatedTermsModal from "../components/portal/announcements/UpdatedTermsModal";

import { DashboardOnboardingStatus } from "../components/get-started/DashboardOnboardingWrapper";
import { BannerToShow } from "../components/settings/billing/components/DashboardBanners";
import { isRouteDisabled } from "../components/shared-components/utils/SharedComponentUtils";
import ComponentForFeature, { isFeatureEnabled } from "./ComponentForFeature";
import G2SurveyModal from "../components/portal/announcements/G2SurveyModal";
import {
  isBillingPlanFreePlan,
  isBillingPlanLaunchPlan,
} from "../components/settings/billing/BillingUtils";
import AuditLogsPage from "../components/settings/audit-logs/AuditLogsPage";
import SettingsPage from "../components/settings/SettingsPage";
import GatedRoute from "./GatedRoute";
import ApiTesterPage from "../components/integrations-management/api-tester/ApiTesterPage";
import TagManager from "../react-gtm-module-nonce";

import * as Frigade from "@frigade/react";

import { MergeFlagFeature, useMergeFlag } from "../components/shared-components/hooks/useMergeFlag";
import useDashboardOnboardingStatus from "../components/get-started/utils/useDashboardOnboardingStatus";
import { FrigadeProvider } from "./FrigadeProvider";
import IntegrationEndpointUsagePage from "../components/configuration/integrations/endpoint-usage/IntegrationEndpointUsagePage";

const getModalForIdentifier = (modalIdentifier: null | string) => {
  // Query string driven modals - use for flows
  switch (modalIdentifier) {
    case "addPaymentMethod":
      return <AddPaymentMethodModal onHide={() => {}} />;
    default:
      return null;
  }
};

const removeTrailingSlash = (pathOrUrl: string) =>
  pathOrUrl.endsWith("/") && pathOrUrl.length > 1
    ? pathOrUrl.substring(0, pathOrUrl.length - 1)
    : pathOrUrl;

type AppContainerProps = {
  isPricingBannerVisible: boolean;
  topResize: number;
};

interface FrigadeIntegrationOption {
  value: string;
  label: string;
}

type FrigadeIntegrationState = Record<string, FrigadeIntegrationOption[]> | undefined;

const AppContainer = styled.div<AppContainerProps>`
  position: fixed;
  ${(props) => (props.isPricingBannerVisible ? `top: ${props.topResize}px;` : `top: 0px;`)}
  right: 0;
  bottom: 0;
  left: 0;
`;

const G2SurveyModalWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  z-index: 1000;
  max-width: 1009px;
  @media (min-width: 768px) {
    position: absolute;
    bottom: 24px;
    width: 60%;
  }
`;

const PYLON_APP_ID = "55deac61-3309-47d6-b720-05a1bb6d653b";

type PortalProps = {
  user: UserWithBillingPlan | undefined;
  setUser: (user: UserWithBillingPlan) => void;
};

const PortalContainer = ({ user, setUser }: PortalProps) => {
  const location = useLocation<Location>();
  const pricingBannerRef = useRef<HTMLInputElement>(null);
  const [bannerHeight, setBannerHeight] = useState<number | null>(null); // 56 px is the default height of the banner
  const [hasBannerLoaded, setBannerHasLoaded] = useState<boolean>(false);
  const freeAccountGated = user?.is_free_account_gating_enabled ?? false;
  const [fetchedOnboardingStatus, setFetchedOnboardingStatus] =
    useState<DashboardOnboardingStatus | null>(null);

  const [categoryToIntegrationMap, setCategoryToIntegrationsMap] =
    useState<FrigadeIntegrationState>(undefined);

  const { enabled: isDefaultOffScopesEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_DEFAULT_OFF_SCOPES,
    organizationId: user?.organization.id,
  });

  const { enabled: isEndpointUsageEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_ENABLE_ENDPOINT_USAGE,
    organizationId: user?.organization.id,
  });

  let shouldHomePageDefaultToDashboard = true;
  if (isDefaultOffScopesEnabled) {
    shouldHomePageDefaultToDashboard =
      (fetchedOnboardingStatus?.has_made_request &&
        fetchedOnboardingStatus?.has_successfully_linked_account &&
        fetchedOnboardingStatus?.has_opened_production_link &&
        fetchedOnboardingStatus?.has_pulled_common_model_data &&
        fetchedOnboardingStatus?.has_toggled_scopes) ??
      false;
  } else {
    shouldHomePageDefaultToDashboard =
      (fetchedOnboardingStatus?.has_made_request &&
        fetchedOnboardingStatus?.has_successfully_linked_account &&
        fetchedOnboardingStatus?.has_opened_production_link &&
        fetchedOnboardingStatus?.has_pulled_common_model_data) ??
      false;
  }

  const isLoadingOnboardingStatus = !fetchedOnboardingStatus;

  useEffect(() => {
    fetchWithoutAuth({
      path: "/integrations",
      method: "GET",
      onResponse: (data: Integration[]) => {
        const integrationMap: FrigadeIntegrationState = {};

        data.forEach((integration: Integration) => {
          const categories = integration.categories;

          categories.forEach((category) => {
            if (!integrationMap[category]) {
              integrationMap[category] = [];
            }

            integrationMap[category].push({
              value: integration.slug,
              label: integration.name,
            });
          });
        });

        setCategoryToIntegrationsMap(integrationMap);
      },
    });
  }, []);

  // hook to determine size of banner to properly adjust AppContainer upon resize
  useEffect(() => {
    const updateHeight = () => {
      if (!pricingBannerRef.current) {
        setBannerHasLoaded(true);
        return;
      }
      setBannerHeight(pricingBannerRef.current.getBoundingClientRect().height);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [hasBannerLoaded, pricingBannerRef.current?.getBoundingClientRect().height]); // doing this to fix a bug on mobile screens where banner height is less than hardcoded expected height

  useEffect(() => {
    fetchWithAuth({
      path: "/users/me/onboarding-status",
      method: "GET",
      onResponse: (data: DashboardOnboardingStatus) => {
        setFetchedOnboardingStatus(data);
      },
    });
  }, []);

  const { addProperties: addFrigadeUserProperties } = Frigade.useUser();
  const { addProperties: addFrigadeGroupProperties } = Frigade.useGroup();

  useEffect(() => {
    if (user) {
      window.pylon = {
        chat_settings: {
          app_id: PYLON_APP_ID,
          email: user.email,
          name: user.name,
          avatar_url: user.avatar,
          email_hash: user.pylon_hmac,
        },
      };

      window?.Vitally.init(
        "ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmhkV1FpT2lJMU5UWm1OV0U1WmkxbE5UZGtMVFJpT0RVdE9ESmtNUzAyT1dVd01ETTBOekUzWVRFaUxDSmhjR2tpT2lKa05tVmlNMlU1WVMwME5ETmtMVFJpTVdZdFlUVmxaUzAyTm1NeFlUUmxNVGswTWpjaUxDSnpaV01pT2lJMllUSTNPR0ZoWXlKOS5uVTRkS1NiMkZrX0d1dGtqbmtEYzBHMW8wLXA3TkxZbVJ3OHlPeHVWNnV3Og==",
      );
      window?.Vitally?.account({
        accountId: user?.organization.id, // A unique ID assigned by your codebase to each account (i.e. a database ID)
        traits: {
          name: user?.organization.name, // A 'name' trait is required
          planTier:
            user.organization.organization_billing_plan?.billing_plan.plan_tier || "NO_PLAN_TIER",
          // Add any other traits here you'd like to attach to your accounts in Vitally
        },
      });

      window?.Vitally?.user({
        userId: user.email, // A unique ID assigned by your codebase to each user (i.e. a database ID)
        accountId: user?.organization?.id, // Same account ID used in Vitally.account above - used to associate the user to the account
        traits: {
          name: user.name,
          email: user.email,
          // Add any other traits here you'd like to attach to your users in Vitally
        },
      });

      // https://docs.vitally.io/using-vitallys-nps-surveys/installing-and-configuring-nps-surveys#step-2-collect-nps-feedback-from-users
      window?.Vitally?.nps("survey", {
        productName: "Merge", // Specify the name of your product. It will be injected into the question: "👋 there! Quick question - how likely are you to recommend {productName} to others?"
        primaryColor: "#121314",
        npsQuestion: "How likely are you to recommend Merge to others?",
      });

      const numberofProductionLinkedAccounts =
        numberOfStaticLinkedAccounts ?? (user.first_prod_linked_account_created_at ? "N/A" : 0);

      addFrigadeUserProperties({
        name: user.name,
        email: user.email,
        organization: user.organization.name,
        createdDate: user.terms_agreement_datetime,
        role: user.type,
        numberOfLinkedAccounts: numberofProductionLinkedAccounts,
        hasFinishedOnboarding: shouldHomePageDefaultToDashboard,
        planTier:
          user.organization.organization_billing_plan?.billing_plan.plan_tier || "NO_PLAN_TIER",
        firstProdLinkedAccountCreatedAt: user.first_prod_linked_account_created_at ?? "N/A",
      });

      addFrigadeGroupProperties({
        id: user.organization.id,
        name: user.organization.name,
        planTier:
          user.organization.organization_billing_plan?.billing_plan.plan_tier || "NO_PLAN_TIER",
        production_linked_account_count: numberofProductionLinkedAccounts,
      });
    }
  }, [user]);

  const dashboardOnboardingStatus = useDashboardOnboardingStatus();

  if (!hasAuthToken()) {
    return <Redirect to={LOGIN_PATH} />;
  }

  if (!user) {
    return null;
  }

  /// /////////////////////////////
  /// /// Pricing Banner Methods & Constants  //////
  /// /////////////////////////////

  const isLaunchPlan = isBillingPlanLaunchPlan(
    user.organization.organization_billing_plan?.billing_plan,
  );

  const isFreePlan = isBillingPlanFreePlan(
    user.organization.organization_billing_plan?.billing_plan,
  );

  const showUpgradedBanner = user.show_upgrade_billing_plan_banner && isLaunchPlan;

  /**
   * The core difference between average_production_linked_account_count and static_production_linked_account_count is one is
   * our moving average, while the other is the actual number that currently exists
   */
  const averageNumberOfLinkedAccounts = user.organization.average_production_linked_account_count;
  const numberOfStaticLinkedAccounts = user.organization.static_production_linked_account_count;
  const dashboardDisabledForBilling =
    user.organization.invoice_overdue_status &&
    INVOICE_FE_DISABLED_STATUSES.includes(user.organization.invoice_overdue_status);

  const linkedAccountsBelowLimitBanner =
    numberOfStaticLinkedAccounts !== undefined &&
    numberOfStaticLinkedAccounts > 0 &&
    numberOfStaticLinkedAccounts <= 3 &&
    freeAccountGated;

  const linkedAccountsAboveLimitBanner =
    numberOfStaticLinkedAccounts !== undefined &&
    numberOfStaticLinkedAccounts > 3 &&
    freeAccountGated;

  const bannerStatus =
    showUpgradedBanner ||
    user.organization.invoice_overdue_status ||
    user.organization.billing_guardrails_status ||
    null;

  const showAnyBanner =
    location.pathname !== ADD_PAYMENT_METHOD_PATH &&
    !user.is_pricing_banner_dismissed &&
    (linkedAccountsBelowLimitBanner ||
      linkedAccountsAboveLimitBanner ||
      (bannerStatus !== null &&
        user.organization.average_production_linked_account_count !== undefined));

  // const isInvoiceDueDateNotNull = user.organization.invoice_due_date !== null;
  function formatInvoiceDueDate(dateString?: string): string {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
    };
    return `due ${date.toLocaleDateString("en-US", options)}`;
  }

  function isInvoiceDueDateNull(string: string | undefined) {
    return string === null;
  }

  function dismissPricingBanner() {
    fetchWithAuth({
      path: "/users/me",
      method: "PATCH",
      body: {
        is_pricing_banner_dismissed: true,
      },
      onResponse: (data) => {
        setUser(data.user);
      },
    });
  }

  /// /////////////////////////////
  /// /// G2 MODAL BELOW  //////
  /// /////////////////////////////

  /**
   * Takes currentDate and lastDismissed date, compares the two to calculate a day difference
   * Per post-sales instructions, if the feature flag is enabled for the org we'll want these banners to appear a week after dismissal,
   * with a maximum of 4 dismissals.
   */
  const currentDateTime = new Date();
  const lastDismissedDate = new Date(user.g2_banner_last_dismiss_date) ?? null;
  const timeDiff = currentDateTime.getTime() - lastDismissedDate.getTime();
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  const renderTimeBanner = daysDiff >= 7 && user.g2_banner_dismiss_count < 4;

  const displayBanner = isFeatureEnabled("is_g2_survey_enabled", user) && renderTimeBanner;
  const FREE_GATING_ANNOUNCEMENT_CSS = {
    ".fr-dialog": {
      overflow: "hidden",
      borderRadius: "8px",
      outline: "none",
    },
    ".fr-announcement-header": {
      order: 0,
      textAlign: "left",
    },
    ".fr-image": {
      order: 1,
      borderRadius: "10px 10px 10px 10px",
      aspectRatio: "1.9",
      margin: "-12px 0 0 0",
      minHeight: "238px",
    },
    ".fr-announcement-footer": {
      order: 3,
    },
    ".fr-announcement": {
      maxWidth: "494px",
      paddingBottom: "24px",
    },
  };

  // NOTE: do not remove div with ref={pricingBannerRef} - this takes the height of the banner and applies it to AppContext's height to move the entire dash down as the banner grows in size
  return (
    <>
      <AppContextProvider user={user} setUser={setUser}>
        <>
          {location.pathname !== ADD_PAYMENT_METHOD_PATH && (
            <BannerToShow
              freeAccountGated={freeAccountGated}
              showAnyBanner={showAnyBanner}
              bannerStatus={bannerStatus}
              showUpgradedBanner={showUpgradedBanner}
              isFreePlan={isFreePlan}
              showLimitBelowThreeBanner={linkedAccountsBelowLimitBanner}
              showLimitAboveThreeBanner={linkedAccountsAboveLimitBanner}
              numberOfLinkedAccounts={averageNumberOfLinkedAccounts}
              numberOfStaticLinkedAccounts={numberOfStaticLinkedAccounts}
              isInvoiceDueDateNull={isInvoiceDueDateNull(user.organization.invoice_due_date)}
              invoiceDueDate={formatInvoiceDueDate(user.organization.invoice_due_date)}
              pricingBannerRef={pricingBannerRef}
              dismiss={dismissPricingBanner}
              pricingBannerDismissed={user.is_pricing_banner_dismissed}
            />
          )}
          {/* <ProductionAccountAlert */}
          <>
            <Frigade.Form
              as={Frigade.Dialog}
              className="new_customer_questionaire"
              flowId="flow_UD7k8W2gCzkrF7kT"
              dismissible
              onPrimary={(step, event, properties) => {
                addFrigadeUserProperties({
                  keyCategory: properties?.["category"],
                  initialIntegration: properties?.["follow-up"],
                  role: properties?.["role"],
                  implementationTimeframe: properties?.["timeframe"],
                  goalWithMerge: properties?.["textMultiline"],
                });

                return true;
              }}
              fieldTypes={{
                DynamicFollowUpBasedOnCategory: (props: Frigade.FormFieldProps) => {
                  const categoryValue = props.formContext.watch("category");

                  if (!categoryValue) {
                    return null;
                  }

                  const category = categoryValue;
                  const categorySpecificData = categoryToIntegrationMap
                    ? categoryToIntegrationMap?.[category] ??
                      Object.values(categoryToIntegrationMap ?? [])[0]
                    : [];

                  const initialChoice: FrigadeIntegrationOption[] = [
                    {
                      value: "i-don't-know-which-integration",
                      label: "Not sure which integration",
                    },
                  ];

                  const options = initialChoice.concat(categorySpecificData) ?? [];

                  return (
                    <Frigade.SelectField
                      {...props}
                      fieldData={{
                        ...props.fieldData,
                        options,
                      }}
                    />
                  );
                },
              }}
              css={{
                ".fr-form": {
                  maxWidth: "500px",
                },
                ".fr-field-label-required": {
                  display: "none",
                },
                ".fr-title": {
                  fontSize: "20px",
                },
                ".fr-field-select-icon": {
                  height: "12px",
                  width: "12px",
                },
              }}
            />
            <Frigade.Form
              as={Frigade.Dialog}
              className="more_than_14_days_without_prod_linked_account"
              flowId="flow_fBg5mXrZXKvq8GWX"
              dismissible
            />
            <Frigade.Announcement
              flowId="flow_OyOhal7jhrw2PYbA"
              className="generic_announcement"
              modalPosition="center"
              dismissible
            />
            {freeAccountGated && (
              <Frigade.Announcement
                flowId="flow_k3M8SUsp"
                css={FREE_GATING_ANNOUNCEMENT_CSS}
                dismissible
              />
            )}
          </>
        </>

        <AppContainer
          isPricingBannerVisible={showAnyBanner}
          topResize={bannerHeight ?? (freeAccountGated ? 62 : 56)}
        >
          <LeftNavigationBar dashboardOnboardingStatus={dashboardOnboardingStatus} />
          <PortalPageContainer>
            <Sentry.ErrorBoundary
              onError={(error) => {
                if (process.env.REACT_APP_MERGE_ENV !== "PRODUCTION") {
                  console.error(error);
                }
              }}
              fallback={({ error }) => <ErrorFallback error={error} />}
            >
              {!isLoadingOnboardingStatus && (
                <Route
                  exact
                  path="/"
                  children={
                    shouldHomePageDefaultToDashboard ? (
                      <Dashboard />
                    ) : (
                      <DashboardOnboarding dashboardOnboardingStatus={dashboardOnboardingStatus} />
                    )
                  }
                />
              )}

              <Route exact path={DASHBOARD_PATH} children={<Dashboard />} />

              <Route
                path={ONBOARDING_PATH}
                children={
                  <DashboardOnboarding dashboardOnboardingStatus={dashboardOnboardingStatus} />
                }
              />

              <GatedRoute path={LINKED_ACCOUNTS_PATH} children={<LinkedAccountsTab />} />

              {!isRouteDisabled(user, LOGS_PATH) && !isRouteDisabled(user, ISSUES_PATH) && (
                <Switch>
                  <Route
                    path={LOGS_PATH}
                    children={({ match }: { match: any }) => (
                      <ComponentForFeature feature="is_webhook_search_enabled" match={match} />
                    )}
                  />
                  <Route
                    path={`${ISSUES_PATH}/:integrationIssueID`}
                    children={<IntegrationsManagementIndividualIssuePageV2 />}
                  />
                </Switch>
              )}

              <GatedRoute
                exact
                path={ISSUES_PATH}
                children={<IntegrationsManagementIssuesPage />}
              />

              <GatedRoute exact path={API_KEYS_PATH} children={<APIKeysPage />} />

              {/* Redirect from old API_TESTER_PATH  */}
              <GatedRoute path={API_TESTER_PATH} exact>
                <Redirect to={API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH} />
              </GatedRoute>

              <GatedRoute path={`${API_TESTER_PATH}/:direction/:linkedAccountType?`}>
                <ApiTesterPage />
              </GatedRoute>

              {/* Integrations Management Pages */}
              <Route path={CONFIGURATION_INTEGRATIONS_PATH}>
                <Switch>
                  {INTEGRATION_CATEGORY_LIST.map((category: APICategory) => (
                    <Route
                      key={category}
                      exact
                      path={getIntegrationConfigurationPathForIndividualCategory(category)}
                      render={() => (
                        <ConfigurationIntegrationsPage
                          category={category}
                          isEndpointUsageEnabled={!!isEndpointUsageEnabled}
                        />
                      )}
                    />
                  ))}
                  <Route
                    exact
                    path={CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH}
                    render={() => (
                      <ConfigurationIntegrationsPageWrapper
                        isEndpointUsageEnabled={!!isEndpointUsageEnabled}
                      >
                        <ConfigurationCustomIntegrations />
                      </ConfigurationIntegrationsPageWrapper>
                    )}
                  />
                  <Route
                    exact
                    path={CONFIGURATION_INTEGRATIONS_ENDPOINT_USAGE_PATH}
                    render={() => (
                      <IntegrationEndpointUsagePage
                        isEndpointUsageEnabled={isEndpointUsageEnabled}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_ADD_PATH}
                    render={() => (
                      <ConfigurationIntegrationsPageWrapper
                        isEndpointUsageEnabled={!!isEndpointUsageEnabled}
                      >
                        <ConfigurationEditCustomIntegrations />
                      </ConfigurationIntegrationsPageWrapper>
                    )}
                  />
                  <Route
                    exact
                    path={`${CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH}/:customIntegrationID/edit`}
                    render={() => (
                      <ConfigurationIntegrationsPageWrapper
                        isEndpointUsageEnabled={!!isEndpointUsageEnabled}
                      >
                        <ConfigurationEditCustomIntegrations />
                      </ConfigurationIntegrationsPageWrapper>
                    )}
                  />
                  <Redirect
                    to={getIntegrationConfigurationPathForIndividualCategory(
                      INTEGRATION_CATEGORY_LIST[0],
                    )}
                  />
                </Switch>
              </Route>

              <Route
                path={CONFIGURATION_COMMON_MODELS_PATH}
                children={<ConfigurationCommonModelsFullPage />}
              />

              <GatedRoute path={CONFIGURATION_PATH} children={<ConfigurationTab />} />
              <GatedRoute exact path={ADD_PAYMENT_METHOD_PATH} children={<AddPaymentMethod />} />

              {/* Settings/Profile Pages */}
              <Route path={SETTINGS_PATHS}>
                <SettingsPage>
                  <>
                    <GatedRoute exact path={PROFILE_PATH} children={<Profile />} />
                    <GatedRoute exact path={SECURITY_PATH} children={<SecurityPage />} />
                    <GatedRoute exact path={ORGANIZATION_PATH} children={<OrganizationPage />} />
                    <GatedRoute exact path={AUDIT_TRAIL_PATH} children={<AuditLogsPage />} />
                    <GatedRoute
                      exact
                      path={NOTIFICATIONS_SETTINGS_PATH}
                      children={<NotificationSettingsPage />}
                    />
                    <GatedRoute exact path={REFERRALS_PATH} children={<Referrals />} />
                    <GatedRoute exact path={BILLING_PATH} children={<BillingPage />} />
                  </>
                </SettingsPage>
              </Route>

              {/* Reroute if the rest of dashboard is disabled */}
              {dashboardDisabledForBilling ? <Redirect to={BILLING_PATH} /> : null}
            </Sentry.ErrorBoundary>
            {displayBanner && (
              <Row className="justify-content-center">
                <G2SurveyModalWrapper>
                  <G2SurveyModal renderBanner={displayBanner} />
                </G2SurveyModalWrapper>
              </Row>
            )}
          </PortalPageContainer>
        </AppContainer>
        <UpdatedTermsModal />
      </AppContextProvider>
    </>
  );
};

const AppRouter = () => {
  const [user, setUser] = useState<UserWithBillingPlan>();
  const location = useLocation<Location>();
  const authToken = getAuthToken();

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  const nonce = document.getElementById("csp-policy")?.getAttribute("nonce-id") || "";

  // @ts-ignore
  gtag("js", new Date());
  // @ts-ignore
  gtag("config", "UA-164577142-1");

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/signup") {
      TagManager.initialize({
        gtmId: "GTM-WZ3TTB4",
        nonce,
      });

      const mutinyScript = document.createElement("script");
      mutinyScript.innerHTML = `(function(){var a=window.mutiny=window.mutiny||{};if(!a.client){a.client={_queue:{}};var b=["identify","trackConversion"];var c=[].concat(b,["defaultOptOut","optOut","optIn"]);var d=function(c){return function(){for(var e=arguments.length,f=new Array(e),g=0;g<e;g++){f[g]=arguments[g]}a.client._queue[c]=a.client._queue[c]||[];if(b.includes(c)){return new Promise(function(b,d){a.client._queue[c].push({args:f,resolve:b,reject:d})})}else{a.client._queue[c].push({args:f})}}};c.forEach(function(b){a.client[b]=d(b)})}})();`;
      mutinyScript.nonce = nonce;
      document.body.appendChild(mutinyScript);

      const mutinyCdnScript = document.createElement("script");
      mutinyCdnScript.setAttribute("data-cfasync", "false");
      mutinyCdnScript.src =
        "https://client-registry.mutinycdn.com/personalize/client/8bdb519dafeac75a.js";
      mutinyCdnScript.nonce = nonce;
      document.body.appendChild(mutinyCdnScript);
    }
  }, [location]);

  // Don't attempt to load user on these pages.
  // Note that the Login page is excluded because the Login page will attempt to load user
  // and will redirect into the app on success, and will stay on login page on 403.
  const unauthenticatedPagePaths = [LOGIN_WITH_SSO_PATH, SAML_NO_ACCOUNT_FOUND, SAML_RELAY_PATH];

  const pathIsUnauthenticated = useCallback(
    (path?: string) => {
      if (!path) return false;
      const unauthPathsWithoutSlashes = unauthenticatedPagePaths.map(removeTrailingSlash);
      const currentPathWithoutSlash = removeTrailingSlash(path);
      return unauthPathsWithoutSlashes.includes(currentPathWithoutSlash);
    },
    [unauthenticatedPagePaths],
  );

  useEffect(() => {
    if (!user && !pathIsUnauthenticated(location.pathname)) {
      fetchCurrentUser(setUser);
    }
  }, [authToken, user, location.pathname, pathIsUnauthenticated]);

  useEffect(() => {
    if (user) {
      Sentry.setUser({ email: user.email, id: `${user.uuid}` });
    }
  }, [user]);

  const fsLoaded = "FS" in window;
  useEffect(() => {
    if (user && fsLoaded && process.env.REACT_APP_MERGE_ENV === "PRODUCTION") {
      const userVars: any = {
        displayName: user.name,
        email: user.email,
        billingPlanTier_str: user.organization.organization_billing_plan?.billing_plan?.plan_tier,
        orgName_str: user.organization.name, // remove by 5/1 (MBOGO) TODO: https://app.asana.com/0/1205550558375762/1206833359154881/f
        orgId_str: user.organization.id, // remove by 5/1 (MBOGO) TODO: https://app.asana.com/0/1205550558375762/1206833359154881/f
        organization: user.organization.name,
        organizationId: user.organization.id,
      };

      if (user.organization.organization_billing_plan?.start_date) {
        userVars["billingStartDate_date"] = new Date(
          user.organization.organization_billing_plan.start_date,
        );
      }

      window.FS.identify(user.uuid.toString(), userVars);
    }
  }, [user, fsLoaded]);

  const query = useQuery();

  return (
    <>
      {query.get("modal") ? getModalForIdentifier(query.get("modal")) : null}
      <FrigadeProvider user={user}>
        <Switch>
          <Route exact path={SAML_RELAY_PATH} children={<SamlRedirectScreen />} />
          <Route exact path={PASSWORD_SEND_RESET_PATH} children={<SendResetPasswordScreen />} />
          <Route path={PASSWORD_RESET_PATH} children={<ResetPasswordScreen />} />
          <Route exact path={LOGIN_PATH} children={<Login setUser={setUser} />} />
          <Route exact path={SIGNUP_PATH} children={<SignUpScreen setUser={setUser} />} />
          <Route exact path={LOGIN_WITH_SSO_PATH} children={<SendSsoUrlScreen />} />
          <Route exact path={SAML_NO_ACCOUNT_FOUND} children={<SamlNoAccountFound />} />
          <Route children={<PortalContainer user={user} setUser={setUser} />} />
        </Switch>
      </FrigadeProvider>
    </>
  );
};

export default AppRouter;
