import React, { useMemo, useState } from "react";
import { match, Route } from "react-router-dom";
import useAppContext from "../../../context/useAppContext";
import useWebhookLogs from "../../utils/useWebhookLogs";
import EmptyState from "../table/EmptyState";
import LoadingRows, { LoadingRowDetails } from "../table/LoadingRows";
import WebhookLogEntryRow from "../table/WebhookLogEntryRow";
import NonAdminNote from "../table/NonAdminNote";
import SearchBar from "../../searchbar/SearchBar";
import MergeTable from "../../../shared-components/MergeTable";
import { isBillingPlanLaunchPlan } from "../../../settings/billing/BillingUtils";
import WebhookLogEntrySidePanel from "../side-panel/WebhookLogEntrySidePanel";
import { LinkedAccount } from "../../../../models/Entities";

interface Props {
  match: match;
  linkedAccount?: LinkedAccount | null;
}

/**
 * The whole of the Webhooks Logs tab is contained within this element. Allows searching,
 * paging, etc of all current logs. Title of the page comes in from elsewhere.
 */
const WebhooksLogsPage = ({ match, linkedAccount }: Props) => {
  const { paginationComponent, logs, isLoading, allFilters, applyFilters, setCurrentPageToken } =
    useWebhookLogs({ linkedAccount });

  const { user, isUserPrivileged } = useAppContext();

  // Each search pill needs an associated focus value - only the last interacted should keep focus in most cases - keyed by id
  const [searchPillFocusId, setSearchPillFocusId] = useState<string | null>(null);

  const loadingRows: LoadingRowDetails[] = [
    { width: 42, columnName: "method" },
    { width: 250, height: 16, columnName: "url" },
    { width: 64, columnName: "status" },
    { width: 72, columnName: "direction" },
    { width: 70, columnName: "type" },
    { width: 70, columnName: "event" },
    { width: 100, columnName: "integration", withCircle: true },
    { width: 70, columnName: "organization" },
    { width: 130, height: 16, columnName: "date" },
  ];

  const loadingRowsLinkedAccountsTab: LoadingRowDetails[] = [
    { width: 42, columnName: "method" },
    { width: 250, height: 16, columnName: "url" },
    { width: 64, columnName: "status" },
    { width: 72, columnName: "direction" },
    { width: 70, columnName: "type" },
    { width: 70, columnName: "event" },
    { width: 130, height: 16, columnName: "date" },
  ];

  const header = (
    <>
      <th scope="col">Method</th>
      <th scope="col">URL</th>
      <th scope="col">Status</th>
      <th scope="col">Direction</th>
      <th scope="col">Webhook type</th>
      <th scope="col">Event</th>
      {!linkedAccount && <th scope="col">Integration</th>}
      {!linkedAccount && <th scope="col">Organization</th>}
      <th scope="col">Date</th>
      <th style={{ width: "24px" }} />
    </>
  );

  const content = useMemo(() => {
    if (isLoading) {
      return linkedAccount ? (
        <LoadingRows loadingRows={loadingRowsLinkedAccountsTab} />
      ) : (
        <LoadingRows loadingRows={loadingRows} />
      );
    } else if (!logs || logs.length <= 0) {
      return <EmptyState />;
    } else {
      // Make sure we close any focus when we push, otherwise coming back opens a dropdown
      return logs.map((log) => (
        <WebhookLogEntryRow key={log.id} log={log} match={match} linkedAccount={linkedAccount} />
      ));
    }
  }, [isLoading, logs, match]);

  return (
    <>
      {isBillingPlanLaunchPlan(user.organization.organization_billing_plan?.billing_plan) &&
        !isUserPrivileged && <NonAdminNote />}
      <div className={linkedAccount ? "mb-6" : "mb-9"}>
        <SearchBar
          linkedAccount={linkedAccount}
          allFilters={allFilters}
          executeSearch={applyFilters}
          filterSearchesBySearchTerm={() => {}}
          searchPillFocusId={searchPillFocusId}
          setSearchPillFocusId={setSearchPillFocusId}
          setCurrentPageToken={setCurrentPageToken}
        />
      </div>
      <MergeTable header={header} content={content} hasMorePaddingOnFirstElement shouldMaskFS />
      {paginationComponent}
      <Route exact path={`${match.url}/:logID`} render={() => <WebhookLogEntrySidePanel />} />
    </>
  );
};

export default WebhooksLogsPage;
