import sortBy from "lodash/sortBy";
import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";
import { useMemo, useCallback } from "react";
import { ConditionPreset, ConditionPresetMeta, Integration } from "../../../../../models/Entities";
import { getSupportedIntegrations } from "../../ConditionPresetInputCombo/helpers";

type UseConditionPresetIntegrationsProps = {
  conditionPresets: Partial<ConditionPreset>[];
  conditionPresetMeta: ConditionPresetMeta | undefined;
};

const useConditionPresetIntegrations = ({
  conditionPresets,
  conditionPresetMeta,
}: UseConditionPresetIntegrationsProps) => {
  /**
   * Condition presets that are complete enough to actually save
   */
  const completeConditionPresets = useMemo(
    () => conditionPresets.filter((conditionPreset) => !!conditionPreset.normalized_key_name),
    [conditionPresets],
  );
  /**
   * list of all supported integration for each condition schema
   *
   * @returns array of array of integrations, where each index maps to an existing conditionPreset
   */
  const supportedIntegrationsForConditionSchemas = useMemo(() => {
    if (!conditionPresetMeta) return [];

    return completeConditionPresets.map((conditionPreset) =>
      getSupportedIntegrations(conditionPreset, conditionPresetMeta),
    );
  }, [conditionPresetMeta, completeConditionPresets]);

  /**
   *  Helper for finding all supported condition presets for a given integration
   * */
  const getSupportedConditionPresets = useCallback(
    (integration: Pick<Integration, "id" | "name" | "square_image">) => {
      const conditionPresetsForIntegration = completeConditionPresets.filter(
        (_, index) =>
          supportedIntegrationsForConditionSchemas[index]?.find(({ id }) => id === integration?.id),
      );

      return sortBy(conditionPresetsForIntegration, [
        "is_end_user_configured",
        "common_model",
        "normalized_key_name",
      ]);
    },
    [completeConditionPresets, supportedIntegrationsForConditionSchemas],
  );

  /**
   * Unique list of all integrations with at least one condition preset.
   */
  const integrations = useMemo(() => {
    return sortBy(uniqBy(flatten(supportedIntegrationsForConditionSchemas), "id"), "name").map(
      (integration?: Pick<Integration, "id" | "name" | "square_image">) => {
        return {
          ...integration,
          conditionPresets: getSupportedConditionPresets(integration!),
        };
      },
    );
  }, [getSupportedConditionPresets, supportedIntegrationsForConditionSchemas]);

  return {
    integrations,
    previewLinkEnabled: !!integrations.length,
    hasMappedFiltersForCategory: conditionPresetMeta && !!Object.keys(conditionPresetMeta).length,
  };
};

export default useConditionPresetIntegrations;
