import React from "react";
import { SectionHeaderWrapper, TitleHeading } from "../../../shared-components/MergeLayouts";
import {
  getLinkedAccountDetailAPIRequestLogsSettingsPathForId,
  getLinkedAccountDetailWebhookLogsSettingsPathForId,
} from "../../../../router/RouterUtils";
import { LinkedAccount } from "../../../../models/Entities";
import SubtabToggle from "../../../shared-components/form-field/SubtabToggle";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import WebhookLogsPage from "../../logs/pages/WebhooksLogsPage";
import LogsPage from "../../logs/pages/LogsPage";
import { Subtab } from "../../../portal/HeaderBar";
import styled from "styled-components";

type Props = {
  linkedAccount: LinkedAccount;
};

const MarginLeftSubtabToggle = styled(SubtabToggle)`
  margin-left: 20px;
`;

const MarginBottomSectionHeader = styled(SectionHeaderWrapper)`
  margin-bottom: 24px;
`;

/**
 * Shows a few settings for the linked account itself. Always guaranteed to have content if there's a linked account
 */
const LinkedAccountLogsTab = ({ linkedAccount }: Props) => {
  const location = useLocation();
  const subtitle = location.pathname.startsWith(
    getLinkedAccountDetailAPIRequestLogsSettingsPathForId(linkedAccount.id),
  )
    ? "View API request logs related to this Linked Account"
    : "View webhook logs related to this Linked Account";

  const subTabs: Subtab[] = [
    {
      label: "API requests",
      destination: getLinkedAccountDetailAPIRequestLogsSettingsPathForId(linkedAccount.id),
    },
    {
      label: "Webhooks",
      destination: getLinkedAccountDetailWebhookLogsSettingsPathForId(linkedAccount.id),
    },
  ];
  return (
    <>
      <MarginBottomSectionHeader
        title={
          <div className="d-flex justify-content-center align-items-center">
            Logs <MarginLeftSubtabToggle isRounded subtabs={subTabs} />{" "}
          </div>
        }
        titleHeading={TitleHeading.H1}
        subtitle={subtitle}
      >
        <Switch>
          <Route
            path={getLinkedAccountDetailAPIRequestLogsSettingsPathForId(linkedAccount.id)}
            render={(props) => <LogsPage {...props} linkedAccount={linkedAccount} />}
          />

          <Route
            path={getLinkedAccountDetailWebhookLogsSettingsPathForId(linkedAccount.id)}
            render={(props) => <WebhookLogsPage {...props} linkedAccount={linkedAccount} />}
          />

          <Redirect to={getLinkedAccountDetailAPIRequestLogsSettingsPathForId(linkedAccount.id)} />
        </Switch>
      </MarginBottomSectionHeader>
    </>
  );
};

export default LinkedAccountLogsTab;
