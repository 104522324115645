import React from "react";
import { SyncConnectionIntegration } from "../../../../../../../models/Entities";
import IntegrationButton from "./IntegrationButton";

type IntegrationsListProps = {
  integrations: SyncConnectionIntegration[];
  onIntegrationSelect: (integration: SyncConnectionIntegration) => void;
};

const IntegrationsList = ({ integrations, onIntegrationSelect }: IntegrationsListProps) => {
  return (
    <div className="flex flex-col overflow-y-auto scrollbar-hide">
      <div className="grid grid-cols-3 gap-3 px-5 pt-4">
        {integrations.map((integration) => (
          <IntegrationButton
            key={integration.id}
            integration={integration}
            onClick={() => onIntegrationSelect(integration)}
          />
        ))}
      </div>

      {/* forces 20px spacing after overflow grid */}
      <div className="h-5" />
    </div>
  );
};

export default IntegrationsList;
