import React from "react";
import { Badge, capitalizeFirstLetter } from "@merge-api/merge-javascript-shared";
import { ChevronRight } from "lucide-react";

type MappingOverviewRowProps = {
  rowHeader: React.ReactNode;
  fieldName: string;
  description: string;
  numberOfMappings: number;
  type?: string;
  typeOfMapping: string;
  onRowClick: () => void;
};
const MappingOverviewRow = ({
  rowHeader,
  fieldName,
  type,
  description,
  numberOfMappings,
  typeOfMapping,
  onRowClick,
}: MappingOverviewRowProps) => {
  return (
    <button className="block w-100 p-0" onClick={onRowClick}>
      <div className="flex gap-5 items-center hover:cursor-pointer hover:bg-slate-0  border-t border-gray-20 py-4 px-6">
        <div className="flex-1 ">
          <div className="flex items-center gap-1">{rowHeader}</div>
          <div className="flex gap-2 items-center">
            <div className="font-mono text-base font-medium ">{fieldName}</div>
            {type && (
              <div>
                <Badge size="lg" color="gray">
                  <span className="text-[11px] font-mono text-gray-70 !font-normal">
                    {capitalizeFirstLetter(type)}
                  </span>
                </Badge>
              </div>
            )}
          </div>
          <div className="text-gray-50 text-left text-sm">{description}</div>
        </div>
        <div className="flex items-center ml-4">
          <div className={`${numberOfMappings == 0 ? "text-gray-50" : " "} font-semibold text-xs`}>
            {numberOfMappings} {typeOfMapping == "field-mappings" ? "mapping" : "override"}
            {numberOfMappings == 1 ? "" : "s"}
          </div>
          <div className="ml-6">
            <ChevronRight size={12} className="text-gray-60" />
          </div>
        </div>
      </div>
    </button>
  );
};
export default MappingOverviewRow;
