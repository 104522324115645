import React from "react";
import clsx from "clsx";
import { Lock } from "lucide-react";
import {
  MergeLinkNavButtons,
  MergeLinkFooter,
} from "../../../../common/MergeLinkDialog/components";
import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { SyncConnectionIntegration } from "../../../../../models/Entities";

type SuccessStepProps = {
  className?: string;
  integration: SyncConnectionIntegration;
  onClose: () => void;
  onBack: () => void;
};

const SuccessStep = ({ className, onClose, onBack, integration }: SuccessStepProps) => {
  return (
    <div className={clsx("h-full flex flex-col justify-start mt-5", className)}>
      {/* Header */}
      <MergeLinkNavButtons onClose={onClose} onBack={onBack} />
      <div className="flex flex-row justify-between items-center mx-5 mb-5 mt-3">
        <Text variant="h3">Successfully connected</Text>
        <img className="flex h-[40px]" src={integration.image} />
      </div>

      {/* Body */}
      <div className="flex flex-col flex-1 overflow-hidden border-t border-b border-gray-10 justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="66"
          height="50"
          viewBox="0 0 66 50"
          fill="none"
        >
          <path
            d="M59.3229 1.30803C57.8699 -0.144993 55.5141 -0.144993 54.0611 1.30803L25.0934 30.2757L11.9386 17.121C10.4856 15.668 8.12982 15.668 6.67681 17.121L1.41496 22.3829C-0.0380597 23.8359 -0.0380591 26.1917 1.41496 27.6447L22.4623 48.6921C23.9154 50.1451 26.2712 50.1451 27.7242 48.6921L27.7279 48.6884C27.7359 48.6805 27.7439 48.6726 27.7519 48.6646L64.5848 11.8317C66.0378 10.3787 66.0378 8.02289 64.5848 6.56987L59.3229 1.30803Z"
            fill="#008561"
          />
        </svg>

        <Text variant="h4" className="mt-8 mb-2">
          Success!
        </Text>
        <Text variant="lg" className="max-w-[216px] text-center">
          You&apos;ve connected your {integration.name} data warehouse
        </Text>
      </div>

      {/* Footer */}
      <MergeLinkFooter submitButtonText="Finish" onSubmit={onClose}>
        <Alert size="sm" color="gray" icon={<Lock size={12} />}>
          Your data is protected with 256-bit SSL bank-level encryption
        </Alert>
      </MergeLinkFooter>
    </div>
  );
};

export default SuccessStep;
