import React, { useEffect, useState } from "react";
import { Button, ButtonVariant, Tooltip } from "@merge-api/merge-javascript-shared";
import { MonitorUp } from "lucide-react";
import { ReactComponent as MergeLink } from "../../../../../src/components/get-started/src/merge-link.svg";
import useCustomizationContext from "../context/useCustomizationContext";
import { fetchCreateTestLinkToken } from "../../../../api-client/APIClient";
import useCustomizationChangeCounts from "./useCustomizationChangeInformation";
import { getTenantConfigForMergeLink } from "../../../integrations-management/linked-accounts/AddTestLinkedAccountButton";
import { useMergeLink } from "@mergeapi/react-merge-link";

const CustomizationViewPublishButtons = () => {
  const [linkToken, setLinkToken] = useState<string | undefined>(undefined);
  const {
    integrationCustomizations,
    originalIntegrationCustomizations,
    organizationCustomization,
    originalOrganizationCustomization,
    setModalTypeShown,
  } = useCustomizationContext();

  const { publishDisabled, hasValidationError } = useCustomizationChangeCounts(
    organizationCustomization,
    originalOrganizationCustomization,
    integrationCustomizations,
    originalIntegrationCustomizations,
  );

  useEffect(() => {
    fetchCreateTestLinkToken(setLinkToken);
  }, [originalIntegrationCustomizations, originalOrganizationCustomization]);

  const tenantConfig = getTenantConfigForMergeLink();

  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess: () => {},
    onExit: () => {
      fetchCreateTestLinkToken(setLinkToken);
    },
    ...tenantConfig,
  });

  return (
    <div className="flex flex-row gap-3">
      <Button
        size="sm"
        variant={ButtonVariant.TertiaryWhite}
        leftIcon={<MergeLink className="h-3 w-3" />}
        onClick={() => (publishDisabled ? open() : setModalTypeShown("VIEW_CHANGES"))}
        loading={!isReady}
      >
        View Merge Link
      </Button>
      <Tooltip
        title={
          hasValidationError && publishDisabled
            ? "Please fix all errors before publishing."
            : undefined
        }
      >
        <Button
          size="sm"
          variant={ButtonVariant.PrimaryBlue}
          leftIcon={<MonitorUp className="h-3 w-3" />}
          onClick={() => setModalTypeShown("PUBLISH")}
          disabled={publishDisabled}
        >
          Publish changes
        </Button>
      </Tooltip>
    </div>
  );
};

export default React.memo(CustomizationViewPublishButtons);
