import React, { useState } from "react";
import { Key, EyeOff, Eye } from "lucide-react";
import useAppContext from "../../../../../../context/useAppContext";
import CopyComponentWithIcon from "../../../../../../shared-components/CopyComponentWithIcon";
import { Text } from "@merge-api/merge-javascript-shared";

type AccountTokenProps = {
  accountToken?: string;
};

const AccountToken = ({ accountToken }: AccountTokenProps) => {
  const [isAccountTokenHidden, setIsAccountTokenHidden] = useState(true);
  const { isUserPrivileged } = useAppContext();

  if (!isUserPrivileged) return null;

  return (
    <>
      <Text variant="title-sm" className="flex items-center">
        Account token
      </Text>
      <div className="flex justify-between items-center px-2 py-[3px] rounded border-[0.5px] border-gray-20 bg-gray-10">
        {isAccountTokenHidden ? (
          <>
            <div className="cursor-pointer">
              <EyeOff size={12} onClick={() => setIsAccountTokenHidden(!isAccountTokenHidden)} />
            </div>
            <Text
              variant="sm-mono"
              className="flex items-center text-ellipsis overflow-hidden whitespace-nowrap mx-2"
            >
              {"•".repeat(accountToken?.length ?? 0)}
            </Text>
          </>
        ) : (
          <>
            <div className="cursor-pointer">
              <Eye size={12} onClick={() => setIsAccountTokenHidden(!isAccountTokenHidden)} />
            </div>
            <Text
              variant="sm-mono"
              className="block text-ellipsis overflow-hidden whitespace-nowrap mx-2"
            >
              {accountToken}
            </Text>
          </>
        )}
        <CopyComponentWithIcon text={accountToken} />
      </div>
    </>
  );
};

export default AccountToken;
