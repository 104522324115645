import React, { useState } from "react";
import UpsellModal from "../../../shared-components/UpsellModal";
import AuditLogsLoadingStateTable from "./AuditLogsLoadingStateTable";
import { ReactComponent as FDAuditLogs } from "../../../../assets/svg/feature-discovery-audit-logs.svg";

// constants
const MODAL_TOP_VALUE = "24px";
const MODAL_WIDTH_VALUE = "476px";

const AuditLogsUpsellModal = () => {
  // state
  const [showUpsellModal, setShowUpsellModal] = useState(true);

  return (
    <div>
      <UpsellModal
        modalTitle="Audit trail"
        customImage={<FDAuditLogs className="-ml-2 mb-6" />}
        modalBodyText={
          <>
            <p>Need to know who did what, where, and when?</p>
            <p>Track all activity for your organization with a detailed audit trail.</p>
            <div className="text-sm text-gray-50 mt-3 mb-6">
              Unlock this feature with our Enterprise plan.
            </div>
          </>
        }
        includeLearnMoreButton={false}
        includeModalCloseOption={false}
        showModal={showUpsellModal}
        setShowModal={setShowUpsellModal}
        modalTopValue={MODAL_TOP_VALUE}
        modalWidthValue={MODAL_WIDTH_VALUE}
        upsellButtonId="upsell_button_audit_trail"
        learnMoreButtonId="learn_more_button_audit_trail"
      />
      <div style={{ opacity: 0.5 }}>
        <AuditLogsLoadingStateTable />
      </div>
    </div>
  );
};

export default AuditLogsUpsellModal;
