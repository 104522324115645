import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import WebhookSystemImg from "../../../../assets/img/webhook-system.png";

import {
  DOCS_WEBHOOK_EMITTERS_PATH,
  DOCS_WEBHOOK_RECEIVERS_PATH,
  ONBOARDING_ADVANCED_SYNCING_DATA_PATH,
} from "../../../../router/RouterUtils";
import DeprecatedH3 from "../../../../deprecated/DeprecatedH3";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import DeprecatedH5 from "../../../../deprecated/DeprecatedH5";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";
import { Button } from "@merge-api/merge-javascript-shared";

const Container = styled.div`
  @media (min-width: 1330px) {
    margin: 32px auto;
    max-width: 75%;
    padding-bottom: 64px;
  }
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 24px;
`;

const ShadedArea = styled.div`
  border-radius: 10px;
  width: 100%;
  display: block;
  background: #eff6ff;
  padding: 16px 20px;
`;

const Header = styled(DeprecatedH3)`
  font-size: 24px;
  line-height: 36px;
`;

const Subtitle = styled(DeprecatedH6)`
  font-size: 14px;
  line-height: 24px;
`;

const Webhooks = () => {
  return (
    <Container>
      <Card>
        <Header className="mb-1">Webhooks</Header>
        <Subtitle className="mb-6 gray font-normal">
          Use Merge webhooks and third-party webhooks to keep your product up to date in real-time
        </Subtitle>

        <p>
          Merge offers webhooks to send you data when it is updated in connected third-party
          platforms.
        </p>
        <p>
          Third-party webhooks will allow you to get near real-time data updates from Merge, without
          having to wait for the next poll.
        </p>
        <aside className="my-9">
          Refer to our <Link to={ONBOARDING_ADVANCED_SYNCING_DATA_PATH}>syncing data</Link> guide to
          learn how to effectively sync data using polling and webhooks.
        </aside>
        <p className="mb-9">
          Merge offers two webhook systems to send data from third parties to your product:{" "}
          <strong>Merge webhooks</strong> and <strong>third-party webhooks</strong>.
        </p>
        <img src={WebhookSystemImg} width="100%" />
        <DeprecatedH3 style={{ marginBottom: 8 }}>Merge webhooks</DeprecatedH3>
        <DeprecatedH5 className="mb-6 gray font-normal">Data from Merge to You</DeprecatedH5>
        <p>
          Configure Merge webhooks to send updates to your product when Merge has received user data
          updates from third parties.
        </p>
        <p>Merge offers webhooks for other event types (such as sync completion notifications).</p>
        <p className="mb-9">
          Learn more in our{" "}
          <a target="_blank" href={DOCS_WEBHOOK_EMITTERS_PATH} rel="noreferrer">
            Merge webhook docs
          </a>
          .
        </p>

        <ShadedArea style={{ marginBottom: 56 }}>
          <DeprecatedH4>Merge webhooks</DeprecatedH4>
          <p className="small">Configure Merge webhooks that send data payloads to your product</p>
          <Button
            size="sm"
            rightIcon={<ChevronRight size={12} />}
            onClick={() => window.open(DOCS_WEBHOOK_EMITTERS_PATH, "_blank")}
          >
            View docs
          </Button>
        </ShadedArea>
        <DeprecatedH3 style={{ marginBottom: 8 }}>Third-party webhooks</DeprecatedH3>
        <DeprecatedH5 className="mb-6 gray font-normal">
          Data from third-party platforms to Merge
        </DeprecatedH5>
        <p>
          When data is updated in third parties, third-party webhooks can send data to Merge
          receivers and update your user data in Merge.
        </p>
        <p>
          Some integrations offer automatic third-party webhook creation when your user connects
          their account. Merge configures these by interacting with the third-party API.
        </p>
        <p>
          Integrations that do not offer automatic third-party webhook creation may have manual
          configuration options.
        </p>
        <p className="mb-9">
          Learn more in our{" "}
          <a target="_blank" href={DOCS_WEBHOOK_RECEIVERS_PATH} rel="noreferrer">
            Third-party webhook docs
          </a>
          .
        </p>
        <ShadedArea className="mb-6">
          <DeprecatedH4>Third-party webhooks</DeprecatedH4>
          <p className="small">
            Configure webhooks in integrated third parties that send data payloads to Merge
            receivers
          </p>
          <Button
            size="sm"
            rightIcon={<ChevronRight size={12} />}
            onClick={() => window.open(DOCS_WEBHOOK_RECEIVERS_PATH, "_blank")}
          >
            View docs
          </Button>
        </ShadedArea>
      </Card>
    </Container>
  );
};

export default Webhooks;
