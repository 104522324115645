import React from "react";
import {
  CONFIGURATION_LINK_CONFIGURATION_PATH,
  CONFIGURATION_LINK_FEATURES_PATH,
  CONFIGURATION_LINK_SELECTIVE_SYNC,
  CONFIGURATION_LINK_THEME_PATH,
  CONFIGURATION_LINK_WHITE_LABEL_GUIDE_PATH,
} from "../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../portal/SubLeftNavigationContainer";
import { canSeeSelectiveSyncSettings } from "../ConfigurationEligibilityHelpers";
import useAppContext from "../../context/useAppContext";
import useProductRestrictions from "../../shared-components/hooks/useProductRestrictions";

/**
 * Creates a wrapper for the Link configurations page
 */
const ConfigurationLinkPageWrapper = ({
  children,
}: Pick<React.ComponentProps<"div">, "children">) => {
  const { isUserPrivileged, user } = useAppContext();
  const { productRestrictions } = useProductRestrictions();

  const customizableLinkEnabled = productRestrictions?.customizable_link_enabled;

  const customizableLinkSubtabs = [
    {
      label: "Themes",
      destination: CONFIGURATION_LINK_THEME_PATH,
      isBeta: false,
    },
    {
      label: "Features",
      destination: CONFIGURATION_LINK_FEATURES_PATH,
    },
    {
      label: "Configuration",
      destination: CONFIGURATION_LINK_CONFIGURATION_PATH,
    },
    {
      label: "White-label guides",
      destination: CONFIGURATION_LINK_WHITE_LABEL_GUIDE_PATH,
      isBeta: true,
    },
  ];

  const subtabs = [
    {
      label: "Configuration",
      destination: CONFIGURATION_LINK_CONFIGURATION_PATH,
    },
  ];

  if (canSeeSelectiveSyncSettings(isUserPrivileged, user)) {
    subtabs.push({
      label: "Selective Sync",
      destination: CONFIGURATION_LINK_SELECTIVE_SYNC,
    });
  }

  return (
    <SubLeftNavigationContainer
      subtabs={customizableLinkEnabled ? customizableLinkSubtabs : subtabs}
    >
      {children}
    </SubLeftNavigationContainer>
  );
};

export default ConfigurationLinkPageWrapper;
