import { useEffect, useState } from "react";
import { format } from "date-fns";

import {
  BillingPlanTier,
  CurrentCategoryCharge,
  OrganizationBillingPlan,
  OrganizationBillingPlanCounts,
} from "../BillingModels";
import { showErrorToast } from "../../../shared-components/Toasts";
import { fetchOrgBillingPlan, fetchOrgBillingPlanCounts } from "../BillingAPIClient";
import { parseCategoryUnitCountsFromBillingPlan } from "../BillingUtils";
import isUndefined from "lodash/isUndefined";

// constants
const TRIAL_ORG_BILLING_PLAN = {
  billing_plan: {
    name: "Merge Beta Plan",
    is_active: true,
    plan_tier: BillingPlanTier.BILLING_PLAN_TIER_LAUNCH,
    monthly_price_cents: 0,
    api_request_price_cents: 0,
    scrape_price_cents: 0,
    webhook_price_cents: 0,
    free_api_requests: 10000,
    free_scrapes: -1,
    free_webhooks: -1,
  },
  start_date: format(new Date(), "M/d/yyyy"),
  end_date: format(new Date(2021, 5, 1), "M/d/yyyy"),
} as OrganizationBillingPlan;

/**
 * Hook to load OrganizationBillingPlan and OrganizationBillingPlanCounts.
 * Also handles parsing common model counts w/ prices to render
 */
const useLoadOrganizationBillingPlan = () => {
  // state
  const [organizationBillingPlan, setOrganizationBillingPlan] = useState<OrganizationBillingPlan>();
  const [organizationBillingPlanCounts, setOrganizationBillingPlanCounts] =
    useState<OrganizationBillingPlanCounts>();
  const [commonModelPrices, setCommonModelPrices] = useState<CurrentCategoryCharge[] | undefined>();
  const [isLoadingOrganizationBillingPlan, setIsLoadingOrganizationBillingPlan] = useState(true);
  const [isLoadingCommonModelPrices, setIsLoadingCommonModelPrices] = useState(true);

  // effects
  // fetch billing plan
  useEffect(() => {
    fetchOrgBillingPlan({
      onFetch: (organizationBillingPlan: OrganizationBillingPlan) => {
        setIsLoadingOrganizationBillingPlan(false);
        setOrganizationBillingPlan(organizationBillingPlan);
      },
      onError: (e: any) => {
        if (e.status === 404) {
          // Orgs that have no plan are considered on trial plan
          setOrganizationBillingPlan(TRIAL_ORG_BILLING_PLAN);
        } else {
          showErrorToast("Error loading billing plan.");
        }
        setIsLoadingOrganizationBillingPlan(false);
      },
    });
  }, []);

  // fetch billing plan counts
  useEffect(() => {
    fetchOrgBillingPlanCounts({
      onFetch: (organizationBillingPlanCounts: OrganizationBillingPlanCounts) => {
        setOrganizationBillingPlanCounts(organizationBillingPlanCounts);
        setIsLoadingCommonModelPrices(false);
      },
      onError: (e: any) => {
        setIsLoadingCommonModelPrices(false);
        if (e.status !== 404) {
          // Orgs that have no plan are considered on trial plan.
          // And we do not fetch counts w/o a plan so if no plan do nothing
          showErrorToast("Error loading billing plan counts.");
        }
      },
    });
  }, []);

  // parse commonModelPrices
  useEffect(() => {
    if (!isUndefined(organizationBillingPlan) && !isUndefined(organizationBillingPlanCounts)) {
      setCommonModelPrices(
        parseCategoryUnitCountsFromBillingPlan(
          organizationBillingPlan,
          organizationBillingPlanCounts,
        ),
      );
    }
  }, [organizationBillingPlan, organizationBillingPlanCounts]);

  return {
    organizationBillingPlan,
    setOrganizationBillingPlan,
    commonModelPrices,
    isLoadingOrganizationBillingPlan,
    isLoadingCommonModelPrices,
  };
};

export default useLoadOrganizationBillingPlan;
