import React from "react";

import { Gem, X } from "lucide-react";
import UpsellButton from "../shared-components/UpsellButton";
import useAppContext from "../context/useAppContext";
import { postUpgradeInterest } from "../../api-client/APIClient";
import { userEventTracker } from "../settings/billing/components/DashboardBanners";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";

type Props = {
  modalTitle: string;
  customImage: JSX.Element;
  modalBodyText: JSX.Element;
  includeLearnMoreButton: boolean;
  learnMoreRedirect?: string;
  includeModalCloseOption: boolean;
  showModal: boolean;
  setShowModal: (isOpen: boolean) => void;
  modalTopValue?: string;
  modalRightValue?: string;
  modalWidthValue?: string;
  upsellButtonId: string;
  learnMoreButtonId: string;
};

const UpsellModal = ({
  modalTitle,
  customImage,
  modalBodyText,
  includeLearnMoreButton,
  learnMoreRedirect,
  includeModalCloseOption,
  showModal,
  setShowModal,
  modalTopValue,
  modalRightValue,
  modalWidthValue,
  upsellButtonId,
  learnMoreButtonId,
}: Props) => {
  const { user, requestedPlanUpgrade, setRequestedPlanUpgrade } = useAppContext();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div
      className="w-100 flex justify-center absolute"
      style={{
        backgroundColor: "rgba(0,0,0,0)",
        zIndex: "1000",
      }}
    >
      {showModal && (
        <div
          className="pt-5 pr-6 pl-6 pb-6 bg-white rounded-lg shadow-md relative overflow-hidden"
          style={{
            width: modalWidthValue,
            top: modalTopValue,
            right: modalRightValue,
          }}
        >
          {includeModalCloseOption && (
            <Button
              onClick={handleCloseModal}
              size="sm"
              variant={ButtonVariant.IconShadowHidden}
              className="absolute top-2.5 right-2.5"
            >
              <X size={12} />
            </Button>
          )}
          <div className="flex flex-wrap mb-3 ml-0 mr-0">
            <div
              className="py-0.5 px-2 rounded-3xl font-semibold title-sm flex items-center"
              style={{
                background: "linear-gradient(149deg, #fbe8ab 0%, #fac975 100%)",
              }}
            >
              <Gem size={10} className="mr-1 mb-0" />
              Premium feature
            </div>
          </div>

          <h3 className="mb-6">{modalTitle}</h3>
          {customImage}
          {modalBodyText}
          <div className="flex flex-row gap-3">
            {includeLearnMoreButton && (
              <UpsellButton
                className="flex"
                onClick={() => {
                  window.open(learnMoreRedirect, "_blank");
                  userEventTracker({
                    event_name_input: learnMoreButtonId,
                    impressions_input: 0,
                    dismisses_input: 0,
                    clicks_input: 1,
                  });
                }}
                buttonContent="Learn more"
                buttonColor="solidGrey"
                hasAdvancedHoverState={requestedPlanUpgrade ? false : true}
                borderRadius="6px"
                buttonIsSelected={requestedPlanUpgrade}
                id={learnMoreButtonId}
              />
            )}
            <UpsellButton
              className="flex "
              onClick={() => {
                postUpgradeInterest(user, requestedPlanUpgrade, setRequestedPlanUpgrade);
                userEventTracker({
                  event_name_input: upsellButtonId,
                  impressions_input: 0,
                  dismisses_input: 0,
                  clicks_input: 1,
                });
              }}
              buttonContent={requestedPlanUpgrade ? "Upgrade requested" : "Request upgrade"}
              buttonColor={requestedPlanUpgrade ? "fadedGradientGold" : "gradientGold"}
              hasAdvancedHoverState={requestedPlanUpgrade ? false : true}
              borderRadius="6px"
              buttonIsSelected={requestedPlanUpgrade}
              id={upsellButtonId}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UpsellModal;
