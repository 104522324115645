import React from "react";
import { SectionHeaderWrapper } from "../../../shared-components/MergeLayouts";

type ConfigurationSettingsPageProps = {
  title: string;
  subtitle: string;
  subtitleRightContent?: JSX.Element;
  children: React.ReactNode;
};

const ConfigurationSettingsPage = ({
  title,
  subtitle,
  subtitleRightContent,
  children,
}: ConfigurationSettingsPageProps) => {
  return (
    <div>
      <SectionHeaderWrapper title={title} />
      <div className="flex flex-row justify-between gap-4">
        <p>{subtitle}</p> {subtitleRightContent}{" "}
      </div>
      {children}
    </div>
  );
};

export default ConfigurationSettingsPage;
