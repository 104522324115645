import React from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { OrganizationBillingPlan } from "../../../settings/billing/BillingModels";
import { getBillingPlanTitle } from "../../../settings/billing/BillingUtils";

const TopCardSection = styled.div`
  border-bottom: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
`;

const BottomCardSection = styled.div`
  background: #fcfdfe;
  color: #939aa5;
`;

const AccountLeftCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  position: static;
  width: 25px;
  left: 5px;
  top: 11.11%;
  bottom: 11.11%;
  background: #f6f2ff;
  border-radius: 3px;
`;

const AccountLeftText = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #6e25d6;
`;

const AccountNumberDisplay = styled.div`
  margin-top: 10px;
`;

const AccountsLeftGraphic = ({ countLeft }: { countLeft: number }) => {
  return (
    <AccountLeftCard>
      <AccountLeftText>{countLeft} left</AccountLeftText>
    </AccountLeftCard>
  );
};

type Props = {
  billingPlan: OrganizationBillingPlan;
};
const OrganizationBillingPlanSideCard = ({ billingPlan }: Props) => {
  const testAccountsAllowed = billingPlan.product_restrictions?.test_accounts;

  return (
    <>
      <Card>
        <TopCardSection>
          <Card.Body>
            Your <b>{`${getBillingPlanTitle(billingPlan)}`}</b> plan is currently limited to the
            following:
            <AccountNumberDisplay>
              <b>{testAccountsAllowed}</b> test accounts
            </AccountNumberDisplay>
            {false && <AccountsLeftGraphic countLeft={3} />}
          </Card.Body>
        </TopCardSection>
        <BottomCardSection>
          <Card.Body>
            For unlimited test accounts,{" "}
            <a href="mailto:hello@merge.dev" className="">
              contact us{" "}
            </a>
            to upgrade your plan today!
          </Card.Body>
        </BottomCardSection>
      </Card>
    </>
  );
};

export default OrganizationBillingPlanSideCard;
