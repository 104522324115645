import React, { useCallback, useState } from "react";
import { Card } from "react-bootstrap";
import { fetchCurrentUser, fetchWithAuth } from "../../../api-client/APIClient";
import useAppContext from "../../context/useAppContext";
import useProductRestrictions from "../../shared-components/hooks/useProductRestrictions";
import { SectionHeaderWrapper, TitleHeading } from "../../shared-components/MergeLayouts";
import { showErrorToast, showSuccessToast } from "../../shared-components/Toasts";
import WebhookReceiverSettingsGraphic from "./WebhookReceiverSettingsGraphic";
import { Button, ButtonVariant, Toggle } from "@merge-api/merge-javascript-shared";
import {
  DOCS_WEBHOOKS_THIRD_PARTIES_TO_MERGE_PATH,
  openInNewTab,
} from "../../../router/RouterUtils";
import { BookOpen } from "lucide-react";

const ConfigurationWebhookReceiversPage = () => {
  const { user, setUser, isUserPrivileged } = useAppContext();
  const { productRestrictions } = useProductRestrictions();
  const areThirdPartyWebhooksEnabled = Boolean(productRestrictions?.third_party_webhooks_enabled);
  const [isEnabled, setIsEnabled] = useState<boolean>(user.organization.enable_auto_webhook_setup);

  const updateOrganizationEnableAutoWebhookSetup = useCallback(
    (newIsEnabled: boolean) => {
      fetchWithAuth({
        path: "/organizations/toggle-enable-auto-webhook-setup",
        method: "PATCH",
        body: {
          enable_auto_webhook_setup: newIsEnabled,
        },
        onResponse: (data) => {
          fetchCurrentUser(setUser);
          setIsEnabled(data.enable_auto_webhook_setup);
          showSuccessToast(
            `Successfully ${newIsEnabled ? "enabled" : "disabled"} automatic webhooks!`,
          );
        },
        onError: () => {
          showErrorToast("Something went wrong, please check your connection and try again.");
        },
      });
    },
    [setUser],
  );

  return (
    <SectionHeaderWrapper
      title="Webhooks from third parties to Merge"
      headerRightHandContent={
        <Button
          size="sm"
          variant={ButtonVariant.TertiaryWhite}
          onClick={() => openInNewTab(DOCS_WEBHOOKS_THIRD_PARTIES_TO_MERGE_PATH)}
          leftIcon={<BookOpen size={12} />}
        >
          Webhook docs
        </Button>
      }
    >
      <Card>
        <Card.Body className="flex justify-between items-center">
          <SectionHeaderWrapper
            title="Allow automatic webhook creation"
            subtitle="You can manage what events are receiving updates in each Linked Account's
                      webhook page"
            titleHeading={TitleHeading.H3}
            image={<WebhookReceiverSettingsGraphic />}
          />
          <Toggle
            className="ml-5"
            checked={isEnabled}
            disabled={!isUserPrivileged || !areThirdPartyWebhooksEnabled}
            label={isEnabled ? "Enabled" : "Disabled"}
            onChange={updateOrganizationEnableAutoWebhookSetup}
            toggleTooltipTitle={
              !isUserPrivileged
                ? "Must be an admin user to change this setting"
                : !areThirdPartyWebhooksEnabled
                ? "Upgrade your plan to enable third party webhooks in order to unlock this feature"
                : ""
            }
          />
        </Card.Body>
      </Card>
    </SectionHeaderWrapper>
  );
};

export default ConfigurationWebhookReceiversPage;
