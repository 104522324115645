import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import SkeletonLoader from "../../../shared-components/SkeletonLoader";

type LinkedAccountsCountsCardDataRowProps = {
  text: string;
  count?: number;
};

const LinkedAccountsCountsCardDataRow: React.FC<LinkedAccountsCountsCardDataRowProps> = ({
  text,
  count,
}) => {
  return (
    <div className="flex justify-between items-center self-stretch mt-2 mb-2">
      <Text className="text-black font-inter text-sm font-normal leading-5">{text}</Text>
      {count != null ? (
        <Text className="text-black font-inter text-sm font-normal leading-5">{count}</Text>
      ) : (
        <SkeletonLoader height={10} borderRadius={10}></SkeletonLoader>
      )}
    </div>
  );
};

export default LinkedAccountsCountsCardDataRow;
