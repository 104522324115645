import React from "react";
import { Card, Toggle } from "@merge-api/merge-javascript-shared";
import { useHistory } from "react-router-dom";
import useAppContext from "../../../../../context/useAppContext";
import MappingOverviewRow from "./MappingOverviewRow";
import { Box, Globe } from "lucide-react";
import {
  navigateToFieldMappingSettings,
  navigateToIndividualCommonModelOverrideTargetPage,
  CONFIGURATION_COMMON_MODELS_PATH,
} from "../../../../../../router/RouterUtils";
import {
  OrganizationFieldMappingValue,
  OverriddenCommonModelTarget,
  FieldMappingTarget,
} from "../../../../../../models/Entities";
import { APICategory } from "../../../../../../models/Entities";
import { patchFieldMappingConfiguration } from "../../utils/FieldMappingUtils";
type CommonModelMappingInformationCardProps = {
  commonModelName: string;
  mappingInfo: OrganizationFieldMappingValue;
  category: APICategory;
};

const CommonModelMappingInformationCard = ({
  commonModelName,
  mappingInfo,
  category,
}: CommonModelMappingInformationCardProps) => {
  const {
    override_common_model_targets: overrideTargets,
    field_mapping_targets: fieldMappingTargets,
    has_remote_data_enabled: isRemoteDataEnabled,
    allow_user_created_field_mapping_targets: endUserTargetsEnabled,
    model_configuration_id: modelConfigurationId,
  } = mappingInfo;

  const history = useHistory();
  const { user } = useAppContext();

  const toggleCustomMappingConfigUserCreatedCustomFields = (
    configID: string,
    new_allow_user_created_field_mapping_targets: string,
  ) => {
    patchFieldMappingConfiguration({
      body: {
        configuration_id: configID,
        allow_user_created_field_mapping_targets:
          new_allow_user_created_field_mapping_targets === "enabled",
      },
      category,
      onResponse: () => {},
      onError: () => {},
    });
  };

  return (
    <div id={`${commonModelName}-mapping-info`}>
      <Card variant="shadow" className="">
        <div className="flex py-4 px-6 items-center">
          <div className="text-lg font-semibold ">{commonModelName}</div>
          <div className="flex items-center ml-auto">
            {!isRemoteDataEnabled || !modelConfigurationId ? (
              <>
                <span className="text-sm">
                  <a href={`${CONFIGURATION_COMMON_MODELS_PATH}`}>Enable Remote Data</a>
                  {"  "}
                  to add fields or mappings for this model
                </span>
              </>
            ) : (
              <>
                <Toggle
                  checked={endUserTargetsEnabled}
                  label="End user-created target fields"
                  onChange={() => {
                    toggleCustomMappingConfigUserCreatedCustomFields(
                      modelConfigurationId,
                      endUserTargetsEnabled ? "disabled" : "enabled",
                    );
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div>
          {overrideTargets.map((overrideTarget: OverriddenCommonModelTarget) => {
            return (
              <MappingOverviewRow
                key={overrideTarget.id}
                rowHeader={
                  <>
                    {" "}
                    <Box className="text-blue-40" size={12} />
                    <div className="text-sm text-blue-40 font-medium">
                      Common Model override field
                    </div>
                  </>
                }
                fieldName={overrideTarget.overridden_field}
                description={overrideTarget.overridden_field_definition?.description || ""}
                type={
                  overrideTarget.overridden_field_definition?.format ||
                  overrideTarget.overridden_field_definition?.type ||
                  ""
                }
                numberOfMappings={
                  overrideTarget.total_int_wide_overrides == null
                    ? 0
                    : overrideTarget.total_int_wide_overrides
                }
                typeOfMapping="common-model-overrides"
                onRowClick={() => {
                  navigateToIndividualCommonModelOverrideTargetPage(history, overrideTarget.id);
                }}
              />
            );
          })}
          {fieldMappingTargets.map((fieldMappingTarget: FieldMappingTarget) => {
            return (
              <MappingOverviewRow
                key={fieldMappingTarget.id}
                rowHeader={
                  <>
                    {" "}
                    <Globe className="text-orange-50" size={12} />
                    <div className="text-sm text-orange-50 font-medium">
                      {user.organization.name}-wide target field
                    </div>
                  </>
                }
                fieldName={fieldMappingTarget.field_key}
                description={fieldMappingTarget.field_description}
                numberOfMappings={
                  fieldMappingTarget.total_int_wide_field_mappings == null
                    ? 0
                    : fieldMappingTarget.total_int_wide_field_mappings
                }
                typeOfMapping="field-mappings"
                onRowClick={() => {
                  navigateToFieldMappingSettings(history, fieldMappingTarget.id);
                }}
              />
            );
          })}
        </div>
      </Card>
    </div>
  );
};
export default CommonModelMappingInformationCard;
