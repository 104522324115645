import React from "react";
import { navigateToLinkedAccountDetailSelectiveSyncPathForId } from "../../../../../../../router/RouterUtils";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import { useHistory } from "react-router-dom";

type SelectiveSyncWidgetCardProps = {
  selectiveSyncFiltersCount: number | undefined;
  linkedAccountId: string;
};

const SelectiveSyncWidgetCard = ({
  selectiveSyncFiltersCount,
  linkedAccountId,
}: SelectiveSyncWidgetCardProps) => {
  const history = useHistory();

  return (
    <ConfigurationWidgetCard
      cardTitle="Selective Sync"
      includeRightChevron
      onClick={() => navigateToLinkedAccountDetailSelectiveSyncPathForId(history, linkedAccountId)}
      badgeChildren={selectiveSyncFiltersCount}
    />
  );
};

export default SelectiveSyncWidgetCard;
