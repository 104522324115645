import React, { Dispatch, SetStateAction, useMemo } from "react";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import {
  getLinkedAccountDetailOverviewPathForId,
  getLinkedAccountDetailWebhooksPathForId,
  getLinkedAccountDetailAPIRequestLogsSettingsPathForId,
  getLinkedAccountDetailLogsSettingsPathForId,
  getLinkedAccountDetailWebhookLogsSettingsPathForId,
  getLinkedAccountDetailSettingsPathForId,
  getLinkedAccountDetailFieldMappingsPathForId,
  getLinkedAccountIssuesPathForId,
  getLinkedAccountSelectiveSyncPathForId,
} from "../../../../router/RouterUtils";
import LinkedAccountLogsTab from "../logs-page/LinkedAccountLogsTab";
import SettingsPage from "./SettingsPage";
import FieldMappingsPage from "./field-mappings/FieldMappingsPage";
import IssuesPage from "./issues/IssuesPage";
import OverviewPage from "./overview/OverviewPage";
import WebhooksPage from "./webhooks/WebhooksPage";
import { LinkedAccount } from "../../../../models/Entities";
import useAppContext from "../../../context/useAppContext";
import { IntegrationIssue } from "../../IntegrationsManagementEntities";
import { Alert } from "@merge-api/merge-javascript-shared";
import LinkedAccountSelectiveSyncPage from "./selective-sync/LinkedAccountSelectiveSyncPage";

type LinkedAccountDetailPageRouterProps = {
  linkedAccount: LinkedAccount | null;
  errorOccurred: boolean;
  shouldShowWebhooksTab: boolean;
  shouldShowSelectiveSyncTab: boolean;
  shouldShowFieldMappingsTab: boolean;
  updateLinkedAccount: () => void;
  ongoingIntegrationIssue: IntegrationIssue | null;
  refetchIntegrationIssues: () => void;
  isDataWarehouseAccount: boolean;
};

const LinkedAccountDetailPageRouter = ({
  linkedAccount,
  errorOccurred,
  shouldShowWebhooksTab,
  shouldShowSelectiveSyncTab,
  shouldShowFieldMappingsTab,
  updateLinkedAccount,
  ongoingIntegrationIssue,
  refetchIntegrationIssues,
  isDataWarehouseAccount,
}: LinkedAccountDetailPageRouterProps) => {
  // hooks
  const { user } = useAppContext();
  const { id: linkedAccountId } = useParams<Pick<LinkedAccount, "id">>();

  // render
  if (!linkedAccount) return null;

  return (
    <Switch>
      {/* Overview */}
      <Route exact path={getLinkedAccountDetailOverviewPathForId(linkedAccountId)}>
        <OverviewPage
          linkedAccount={linkedAccount}
          ongoingIntegrationIssue={ongoingIntegrationIssue}
          shouldShowWebhooksTab={shouldShowWebhooksTab}
          shouldShowSelectiveSyncTab={shouldShowSelectiveSyncTab}
          shouldShowFieldMappingsTab={shouldShowFieldMappingsTab}
          refetchIntegrationIssues={refetchIntegrationIssues}
          isDataWarehouseAccount={isDataWarehouseAccount}
        />
      </Route>

      {/* Webhooks */}
      <Route exact path={getLinkedAccountDetailWebhooksPathForId(linkedAccountId)}>
        {shouldShowWebhooksTab && <WebhooksPage linkedAccount={linkedAccount} />}
      </Route>

      {/* Logs */}
      <Route
        path={[
          getLinkedAccountDetailAPIRequestLogsSettingsPathForId(linkedAccountId),
          getLinkedAccountDetailLogsSettingsPathForId(linkedAccountId),
          getLinkedAccountDetailWebhookLogsSettingsPathForId(linkedAccountId),
        ]}
      >
        <LinkedAccountLogsTab linkedAccount={linkedAccount} />
      </Route>

      {/* Settings */}
      <Route exact path={getLinkedAccountDetailSettingsPathForId(linkedAccountId)}>
        <SettingsPage linkedAccount={linkedAccount} updateLinkedAccount={updateLinkedAccount} />
      </Route>

      {/* Field Mappings */}
      <Route exact path={getLinkedAccountDetailFieldMappingsPathForId(linkedAccountId)}>
        {shouldShowFieldMappingsTab && <FieldMappingsPage linkedAccount={linkedAccount} />}
      </Route>

      {/* Issues */}
      <Route exact path={getLinkedAccountIssuesPathForId(linkedAccountId)}>
        <IssuesPage linkedAccount={linkedAccount} />
      </Route>

      {/* Selective Sync */}
      <Route exact path={getLinkedAccountSelectiveSyncPathForId(linkedAccountId)}>
        {shouldShowSelectiveSyncTab && (
          <LinkedAccountSelectiveSyncPage linkedAccount={linkedAccount} />
        )}
      </Route>

      <Redirect to={getLinkedAccountDetailOverviewPathForId(linkedAccountId)} />
    </Switch>
  );
};

export default LinkedAccountDetailPageRouter;
