import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { showSuccessToast, showErrorToast } from "../../shared-components/Toasts";
import Dropzone from "../../shared-components/Dropzone";
import useAppContext from "../../context/useAppContext";
import classNames from "classnames";
import { Form } from "react-bootstrap";
import { Button } from "@merge-api/merge-javascript-shared";

type Props = { onSuccess?: () => void };
function ChangeMeUserForm(props: Props) {
  const { setUser, user } = useAppContext();
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState<null | Blob>();

  function handleAvatarUpload(files: Array<any>) {
    const avatarFile = files[0];
    const blob = new Blob([avatarFile], { type: avatarFile.type });
    setAvatar(blob);
  }

  const onSubmit = (data: { name: string; email: string }) => {
    setIsLoading(true);
    const formData = {
      avatar,
      name: data.name,
    };

    fetchWithAuth({
      path: "/users/me",
      method: "PATCH",
      body: formData,
      onResponse: (data) => {
        showSuccessToast("Successfully updated your profile!");
        setUser(data.user);
        setIsLoading(false);
        props.onSuccess && props.onSuccess();
      },
      onError: () => {
        showErrorToast("Failed to update your profile.");
        setIsLoading(false);
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Form.Group>
        <Form.Label>Full name *</Form.Label>
        <Form.Control
          name="name"
          type="text"
          className={classNames({ "is-invalid": errors.name })}
          ref={register({ required: true, minLength: 1, maxLength: 100 })}
          defaultValue={user.name}
        />
        <Form.Control.Feedback type="invalid">Please enter a valid name.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control readOnly name="email" type="text" disabled defaultValue={user.email} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Avatar</Form.Label>
        <Dropzone upload={handleAvatarUpload} />
      </Form.Group>
      <Button loading={isLoading} type="submit">
        Save changes
      </Button>
    </Form>
  );
}

export default ChangeMeUserForm;
