import React, { useEffect, useState } from "react";
import { Card, Form, ListGroup } from "react-bootstrap";
import styled from "styled-components";

import { fetchWithAuth } from "../../../api-client/APIClient";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import { showErrorToast } from "../../shared-components/Toasts";
import DeprecatedH2 from "../../../deprecated/DeprecatedH2";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";
import DeprecatedH5 from "../../../deprecated/DeprecatedH5";
import { Checkbox, Button, Toggle, ButtonVariant } from "@merge-api/merge-javascript-shared";

const CardHeader = styled(Card.Header)`
  && {
    display: block;
    height: auto;
    padding-top: 8px;
  }
`;

const CardHeaderText = styled(DeprecatedH2)`
  && {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 3px;
  }
`;

const CardSubtitle = styled(Card.Subtitle)`
  && {
    font-size: 14px;
    line-height: 24px;
    color: #99a9c6;
    font-weight: normal;
    margin: 0;
  }
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const ItemTitle = styled(DeprecatedH4)`
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin: 0 12px 0 0;
`;

const ItemSectionTitle = styled(DeprecatedH5)<{ $isFirst?: boolean }>`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #737882;
  margin-top: ${({ $isFirst }) => ($isFirst ? "0" : "35px")};
  margin-bottom: 0;
  padding 0;
`;

const ButtonsInline = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  > * + * {
    margin-left: 8px;
  }
`;

interface NotificationConfiguration {
  user: string | number;
  activity_email: boolean;
  new_issue_opened: boolean;
  resolved_issue_reopened: boolean;
}

type PartialNotificationConfiguration = Partial<Omit<NotificationConfiguration, "user">>;
type NotificationConfigurationName = keyof PartialNotificationConfiguration;
type NotificationConfigurationNameSet = Set<NotificationConfigurationName>;

function NotificationSettingsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [configsPendingChange, setConfigsPendingChange] =
    useState<NotificationConfigurationNameSet>(new Set());
  const [notificationConfiguration, setNotificationConfiguration] = useState<
    NotificationConfiguration | undefined
  >(undefined);

  const activityNotificationsAreEnabled = notificationConfiguration?.activity_email || false;

  const patchNotificationConfiguration = (configs: PartialNotificationConfiguration) => {
    const formData = { ...configs };
    for (const configName in configs) {
      if (configsPendingChange.has(configName as NotificationConfigurationName)) {
        // Debounce buttons by doing a no-op for a given config name if a PATCH is already pending for it.
        delete formData[configName as NotificationConfigurationName];
      }
    }
    if (Object.keys(formData).length === 0) {
      return;
    }
    const newConfigsPendingChange = new Set([
      ...Array.from(configsPendingChange),
      ...Object.keys(formData),
    ]) as NotificationConfigurationNameSet;
    setConfigsPendingChange(newConfigsPendingChange);

    fetchWithAuth({
      path: "/users/me/notification-configuration",
      method: "PATCH",
      body: formData,
      onResponse: (data) => {
        setNotificationConfiguration(data);
        setIsLoading(false);
        setConfigsPendingChange(new Set());
      },
      onError: () => {
        showErrorToast("Failed to update your profile.");
        setIsLoading(false);
        setConfigsPendingChange(new Set());
      },
    });
  };
  const fetchNotificationConfiguration = () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    fetchWithAuth({
      path: "/users/me/notification-configuration",
      method: "GET",
      onResponse: (data: NotificationConfiguration) => {
        setNotificationConfiguration(data);
        setIsLoading(false);
      },
      onError: () => {
        showErrorToast(
          "Failed to fetch your notification settings. Please check your connection and try again.",
        );
        setIsLoading(false);
      },
    });
  };
  useEffect(fetchNotificationConfiguration, []);
  return (
    <>
      <Card>
        <CardHeader>
          <CardHeaderText>Activity</CardHeaderText>
          <CardSubtitle>Receive updates about activity in your integrations</CardSubtitle>
        </CardHeader>
        <Card.Body className="p-0">
          {isLoading ? (
            <EmptyStateWrapper isSpinner />
          ) : (
            <ListGroup variant="flush">
              <ListGroup.Item className="px-6">
                <Checkbox
                  checked={notificationConfiguration?.activity_email}
                  onChange={() =>
                    patchNotificationConfiguration({
                      activity_email: !notificationConfiguration?.activity_email,
                    })
                  }
                  name="checkbox-activity-email"
                  label="Email notifications"
                  variant="primary"
                />
              </ListGroup.Item>
              <ListGroup.Item className="px-6 pt-0">
                <FlexSpaceBetween>
                  <ItemTitle>Notify me about these events</ItemTitle>
                  <ButtonsInline>
                    <Button
                      variant={ButtonVariant.TertiaryWhite}
                      size="sm"
                      onClick={() =>
                        patchNotificationConfiguration({
                          new_issue_opened: true,
                          resolved_issue_reopened: true,
                        })
                      }
                      disabled={!activityNotificationsAreEnabled}
                    >
                      Enable all
                    </Button>
                    <Button
                      variant={ButtonVariant.TertiaryWhite}
                      size="sm"
                      onClick={() =>
                        patchNotificationConfiguration({
                          new_issue_opened: false,
                          resolved_issue_reopened: false,
                        })
                      }
                      disabled={!activityNotificationsAreEnabled}
                    >
                      Disable all
                    </Button>
                  </ButtonsInline>
                </FlexSpaceBetween>
                <ItemSectionTitle $isFirst>Issues</ItemSectionTitle>
                <Form>
                  <Toggle
                    className="mt-2.5 mb-3"
                    name="toggle-new-issue-opened"
                    checked={notificationConfiguration?.new_issue_opened || false}
                    onChange={() =>
                      patchNotificationConfiguration({
                        new_issue_opened: !notificationConfiguration?.new_issue_opened,
                      })
                    }
                    label="New issue opened"
                    disabled={!activityNotificationsAreEnabled}
                    labelPlacement="right"
                  />
                  <Toggle
                    name="toggle-resolved-issue-reopened"
                    checked={notificationConfiguration?.resolved_issue_reopened || false}
                    onChange={() =>
                      patchNotificationConfiguration({
                        resolved_issue_reopened:
                          !notificationConfiguration?.resolved_issue_reopened,
                      })
                    }
                    label="Resolved issue reopened"
                    disabled={!activityNotificationsAreEnabled}
                    labelPlacement="right"
                  />
                </Form>
              </ListGroup.Item>
            </ListGroup>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default NotificationSettingsPage;
