import React from "react";
import clsx from "clsx";
import ConditionPresetInputCombo from "./ConditionPresetInputCombo";
import { ConditionPreset, ConditionPresetMeta } from "../../../models/Entities";
import { Text, Button, Tooltip } from "@merge-api/merge-javascript-shared";
import { getFilteredModelOptions } from "./ConditionPresetInputCombo/helpers";
import { Plus } from "lucide-react";

type ConditionPresetsListProps = {
  className?: string;
  conditionPresets: Partial<ConditionPreset>[];
  conditionPresetErrors: (string | undefined)[];
  conditionPresetMeta: ConditionPresetMeta;
  onConditionPresetAdd: () => void;
  onConditionPresetDelete: (index: number) => void;
  onConditionPresetChange: (conditionPreset: Partial<ConditionPreset>, index: number) => void;
};

const ConditionPresetsList = ({
  className,
  conditionPresets,
  conditionPresetErrors,
  conditionPresetMeta,
  onConditionPresetAdd,
  onConditionPresetDelete,
  onConditionPresetChange: propsOnConditionPresetChange,
}: ConditionPresetsListProps) => {
  // derived state
  const hasConditionPresets = !!conditionPresets.length;
  const canAddFilter = !!getFilteredModelOptions({}, conditionPresetMeta, conditionPresets).length;

  // event handlers
  const onConditionPresetChange =
    (index: number) => (conditionPreset: Partial<ConditionPreset>) => {
      propsOnConditionPresetChange(conditionPreset, index);
    };

  return (
    <div className={clsx("", className)}>
      <Text>Selectively sync data...</Text>

      {conditionPresets.map((conditionPreset, index) => {
        // derive key from common_model + normalized_key_name in the
        // event that it is a new condition preset, which wont have an id
        const conditionPresetKey =
          conditionPreset.id ||
          `${conditionPreset.common_model}-${conditionPreset.normalized_key_name}-${index}`;

        return (
          <ConditionPresetInputCombo
            className="my-6"
            key={conditionPresetKey}
            conditionPreset={conditionPreset}
            errorText={conditionPresetErrors[index]}
            conditionPresetMeta={conditionPresetMeta}
            conditionPresets={conditionPresets}
            onConditionPresetChange={onConditionPresetChange(index)}
            onConditionPresetDelete={() => onConditionPresetDelete(index)}
            isFirst={index === 0}
          />
        );
      })}

      <div className={clsx("flex flex-row items-center", !hasConditionPresets && "mt-6")}>
        {hasConditionPresets && <Text className="text-gray-40 w-[57px]">and</Text>}
        <Tooltip title={!canAddFilter && "No more filters available without creating duplicates"}>
          <Button
            leftIcon={<Plus size={16} />}
            onClick={onConditionPresetAdd}
            disabled={!canAddFilter}
          >
            Filter
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ConditionPresetsList;
