import React, { useEffect, useState } from "react";
import { Text, Button, ButtonVariant, Toggle } from "@merge-api/merge-javascript-shared";
import { WhiteLabelGuide } from "../pages/ConfigurationWhiteLabelGuideSettingsPage";
import ConfigurationSettingsCard from "../helpers/ConfigurationSettingsCard";
import { Upload } from "lucide-react";
import WhiteLabelGuideModal from "../modals/WhiteLabelGuideModal";
import { toggleWhiteLabelGuideEnabled } from "../../../../api-client/APIClient";

export type props = {
  whiteLabelGuide: WhiteLabelGuide;
  handleWhiteLabelGuideChanges: (whiteLabelGuide: WhiteLabelGuide) => void;
  handleHasSavedWhiteLabelGuide: (handleHasSavedWhiteLabelGuide: boolean) => void;
};

const ConfigurationWhiteLabelGuideCard = ({
  whiteLabelGuide,
  handleWhiteLabelGuideChanges,
  handleHasSavedWhiteLabelGuide,
}: props) => {
  // STATE
  const [savedLogo, setSavedLogo] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // HOOKS
  useEffect(() => {
    // @ts-ignore
    setSavedLogo(whiteLabelGuide.square_logo); //the saved logo is returned as an AWS URL so it's a string
  }, [whiteLabelGuide.square_logo]);

  return (
    <ConfigurationSettingsCard
      title="Enable white-label guides"
      subtitle="Automatically point URLs shown to your user during authentication in Merge Link to a white-labeled version with your logo and preferred subdomain. Any customized links saved in Configuration will still be used"
      rightHandContent={
        <Toggle
          label="Enabled"
          checked={whiteLabelGuide.active}
          onChange={() => {
            toggleWhiteLabelGuideEnabled(!whiteLabelGuide.active);
          }}
        />
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between items-center">
          <div className="font-semibold">Logo</div>
          <div className="flex flex-row gap-4">
            <img className="rounded-md" src={savedLogo} width={40} height={40} />
            <Button variant={ButtonVariant.TertiaryWhite} onClick={() => setIsModalOpen(true)}>
              <Upload className="mr-2" size={16} />
              Reupload
            </Button>
            <WhiteLabelGuideModal
              whiteLabelGuide={whiteLabelGuide}
              handleWhiteLabelGuideChanges={handleWhiteLabelGuideChanges}
              handleHasSavedWhiteLabelGuide={handleHasSavedWhiteLabelGuide}
              isReupload
              isModalOpen={isModalOpen}
              onHide={() => setIsModalOpen(false)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="font-semibold">Subdomain</div>
          <Text
            variant="lg"
            className="bg-gray-10 text-gray-60 rounded-md h-10 items-center flex pl-4 pr-4"
          >
            https://<span className="text-black">{whiteLabelGuide.subdomain}</span>
            .integrations.guide
          </Text>
        </div>
      </div>
    </ConfigurationSettingsCard>
  );
};

export default ConfigurationWhiteLabelGuideCard;
