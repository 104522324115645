import React from "react";
import clsx from "clsx";
import {
  SyncConnectionAuthConfigAuthSchema,
  SyncConnectionAuthConfigAuthSchemaFieldType,
} from "../../../../../../../models/Entities";
import { Text, TextField } from "@merge-api/merge-javascript-shared";
import { isValidJSON } from "../../../../../../../services";

type CreateDestinationFormProps = {
  className?: string;
  authSchema: SyncConnectionAuthConfigAuthSchema;
  authFields: { [key: string]: string };
  onAuthFieldsChange: (key: string, value: string) => void;
  name: string;
  onNameChange: (name: string) => void;
};

const CreateDestinationForm = ({
  className,
  authSchema,
  authFields,
  onAuthFieldsChange,
  name,
  onNameChange,
}: CreateDestinationFormProps) => {
  return (
    <div className={clsx("w-full", className)}>
      {/* destination name */}
      <div className="mb-6">
        <Text variant="h5">Name your new destination</Text>
        <TextField
          variant="bordered"
          className="mt-4"
          value={name}
          placeholder="Destination name"
          onChange={(e) => onNameChange(e.target.value)}
        />
      </div>

      {/* auth schema fields */}
      {authSchema.map(({ header, fields }) => (
        <div key={header} className="mb-6">
          <Text variant="h5">{header}</Text>
          {fields.map(({ key, placeholder, type }) => {
            const hasError =
              type === SyncConnectionAuthConfigAuthSchemaFieldType.JSON &&
              !isValidJSON(authFields[key]) &&
              !!authFields[key];

            return (
              <TextField
                key={key}
                variant="bordered"
                className="mt-4"
                multiline={type === SyncConnectionAuthConfigAuthSchemaFieldType.JSON}
                value={authFields[key]}
                placeholder={placeholder}
                onChange={(e) => onAuthFieldsChange(key, e.target.value)}
                error={hasError}
                errorText="Invalid JSON format. Please ensure your input is correctly structured as JSON."
              />
            );
          })}
        </div>
      ))}

      {/* placeholder div to prevent overflow of bottom margin being hidden */}
      <div className="h-[1px]" />
    </div>
  );
};

export default CreateDestinationForm;
