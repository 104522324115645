import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MergeFlagWrapper from "../../../../../../shared-components/MergeFlagWrapper";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import {
  CONFIGURATION_COMMON_MODELS_PATH,
  getLinkedAccountDetailFieldMappingsPathForId,
} from "../../../../../../../router/RouterUtils";
import { MergeFlagFeature } from "../../../../../../shared-components/hooks/useMergeFlag";
import { LinkedAccountSettingToggleDropdownOption } from "../../LinkedAccountSettingToggle";
import { toggleRedactUnmappedData } from "../../../../../../../api-client/APIClient";
import { LinkedAccount } from "../../../../../../../models/Entities";

const CardSubtitleText = styled.div`
  line-height: 18px;
`;

interface Props {
  linkedAccount: LinkedAccount;
}

const RedactUnmappedDataWidgetCard: React.FC<Props> = ({ linkedAccount }: Props) => {
  const [isRedactionEnabledForLinkedAccount, setIsRedactionEnabledForLinkedAccount] = useState<
    boolean | undefined
  >(undefined);

  const isRedactionEnabledForOrganization = linkedAccount.redact_unmapped_data_enabled_for_org;
  const isRedactionEnabled = linkedAccount.should_redact_unmapped_data;

  useEffect(() => {
    setIsRedactionEnabledForLinkedAccount(isRedactionEnabled);
  }, [isRedactionEnabled]);

  const subtitle = (
    <CardSubtitleText>
      <div>
        {" "}
        Redact all fields in API logs and
        <a href={CONFIGURATION_COMMON_MODELS_PATH} target="_blank" rel="noreferrer">
          {" "}
          Remote Data{" "}
        </a>
        for this Linked Account that are not mapped to Merge Common Model fields or used in
        <a
          href={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Field Mapping.
        </a>
      </div>
      <div className="mt-1">
        Visit our
        <a
          href="https://help.merge.dev/en/articles/8358419-redacting-unmapped-data"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Help Center{" "}
        </a>
        to learn more.
      </div>
    </CardSubtitleText>
  );

  const _onResponse = (res: { feature_enabled_for_linked_account?: boolean }) => {
    setIsRedactionEnabledForLinkedAccount(res.feature_enabled_for_linked_account ?? undefined);
  };

  const _setRedactionEnabledForLinkedAccount = (
    scopeKey: LinkedAccountSettingToggleDropdownOption,
  ) => {
    switch (scopeKey) {
      case LinkedAccountSettingToggleDropdownOption.ENABLE:
        if (isRedactionEnabledForLinkedAccount !== true)
          toggleRedactUnmappedData(true, undefined, linkedAccount.id, _onResponse);
        break;
      case LinkedAccountSettingToggleDropdownOption.DISABLE:
        if (isRedactionEnabledForLinkedAccount !== false)
          toggleRedactUnmappedData(false, undefined, linkedAccount.id, _onResponse);
        break;
      case LinkedAccountSettingToggleDropdownOption.RESET:
        if (
          isRedactionEnabledForOrganization !== undefined &&
          isRedactionEnabledForLinkedAccount !== isRedactionEnabledForOrganization
        ) {
          toggleRedactUnmappedData(
            isRedactionEnabledForOrganization,
            undefined,
            linkedAccount.id,
            _onResponse,
          );
        }
        break;
    }
  };

  return (
    <MergeFlagWrapper
      feature={MergeFlagFeature.MERGE_FLAG_ENABLE_REDACT_UNMAPPED_DATA_FRONTEND}
      linkedAccountId={linkedAccount.id}
    >
      <ConfigurationWidgetCard
        includeCardDivider
        cardTitle="Redact unmapped data"
        cardSubtitle={subtitle}
        includeRightChevron={false}
        linkedAccountSettingToggleProps={
          isRedactionEnabledForLinkedAccount !== undefined
            ? {
                onDropdownSelected: _setRedactionEnabledForLinkedAccount,
                isSettingEnabled: isRedactionEnabledForLinkedAccount,
                isDefaultOverridden:
                  isRedactionEnabledForLinkedAccount !== isRedactionEnabledForOrganization,
                includeToggleIcon: true,
              }
            : undefined
        }
      />
    </MergeFlagWrapper>
  );
};

export default RedactUnmappedDataWidgetCard;
