import React from "react";
import { Comments } from "../../shared-components/MergeComments";
import { IntegrationIssue } from "../IntegrationsManagementEntities";
import { Dialog } from "@merge-api/merge-javascript-shared";

type Props = {
  integrationIssue: IntegrationIssue | undefined;
  isShown: boolean;
  setIsShown: (value: boolean) => void;
};

export const IntegrationIssueCommentModal = ({ integrationIssue, isShown, setIsShown }: Props) => {
  return (
    <Dialog
      footerButtonsHidden
      open={isShown}
      onClose={() => setIsShown(false)}
      title="Comments"
      variant="lg"
    >
      {integrationIssue && <Comments integrationIssueID={integrationIssue.id} />}
    </Dialog>
  );
};
