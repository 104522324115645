import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { HTTPMethodBadge, ResponseCodeBadge } from "../../../../../shared-components/MergeBadges";
import DeprecatedH2 from "../../../../../../deprecated/DeprecatedH2";
import { Tooltip } from "@merge-api/merge-javascript-shared";
import CopyComponentWithIcon from "../../../../../shared-components/CopyComponentWithIcon";

type Props = {
  title: string;
  subtitle: string;
  image?: JSX.Element;
  request_method?: string;
  response_status?: number;
  hideBorderBottom?: boolean;
};

export const LogSidePanelBodySectionHeader = ({
  title,
  subtitle,
  image,
  request_method,
  response_status,
  hideBorderBottom = false,
}: Props) => {
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState<boolean>(false);

  const copyElement = () => {
    // Copy subtitle to clipboard
    setHasCopiedToClipboard(true);
  };

  return (
    <Card.Header
      className="log-card-header"
      {...(hideBorderBottom && { style: { borderBottom: "none" } })}
    >
      <Row className="log-entry-side-panel-header">
        {image && (
          <Col className="col-auto">
            <div>{image}</div>
          </Col>
        )}
        <Col className="overflow-auto">
          <header>
            <DeprecatedH2 className="mb-1 log-entry-side-panel-header-title">
              {title}
              <span>
                {response_status && (
                  <ResponseCodeBadge
                    className="float-right log-entry-side-panel-header-badge"
                    responseCode={response_status}
                  />
                )}
                {request_method && (
                  <HTTPMethodBadge className="float-right" method={request_method} />
                )}
              </span>
            </DeprecatedH2>
          </header>
          <div className="float-left mr-3">
            <CopyComponentWithIcon text={subtitle} />
          </div>
          <p className="log-entry-side-panel-header-subtitle" title={subtitle}>
            <Tooltip title={subtitle}>
              <span>{subtitle}</span>
            </Tooltip>
          </p>
        </Col>
      </Row>
    </Card.Header>
  );
};
