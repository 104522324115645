import React from "react";
import styled from "styled-components";
import { Rotate3d, BaggageClaim, ChevronRight } from "lucide-react";
import {
  DOCS_REMOTE_DATA_PATH,
  DOCS_PASSTHROUGH_REQUESTS_PATH,
} from "../../../../router/RouterUtils";
import DeprecatedH3 from "../../../../deprecated/DeprecatedH3";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";
import { Button } from "@merge-api/merge-javascript-shared";

const Container = styled.div`
  @media (min-width: 1330px) {
    margin: 32px auto;
    max-width: 75%;
    padding-bottom: 64px;
  }
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 24px;
`;

const ShadedArea = styled.div`
  border-radius: 10px;
  width: 100%;
  display: block;
  background: #eff6ff;
  padding: 16px 20px;
`;

const Header = styled(DeprecatedH3)`
  font-size: 24px;
  line-height: 36px;
`;

const Subtitle = styled(DeprecatedH6)`
  font-size: 14px;
  line-height: 24px;
`;

const SupplementalData = () => {
  return (
    <Container>
      <Card>
        <Header className="mb-1">Supplemental data</Header>
        <Subtitle className="mb-6 gray font-normal">
          Interact with original data from integrated third-party platforms
        </Subtitle>

        <p>
          Merge normalizes integration data from different third-party platforms - each with its own
          data schema - into a standardized data model format ("Common Model") for a unified
          integration experience.
        </p>
        <p>
          While the Common Model covers most use cases, some third-parties may have uncommon fields.
        </p>
        <p>
          In these cases, you can leverage Merge's Unified API and SDK to interact with original
          data from integrated third-parties.
        </p>
        <p className="mb-9">Explore our two advanced capabilities below:</p>

        <ShadedArea className="mb-6">
          <BaggageClaim size={16} color="var(--blue40)" style={{ marginBottom: 12 }} />
          <DeprecatedH4>Remote Data</DeprecatedH4>
          <p className="small">
            Pull user data from integrated third-parties in its <b>original format</b> (before
            normalization). Professional and Enterprise plans only.
          </p>
          <Button
            size="sm"
            rightIcon={<ChevronRight size={12} />}
            onClick={() => window.open(DOCS_REMOTE_DATA_PATH, "_blank")}
          >
            View docs
          </Button>
        </ShadedArea>
        <ShadedArea>
          <Rotate3d size={16} color="var(--blue40)" style={{ marginBottom: 12 }} />
          <DeprecatedH4>Passthrough Requests</DeprecatedH4>
          <p className="small">
            Use our <strong>Passthrough Requests</strong> to make any supported request
            (pre-authenticated) to an integration's API.
          </p>
          <Button
            size="sm"
            rightIcon={<ChevronRight size={12} />}
            onClick={() => window.open(DOCS_PASSTHROUGH_REQUESTS_PATH, "_blank")}
          >
            View docs
          </Button>
        </ShadedArea>
      </Card>
    </Container>
  );
};

export default SupplementalData;
