import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import ChangeMeUserForm from "./ChangeMeUserForm";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import useAppContext from "../../context/useAppContext";
import { UserAvatar, UserAvatarSize } from "../../shared-components/MergeAvatars";
import { CardHeaderTitle } from "../../shared-components/MergeText";

function Profile() {
  const { user } = useAppContext();

  return (
    <>
      <Row className="justify-content-between">
        <Col className="col-12 col-lg-3">
          <Card>
            <Card.Body className="text-center">
              <UserAvatar user={user} size={UserAvatarSize.xxl} />
              <div className="mt-3" />
              {user ? (
                <p>
                  <b>{user.name}</b>
                  <br />
                  {user.organization ? user.organization.name : ""}
                </p>
              ) : (
                <EmptyStateWrapper isSpinner />
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-lg-9">
          <Card>
            <Card.Header>
              <CardHeaderTitle>Personal information</CardHeaderTitle>
            </Card.Header>

            {/* Change name and email form */}
            <Card.Body>{user ? <ChangeMeUserForm /> : <EmptyStateWrapper isSpinner />}</Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Profile;
