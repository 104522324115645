import React, { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import MaybeDisable from "../shared-components/MaybeDisable";
import { HeaderPretitle } from "../shared-components/MergeText";
import { EllipsesToggle } from "../shared-components/MergeToggles";
import { useActiveSubtab } from "./SubtabUtils";
import DeprecatedH1 from "../../deprecated/DeprecatedH1";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

type Props = Pick<React.ComponentProps<"div">, "className"> & {
  /**
   * The big black title, required
   */
  title: string | JSX.Element;

  /**
   * A smaller pretitle that appears stacked above the title if present
   */
  pretitle?: string | JSX.Element;

  /**
   * A description or short subtitle that appears below the title if present
   */
  subtitle?: string | JSX.Element;

  /**
   * If present, renders a badge directly to the right of the title
   */
  badge?: string | JSX.Element;

  /**
   * If present, renders content to the far right of the title
   */
  rightElement?: JSX.Element;

  /**
   * Provides tab titles & icons & components that appear as subnavigation
   * under the title/description
   */
  subtabs?: Array<Subtab>;
  indicator?: JSX.Element;
  isLinkedAccountPortalView?: boolean;
};
interface HeaderBarInterface {
  hasPretitle: boolean;
  isLinkedAccountPortalView: boolean;
}

const Container = styled.div<HeaderBarInterface>`
  padding: 40px 0px 24px;
  ${(props) => props.hasPretitle && `padding: 24px 0`}
  ${(props) => props.isLinkedAccountPortalView && `padding: 16px 0 20px`}
`;

const Title = styled(DeprecatedH1)`
  font-size: 28px;
  line-height: 36px;
  margin: 0;
  min-width: 0;
`;

const Subtitle = styled(DeprecatedH4)`
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0;
  margin: 12px 0 0;
`;

export type Subtab = {
  label: JSX.Element | string;
  destination?: string;
  disabled?: boolean;
  disabled_tip_message?: string;
  icon?: React.ReactElement;
};

/**
 * Takes a subtab and returns proper html based on disabled
 * and tooltip options
 */
const SubtabLink = ({ subtab }: { subtab: Subtab }) => (
  <MaybeDisable
    disable={subtab.disabled}
    tooltip={subtab.disabled_tip_message}
    className="nav-item"
  >
    <div className="flex items-center">
      {subtab.destination ? (
        <NavLink to={subtab.destination} className="nav-link">
          {subtab.icon} {subtab.label}{" "}
        </NavLink>
      ) : (
        <div className="nav-link">
          {subtab.icon} {subtab.label}{" "}
        </div>
      )}
    </div>
  </MaybeDisable>
);

const TitleStack = styled.div.attrs({ className: "flex items-center" })`
  gap: 16px;
`;

const Spacer = styled.span`
  flex-grow: 1;
`;

const RightElement = styled.div`
  align-self: flex-end;
`;

const HeaderBar = ({
  className,
  pretitle,
  title,
  subtitle,
  badge,
  rightElement,
  subtabs,
  isLinkedAccountPortalView,
}: Props) => {
  // Only changes if the subtabs/location does
  const activeSubtab = useActiveSubtab(subtabs);

  // Make sure the rendered content in here doesn't change when other data does
  const subtabElements = useMemo(
    () =>
      subtabs && (
        <div>
          <ul className="nav nav-tabs border-bottom-0 nav-lg-show">
            {subtabs.map((subtab) => (
              <li key={(subtab.destination ?? subtab.label) as string}>
                <SubtabLink subtab={subtab} />
              </li>
            ))}
          </ul>
          <Dropdown className="nav nav-tabs border-bottom-0 nav-lg-hide">
            <Dropdown.Toggle id="dropdown-custom-components" as={EllipsesToggle}>
              {activeSubtab && <SubtabLink subtab={activeSubtab as Subtab} />}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {subtabs.map((subtab) => (
                <Dropdown.Item
                  key={(subtab.destination ?? subtab.label) as string}
                  className="dropdown-nav-link"
                >
                  <SubtabLink subtab={subtab} />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    [activeSubtab, subtabs],
  );

  return (
    <Container
      className={className}
      hasPretitle={!!pretitle}
      isLinkedAccountPortalView={isLinkedAccountPortalView || false}
    >
      <div className="custom-header-border">
        <div className="custom-header-body">
          <div>
            {pretitle && <HeaderPretitle>{pretitle}</HeaderPretitle>}
            {badge || rightElement ? (
              <TitleStack>
                <Title>{title}</Title>
                {badge}
                <Spacer />
                <RightElement>{rightElement}</RightElement>
              </TitleStack>
            ) : (
              <Title>{title}</Title>
            )}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </div>
        </div>
        {subtabElements}
      </div>
    </Container>
  );
};

export default HeaderBar;
