import React, { useEffect, useState } from "react";
import { Dialog } from "@merge-api/merge-javascript-shared";
import { ConditionPreset, ConditionPresetMeta } from "../../../../models/Entities";
import IntegrationSelect, { IntegrationWithConditionPresets } from "./components/IntegrationSelect";
import LinkCard from "./components/LinkCard";
import useConditionPresetIntegrations from "./hooks/useConditionPresetIntegrations";

type ConditionPresetPreviewLinkDialogProps = {
  conditionPresets?: Partial<ConditionPreset>[];
  conditionPresetMeta: ConditionPresetMeta | undefined;
  open: boolean;
  onClose: () => void;
};

const ConditionPresetPreviewLinkDialog = ({
  conditionPresets = [],
  conditionPresetMeta,
  open,
  onClose,
}: ConditionPresetPreviewLinkDialogProps) => {
  // state
  const [integration, setIntegration] = useState<IntegrationWithConditionPresets>();
  const { integrations } = useConditionPresetIntegrations({
    conditionPresets,
    conditionPresetMeta,
  });

  // effects
  // reset to not have any selected integrations when mapped
  useEffect(() => {
    !open && setTimeout(() => setIntegration(undefined), 300);
  }, [open]);

  return (
    <Dialog
      title="Preview Merge Link"
      variant="md"
      className="w-[423px]"
      open={open}
      onClose={onClose}
      footerButtonsHidden
    >
      <div>
        <IntegrationSelect
          className="mb-5"
          integration={integration}
          setIntegration={setIntegration}
          integrations={integrations}
        />
        <LinkCard
          conditionPresets={integration?.conditionPresets}
          integrationName={integration?.name}
        />
      </div>
    </Dialog>
  );
};

export default ConditionPresetPreviewLinkDialog;
