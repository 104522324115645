import React from "react";
import clsx from "clsx";
import { AuthType, LinkedAccount } from "../../../../../../models/Entities";
import RelinkProductionLinkedAccountButton from "../../../RelinkProductionLinkedAccountButton";
import { Card } from "@merge-api/merge-javascript-shared";
import IDsCardSkeleton from "./components/IDsCardSkeleton";
import LinkedAccountID from "./components/LinkedAccountID";
import SyncConnectionId from "./components/SyncConnectionId";
import AccountToken from "./components/AccountToken";
import APITesterButton from "./components/APITesterButton";

type OverviewIDsCardProps = {
  linkedAccount: LinkedAccount | null;
  integrationIssueComponent?: React.ReactNode;
  className?: string;
};

const OverviewIDsCard = ({
  linkedAccount,
  integrationIssueComponent,
  className,
}: OverviewIDsCardProps) => {
  // skeleton
  if (!linkedAccount) {
    return <IDsCardSkeleton className={className} />;
  }

  return (
    <div className={clsx(className, "flex flex-col w-full")}>
      {!linkedAccount.is_test_account && (
        <div className="mb-5">
          <RelinkProductionLinkedAccountButton linkedAccount={linkedAccount} />
        </div>
      )}
      <Card variant="none" className="overflow-hidden">
        <div className="flex flex-col gap-2 bg-gray-0 p-2">
          {/* integration issue */}
          {integrationIssueComponent}

          {/* Linked Account Id */}
          <LinkedAccountID
            linkedAccountId={linkedAccount.id}
            hasIssue={!!integrationIssueComponent}
          />

          {/* Sync Connection Id (used for data warehouse) */}
          {linkedAccount.sync_connection_id && (
            <SyncConnectionId syncConnectionId={linkedAccount.sync_connection_id} />
          )}

          {/* Account Token */}
          <AccountToken accountToken={linkedAccount.account_token} />

          {/* API Tester Button */}
          <APITesterButton linkedAccount={linkedAccount} />
        </div>
      </Card>
    </div>
  );
};
export default OverviewIDsCard;
