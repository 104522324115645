import React from "react";
import type { match } from "react-router-dom";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { WebhookType } from "../../../../models/LogFilter";
import IntegrationNameAndLogo from "../../../configuration/integrations/IntegrationNameAndLogo";
import ClickableContainer from "../../../shared-components/ClickableContainer";
import { HTTPMethodBadge, ResponseCodeBadge } from "../../../shared-components/MergeBadges";
import type { WebhookLogEntry } from "../../IntegrationsManagementEntities";
import DirectionCell from "./DirectionCell";
import { LinkedAccount } from "../../../../models/Entities";
import { formatDate } from "../../../../models/Helpers";
import { Tooltip } from "@merge-api/merge-javascript-shared";

const UrlRow = styled.td`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  font-size: 12px;
  position: relative;
`;

const DateRow = styled.td`
  color: var(--gray50);
  font-size: 12px;
  line-height: 22px;
`;

const BadgeRow = styled.td`
  && .badge-component {
    font-size: 10px;
    line-height: 14px;
  }
`;

const LogsTableFont = styled.td`
  font-size: 12px;
`;

interface Props {
  log: WebhookLogEntry;
  match: match;
  linkedAccount?: LinkedAccount | null;

  /**
   * Called when the route is pushed when clicking this row
   */
  onPush?: () => void;
}

/**
 * Creates one table row for a log entry
 */
const WebhookLogEntryRow = ({ log, match, onPush, linkedAccount }: Props) => {
  const history = useHistory();

  const navigateToLogEntrySidePanel = (logId: string) => {
    onPush && onPush();
    history.push(`${match.url}/${logId}`, { from: window.location.pathname });
  };

  const methodCell = (
    <BadgeRow>
      <HTTPMethodBadge method={log?.method ?? "POST"} />
    </BadgeRow>
  );

  const urlCell = (
    <UrlRow>
      {log.url && (
        <Tooltip title={log.url}>
          <span className="truncate">{log.url}</span>
        </Tooltip>
      )}
    </UrlRow>
  );

  const statusCell = (
    <BadgeRow>
      <ResponseCodeBadge responseCode={log.response_code} className="badge-component" />
    </BadgeRow>
  );

  const integrationCell = !linkedAccount ? (
    <LogsTableFont>
      {log.linked_account?.integration && (
        <div className="d-flex align-items-center">
          <IntegrationNameAndLogo integration={log.linked_account.integration} />
        </div>
      )}
    </LogsTableFont>
  ) : null;

  const userCell = !linkedAccount ? (
    <LogsTableFont>
      <div className="d-flex align-items-center">
        <i className="fe fe-user mr-2" />
        {log.linked_account?.end_user?.organization_name}
      </div>
    </LogsTableFont>
  ) : null;
  const typeCell = (
    <LogsTableFont>
      <div className="d-flex align-items-center">
        {log?.webhook.type === WebhookType.CHANGED_DATA
          ? "Changed data"
          : log?.webhook?.type === WebhookType.SYNC_NOTIFICATION
          ? "Sync notification"
          : log?.webhook?.type === WebhookType.RECEIVING_WEBHOOK
          ? "Receiving webhook"
          : log?.webhook.type === WebhookType.LINKED_ACCOUNT_ISSUE
          ? "Linked Account issue"
          : ""}
      </div>
    </LogsTableFont>
  );

  const eventCell = (
    <LogsTableFont>
      <div className="d-flex align-items-center">{log?.webhook?.event}</div>
    </LogsTableFont>
  );

  const dateCell = <DateRow>{formatDate(log.created_at)}</DateRow>;

  return (
    <tr className="table-link" onClick={() => navigateToLogEntrySidePanel(log.id)}>
      {methodCell}
      {urlCell}
      {statusCell}
      {log?.direction && <DirectionCell log={log} />}
      {log?.webhook?.type && typeCell}
      {log?.webhook?.event && eventCell}
      {integrationCell}
      {userCell}
      {dateCell}
      <td className="text-right">
        <Tooltip title="Review log details">
          <ClickableContainer>
            <span className="black fe fe-chevron-right" />
          </ClickableContainer>
        </Tooltip>
      </td>
    </tr>
  );
};

export default WebhookLogEntryRow;
