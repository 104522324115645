import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import IntegrationNameAndLogo from "../IntegrationNameAndLogo";
import { MoreHorizontal, Plus } from "lucide-react";
import DeleteFieldMappingModal from "../../../integrations-management/linked-accounts/detail-page/field-mappings/DeleteFieldMappingModal";
import useAppContext from "../../../context/useAppContext";
import {
  EditFieldMappingInstanceProps,
  NewIntegrationWideFieldMapping,
  createIntegrationWideFieldMapping,
  editFieldMappingInstance,
  fetchFieldMappingInstance,
  fetchIntegrationWideFieldMappingOptions,
} from "../../../../api-client/APIClient";
import { showSuccessToast } from "../../../shared-components/Toasts";
import MergeTypeahead from "../../../shared-components/MergeTypeahead";

import { FieldMappingCreationAndEditDict } from "../../../../models/Entities";
import useOnClickOutside from "use-onclickoutside";
import { FieldMappingSource } from "../../../../constants";
import FieldMappingDropdownChild from "./FieldMappingDropdownChild";
import {
  Button,
  ButtonVariant,
  Dropdown,
  MenuItem,
  Tooltip,
} from "@merge-api/merge-javascript-shared";

export const StyledDash = styled.span`
  color: #737982;
  height: 20px;
`;

export const StyledEndpointV2 = styled.div`
  color: #737982;
  max-width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: fit-content;
`;

export const TruncatedOriginField = styled.div`
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: fit-content;
`;

export const StyledTypeaheadDiv = styled.div`
  input {
    height: 34px;
  }
`;

export const StyledMethodDiv = styled.div`
  color: var(--Gray60, #737982);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const FitDiv = styled.div`
  width: fit-content;
`;

export const MinHeightDiv = styled.div`
  min-height: 34px;
  align-items: center;
  display: flex;
`;

type FieldMappingByIntegrationRowProps = {
  fieldMappingTargetID: string;
  fieldMappingInstanceId: string | undefined;
  integrationID: string;
  name: string;
  squareImage: string | undefined;
  remoteEndpointPath: string | undefined;
  remoteEndpointMethod: string | undefined;
  originType: string | undefined;
  originField: string | undefined;
  isLinkedAccountOverrideEnabled: boolean | undefined;
  fieldMappingTargetCommonModelName: string;
  fieldMappingTargetCategory: string;
  fieldMappingTargetFieldKey: string;
  updateAvailableIntegrations?: (
    integrationID: string,
    fieldMappingInstanceId: string,
    originField: string,
    remoteEndpointPath: string,
    remoteEndpointMethod: string,
    originType: string,
    isLinkedAccountOverrideEnabled: boolean,
    traversalPath: Array<string>,
  ) => void;
  handleDelete?: () => void;
  isRemoteDataEnabled?: boolean;
};

const FieldMappingByIntegrationRow = ({
  fieldMappingTargetID,
  fieldMappingInstanceId,
  integrationID,
  name,
  squareImage,
  remoteEndpointPath,
  remoteEndpointMethod,
  originField,
  originType,
  isLinkedAccountOverrideEnabled,
  fieldMappingTargetCommonModelName,
  fieldMappingTargetCategory,
  fieldMappingTargetFieldKey,
  updateAvailableIntegrations,
  handleDelete,
  isRemoteDataEnabled = true,
}: FieldMappingByIntegrationRowProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLinkedAccountOverrideEnabledState, setIsLinkedAccountOverridesEnabledState] = useState(
    isLinkedAccountOverrideEnabled,
  );
  const [fieldMappingOptions, setFieldMappingOptions] =
    useState<FieldMappingCreationAndEditDict[]>();
  const [originFields, setOriginFields] = useState<FieldMappingCreationAndEditDict[]>();

  const ref = useRef<HTMLDivElement>(null);
  const [isAddingFieldMapping, setIsAddingFieldMapping] = useState(false);
  const { user } = useAppContext();

  const portalRef = document.getElementById("field-mapping-target-field-settings-page")!;

  useEffect(() => {
    setIsLinkedAccountOverridesEnabledState(isLinkedAccountOverrideEnabled);
  }, [isLinkedAccountOverrideEnabled]);

  const createIntegrationFieldMapping = (currOriginField: FieldMappingCreationAndEditDict) => {
    const body: NewIntegrationWideFieldMapping = {
      integration_id: integrationID,
      organization_id: user.organization.id,
      common_model_id: `${fieldMappingTargetCategory}.${fieldMappingTargetCommonModelName}`,
      field_key: fieldMappingTargetFieldKey,
      field_traversal_path: currOriginField.traversal_path.split("."),
      create_for_organization: true,
      field_mapping_target_id: fieldMappingTargetID,
      configured_by: FieldMappingSource.ORGANIZATION,
      api_endpoint_id: currOriginField.api_endpoint_id,
      display_name: currOriginField.display_name,
      enable_linked_account_level_overrides: true,
      origin_type: currOriginField.type,
    };
    createIntegrationWideFieldMapping(
      body,
      () => {
        showSuccessToast("Successfully added Field Mapping!");
      },
      (new_instance_id) => {
        fetchFieldMappingInstance(new_instance_id, (data) => {
          updateAvailableIntegrations!(
            integrationID,
            new_instance_id,
            currOriginField.display_name,
            data?.remote_endpoint_path,
            currOriginField.api_endpoint_method,
            currOriginField.type,
            isLinkedAccountOverrideEnabledState ?? false,
            currOriginField.traversal_path.split("."),
          );
          setIsAddingFieldMapping(false);
        });
      },
    );
  };

  const editIntegrationFieldMapping = (currOriginField: FieldMappingCreationAndEditDict) => {
    if (fieldMappingInstanceId) {
      const body: EditFieldMappingInstanceProps = {
        field_mapping_instance_id: fieldMappingInstanceId,
        field_traversal_path: currOriginField.traversal_path.split("."),
        api_endpoint_id: currOriginField.api_endpoint_id,
        display_name: currOriginField.display_name,
        enable_linked_account_level_overrides: true,
        origin_type: currOriginField.type,
      };
      editFieldMappingInstance(fieldMappingInstanceId, body, () => {
        showSuccessToast("Successfully edited Field Mapping!");
        fetchFieldMappingInstance(fieldMappingInstanceId, (data) => {
          updateAvailableIntegrations!(
            integrationID,
            fieldMappingInstanceId,
            currOriginField.display_name,
            data?.remote_endpoint_path,
            currOriginField.api_endpoint_method,
            currOriginField.type,
            isLinkedAccountOverrideEnabledState ?? false,
            currOriginField.traversal_path.split("."),
          );
          setIsAddingFieldMapping(false);
        });
      });
    }
  };

  useEffect(() => {
    const optionallySelected = originField
      ? fieldMappingOptions?.find((option) => option.display_name === originField)
      : null;
    if (optionallySelected != null) {
      setOriginFields([optionallySelected]);
    }
  }, [fieldMappingOptions]);

  const fieldMappingOptionsLoading = fieldMappingOptions === undefined;

  useOnClickOutside(ref, (event) => {
    if ((event.target as HTMLElement).className !== "custom-control-label") {
      setIsAddingFieldMapping(false);
      setOriginFields([]);
    }
  });

  return (
    <tr>
      <td>
        <div className="d-flex">
          <IntegrationNameAndLogo
            integration={{
              id: name,
              name: name,
              square_image: squareImage,
            }}
          />
        </div>
      </td>
      <td
        colSpan={isAddingFieldMapping ? 2 : 1}
        width={isAddingFieldMapping ? 550 : 192}
        height={isAddingFieldMapping ? 67 : undefined}
      >
        {isAddingFieldMapping ? (
          <StyledTypeaheadDiv ref={ref}>
            <MergeTypeahead
              id="typeahead"
              disabled={fieldMappingOptionsLoading}
              multiple={false}
              positionFixed
              renderMenuItemChildren={(option) => (
                <FieldMappingDropdownChild
                  originField={option.display_name}
                  originType={option.type}
                  remoteEndpointMethod={option.api_endpoint_method ?? "GET"}
                  remoteEndpointPath={option.api_endpoint_path ?? "/"}
                />
              )}
              options={fieldMappingOptions || []}
              includeChevronDown={!fieldMappingOptionsLoading}
              inputProps={{ autoComplete: "none" }}
              placeholder="Search fields..."
              selected={originFields}
              onChange={(selectedOriginFields) => {
                if (selectedOriginFields.length == 1) {
                  if (originField != null) {
                    // edit field
                    editIntegrationFieldMapping(selectedOriginFields[0]);
                  } else {
                    // create field
                    createIntegrationFieldMapping(selectedOriginFields[0]);
                  }
                } else {
                  setOriginFields(selectedOriginFields);
                }
              }}
              labelKey={(option: FieldMappingCreationAndEditDict) => option?.display_name ?? ""}
            />
          </StyledTypeaheadDiv>
        ) : originField ? (
          <MinHeightDiv>
            <Tooltip title={originField}>
              <TruncatedOriginField>{originField}</TruncatedOriginField>
            </Tooltip>
          </MinHeightDiv>
        ) : isRemoteDataEnabled ? (
          <Button
            variant={ButtonVariant.SecondaryBlue}
            size="sm"
            leftIcon={<Plus size={12} />}
            onClick={() => {
              fetchIntegrationWideFieldMappingOptions(
                integrationID,
                fieldMappingTargetID,
                fieldMappingTargetCommonModelName,
                setFieldMappingOptions,
              );
              setIsAddingFieldMapping(true);
            }}
          >
            Field Mapping
          </Button>
        ) : (
          <div className="text-gray-40">—</div>
        )}
      </td>
      {!isAddingFieldMapping && (
        <td width={278}>
          {remoteEndpointPath ? (
            <Tooltip title={remoteEndpointPath}>
              <FitDiv className="d-flex">
                <StyledMethodDiv className="mr-1.5">
                  {" "}
                  {remoteEndpointMethod ?? "GET"}
                </StyledMethodDiv>
                <StyledEndpointV2>{remoteEndpointPath}</StyledEndpointV2>
              </FitDiv>
            </Tooltip>
          ) : (
            <div className="text-gray-40">—</div>
          )}
        </td>
      )}

      <td>
        {originField && (
          <>
            <DeleteFieldMappingModal
              showModal={showDeleteModal}
              fieldMappingID={fieldMappingInstanceId!}
              headerText="Delete mapping"
              onHide={() => {
                setShowDeleteModal(false);
              }}
              handlePostDelete={handleDelete}
              text={"This will delete all Field Mappings to this " + name + " origin field."}
              secondaryText="Mapping overrides will not be deleted."
            />
            <div className="float-right">
              <Dropdown
                ButtonProps={{
                  children: <MoreHorizontal size={16} />,
                  size: "sm",
                  variant: ButtonVariant.IconShadowHidden,
                }}
              >
                <MenuItem
                  onClick={() => {
                    fetchIntegrationWideFieldMappingOptions(
                      integrationID,
                      fieldMappingTargetID,
                      fieldMappingTargetCommonModelName,
                      setFieldMappingOptions,
                    );
                    setIsAddingFieldMapping(true);
                  }}
                >
                  Edit mapping
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  Delete mapping
                </MenuItem>
              </Dropdown>
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default FieldMappingByIntegrationRow;
