import React, { useState, useEffect } from "react";
import {
  LinkedAccount,
  FieldMappingMetaResponse,
  FieldMappingOptionsResponse,
  FieldMappingTargetsAndFieldMappingInstances,
  FieldMappingCommonModelConfiguration,
  FieldMappingOptions,
} from "../../../../../../../models/Entities";
import {
  fetchWithAuth,
  fetchLinkedAccountFieldMappingInstances,
} from "../../../../../../../api-client/APIClient";
import {
  getFieldMappingMeta,
  getLinkedAccountFieldMappings,
} from "../../../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";

type useFieldmappingTargetsPageParams = {
  linkedAccountID: string;
  preSelectedCommonModel: string | null;
};
const useFieldmappingTargetsPage = ({
  linkedAccountID,
  preSelectedCommonModel,
}: useFieldmappingTargetsPageParams) => {
  // state
  const [linkedAccount, setLinkedAccount] = useState<LinkedAccount | null>(null);
  const [fieldMappingInformation, setFieldMappingInformation] =
    useState<FieldMappingTargetsAndFieldMappingInstances | null>(null);
  const [fieldMappingConfigurations, setFieldMappingConfigurations] = useState<
    FieldMappingCommonModelConfiguration[] | null
  >(null);
  const [availableFieldMappings, setAvaiableFieldMappings] = useState<FieldMappingOptions | null>(
    null,
  );
  const [selectedCommonModels, setSelectedCommonModels] = useState<Array<string>>(
    preSelectedCommonModel ? [preSelectedCommonModel] : [],
  );

  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccountID}`,
      method: "GET",
      onResponse: (data) => {
        setLinkedAccount(data);
      },
    });
  }, [linkedAccountID]);

  useEffect(() => {
    if (linkedAccount) {
      fetchLinkedAccountFieldMappingInstances(
        linkedAccount.id,
        setFieldMappingInformation,
        () => {},
      );
    }
  }, [linkedAccount]);

  useEffect(() => {
    if (linkedAccount) {
      getFieldMappingMeta({
        linkedAccountID: linkedAccount.id,
        onFetch: (fieldMappingConfigs: FieldMappingMetaResponse) => {
          setFieldMappingConfigurations(
            fieldMappingConfigs.field_mapping_common_model_configurations.sort((a, b) => {
              if (a.common_model_name < b.common_model_name) return -1;
              if (a.common_model_name > b.common_model_name) return 1;
              return 0;
            }),
          );
        },
        onError: () => {},
      });
    }
  }, [linkedAccount]);

  useEffect(() => {
    if (linkedAccount) {
      getLinkedAccountFieldMappings({
        linkedAccountID: linkedAccount.id,
        onFetch: (fieldMappingOptions: FieldMappingOptionsResponse) => {
          setAvaiableFieldMappings(fieldMappingOptions.remote_field_options);
        },
        onError: () => {},
      });
    }
  }, [linkedAccount]);

  return {
    linkedAccount,
    fieldMappingInformation,
    availableFieldMappings,
    fieldMappingConfigurations,
    selectedCommonModels,
    setSelectedCommonModels,
  };
};

export default useFieldmappingTargetsPage;
