import React from "react";
import { Text } from "@merge-api/merge-javascript-shared";
import { Tooltip } from "@merge-api/merge-javascript-shared";
import { InfoIcon } from "lucide-react";

type LinkedAccountsCountsCardSubheaderProps = {
  text: string;
  tooltipText: string;
};

const LinkedAccountsCountsCardSubheader: React.FC<LinkedAccountsCountsCardSubheaderProps> = ({
  text,
  tooltipText,
}) => {
  return (
    <div className="flex items-center gap-2 mt-4 mb-2">
      <Text className="text-black text-left font-inter text-base font-semibold leading-6">
        {text}
      </Text>
      <Tooltip title={tooltipText}>
        <InfoIcon width={16}> height={16}</InfoIcon>
      </Tooltip>
    </div>
  );
};

export default LinkedAccountsCountsCardSubheader;
