import React, { useState } from "react";
import cx from "classnames";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";

import { hasAuthToken } from "../../api-client/APIClient";
import { spectrum } from "../../styles/theme";
import MergeLogo from "../../assets/svg/logos/merge/logo-large.svg";
import { LANDING_PAGE_PATH, LOGIN_PATH } from "../../router/RouterUtils";
import { REGEX_EMAIL_ADDRESS } from "../shared-components/utils/SharedComponentUtils";
import SpinnerButton from "../shared-components/SpinnerButton";
import DeprecatedH1 from "../../deprecated/DeprecatedH1";

const TOP_ROW_HEIGHT_PX = 88;

const FullPageMessageContainer = styled.div`
  position: relative;
`;

const PositiveZIndex = styled.div`
  position: relative;
  z-index: 1;
`;

const TopRowFlex = styled.div`
  @media (max-width: 797.96px) {
    justify-content: center;
  }
  margin: 0;
  padding: 24px 40px;
  height: ${TOP_ROW_HEIGHT_PX}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackToLoginLink = styled(Link)`
  && {
    color: ${spectrum.gray90}
    font-size: 15px;
    font-weight: 400;
  }
`;

const CenterTextOnScreen = styled.div`
  height: 100vh;
  width: 100%;
  margin: -${TOP_ROW_HEIGHT_PX}px auto 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #24272e;
`;

const LargeTextCentered = styled(DeprecatedH1)`
  && {
    @media (max-width: 767.96px) {
      font-size: 26px;
      line-height: 30px;
    }
    font-size: 44px;
    line-height: 56px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin: 0 0 18px 0;
  }
`;

const SmallTextCentered = styled.p`
  @media (max-width: 767.96px) {
    font-size: 16px;
    line-height: 24px;
  }
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  margin: 0 0 32px 0;
  padding: 0 8px;
  max-width: 360px;
`;

const FinePrintText = styled.p`
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${spectrum.gray60};
  text-align: center;
  margin: 12px 0;
  padding: 0 8px;
  max-width: 260px;
  && {
    a {
      font-weight: 600;
      color: ${spectrum.gray90};
    }
  }
`;

interface AuthLayoutProps {
  title?: string;
  description?: JSX.Element | string;
  finePrint?: JSX.Element | string;
  hideBackToLogin?: boolean;
  children?: JSX.Element;
}

/**
 * Render an auth-style page, such as "Login", "Forgot password", "No account found (SAML)", etc.
 * These pages all share similar styles, including the big gray Merge watermark in the background.
 * If you want to include a form, it should be the `children` of the AuthLayout.
 * If you don't want to include a form, then simply call the AuthLayout without any children.
 */
export const AuthLayout = ({ title, description, finePrint, children }: AuthLayoutProps) => {
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });
  if (hasAuthToken()) {
    return <Redirect to="/" />;
  }
  return (
    <FullPageMessageContainer className="AuthForm">
      <PositiveZIndex>
        <TopRowFlex>
          <a href={LANDING_PAGE_PATH} aria-label="Merge Homepage">
            <img src={MergeLogo} />
          </a>
          {isDesktopOrTablet && <BackToLoginLink to={LOGIN_PATH}>Back to Login</BackToLoginLink>}
        </TopRowFlex>
        <CenterTextOnScreen>
          {title && <LargeTextCentered>{title}</LargeTextCentered>}
          {description && <SmallTextCentered>{description}</SmallTextCentered>}
          {children && (
            <Container>
              <Row className="justify-content-center">
                <Col className="px-6" xs={12} sm={8} md={8} xl={6}>
                  {children}
                </Col>
              </Row>
            </Container>
          )}
          {finePrint && <FinePrintText>{finePrint}</FinePrintText>}
          {!isDesktopOrTablet && <BackToLoginLink to={LOGIN_PATH}>Back to Login</BackToLoginLink>}
        </CenterTextOnScreen>
      </PositiveZIndex>
    </FullPageMessageContainer>
  );
};

interface EmailFormData {
  email: string;
}
type SetErrorMessageFunction = (errorMessage?: string) => void;
type SetIsLoadingFunction = (isLoading?: boolean) => void;
export type OnEmailFormSubmitFunction = (
  data: EmailFormData,
  setEmailErrorMessage: SetErrorMessageFunction,
  setEmailFormIsLoading: SetIsLoadingFunction,
) => void;
interface AuthEmailFormProps extends AuthLayoutProps {
  onEmailFormSubmit: OnEmailFormSubmitFunction;
}
export const AuthEmailForm = ({
  title,
  description,
  finePrint,
  onEmailFormSubmit,
}: AuthEmailFormProps) => {
  const emailForm = useForm();
  const [emailFormIsLoading, setEmailFormIsLoading] = useState(false);
  const setEmailErrorMessage: SetErrorMessageFunction = (errorMessage?: string) => {
    return emailForm.setError("email", { message: errorMessage });
  };
  const setEmailFormIsLoadingWrapper: SetIsLoadingFunction = (isLoading?: boolean) =>
    setEmailFormIsLoading(Boolean(isLoading));
  const onValidEmailForm = (data: EmailFormData) =>
    onEmailFormSubmit(data, setEmailErrorMessage, setEmailFormIsLoadingWrapper);
  return (
    <AuthLayout title={title} description={description} finePrint={finePrint}>
      <Form onSubmit={emailForm.handleSubmit(onValidEmailForm)}>
        <Form.Group>
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="Email"
            className={cx({ "is-invalid": emailForm.errors.email })}
            ref={emailForm.register({
              required: true,
              pattern: REGEX_EMAIL_ADDRESS,
            })}
          />
          {emailForm.errors.email && (
            <Form.Control.Feedback type="invalid">
              {emailForm.errors.email.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <SpinnerButton
            text="Submit"
            isLoading={emailFormIsLoading}
            className="primaryAction btn btn-primary btn-block"
          />
        </Form.Group>
      </Form>
    </AuthLayout>
  );
};
