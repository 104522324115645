import React from "react";
import {
  ConditionPreset,
  ConditionPresetMeta,
  USER_CONFIGURED_OPERATOR,
} from "../../../../../models/Entities";
import { TextField } from "@merge-api/merge-javascript-shared";
import { Calculator } from "lucide-react";
import { getOperatorValue, getOperatorValueIsDate } from "../helpers";
import { LIST_CONDITION_OPERATORS } from "../../constants";

type ValueTextFieldProps = {
  conditionPreset: Partial<ConditionPreset>;
  conditionPresetMeta: ConditionPresetMeta;
  onConditionPresetChange: (ConditionPreset: Partial<ConditionPreset>) => void;
};

const ValueTextField = ({
  conditionPreset,
  onConditionPresetChange,
  conditionPresetMeta,
}: ValueTextFieldProps) => {
  // derived state
  const operatorValue = getOperatorValue(conditionPreset, conditionPresetMeta);
  const isDate = getOperatorValueIsDate(operatorValue);
  const isList =
    operatorValue &&
    operatorValue.operator !== USER_CONFIGURED_OPERATOR &&
    LIST_CONDITION_OPERATORS.includes(operatorValue.operator);

  // end user configured is default disabled, which we render like a disabled input
  // but don't use input so that Calculator icon is disabled as well
  if (conditionPreset.is_end_user_configured) {
    return (
      <div className="w-full h-full py-[3px] text-gray-40 select-none">
        <div className="flex items-center rounded-md px-3 bg-gray-0 h-full w-full whitespace-nowrap">
          <Calculator size={16} className="mr-1.5 " />
          is user configured
        </div>
      </div>
    );
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onConditionPresetChange({
      ...conditionPreset,
      value: e.target.value || undefined,
    });
  };

  return (
    <TextField
      variant="inlined-rounded"
      placeholder={isList ? "Enter comma-separated list..." : "Enter value..."}
      className="flex flex-1 w-full h-full py-[3px]"
      inputWrapperClassName="w-full"
      {...(isDate && { type: "date" })}
      value={conditionPreset.value?.toString() || ""}
      onChange={onValueChange}
    />
  );
};

export default ValueTextField;
