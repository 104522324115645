import React, { useEffect, useState } from "react";
import INTEGRATION_CATEGORY_LIST, { displayNameForAPICategory } from "../../../../models/Helpers";
import { APICategory, TextField, Select } from "@merge-api/merge-javascript-shared";
import ConfigurationSettingsCard from "../helpers/ConfigurationSettingsCard";
import useCustomizationContext from "../context/useCustomizationContext";
import {
  IntegrationCustomization,
  MergeLinkCustomizationStatus,
} from "../context/CustomizationContext";
import CustomizationIntegrationsDropdown from "../components/CustomizationIntegrationsDropdown";
import validUrl from "valid-url";
import { customNotEquals } from "../helpers/utils";

const ConfigurationHelpGuideURLCard = () => {
  const [selectedCategory, setSelectedCategory] = useState<APICategory | null>(null);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState<number | null>(null);
  const {
    integrationCustomizations,
    originalIntegrationCustomizations,
    setIntegrationCustomizations,
  } = useCustomizationContext();
  const currIntegrationCustomization = integrationCustomizations?.find(
    (integrationCustomization) =>
      integrationCustomization.integration === selectedIntegrationId &&
      integrationCustomization.category === selectedCategory,
  );
  const [authMessage, setAuthMessage] = useState<string>("");
  const [authURL, setAuthURL] = useState<string | null>(null);
  const [validateURL, setValidateURL] = useState<string | null>(null);
  const [authMessageErrorLabel, setAuthMessageErrorLabel] = useState<string | null>(null);
  const [authURLErrorLabel, setAuthURLErrorLabel] = useState<string | null>(null);
  const [validateURLErrorLabel, setValidateURLErrorLabel] = useState<string | null>(null);

  const isCustomized = (integCustomizationOption: IntegrationCustomization) => {
    const {
      help_center_authentication_message,
      help_center_authentication_url,
      help_center_validation_url,
    } = integCustomizationOption;
    // if the customization option is published and has a value for any of the fields, it is customized
    return (
      integCustomizationOption.status === "PUBLISHED" &&
      ((help_center_authentication_message != null && help_center_authentication_message != "") ||
        (help_center_authentication_url != null && help_center_authentication_url != "") ||
        (help_center_validation_url != null && help_center_validation_url != ""))
    );
  };

  useEffect(() => {
    if (currIntegrationCustomization !== undefined) {
      if (currIntegrationCustomization?.help_center_authentication_message != null) {
        setAuthMessage(currIntegrationCustomization?.help_center_authentication_message);
        if (currIntegrationCustomization.help_center_authentication_message.length > 55) {
          setAuthMessageErrorLabel("Authentication message must be less than 55 characters.");
        }
      }
      if (currIntegrationCustomization?.help_center_authentication_url != null) {
        setAuthURL(currIntegrationCustomization?.help_center_authentication_url);
        if (
          validUrl.isWebUri(currIntegrationCustomization.help_center_authentication_url) ===
          undefined
        ) {
          setAuthURLErrorLabel("Please enter a valid http or https URL.");
        }
      }
      if (currIntegrationCustomization?.help_center_validation_url != null) {
        setValidateURL(currIntegrationCustomization?.help_center_validation_url);
        if (
          validUrl.isWebUri(currIntegrationCustomization.help_center_validation_url) === undefined
        ) {
          setValidateURLErrorLabel("Please enter a valid http or https URL.");
        }
      }
    }
  }, [currIntegrationCustomization]);

  const onSaveAuthURL = () => {
    if (currIntegrationCustomization !== undefined) {
      if (customNotEquals(currIntegrationCustomization?.help_center_authentication_url, authURL)) {
        currIntegrationCustomization!.help_center_authentication_url = authURL;
        setIntegrationCustomizations([...integrationCustomizations!]);
      }
    } else {
      if (
        selectedIntegrationId !== null &&
        selectedCategory != null &&
        authURL !== null &&
        authURL !== ""
      ) {
        const newIntegrationCustomization: IntegrationCustomization = {
          integration: selectedIntegrationId,
          category: selectedCategory,
          help_center_authentication_url: authURL,
          status: "PUBLISHED" as MergeLinkCustomizationStatus,
        };
        if (integrationCustomizations == null || integrationCustomizations?.length == 0) {
          setIntegrationCustomizations([newIntegrationCustomization]);
        } else {
          setIntegrationCustomizations([...integrationCustomizations, newIntegrationCustomization]);
        }
      }
    }
  };

  const onSaveAuthMessage = () => {
    if (currIntegrationCustomization !== undefined) {
      if (
        customNotEquals(
          currIntegrationCustomization?.help_center_authentication_message,
          authMessage,
        )
      ) {
        currIntegrationCustomization!.help_center_authentication_message = authMessage;
        setIntegrationCustomizations([...integrationCustomizations!]);
      }
    } else {
      if (
        selectedIntegrationId !== null &&
        selectedCategory != null &&
        authMessage !== null &&
        authMessage !== ""
      ) {
        const newIntegrationCustomization: IntegrationCustomization = {
          integration: selectedIntegrationId,
          category: selectedCategory,
          help_center_authentication_message: authMessage,
          status: "PUBLISHED" as MergeLinkCustomizationStatus,
        };
        if (integrationCustomizations == null || integrationCustomizations?.length == 0) {
          setIntegrationCustomizations([newIntegrationCustomization]);
        } else {
          setIntegrationCustomizations([...integrationCustomizations, newIntegrationCustomization]);
        }
      }
    }
  };

  const onSaveValidateURL = () => {
    if (currIntegrationCustomization !== undefined) {
      if (customNotEquals(currIntegrationCustomization?.help_center_validation_url, validateURL)) {
        currIntegrationCustomization!.help_center_validation_url = validateURL;
        setIntegrationCustomizations([...integrationCustomizations!]);
      }
    } else {
      if (
        selectedIntegrationId !== null &&
        selectedCategory != null &&
        validateURL !== null &&
        validateURL !== ""
      ) {
        const newIntegrationCustomization: IntegrationCustomization = {
          integration: selectedIntegrationId,
          category: selectedCategory,
          help_center_validation_url: validateURL,
          status: "PUBLISHED" as MergeLinkCustomizationStatus,
        };
        if (integrationCustomizations == null || integrationCustomizations?.length == 0) {
          setIntegrationCustomizations([newIntegrationCustomization]);
        } else {
          setIntegrationCustomizations([...integrationCustomizations, newIntegrationCustomization]);
        }
      }
    }
  };

  const subtitle = (
    <>
      <div>
        Override the URLs shown to your user during authentication in Merge Link. Merge Link will
        show default help guide articles for all integrations that are not customized.
      </div>
      <div className="flex flex-row w-100 justify-between gap-3">
        <div className="flex shrink-0 w-[160px]">
          <Select
            options={INTEGRATION_CATEGORY_LIST}
            renderOption={(category: APICategory) => <>{displayNameForAPICategory(category)}</>}
            placeholder="Select category..."
            onChange={(_: any, categoryPicked: APICategory | null) => {
              setSelectedCategory(categoryPicked);
              setSelectedIntegrationId(null);
              setAuthMessage("");
              setAuthURL("");
              setValidateURL("");
              setAuthMessageErrorLabel(null);
              setAuthURLErrorLabel(null);
              setValidateURLErrorLabel(null);
            }}
          />
        </div>
        <div className="w-100">
          <CustomizationIntegrationsDropdown
            category={selectedCategory ?? undefined}
            filterDisabled
            disabled={selectedCategory == null}
            selectedIntegrationID={selectedIntegrationId}
            onChange={(_, integration) => {
              setSelectedIntegrationId(integration?.id ?? null);
              setAuthMessage("");
              setAuthURL("");
              setValidateURL("");
              setAuthMessageErrorLabel(null);
              setAuthURLErrorLabel(null);
              setValidateURLErrorLabel(null);
            }}
            customizedIntegrationIDs={originalIntegrationCustomizations
              ?.filter((option) => option.category === selectedCategory && isCustomized(option))
              .map((option) => option.integration)}
          />
        </div>
      </div>
    </>
  );

  const linkToHelp = (
    <a
      href="https://help.merge.dev/en/collections/3033005-authentication-guides"
      className="flex items-center text-sm font-medium"
      target="_blank"
      rel="noreferrer"
    >
      See Merge Authentication Guides <i className="fe fe-chevron-right ml-1" />
    </a>
  );

  return (
    <ConfigurationSettingsCard
      title="Override guide links"
      subtitle={subtitle}
      rightHandContent={linkToHelp}
    >
      <>
        <div className="flex flex-row items-center justify-between border-b border-gray-10 min-w-0 ml-[-24px] mr-[-24px]">
          <div className="flex flex-col w-full px-6 mb-6 gap-3">
            <p className="text-black text-base font-semibold mb-1">Authentication</p>
            {selectedCategory == null || selectedIntegrationId == null ? (
              <TextField
                placeholder="Select a category and integration to customize the help guide URL"
                disabled
                value=""
              />
            ) : (
              <>
                <TextField
                  onChange={(newEvent) => {
                    const characterCount = newEvent.target.value?.length;
                    if (characterCount > 55) {
                      setAuthMessageErrorLabel(
                        "Authentication message must be less than 55 characters.",
                      );
                      setAuthMessage(newEvent.target.value.substring(0, 55));
                    } else {
                      setAuthMessageErrorLabel(null);
                      setAuthMessage(newEvent.target.value);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      onSaveAuthMessage();
                    }
                  }}
                  errorText={authMessageErrorLabel ?? undefined}
                  onBlur={() => onSaveAuthMessage()}
                  // on the backend we update this to None if they clear it which defaults to this text but since we're tracking state in frontend need to check this here
                  value={authMessage}
                  placeholder="Stuck? See detailed instructions with screenshots"
                />
                <TextField
                  placeholder="Enter URL"
                  onChange={(newEvent) => {
                    if (
                      newEvent.target.value != "" &&
                      validUrl.isWebUri(newEvent.target.value) === undefined
                    ) {
                      setAuthURLErrorLabel("Please enter a valid http or https URL.");
                    } else {
                      setAuthURLErrorLabel(null);
                    }
                    setAuthURL(newEvent.target.value);
                  }}
                  value={authURL ?? undefined}
                  error={authURLErrorLabel != null}
                  errorText={authURLErrorLabel ?? undefined}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      onSaveAuthURL();
                    }
                  }}
                  onBlur={() => onSaveAuthURL()}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col pt-5 items-center justify-between min-w-0 gap-4 mb-1">
          <div className="flex flex-row w-100">
            <p className="text-black text-base font-semibold mb-1 mr-1">Inaccessible data </p>
            <p className="text-black text-base font-normal mb-1">
              (missing permissions or bad API key)
            </p>
          </div>
          <div className="w-100">
            {selectedCategory == null || selectedIntegrationId == null ? (
              <TextField
                placeholder="Select a category and integration to customize the help guide URL"
                disabled
                value=""
              />
            ) : (
              <TextField
                placeholder="Enter URL"
                onChange={(newEvent) => {
                  if (
                    newEvent.target.value != "" &&
                    validUrl.isWebUri(newEvent.target.value) === undefined
                  ) {
                    setValidateURLErrorLabel("Please enter a valid http or https URL.");
                  } else {
                    setValidateURLErrorLabel(null);
                  }
                  setValidateURL(newEvent.target.value);
                }}
                value={validateURL ?? undefined}
                error={validateURLErrorLabel != null}
                errorText={validateURLErrorLabel ?? undefined}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    onSaveValidateURL();
                  }
                }}
                onBlur={() => onSaveValidateURL()}
              />
            )}
          </div>
        </div>
      </>
    </ConfigurationSettingsCard>
  );
};

export default ConfigurationHelpGuideURLCard;
