import React from "react";
import { format } from "date-fns";
import { Col, Row } from "react-bootstrap";
import ClickableContainer from "../../../../../shared-components/ClickableContainer";
import EmptyStateWrapper from "../../../../../shared-components/EmptyStateWrapper";
import DeprecatedH2 from "../../../../../../deprecated/DeprecatedH2";

export const LogSidePanelUnauthorized = ({
  title,
  time,
  onClickExit,
}: {
  title?: string;
  time?: string;
  onClickExit: () => void;
}) => (
  <>
    <Row className="mt-6">
      <Col className="text-center">
        <ClickableContainer onClick={() => onClickExit()}>
          <span className="fe fe-x float-right" />
        </ClickableContainer>
        <DeprecatedH2 className="mb-3">{title}</DeprecatedH2>
        <p className="text-gray-50">{time && format(new Date(time), "MMM dd, hh:mm a")}</p>
      </Col>
    </Row>
    <Row>
      <Col className="m-6">
        <EmptyStateWrapper title="You must be an admin or developer to view Logs" />
      </Col>
    </Row>
  </>
);
