import { useMergeLink } from "@mergeapi/react-merge-link";
import React from "react";
import { useState } from "react";
import { getTenantConfigForMergeLink } from "../../../integrations-management/linked-accounts/AddTestLinkedAccountButton";
import { Button } from "@merge-api/merge-javascript-shared";

interface Props {
  /**
   * Called when a new account is linked via the linking flow.
   */
  linkToken: string;
  onNewLinkedAccount: (wasSuccess: boolean) => void;
}

const CustomAddTestLinkedAccountButton = ({ linkToken, onNewLinkedAccount }: Props) => {
  const [wasSuccess, setWasSuccess] = useState(false);

  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess: () => setWasSuccess(true),
    onExit: () => onNewLinkedAccount(wasSuccess),
    ...getTenantConfigForMergeLink(),
    filePickerConfig: {
      onSubmit: () => {},
      allowMultiSelect: true,
    },
  });

  return (
    <Button
      disabled={!isReady}
      fullWidth
      className="mb-5"
      onClick={() => {
        setWasSuccess(false);
        open();
      }}
    >
      Open Merge Link
    </Button>
  );
};

export default CustomAddTestLinkedAccountButton;
