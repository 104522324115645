import React from "react";
import {
  LinkedAccountCondition,
  LinkedAccountConditionMeta,
  LinkedAccountConditionMetaOperator,
} from "../../../../../../../../models/Entities";
import { Select, Text } from "@merge-api/merge-javascript-shared";
import { getOperatorOptions, getOperatorValue } from "../helpers";
import { operatorMap } from "../../../../../../../configuration/selective-sync/constants";

type OperatorSelectProps = {
  linkedAccountCondition: Partial<LinkedAccountCondition>;
  linkedAccountConditionMeta: LinkedAccountConditionMeta;
  onLinkedAccountConditionChange: (LinkedAccountCondition: Partial<LinkedAccountCondition>) => void;
};

const OperatorSelect = ({
  linkedAccountCondition,
  linkedAccountConditionMeta,
  onLinkedAccountConditionChange,
}: OperatorSelectProps) => {
  // derived state
  const operatorOptions = getOperatorOptions(linkedAccountCondition, linkedAccountConditionMeta);
  const operatorValue = getOperatorValue(linkedAccountCondition, linkedAccountConditionMeta);

  // event handlers
  const onOperatorChange = (_: any, option: LinkedAccountConditionMetaOperator | null) => {
    onLinkedAccountConditionChange({
      ...linkedAccountCondition,
      operator: option?.operator || undefined,
      condition_schema_id: option?.condition_schema_id || undefined,
    });
  };

  return (
    <Select
      className="flex flex-1 h-full py-[3px]"
      placeholder="Select operator..."
      clearable={false}
      shadowHidden
      options={operatorOptions}
      value={operatorValue}
      onChange={onOperatorChange}
      getOptionLabel={({ operator }) => {
        return operatorMap[operator].title as string;
      }}
      renderValue={({ operator }) => {
        const option = operatorMap[operator];
        return <>{option.title}</>;
      }}
      renderOption={({ operator }) => {
        const option = operatorMap[operator];

        return (
          <div className="flex flex-col">
            <Text className="text-gray-90">{option.title}</Text>
            <Text variant="caption" className="text-gray-60">
              {option.subtitle}
            </Text>
          </div>
        );
      }}
    />
  );
};

export default OperatorSelect;
