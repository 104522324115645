import { APICategory, ButtonVariant, Dialog, Text } from "@merge-api/merge-javascript-shared";
import React, { useEffect, useState } from "react";
import { EnabledAction } from "../../../models/CommonModel";
import { formatValues, getExternalScopeActionName } from "../../../services";
import { fetchWithAuthAsync } from "../../../api-client/APIClient";

const COMMON_MODELS_URI = "/integrations/common-model-toggles";

export interface ScopeConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (capabilities: EnabledAction[]) => void;
  capabilities: EnabledAction[];
  modelName: string;
  optionId: string;
  category: APICategory;
  dependentModelScopesFlag?: boolean;
  showCapabilities?: boolean;
}

export const getExternalDependentScopeText = (
  action: string,
  capabilities: string[],
  modelName: string,
  dialogText: string,
  showCapabilities: boolean = true,
) => {
  const externalScopeActionNames = capabilities.map((capability) =>
    getExternalScopeActionName(capability).toLowerCase(),
  );

  let optionalActionText = dialogText;
  if (dialogText == "enabled") {
    optionalActionText = "enable";
  } else if (dialogText == "disabled") {
    optionalActionText = "disable";
  }

  const scopeActionNamesFormatted = showCapabilities
    ? `${formatValues(externalScopeActionNames)} access`
    : "access";

  switch (action) {
    case "disabled":
      return `Disabling ${scopeActionNamesFormatted} to ${modelName} also requires you to disable read access to the following models:`;
    case "enabled":
      return `Enabling ${scopeActionNamesFormatted} to ${modelName} also requires you to enable read access to the following models:`;
    default:
      return `Making ${scopeActionNamesFormatted} to ${modelName} optional requires you to also ${optionalActionText} read access to the following models:`;
  }
};

export const getButtonText = (action: string) => {
  switch (action) {
    case "disabled":
      return "Disable all";
    case "enabled":
      return "Enable all";
    default:
      return "Disable all";
  }
};

export type DependentCommonModelResponse = {
  action_text: string;
  dependent_models: string[];
};

export const getDependentModels = (modelName: string, action: string, category: APICategory) => {
  return fetchWithAuthAsync<DependentCommonModelResponse>({
    path: `${COMMON_MODELS_URI}/${category}/${modelName}/${action}`,
    method: "GET",
  });
};

const ScopeConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  capabilities,
  modelName,
  optionId,
  category,
  dependentModelScopesFlag,
  showCapabilities = true,
}: ScopeConfirmationDialogProps) => {
  const [relatedModels, setRelatedModels] = useState<string[]>([]);
  const [shouldShowDialog, setShouldShowDialog] = useState(isOpen);
  const [dialogText, setDialogText] = useState<string>("enabled");

  // This pattern is a bit weird but we do this because we want to pull in data
  // from the backend to determine if this dialogue should actually open or not.
  // We thus have a state in the parent element to initially open it on click,
  // and then another state here to determine if the dialogue should actually open.
  useEffect(() => {
    if (isOpen) {
      getDependentModels(modelName, optionId, category).then((result) => {
        if (dependentModelScopesFlag && result && result.body.dependent_models.length > 0) {
          setRelatedModels(result.body.dependent_models);
          setDialogText(result.body.action_text);
          setShouldShowDialog(true);
        } else {
          setShouldShowDialog(false);
          onConfirm(capabilities);
        }
      });
    } else {
      setShouldShowDialog(false);
    }
  }, [isOpen, modelName, optionId, category]);

  const handleClose = () => {
    onClose();
    setShouldShowDialog(false);
  };

  return (
    <Dialog
      open={shouldShowDialog}
      onClose={handleClose}
      onPrimaryButtonClick={() => onConfirm(capabilities)}
      onSecondaryButtonClick={onClose}
      primaryButtonVariant={ButtonVariant.DangerFilled}
      primaryButtonText={getButtonText(dialogText)}
      secondaryButtonText="Cancel"
      title="Confirm Scopes changes"
      variant="md"
    >
      <Text as="p" variant="md">
        {getExternalDependentScopeText(
          optionId,
          capabilities,
          modelName,
          dialogText,
          showCapabilities,
        )}
      </Text>
      <ul className="mt-3 mx-6">
        {relatedModels.map((model, index) => (
          <li key={index}>{model}</li>
        ))}
      </ul>
    </Dialog>
  );
};

export default ScopeConfirmationDialog;
