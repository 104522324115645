import React, { useMemo } from "react";
import clsx from "clsx";
import { ChevronRight } from "lucide-react";

import {
  Text,
  Button,
  TextField,
  Link,
  APICategory,
  Select,
  UnreleasedAPICategory,
} from "@merge-api/merge-javascript-shared";
import { APIEndpointMethod, APITesterLinkedAccount } from "../../../../../../../models/Entities";
import { ApiTesterDirection, LinkedAccountType } from "../../../../enums";
import useAppContext from "../../../../../../context/useAppContext";
import { MERGE_BASE_URL } from "../../../hooks/useSendAPITesterRequest";
import useApiTesterDirection from "../../../hooks/useApiTesterDirection";
import useLinkedAccountType from "../../../hooks/useLinkedAccountType";

const BASE_URL_REPLACEMENT = "{Base URL}";

type EndpointPathSelectorProps = {
  className?: string;
  linkedAccount?: APITesterLinkedAccount | null;
  onSendClick: () => void;
  method: APIEndpointMethod;
  setMethod: React.Dispatch<React.SetStateAction<APIEndpointMethod>>;
  path: string;
  setPath: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  category?: APICategory | UnreleasedAPICategory;
};

const EndpointPathSelector = ({
  className,
  linkedAccount,
  onSendClick,
  path,
  setPath,
  isLoading,
  category,
  method,
  setMethod,
}: EndpointPathSelectorProps) => {
  // hooks
  const { user } = useAppContext();
  const direction = useApiTesterDirection();
  const { linkedAccountType } = useLinkedAccountType();

  // derived state
  // API Reference Link
  const apiReferenceLink =
    direction === ApiTesterDirection.YOU_TO_MERGE
      ? `https://docs.merge.dev/${category}/overview`
      : `https://docs.merge.dev/integrations/${category}/overview`;

  const prefix = useMemo(() => {
    if (direction === ApiTesterDirection.MERGE_TO_THIRD_PARTY) {
      return linkedAccount?.override_base_api_url || linkedAccount?.integration.base_api_url || "";
    }

    return `${MERGE_BASE_URL}/api/${category}/v1`;
  }, [direction, linkedAccount, category]);

  return (
    <div className={clsx("p-6 w-full", className)}>
      {/* Header */}
      <div className="w-full flex justify-between items-center flex-row bg-transparent mb-6">
        <h6>Endpoint path</h6>
        <Link className="text-sm" target="_blank" href={apiReferenceLink}>
          <Text variant="pre-title">
            API reference <ChevronRight size={10} />
          </Text>
        </Link>
      </div>
      <div className="flex flex-row gap-4 w-full">
        {/* Method Selector */}
        {linkedAccountType !== LinkedAccountType.MOCK_SANDBOX && (
          <Select
            clearable={false}
            value={method}
            popperMaxHeight={350}
            onChange={(e, method) => method && setMethod(method)}
            options={[APIEndpointMethod.GET, APIEndpointMethod.POST]}
            className="max-w-[113px] text-base"
          />
        )}

        {/* Path TextField */}
        <TextField
          className="w-full"
          placeholder="/endpoint"
          prefixVariant="url"
          prefixTooltipTitle={prefix}
          prefix={BASE_URL_REPLACEMENT}
          value={path}
          onChange={(e) => setPath(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && onSendClick()} // submit on enter from TextField
        />

        {/* Send */}
        <Button
          disabled={!linkedAccount && linkedAccountType !== LinkedAccountType.MOCK_SANDBOX}
          onClick={onSendClick}
          loading={isLoading}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default EndpointPathSelector;
