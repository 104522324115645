import React from "react";
import useCustomizationContext from "../context/useCustomizationContext";
import { ButtonVariant, Dialog } from "@merge-api/merge-javascript-shared";
import { publishCustomizableLinkChanges } from "../../../../api-client/APIClient";
import CustomizationChangesOverview from "../helpers/CustomizationChangesOverview";

const MergeLinkPublishModal = () => {
  const {
    organizationCustomization,
    integrationCustomizations,
    setOriginalOrganizationCustomization,
    setOriginalIntegrationCustomizations,
    setModalTypeShown,
  } = useCustomizationContext();
  return (
    <Dialog
      open
      onClose={() => setModalTypeShown(null)}
      onPrimaryButtonClick={() => {
        publishCustomizableLinkChanges({
          status: "PUBLISHED",
          organization_customization_data: organizationCustomization,
          integration_customizations_data: integrationCustomizations,
        });
        setModalTypeShown(null);
        setOriginalOrganizationCustomization(organizationCustomization);
        setOriginalIntegrationCustomizations(JSON.parse(JSON.stringify(integrationCustomizations)));
      }}
      onSecondaryButtonClick={() => setModalTypeShown(null)}
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      primaryButtonText="Publish changes"
      secondaryButtonText="Cancel"
      title="Publish changes"
      variant="md"
    >
      <p>
        Publishing your changes will affect Merge Link for all your users,{" "}
        <b>effective immediately.</b>
      </p>
      <CustomizationChangesOverview />
    </Dialog>
  );
};

export default MergeLinkPublishModal;
