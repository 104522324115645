import React from "react";
import { AuditLogEvent } from "../../../../models/Entities";
import MergeTable from "../../../shared-components/MergeTable";
import TableSkeletonLoader from "../../../integrations-management/shared/TableSkeletonLoader";
import { AuditLogsTableRow } from ".";
import EmptyStateWrapper from "../../../shared-components/EmptyStateWrapper";

type AuditLogTableProps = {
  auditLogEvents?: AuditLogEvent[];
  isLoading: boolean;
};

const AuditLogTable = ({
  auditLogEvents = [],
  isLoading,
  className,
}: AuditLogTableProps & Pick<React.ComponentProps<"div">, "className">) => {
  return (
    <div className={className}>
      <MergeTable
        hover={false}
        hasMorePaddingOnFirstElement
        header={
          <>
            <th scope="col">User</th>
            <th scope="col">Role</th>
            <th scope="col">Event description</th>
            <th scope="col">IP address</th>
            <th scope="col">Date</th>
          </>
        }
        content={
          isLoading ? (
            <TableSkeletonLoader cols={6} rows={8} size="large" />
          ) : auditLogEvents.length ? (
            auditLogEvents.map((auditLogEvent) => (
              <AuditLogsTableRow key={auditLogEvent.id} auditLogEvent={auditLogEvent} />
            ))
          ) : (
            <tr>
              <td colSpan={5} className="py-9">
                <EmptyStateWrapper isTable title="No audit trail" />
              </td>
            </tr>
          )
        }
      />
    </div>
  );
};

export default AuditLogTable;
