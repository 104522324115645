import React, { useMemo } from "react";
import styled from "styled-components";
import { LinkedAccount } from "../../../../../models/Entities";
import { palette } from "../../../../../styles/theme";
import {
  MustUseAutoWebhookSetupNoticeCard,
  ThirdPartyWebhooksDisabledDueToFileStorageSelectiveSyncFiltersNoticeCard,
  ThirdPartyWebhooksUpsellNoticeCard,
} from "../../../../configuration/webhooks/WebhooksRestrictionsNotices";
import useProductRestrictions from "../../../../shared-components/hooks/useProductRestrictions";
import { SectionHeaderWrapper, TitleHeading } from "../../../../shared-components/MergeLayouts";
import WebhookEventsTable from "./WebhookEventsTable";
import useAppContext from "../../../../context/useAppContext";
import MaybeDisable from "../../../../shared-components/MaybeDisable";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import {
  DOCS_WEBHOOKS_PATH,
  MERGE_QUICKBOOKS_WEBHOOKS_HELP_CENTER,
  MERGE_XERO_WEBHOOKS_HELP_CENTER,
  openInNewTab,
} from "../../../../../router/RouterUtils";
import { BookOpen } from "lucide-react";
import useLoadLinkedAccountFileStorageFilters from "../selective-sync/hooks/useLoadLinkedAccountFileStorageFilters";

type Props = {
  linkedAccount: LinkedAccount;
};

const Section = styled.div`
  padding: 0 0 56px 0;
  &:not(:first-child) {
    padding: 56px 0;
    border-top: 1px solid ${palette.border};
  }
`;

const HeaderExplanation = styled.p`
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 32px;
`;

const WebhooksPage = ({ linkedAccount }: Props) => {
  // hooks
  const { user } = useAppContext();
  const { productRestrictions } = useProductRestrictions();

  // derived state
  const integrationName = useMemo(() => linkedAccount?.integration.name ?? "", [linkedAccount]);
  const isUsingAutoWebhookSetup = Boolean(
    user.organization.enable_auto_webhook_setup &&
      linkedAccount?.integration?.is_auto_webhook_setup_enabled,
  );

  const getWebhookDocsURL = (integrationName: string) => {
    switch (integrationName) {
      case "QuickBooks Online":
        return MERGE_QUICKBOOKS_WEBHOOKS_HELP_CENTER;
      case "Xero":
        return MERGE_XERO_WEBHOOKS_HELP_CENTER;
      default:
        return DOCS_WEBHOOKS_PATH;
    }
  };

  const isPlanWithThirdPartyWebhooks = Boolean(productRestrictions?.third_party_webhooks_enabled);

  const { linkedAccountFileStorageFilters } = useLoadLinkedAccountFileStorageFilters({
    linkedAccount,
  });

  const noLinkedAccountFileStorageFilters =
    !linkedAccountFileStorageFilters ||
    (linkedAccountFileStorageFilters && linkedAccountFileStorageFilters.length === 0);

  const hasLinkedAccountFileStorageFilters =
    linkedAccountFileStorageFilters && linkedAccountFileStorageFilters.length > 0;

  // If the integration supports Auto Webhook Setup then manual setup is not supported.
  const showMustEnableAutoWebhookSetup =
    isPlanWithThirdPartyWebhooks &&
    linkedAccount?.integration.is_auto_webhook_setup_enabled &&
    !user.organization.enable_auto_webhook_setup;

  const isWebhookEventsTableDisabled =
    !isPlanWithThirdPartyWebhooks ||
    showMustEnableAutoWebhookSetup ||
    hasLinkedAccountFileStorageFilters;

  const headerContents = (
    <SectionHeaderWrapper
      title={<>{integrationName} webhooks</>}
      titleHeading={TitleHeading.H1}
      subtitle={
        noLinkedAccountFileStorageFilters ? (
          <HeaderExplanation>
            Configure {integrationName} to send real-time data updates to Merge for this Linked
            Account
          </HeaderExplanation>
        ) : (
          <></>
        )
      }
      button={
        <Button
          size="sm"
          variant={ButtonVariant.TertiaryWhite}
          onClick={() => openInNewTab(getWebhookDocsURL(linkedAccount?.integration.name))}
          leftIcon={<BookOpen size={12} />}
        >
          Webhook docs
        </Button>
      }
    />
  );

  // If webhooks are disabled in ProductRestrictions, then we show an upsell to upgrade their organization's plan.
  // Otherwise, if the integration only supports auto webhook creation but the customer
  // doesn't have that setting enabled, then instruct them on how to do so.
  const webhooksUpsellOrEnableSetting = !isPlanWithThirdPartyWebhooks ? (
    <div style={{ marginBottom: "36px" }}>
      <ThirdPartyWebhooksUpsellNoticeCard />
    </div>
  ) : showMustEnableAutoWebhookSetup && noLinkedAccountFileStorageFilters ? (
    <div style={{ marginBottom: "36px" }}>
      <MustUseAutoWebhookSetupNoticeCard integrationName={integrationName} />
    </div>
  ) : null;

  const webhooksDisabledDueToFileStorageSelectiveSyncFilters =
    isPlanWithThirdPartyWebhooks && hasLinkedAccountFileStorageFilters ? (
      <div className=" mb-9">
        <ThirdPartyWebhooksDisabledDueToFileStorageSelectiveSyncFiltersNoticeCard
          path={window.location.pathname.replace("webhooks", "selective-sync")}
        />
      </div>
    ) : null;

  if (!linkedAccount) return null;

  // Show detailed info if there's an upsell for webhooks to make the page more full
  return (
    <Section>
      {headerContents}
      {webhooksUpsellOrEnableSetting}
      {webhooksDisabledDueToFileStorageSelectiveSyncFilters}
      <MaybeDisable disable={isWebhookEventsTableDisabled}>
        <WebhookEventsTable
          integrationName={integrationName}
          linkedAccountId={linkedAccount.id}
          isUsingAutoWebhookSetup={isUsingAutoWebhookSetup}
        />
      </MaybeDisable>
    </Section>
  );
};

export default WebhooksPage;
