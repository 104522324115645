import React, { useEffect, useRef, useState } from "react";
import { HTTPMethod, LinkedAccountWebhookReceivers } from "../../../../../models/Entities";
import WebhookReceiverRow from "./WebhookReceiverRow";
import { ChevronRight } from "lucide-react";
import SkeletonLoader from "../../../../shared-components/SkeletonLoader";
import MergeTable from "../../../../shared-components/MergeTable";
import { useRequest } from "../../../../shared-components/hooks/useRequest";
import { Alert, Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { CONFIGURATION_INTEGRATIONS_PATH } from "../../../../../router/RouterUtils";

interface Props {
  /**
   * Name of the linked integration
   */
  integrationName: string;

  /**
   * The id of the linked account that shows these events
   */
  linkedAccountId: string;

  /**
   * True if the org has enabled auto webhook setup and the integration uses it.
   */
  isUsingAutoWebhookSetup?: boolean;
}

/**
 * Shows a table with rows for each event that has a webhook receiver for it right now.
 */

const WebhookEventsTable = ({
  integrationName,
  linkedAccountId,
  isUsingAutoWebhookSetup,
}: Props) => {
  // hooks
  const { data: webhookReceivers } = useRequest<LinkedAccountWebhookReceivers>({
    path: `/integrations/webhook-receivers/${linkedAccountId}`,
    method: HTTPMethod.GET,
  });

  // derived state
  const receivers = webhookReceivers?.webhook_receivers || [];
  const integrationUsesWebhookHandshake =
    (webhookReceivers && webhookReceivers.integration_uses_webhook_handshake) || false;
  const integrationUsesAppLevelSecretKey =
    (webhookReceivers && webhookReceivers.integration_uses_app_level_secret_key) || false;
  const isUsingOrgLevelSignatureKey = webhookReceivers?.uses_org_level_signature_key || false;

  const setupOrgLevelSignatureKeyBanner = isUsingOrgLevelSignatureKey ? (
    <div className="mb-4">
      <Alert color="gray" showWarningIcon size="sm">
        <div className="flex items-center w-full">
          <span className="flex-grow">
            Fill out the signature key to verify the authenticity of webhook requests in{" "}
            {integrationName}'s organization-level credentials.
          </span>
          <span>
            <Button
              size="sm"
              variant={ButtonVariant.TertiaryWhite}
              onClick={() => open(CONFIGURATION_INTEGRATIONS_PATH)}
            >
              Go to integrations
            </Button>
          </span>
        </div>
      </Alert>
    </div>
  ) : null;

  // state
  const [rowWidth, setRowWidth] = useState<number>(516);
  const tableRef = useRef<HTMLDivElement>(null);

  // event handlers
  const updateWidth = () => {
    if (tableRef.current) {
      setRowWidth(tableRef.current.getBoundingClientRect().width);
    }
  };

  // effects
  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [tableRef, rowWidth]);

  const skeletonRows = new Array(8).fill(null).map((_, index) => (
    <tr key={index}>
      <td>
        <SkeletonLoader width={280} height={12} borderRadius={4} />
      </td>
      <td>
        <SkeletonLoader width={106} height={12} borderRadius={4} />
      </td>
      <td>
        <SkeletonLoader width={120} height={12} borderRadius={4} />
      </td>
      <td className="d-flex justify-content-end">
        <ChevronRight size={12} className="text-gray-50" />
      </td>
    </tr>
  ));

  return (
    <>
      {setupOrgLevelSignatureKeyBanner}
      <MergeTable
        hasMorePaddingOnFirstElement
        header={
          <>
            <th scope="col">{integrationName} event</th>
            <th scope="col">Third-party webhooks</th>
            <th scope="col">Last received</th>
          </>
        }
        content={
          webhookReceivers
            ? receivers.map((receiver) => (
                <WebhookReceiverRow
                  key={receiver.event}
                  receiver={receiver}
                  width={rowWidth}
                  integrationName={integrationName}
                  linkedAccountId={linkedAccountId}
                  integrationUsesWebhookHandshake={integrationUsesWebhookHandshake}
                  integrationUsesAppLevelSecretKey={integrationUsesAppLevelSecretKey}
                  isUsingAutoWebhookSetup={isUsingAutoWebhookSetup}
                  isUsingOrgLevelSignatureKey={isUsingOrgLevelSignatureKey}
                />
              ))
            : skeletonRows
        }
        hasMarginBottom={false}
      />
    </>
  );
};

export default WebhookEventsTable;
