import React, { useCallback, useState } from "react";
import useAppContext from "../../context/useAppContext";
import { fetchCurrentUser, fetchWithAuth } from "../../../api-client/APIClient";
import { showErrorToast } from "../../shared-components/Toasts";
import { Toggle } from "@merge-api/merge-javascript-shared";
import ConfigurationSettingsCard from "../link/helpers/ConfigurationSettingsCard";

const ConfigurationSelectiveSyncSettingsPage = () => {
  const { user, setUser } = useAppContext();
  const [showSelectiveSync, setShowSelectiveSync] = useState<boolean | undefined>(
    user.organization?.show_selective_sync,
  );

  const updateEnableOrganizationSelectiveSync = useCallback(
    ({ showSelectiveSync }: { showSelectiveSync?: boolean }) => {
      fetchWithAuth({
        path: "/organizations/toggle-show-selective-sync",
        method: "PATCH",
        body: {
          show_selective_sync: showSelectiveSync,
        },
        onResponse: (data) => {
          fetchCurrentUser(setUser);
          setShowSelectiveSync(data.show_selective_sync);
        },
        onError: () => {
          showErrorToast("Something went wrong, please check your connection and try again.");
        },
      });
    },
    [setUser],
  );

  return (
    <ConfigurationSettingsCard title="Selective Sync" isBeta>
      <div className="flex flex-row justify-between">
        <span>Allow your users to select what data is synced from third-party platforms</span>
        <Toggle
          checked={showSelectiveSync}
          label={showSelectiveSync ? "Show in Merge Link" : " "}
          onChange={(newIsEnabled) =>
            updateEnableOrganizationSelectiveSync({
              showSelectiveSync: newIsEnabled,
            })
          }
        />
      </div>
    </ConfigurationSettingsCard>
  );
};

export default ConfigurationSelectiveSyncSettingsPage;
