import React from "react";
import styled from "styled-components";
import { ChevronRight, Codesandbox } from "lucide-react";
import { DOCS_METADATA_PATH } from "../../../../router/RouterUtils";
import AppMarketplaceImg from "../../../../assets/img/app-marketplace.png";
import DeprecatedH3 from "../../../../deprecated/DeprecatedH3";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";
import { Button } from "@merge-api/merge-javascript-shared";

const Container = styled.div`
  @media (min-width: 1330px) {
    margin: 32px auto;
    min-width: 75%;
    max-width: 75%;
    padding-bottom: 64px;
  }
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 24px;
`;

const ShadedArea = styled.div`
  border-radius: 10px;
  width: 100%;
  display: block;
  background: #eff6ff;
  padding: 16px 20px;
`;

const Header = styled(DeprecatedH3)`
  font-size: 24px;
  line-height: 36px;
`;

const Subtitle = styled(DeprecatedH6)`
  font-size: 14px;
  line-height: 24px;
`;

const Metadata = () => {
  return (
    <Container>
      <Card>
        <Header className="mb-1">Integration Metadata</Header>
        <Subtitle className="mb-6 gray font-normal">
          Use Integration Metadata to programmatically fetch information about the integrations
          Merge offers
        </Subtitle>

        <p>
          Use the Merge API's Integration Metadata endpoint to fetch integration names, identifiers,
          images, brand colors, and more.
        </p>
        <p>
          You can use this data to create a custom integrations menu or applications marketplace for
          your product.
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "64px 0",
          }}
        >
          <img src={AppMarketplaceImg} width="400px" />
        </div>

        <ShadedArea className="mb-2">
          <Codesandbox size={16} color="var(--blue40)" style={{ marginBottom: 12 }} />
          <DeprecatedH4>Integration Metadata</DeprecatedH4>
          <p className="small">Programmatically fetch information about Merge integrations</p>
          <Button
            size="sm"
            rightIcon={<ChevronRight size={12} />}
            onClick={() => window.open(DOCS_METADATA_PATH, "_blank")}
          >
            View docs
          </Button>
        </ShadedArea>
      </Card>
    </Container>
  );
};

export default Metadata;
