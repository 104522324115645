/**
 * Conditionally pluralizes a word based on a count.
 *
 * Example 1:
 * conditionallyPluralize('cat', 0);   // Output: '0 cats'
 *
 * Example 2:
 * conditionallyPluralize('dog', 1);    // Output: '1 dog'
 *
 * Example 3:
 * conditionallyPluralize('horse', 3);  // Output: '3 horses'
 */
export const conditionallyPluralize = (word: string, count: number) => {
  if (count !== 1) {
    word += "s";
  }

  return `${count} ${word}`;
};
