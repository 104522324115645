import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Users,
  VolumeX,
  CheckCircle2,
  PauseCircle,
  AlertCircle,
  Info,
  XCircle,
} from "lucide-react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { LinkedAccount, LinkedAccountMetrics } from "../../../models/Entities";
import {
  BILLING_PATH,
  navigateToLinkedAccountsTablePage,
  useQuery,
} from "../../../router/RouterUtils";
import { spectrum, palette } from "../../../styles/theme";
import useAppContext from "../../context/useAppContext";
import IntegrationsManagementFilter from "../shared/IntegrationsManagementFilter";
import useProductRestrictions from "../../shared-components/hooks/useProductRestrictions";
import PaginationFooter from "../../shared-components/PaginationFooter";
import { showErrorToast, showSuccessToast } from "../../shared-components/Toasts";
import LinkedAccountDeletionModal from "./shared/LinkedAccountDeletionModal";
import OrganizationBillingPlanSideCard from "./shared/OrganizationBillingPlanSideCard";
import { ReactComponent as FilterIcon } from "../../../assets/svg/filter-custom.svg";
import MaybeDisable from "../../shared-components/MaybeDisable";
import { useMemo } from "react";
import { LinkedAccountStatuses } from "../../../constants";
import { InputCol, InputColDeprecated } from "../shared/IntegrationsManagementFilter";
import LinkedAccountTable from "./LinkedAccountTable";
import {
  Button,
  Alert,
  Tooltip,
  ButtonVariant,
  Badge,
  Text,
} from "@merge-api/merge-javascript-shared";
import { useLazyRequest } from "../../shared-components/hooks/useLazyRequest";
import { isBillingPlanFreePlan } from "../../settings/billing/BillingUtils";
import { useMergeFlag, MergeFlagFeature } from "../../shared-components/hooks/useMergeFlag";

type Props = {
  isTestAccounts: boolean;
  linkedAccounts: LinkedAccount[] | undefined;
  hasNext: boolean;
  hasPrevious: boolean;
  onNext: () => void;
  onPrevious: () => void;
  onRemove: (index: number) => void;
  onRefetch: (cursor?: any) => void;
  hasInitialized: boolean;
  isLoading: boolean;
  onLinkedAccountsParamsPathChange: (paramsPath: string) => void;
  freeAccountGated?: boolean;
};

type ColoredTextProps = {
  textColor: string;
};

const ColoredText = styled.p<ColoredTextProps>`
  color: ${(props) => props.textColor};
  font-size: 10px;
  font-weight: 400;
`;

type LinkedAccountTypeCardCountProps = {
  isSelected: boolean;
  showOutline: boolean;
};
const LinkedAccountTypeCardCount = styled.div<LinkedAccountTypeCardCountProps>`
  background-color: ${palette.white};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow:
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2),
    0px 3px 12px -3px rgba(0, 0, 0, 0.12);
  padding: 12px 12px 6px !important;
  cursor: pointer;
  opacity: ${(props) => (props.isSelected ? "1" : 0.35)};
  border: ${(props) => (props.showOutline ? "1px solid #075FF7" : "")};
  &:hover {
    opacity: 1 !important;
  }
`;

const LinkedAccountMetricsRow = styled(Row)`
  margin-bottom: 24px;
}
`;

const LinkedAccountMetric = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 1.5;
  max-height: 48px;
`;

const ToolTipText = styled.p`
  line-height: 1.5;
`;

const LinkedAccountsPageData = (props: Props) => {
  const history = useHistory();
  const { user } = useAppContext();

  const { productRestrictions, orgBillingPlan } = useProductRestrictions();
  const [hoveredStatus, setHoveredStatus] = useState<string>();
  const [linkedAccountMetrics, setLinkedAccountMetrics] = useState<LinkedAccountMetrics>();
  const [selectedStatus, setSelectedStatus] = useState(LinkedAccountStatuses.ALL);
  const [paramsPath, setParamsPath] = useState<string | undefined>(undefined);
  const [linkedAccountsBeingResynced, setLinkedAccountsBeingResynced] = useState<
    Set<LinkedAccount>
  >(new Set());
  const [linkedAccountToDelete, setLinkedAccountToDelete] = useState<LinkedAccount | undefined>();
  const isLinkedAccountDeletionModalOpen = linkedAccountToDelete != undefined;
  const query = useQuery();
  const [fetchDetailedCounts, _] = useLazyRequest<LinkedAccountMetrics>({});

  const getLinkedAccountParams = () => {
    return selectedStatus !== "ALL" ? `status=${selectedStatus}` : "";
  };

  const completeProductionDuplicatesOf = useMemo<string | null>(
    () => query.get("complete_production_duplicates_of"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const showDuplicatesOnly = !!completeProductionDuplicatesOf;

  const testAccountPath = props.isTestAccounts ? "&is_test_account=true" : "";

  const getLinkedAccountParamsPath = () => {
    let result = getLinkedAccountParams();

    if (paramsPath) {
      if (result) {
        result += `&${paramsPath}`;
      } else {
        result = paramsPath;
      }
    }

    if (testAccountPath) {
      if (result) {
        result += `&${testAccountPath}`;
      } else {
        result = testAccountPath;
      }
    }

    return result;
  };

  const updateDetailedCounts = async () => {
    const { data } = await fetchDetailedCounts({
      path: `/integrations/linked-accounts/detailed-counts?${getLinkedAccountParamsPath()}`,
    });
    setLinkedAccountMetrics(data);
  };

  const isFreePlan = isBillingPlanFreePlan(
    user.organization.organization_billing_plan?.billing_plan,
  );

  useEffect(() => {
    if (paramsPath == undefined) {
      return;
    }
    updateDetailedCounts();
  }, [paramsPath]);

  useEffect(() => {
    const status = query.get("status");
    if (status) {
      setSelectedStatus(status);
    }
  }, [location.search]);

  useEffect(() => {
    const newParamsPath = getLinkedAccountParamsPath();
    history.replace({
      pathname: location.pathname,
      search: newParamsPath,
    });

    props.onLinkedAccountsParamsPathChange(getLinkedAccountParamsPath());
  }, [paramsPath, selectedStatus, location]);

  // refetch if demo state changes
  useEffect(() => {
    props.hasInitialized && props.onRefetch();
  }, [props.onRefetch, props.hasInitialized]);

  const forceResync = (linkedAccount: LinkedAccount) => {
    setLinkedAccountsBeingResynced(new Set(linkedAccountsBeingResynced).add(linkedAccount));
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccount.id}/force-resync`,
      method: "POST",
      onResponse: (_) => {
        const newSet = new Set(linkedAccountsBeingResynced);
        newSet.delete(linkedAccount);
        setLinkedAccountsBeingResynced(newSet);
        showSuccessToast(
          `Resync queued for ${linkedAccount.end_user.organization_name} integration with ${linkedAccount.integration.name}.`,
        );
      },
      onError: () => {
        const newSet = new Set(linkedAccountsBeingResynced);
        newSet.delete(linkedAccount);
        setLinkedAccountsBeingResynced(newSet);
        showErrorToast("Failed to force resync. Please try again.");
      },
    });
  };

  const deleteLinkedAccount = () => {
    if (linkedAccountToDelete && props.linkedAccounts) {
      fetchWithAuth({
        path: `/integrations/linked-accounts/${linkedAccountToDelete.id}`,
        method: "DELETE",
        onResponse: () => {
          const newSet = new Set(linkedAccountsBeingResynced);
          newSet.delete(linkedAccountToDelete);
          setLinkedAccountsBeingResynced(newSet);
          showSuccessToast("Deleted linked account!");
          setLinkedAccountToDelete(undefined);
          const indexToRemove = props.linkedAccounts?.findIndex(
            (linkedAccount) => linkedAccount.id == linkedAccountToDelete.id,
          );
          if (indexToRemove || indexToRemove === 0) {
            props.onRemove(indexToRemove);
          }
        },
        onError: () => {
          showErrorToast("Failed to delete linked account. Please try again.");
        },
      });
    }
  };

  const onIgnoreDuplicatesClick = () => {
    fetchWithAuth({
      path: `/integrations/linked-accounts/${completeProductionDuplicatesOf}/ignore-duplicates`,
      method: "POST",
      onResponse: () => {
        showSuccessToast("Successfully ignored accounts with these credentials!");
        navigateToLinkedAccountsTablePage(history);
      },
      onError: () => {
        showErrorToast("Failed to ignore accounts with these credentials.");
      },
    });
  };

  const shouldDisplayTestAccountsSideCard =
    props.isTestAccounts && productRestrictions?.test_accounts && props.linkedAccounts;

  const handleStatusSelect = (status: string) => {
    setSelectedStatus(selectedStatus === status ? LinkedAccountStatuses.ALL : status);
  };

  const hasPagination = props.hasNext || props.hasPrevious;
  return (
    <>
      {linkedAccountToDelete && (
        <LinkedAccountDeletionModal
          isModalOpen={isLinkedAccountDeletionModalOpen}
          deleteLinkedAccount={deleteLinkedAccount}
          closeModal={() => setLinkedAccountToDelete(undefined)}
          linkedAccount={linkedAccountToDelete}
        />
      )}
      {showDuplicatesOnly && (
        <Alert className="mt-2 mb-6" icon={<Users size={16} className=" text-yellow-50" />}>
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-col">
              <b>These Linked Accounts are configured using the same credentials. </b>
              <div>
                If this is intentional, press &quot;Ignore&quot; to dismiss warnings for these
                accounts. Otherwise, delete unwanted Linked Accounts to avoid unnecessary syncing.
              </div>
            </div>
            <Button leftIcon={<VolumeX size={12} />} onClick={onIgnoreDuplicatesClick}>
              Ignore
            </Button>
          </div>
        </Alert>
      )}
      <MaybeDisable disable={showDuplicatesOnly}>
        <IntegrationsManagementFilter
          isLinkedAccounts
          setParamsPath={setParamsPath}
          isTestAccounts={props.isTestAccounts}
        />
      </MaybeDisable>
      <LinkedAccountMetricsRow className="no-gutters" style={{ minWidth: "100%" }}>
        {props.freeAccountGated ? (
          <>
            <InputCol lg="3" md="6" sm="12">
              <div
                className={`rounded-lg shadow overflow-hidden cursor-pointer h-[124px] ${
                  selectedStatus === LinkedAccountStatuses.COMPLETE ? "border border-[#075FF7]" : ""
                }`}
                onClick={() => {
                  handleStatusSelect(LinkedAccountStatuses.COMPLETE);
                }}
              >
                <Badge size="lg" color="teal" className="w-full p-0">
                  <div className="d-flex align-items-center px-4 py-1.5 w-100">
                    <div className="d-flex align-items-center gap-1.5 mr-auto ">
                      <CheckCircle2 size={16} />
                      <Text className="font-semibold">Linked</Text>
                    </div>
                    <button
                      className="group"
                      onClick={() => {
                        handleStatusSelect(LinkedAccountStatuses.COMPLETE);
                      }}
                    >
                      <Text
                        variant="title-sm"
                        className="font-semibold text-blue-40 group-hover:text-blue-60"
                      >
                        {selectedStatus === LinkedAccountStatuses.COMPLETE
                          ? "Remove filter"
                          : "Filter"}
                      </Text>
                    </button>
                  </div>
                </Badge>
                <div className="p-5">
                  <div className="flex justify-between items-center">
                    <Text variant="h2">
                      {linkedAccountMetrics
                        ? linkedAccountMetrics.completed_linked_accounts_count
                        : ""}
                    </Text>
                  </div>
                </div>
              </div>
            </InputCol>
            <InputCol lg="3" md="6" sm="12">
              <div
                className={`rounded-lg shadow overflow-hidden cursor-pointer min-h-[124px] ${
                  selectedStatus === LinkedAccountStatuses.IDLE ? "border border-[#075FF7]" : ""
                }`}
                onClick={() => {
                  handleStatusSelect(LinkedAccountStatuses.IDLE);
                }}
              >
                <Badge size="lg" color="gray" className="w-full p-0">
                  <div className="flex align-items-center px-4 py-1.5 w-100">
                    <div className="d-flex align-items-center gap-1.5 mr-auto">
                      <PauseCircle size={16} />
                      <Text className="font-semibold">Idle</Text>
                    </div>

                    <button
                      className="group"
                      onClick={() => handleStatusSelect(LinkedAccountStatuses.IDLE)}
                    >
                      <Text
                        variant="title-sm"
                        className="font-semibold text-blue-40 group-hover:text-blue-60"
                      >
                        {selectedStatus === LinkedAccountStatuses.IDLE ? "Remove filter" : "Filter"}
                      </Text>
                    </button>
                  </div>
                </Badge>
                <div className="p-5">
                  <div className="flex justify-between items-center">
                    <Text variant="h2">
                      {linkedAccountMetrics ? linkedAccountMetrics.idle_accounts_count : ""}
                    </Text>
                  </div>
                </div>
              </div>
            </InputCol>
            <InputCol lg="3" md="6" sm="12">
              <div
                className={`rounded-lg shadow overflow-hidden cursor-pointer min-h-[124px] ${
                  selectedStatus === LinkedAccountStatuses.INCOMPLETE
                    ? "border border-[#075FF7]"
                    : ""
                }`}
                onClick={() => {
                  handleStatusSelect(LinkedAccountStatuses.INCOMPLETE);
                }}
              >
                <Badge size="lg" color="yellow" className="w-full p-0">
                  <div className="d-flex align-items-center px-4 py-1.5 w-100">
                    <div className="d-flex align-items-center gap-1.5 mr-auto">
                      <AlertCircle size={16} />
                      <Text className="font-semibold">Incomplete</Text>
                    </div>
                    <button
                      className="group"
                      onClick={() => {
                        handleStatusSelect(LinkedAccountStatuses.INCOMPLETE);
                      }}
                    >
                      <Text
                        variant="title-sm"
                        className="font-semibold text-blue-40 group-hover:text-blue-60"
                      >
                        {selectedStatus === LinkedAccountStatuses.INCOMPLETE
                          ? "Remove filter"
                          : "Filter"}
                      </Text>
                    </button>
                  </div>
                </Badge>
                <div className="p-5">
                  <div className="flex justify-between items-center">
                    <Text variant="h2">
                      {linkedAccountMetrics
                        ? linkedAccountMetrics.incomplete_linked_accounts_count
                        : ""}
                    </Text>
                  </div>
                </div>
              </div>
            </InputCol>
            <InputCol lg="3" md="6" sm="12">
              <div
                className={`rounded-lg shadow overflow-hidden cursor-pointer min-h-[124px] ${
                  selectedStatus === LinkedAccountStatuses.RELINK_NEEDED
                    ? "border border-[#075FF7]"
                    : ""
                }`}
                onClick={() => {
                  handleStatusSelect(LinkedAccountStatuses.RELINK_NEEDED);
                }}
              >
                <Badge size="lg" color="red" className="w-full p-0">
                  <div className="d-flex justify-content-between align-items-center px-4 py-1.5 w-100">
                    <div className="d-flex align-items-center gap-1.5 mr-auto">
                      <XCircle size={16} />
                      <Text className="font-semibold">Relink</Text>
                    </div>
                    <button
                      className="group"
                      onClick={() => {
                        handleStatusSelect(LinkedAccountStatuses.RELINK_NEEDED);
                      }}
                    >
                      <Text
                        variant="title-sm"
                        className="font-semibold text-blue-40 group-hover:text-blue-60"
                      >
                        {selectedStatus === LinkedAccountStatuses.RELINK_NEEDED
                          ? "Remove filter"
                          : "Filter"}
                      </Text>
                    </button>
                  </div>
                </Badge>
                <div className="p-5">
                  <div className="flex justify-between items-center">
                    <Text variant="h2">
                      {linkedAccountMetrics
                        ? linkedAccountMetrics.relink_needed_accounts_count
                        : ""}
                    </Text>
                  </div>
                </div>
              </div>
            </InputCol>
          </>
        ) : (
          <>
            <InputColDeprecated lg="4" md="6" sm="12">
              <Tooltip className="inline" title="See all completed Linked Accounts">
                <LinkedAccountTypeCardCount
                  className="p-6"
                  isSelected={[LinkedAccountStatuses.COMPLETE, LinkedAccountStatuses.ALL].includes(
                    selectedStatus,
                  )}
                  showOutline={LinkedAccountStatuses.COMPLETE == selectedStatus}
                  onClick={() => {
                    handleStatusSelect(LinkedAccountStatuses.COMPLETE);
                  }}
                  onMouseEnter={() => {
                    setHoveredStatus(LinkedAccountStatuses.COMPLETE);
                  }}
                  onMouseLeave={() => {
                    setHoveredStatus("");
                  }}
                >
                  <div className="d-flex align-items-center mb-0" style={{ color: "#1A9B87" }}>
                    <ColoredText textColor={spectrum.teal50} className="mb-0 mr-auto">
                      Linked
                    </ColoredText>
                    {LinkedAccountStatuses.COMPLETE == selectedStatus &&
                    LinkedAccountStatuses.COMPLETE == hoveredStatus ? (
                      <ColoredText textColor={spectrum.blue40} className="mb-0">
                        Remove Filter
                      </ColoredText>
                    ) : LinkedAccountStatuses.COMPLETE == selectedStatus ? (
                      <ColoredText textColor={spectrum.blue40} className="mb-0">
                        Filtered
                      </ColoredText>
                    ) : LinkedAccountStatuses.COMPLETE == hoveredStatus ? (
                      <ColoredText textColor={spectrum.blue40} className="mb-0">
                        Filter
                      </ColoredText>
                    ) : (
                      <FilterIcon />
                    )}
                  </div>
                  <LinkedAccountMetric>
                    {linkedAccountMetrics?.completed_linked_accounts_count ? (
                      linkedAccountMetrics.completed_linked_accounts_count
                    ) : (
                      <>
                        <span className="gray dashboard-number-header">-</span>
                      </>
                    )}
                  </LinkedAccountMetric>
                </LinkedAccountTypeCardCount>
              </Tooltip>
            </InputColDeprecated>

            <InputColDeprecated lg="4" md="6" sm="12">
              <Tooltip className="inline" title="See all incomplete Linked Accounts">
                <LinkedAccountTypeCardCount
                  className="p-6"
                  isSelected={[
                    LinkedAccountStatuses.INCOMPLETE,
                    LinkedAccountStatuses.ALL,
                  ].includes(selectedStatus)}
                  showOutline={LinkedAccountStatuses.INCOMPLETE == selectedStatus}
                  onClick={() => {
                    handleStatusSelect(LinkedAccountStatuses.INCOMPLETE);
                  }}
                  onMouseEnter={() => {
                    setHoveredStatus(LinkedAccountStatuses.INCOMPLETE);
                  }}
                  onMouseLeave={() => {
                    setHoveredStatus("");
                  }}
                >
                  <div className="d-flex align-items-center mb-0">
                    <ColoredText textColor={spectrum.yellow40} className="mb-0 mr-auto">
                      Incomplete link
                    </ColoredText>
                    {LinkedAccountStatuses.INCOMPLETE == selectedStatus &&
                    LinkedAccountStatuses.INCOMPLETE == hoveredStatus ? (
                      <ColoredText textColor={spectrum.blue40} className="mb-0">
                        Remove filter
                      </ColoredText>
                    ) : LinkedAccountStatuses.INCOMPLETE == selectedStatus ? (
                      <ColoredText textColor={spectrum.blue40} className="mb-0">
                        Filtered
                      </ColoredText>
                    ) : LinkedAccountStatuses.INCOMPLETE == hoveredStatus ? (
                      <ColoredText textColor={spectrum.blue40} className="mb-0">
                        Filter
                      </ColoredText>
                    ) : (
                      <FilterIcon />
                    )}
                  </div>
                  <LinkedAccountMetric>
                    {linkedAccountMetrics?.incomplete_linked_accounts_count ? (
                      linkedAccountMetrics.incomplete_linked_accounts_count
                    ) : (
                      <>
                        <span className="gray dashboard-number-header">-</span>
                      </>
                    )}
                  </LinkedAccountMetric>
                </LinkedAccountTypeCardCount>
              </Tooltip>
            </InputColDeprecated>

            <InputColDeprecated lg="4" md="6" sm="12">
              <Tooltip className="inline" title="See all Linked Accounts that need to be relinked">
                <LinkedAccountTypeCardCount
                  isSelected={[
                    LinkedAccountStatuses.RELINK_NEEDED,
                    LinkedAccountStatuses.ALL,
                  ].includes(selectedStatus)}
                  showOutline={LinkedAccountStatuses.RELINK_NEEDED == selectedStatus}
                  className="p-6"
                  onClick={() => {
                    handleStatusSelect(LinkedAccountStatuses.RELINK_NEEDED);
                  }}
                  onMouseEnter={() => {
                    setHoveredStatus(LinkedAccountStatuses.RELINK_NEEDED);
                  }}
                  onMouseLeave={() => {
                    setHoveredStatus("");
                  }}
                >
                  <div className="d-flex align-items-center mb-0">
                    <ColoredText textColor={spectrum.red40} className="mb-0 mr-auto">
                      Relink needed
                    </ColoredText>
                    {LinkedAccountStatuses.RELINK_NEEDED == selectedStatus &&
                    LinkedAccountStatuses.RELINK_NEEDED == hoveredStatus ? (
                      <ColoredText textColor={spectrum.blue40} className="mb-0">
                        Remove filter
                      </ColoredText>
                    ) : LinkedAccountStatuses.RELINK_NEEDED == selectedStatus ? (
                      <ColoredText textColor={spectrum.blue40} className="mb-0">
                        Filtered
                      </ColoredText>
                    ) : LinkedAccountStatuses.RELINK_NEEDED == hoveredStatus ? (
                      <ColoredText textColor={spectrum.blue40} className="mb-0">
                        Filter
                      </ColoredText>
                    ) : (
                      <FilterIcon />
                    )}
                  </div>
                  <LinkedAccountMetric>
                    {linkedAccountMetrics?.relink_needed_accounts_count ? (
                      linkedAccountMetrics.relink_needed_accounts_count
                    ) : (
                      <>
                        <span className="gray dashboard-number-header">-</span>
                      </>
                    )}
                  </LinkedAccountMetric>
                </LinkedAccountTypeCardCount>
              </Tooltip>
            </InputColDeprecated>
          </>
        )}
      </LinkedAccountMetricsRow>
      {props.freeAccountGated &&
        isFreePlan &&
        linkedAccountMetrics &&
        linkedAccountMetrics.completed_linked_accounts_count != null &&
        linkedAccountMetrics.plan_limit_reached_accounts_count != null && (
          <div className="mb-4 flex items-center gap-1.5">
            <Text className="text-gray-60">
              {linkedAccountMetrics.plan_limit_reached_accounts_count > 0
                ? `You are ${linkedAccountMetrics.plan_limit_reached_accounts_count} Linked ${
                    linkedAccountMetrics.plan_limit_reached_accounts_count === 1
                      ? "Account"
                      : "Accounts"
                  } over the Free Plan Limit`
                : `Currently using ${
                    linkedAccountMetrics.completed_linked_accounts_count -
                    linkedAccountMetrics.plan_limit_reached_accounts_count
                  } / 3 Linked ${
                    linkedAccountMetrics.completed_linked_accounts_count -
                      linkedAccountMetrics.plan_limit_reached_accounts_count ===
                    1
                      ? "Account"
                      : "Accounts"
                  } on the Free Plan`}
            </Text>
            <Tooltip
              title={
                <div className="flex flex-col items-center">
                  <span>
                    You can only connect 3 production and 3 test Linked Accounts on the Free plan.{" "}
                    <a href={BILLING_PATH} className="underline text-white">
                      Upgrade your plan
                    </a>{" "}
                    to link more accounts.
                  </span>
                </div>
              }
            >
              <Info className="text-gray-60 mt-0.25" size={16} />
            </Tooltip>
          </div>
        )}

      <Row>
        <Col md={props.freeAccountGated || !shouldDisplayTestAccountsSideCard ? 12 : 10}>
          <LinkedAccountTable
            linkedAccounts={props.linkedAccounts}
            isLoading={props.isLoading}
            forceResync={forceResync}
            isTestAccounts={props.isTestAccounts}
            setLinkedAccountToDelete={setLinkedAccountToDelete}
            //remove the below prop after testing : @avirathtib
            freeAccountGated={props.freeAccountGated}
          />
        </Col>
        {!props.freeAccountGated && shouldDisplayTestAccountsSideCard && orgBillingPlan && (
          <Col md="2" className="pl-0">
            <OrganizationBillingPlanSideCard billingPlan={orgBillingPlan} />
          </Col>
        )}
      </Row>

      {hasPagination && (
        <PaginationFooter
          hasPrevious={props.hasPrevious}
          hasNext={props.hasNext}
          onPreviousClick={props.onPrevious}
          onNextClick={props.onNext}
        />
      )}
    </>
  );
};

export default LinkedAccountsPageData;
