import React from "react";
import PortalPageHeaderWrapper from "../../portal/PortalPageHeaderWrapper";
import { ApiTesterRequestCard, ApiTesterResponseCard } from "./components";
import useApiTesterRequestState from "./components/hooks/useApiTesterRequestState";
import { ArrowRight } from "lucide-react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import {
  API_TESTER_MERGE_TO_THIRD_PARTY_PATH,
  API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH,
  API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH,
  API_TESTER_YOU_TO_MERGE_PATH,
} from "../../../router/RouterUtils";
import CurlCodeBlock from "./components/CurlCodeBlock";
import { formatRows } from "./components/ApiTesterRequestCard/components/ParamsHeadersAndBody/components/PostmanTable/components/utils";
import { ApiTesterDirection, LinkedAccountType } from "./enums";
import useAppContext from "../../context/useAppContext";
import { UserType } from "../../../models/Entities";
import NoPermissionsBlock from "../../common/NoPermissionsBlock";
import { formatMockSandboxAccountToken } from "./utils";
import useNoLinkedAccountsRedirect from "./components/hooks/useNoLinkedAccountsRedirect";
import useLoadLinkedAccounts from "./components/hooks/useLoadLinkedAccounts";
import useLinkedAccountType from "./components/hooks/useLinkedAccountType";
import useSelectedLinkedAccount from "./components/hooks/useSelectedLinkedAccount";

const { YOU_TO_MERGE, MERGE_TO_THIRD_PARTY } = ApiTesterDirection;

const ApiTesterPage = () => {
  // hooks
  const { user } = useAppContext();
  const location = useLocation();
  const { linkedAccounts, onSearchChange } = useLoadLinkedAccounts();
  const { linkedAccount, setLinkedAccount } = useSelectedLinkedAccount({
    linkedAccounts,
  });
  const youToMergeState = useApiTesterRequestState({
    direction: YOU_TO_MERGE,
    linkedAccount,
  });
  const mergeToThirdPartyState = useApiTesterRequestState({
    direction: MERGE_TO_THIRD_PARTY,
    linkedAccount,
  });
  useNoLinkedAccountsRedirect({ linkedAccounts });
  const { lastLinkedAccountType } = useLinkedAccountType();

  // derived state
  const isPageRestricted =
    user.type !== UserType.admin_with_billing && user.type !== UserType.admin_without_billing;

  return (
    <PortalPageHeaderWrapper
      title="API tester"
      subtitle="Send requests to Merge’s APIs or the APIs of supported third-party platforms"
      isMaxWidthEnabled
      subtabs={[
        {
          label: (
            <div>
              You <ArrowRight size={16} className="mr-0" /> Merge
            </div>
          ),
          destination:
            lastLinkedAccountType === LinkedAccountType.MOCK_SANDBOX
              ? API_TESTER_YOU_TO_MERGE_MOCK_SANDBOX_PATH + location.search
              : API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH + location.search,
        },
        {
          label: (
            <div>
              Merge <ArrowRight size={16} className="mr-0" /> Third party
            </div>
          ),
          destination: API_TESTER_MERGE_TO_THIRD_PARTY_PATH + location.search,
        },
      ]}
    >
      {isPageRestricted ? (
        <NoPermissionsBlock restrictedPageName="API tester" />
      ) : (
        <>
          {/* request card */}
          <div className="flex flex-col xl:flex-row w-full xl:pr-8">
            <Switch>
              {/* /you-to-merge */}
              <Route path={API_TESTER_YOU_TO_MERGE_PATH}>
                <div className="flex flex-col xl:w-1/2 shrink-0 mb-9 xl:mr-8">
                  <ApiTesterRequestCard
                    className="mb-9"
                    {...youToMergeState}
                    linkedAccounts={linkedAccounts}
                    linkedAccount={linkedAccount}
                    setLinkedAccount={setLinkedAccount}
                    onSearchChange={onSearchChange}
                  />
                  <CurlCodeBlock
                    className="xl:mb-9"
                    url={youToMergeState.url}
                    headers={formatRows(youToMergeState.headers)}
                    body={youToMergeState.body}
                    method={youToMergeState.method}
                    mockSandboxAccountToken={formatMockSandboxAccountToken(
                      youToMergeState.mockSandboxIntegration,
                    )}
                  />
                </div>
                <ApiTesterResponseCard
                  className="mb-9 xl:w-1/2 h-full flex flex-col shrink-0"
                  isLoading={youToMergeState.isLoading}
                  responseBody={youToMergeState.responseBody}
                  responseHeaders={youToMergeState.responseHeaders}
                  responseCode={youToMergeState.responseCode}
                />
              </Route>

              {/* /merge-to-third-party */}
              <Route path={API_TESTER_MERGE_TO_THIRD_PARTY_PATH}>
                <div className="mb-9 xl:w-1/2 xl:mr-8 shrink-0">
                  <ApiTesterRequestCard
                    {...mergeToThirdPartyState}
                    linkedAccounts={linkedAccounts}
                    linkedAccount={linkedAccount}
                    setLinkedAccount={setLinkedAccount}
                    onSearchChange={onSearchChange}
                  />
                </div>
                <ApiTesterResponseCard
                  className="mb-9 xl:w-1/2 h-full shrink-0"
                  isLoading={mergeToThirdPartyState.isLoading}
                  responseBody={mergeToThirdPartyState.responseBody}
                  responseHeaders={mergeToThirdPartyState.responseHeaders}
                  responseCode={mergeToThirdPartyState.responseCode}
                />
              </Route>
              <Redirect to={API_TESTER_YOU_TO_MERGE_LINKED_ACCOUNT_PATH} />
            </Switch>
          </div>
        </>
      )}
    </PortalPageHeaderWrapper>
  );
};

export default ApiTesterPage;
