import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { TextField, Card, Text } from "@merge-api/merge-javascript-shared";
import { isValidJSON } from "../../../../../../../../services";

type BodyPanelProps = {
  className?: string;
  body: string;
  setBody: React.Dispatch<React.SetStateAction<string>>;
};

const BodyPanel = ({ className, body, setBody }: BodyPanelProps) => {
  // state
  const [error, setError] = useState(false);

  // event handlers
  const validateBody = () => {
    setError(!!body && !isValidJSON(body));
  };

  // reset error state on change
  useEffect(() => {
    setError(false);
  }, [body]);

  return (
    <div className={clsx("", className)}>
      <Card className="mx-6 mb-6 mt-4 border-none overflow-hidden h-auto">
        <div className="flex flex-col h-auto">
          <div className="h-11 p-3 bg-[#F1F3F4]">
            <Text variant="title-sm" className="text-gray-80 ">
              JSON
            </Text>
          </div>
          <div className="m-1">
            <TextField
              error={error}
              errorText="Invalid JSON format. Please ensure your input is correctly structured as JSON."
              autosize
              variant="inlined-code"
              placeholder={'{ "key" : "value" }'}
              value={body}
              onBlur={validateBody}
              onChange={(e) => setBody(e.target.value)}
              minRows={3}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default BodyPanel;
