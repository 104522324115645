import React, { useCallback, useEffect, useState } from "react";
import { User, PendingInvite } from "../../models/Entities";

import AppContext, { UserWithBillingPlan } from "./AppContext";
import { getBillingPlanTitle } from "../settings/billing/BillingUtils";
import { doesUserHavePrivilege } from "../../models/Helpers";
import { MergeFlagFeature, useMergeFlag } from "../shared-components/hooks/useMergeFlag";

interface Props {
  children: (JSX.Element | undefined | null)[];
  user: UserWithBillingPlan;
  setUser: (user: User) => void;
}

// Wraps around App to be able to pull global state anywhere in tree via useContext
const AppContextProvider = ({ children, user, setUser }: Props) => {
  // state
  const [pendingOrganizationInvites, setPendingOrganizationInvites] = useState<PendingInvite[]>();
  const [requestedPlanUpgrade, setRequestedPlanUpgrade] = useState<boolean>(false);

  const isUserPrivileged = user ? doesUserHavePrivilege(user) : false;

  // hooks
  const { enabled: showDeprecatedSunsetItems = false } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_SHOW_DEPRECATED_SUNSET_ITEMS,
    organizationId: user.organization.id,
  });

  // effects
  useEffect(() => {
    if (user) {
      const billingPlanTitle = getBillingPlanTitle(user.organization.organization_billing_plan);
    }
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        isUserPrivileged,
        pendingOrganizationInvites,
        setPendingOrganizationInvites,
        setRequestedPlanUpgrade,
        requestedPlanUpgrade,
        showDeprecatedSunsetItems,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
