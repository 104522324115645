import React from "react";
import { useHistory } from "react-router-dom";
import { navigateToLinkedAccountFieldMappings } from "../../../../../../../router/RouterUtils";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import { Text } from "@merge-api/merge-javascript-shared";
import { conditionallyPluralize } from "../../../../../../../utils/general";

type FieldMappingsWidgetCardProps = {
  linkedAccountFieldMappingsCount: number | undefined;
  orgWideFieldMappingsCount: number | undefined;
  linkedAccountId: string;
  endUserOrganizationName: string;
};

const FieldMappingsWidgetCard = ({
  linkedAccountFieldMappingsCount = 0,
  orgWideFieldMappingsCount = 0,
  linkedAccountId,
  endUserOrganizationName,
}: FieldMappingsWidgetCardProps) => {
  const history = useHistory();

  return (
    <ConfigurationWidgetCard
      includeRightChevron
      includeCardDivider
      cardTitle="Field Mapping"
      onClick={() => navigateToLinkedAccountFieldMappings(history, linkedAccountId)}
      badgeChildren={linkedAccountFieldMappingsCount + orgWideFieldMappingsCount}
      cardSubtitle={
        <div className="flex flex-col">
          {/* Linked account */}
          <div className="flex flex-row justify-between">
            <Text variant="sm" className="text-black truncate">
              {endUserOrganizationName}-specific
            </Text>
            <Text variant="caption" className="whitespace-nowrap">
              {conditionallyPluralize("mapping", linkedAccountFieldMappingsCount)}
            </Text>
          </div>

          {/* Org wide */}
          <div className="flex flex-row justify-between">
            <Text variant="sm" className="text-black">
              Organization-wide
            </Text>
            <Text variant="caption">
              {conditionallyPluralize("mapping", orgWideFieldMappingsCount)}
            </Text>
          </div>
        </div>
      }
    />
  );
};

export default FieldMappingsWidgetCard;
