import React from "react";
import { useState } from "react";
import { updateBillingPlanAutoPlay } from "../BillingAPIClient";
import { OrganizationBillingPlan } from "../BillingModels";
import { showErrorToast } from "../../../shared-components/Toasts";
import { Toggle } from "@merge-api/merge-javascript-shared";

type Props = {
  shouldAutoCharge: boolean;
  updateBillingPlan: (billingPlan: OrganizationBillingPlan) => void;
};

const BillingAutoPayToggle = ({ shouldAutoCharge, updateBillingPlan }: Props) => {
  const [autoPayEnabled, setAutoPayEnabled] = useState(shouldAutoCharge);

  const toggleAutoPlay = () => {
    setAutoPayEnabled(!autoPayEnabled);
    updateBillingPlanAutoPlay({
      shouldAutoCharge: !autoPayEnabled,
      onSuccess: (billingPlan: OrganizationBillingPlan) => {
        updateBillingPlan(billingPlan);
      },
      onError: () => {
        setAutoPayEnabled(!autoPayEnabled);
        showErrorToast(
          "We were unable to enable auto-pay at this time. Please try again and if the problem persists contact Merge support.",
        );
      },
    });
  };

  return (
    <Toggle
      label={autoPayEnabled ? "Autopay enabled" : "Autopay disabled"}
      labelPlacement="left"
      checked={autoPayEnabled}
      onChange={toggleAutoPlay}
      className="mr-10 text-[14px]"
    />
  );
};

export default BillingAutoPayToggle;
