import React from "react";
import { useHistory } from "react-router-dom";
import { navigateToLinkedAccountFieldMappings } from "../../../../../../../router/RouterUtils";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import { Text } from "@merge-api/merge-javascript-shared";
import { conditionallyPluralize } from "../../../../../../../utils/general";

type CommonModelOverridesWidgetCardProps = {
  linkedAccountCommonModelOverridesCount: number | undefined;
  orgWideCommonModelOverridesCount: number | undefined;
  linkedAccountId: string;
  endUserOrganizationName: string;
};

const CommonModelOverridesWidgetCard = ({
  linkedAccountCommonModelOverridesCount = 0,
  orgWideCommonModelOverridesCount = 0,
  linkedAccountId,
  endUserOrganizationName,
}: CommonModelOverridesWidgetCardProps) => {
  const history = useHistory();

  return (
    <ConfigurationWidgetCard
      includeRightChevron
      includeCardDivider
      cardTitle="Common Model overrides"
      onClick={() => navigateToLinkedAccountFieldMappings(history, linkedAccountId)}
      badgeChildren={linkedAccountCommonModelOverridesCount + orgWideCommonModelOverridesCount}
      cardSubtitle={
        <div className="flex flex-col">
          {/* Linked account */}
          <div className="flex flex-row justify-between">
            <Text variant="sm" className="text-black truncate">
              {endUserOrganizationName}-specific
            </Text>
            <Text variant="caption" className="whitespace-nowrap">
              {conditionallyPluralize("mapping", linkedAccountCommonModelOverridesCount)}
            </Text>
          </div>

          {/* Org wide */}
          <div className="flex flex-row justify-between">
            <Text variant="sm" className="text-black">
              Organization-wide
            </Text>
            <Text variant="caption">
              {conditionallyPluralize("mapping", orgWideCommonModelOverridesCount)}
            </Text>
          </div>
        </div>
      }
    />
  );
};

export default CommonModelOverridesWidgetCard;
