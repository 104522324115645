import React from "react";
import { LinkedIntegration, APICategory } from "../../models/Entities";
import { useHistory } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { navigateToProductionLinkedAccountsTablePage } from "../../router/RouterUtils";
import { spectrum } from "../../styles/theme";
import INTEGRATION_CATEGORY_LIST, { fullDisplayNameForAPICategory } from "../../models/Helpers";
import { Tooltip, Button, Card, Text, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { ChevronRight } from "lucide-react";

interface Props {
  integrations: LinkedIntegration[] | undefined;
}

const LinkedIntegrationsScreen = (props: Props) => {
  return (
    <Card>
      <div className="py-5 px-6 border-b-solid border-b-gray-20 border-b-[0.5px]">
        <Text variant="h4">Linked integrations</Text>
      </div>
      <div>
        {INTEGRATION_CATEGORY_LIST.map((category) => (
          <div
            key={category}
            className="py-5 px-6 border-b-solid border-b-gray-20 border-b-[0.5px]"
          >
            <LinkedIntegrationsCategoryColumn
              category={category}
              key={category}
              description={fullDisplayNameForAPICategory(category)}
              integrations={props.integrations}
            />
          </div>
        ))}
      </div>
    </Card>
  );
};

const LinkedIntegrationsCategoryColumn = ({
  category,
  description,
  integrations,
}: {
  category: APICategory;
  description: string;
  integrations: LinkedIntegration[] | undefined;
}) => {
  const categoryIntegrations = integrations?.filter(
    (integration) =>
      integration.categories
        ?.map((integrationCategory) => integrationCategory?.toLowerCase())
        .includes(category),
  );

  const history = useHistory();

  return (
    <div className="flex flex-row w-full justify-between">
      <div className="d-flex mb-3 align-top">
        {categoryIntegrations && categoryIntegrations.length > 0 ? (
          <div className="dashboard-number-header mb-0">{categoryIntegrations.length}</div>
        ) : (
          <div className="text-gray-50 dashboard-number-header mb-0">0</div>
        )}
        <div className="ml-3 text-gray-50 linked-accounts-description">{description}</div>
      </div>

      <div className="w-1/2">
        {categoryIntegrations ? (
          categoryIntegrations.length > 0 ? (
            categoryIntegrations.map((integration: LinkedIntegration) => (
              <div className="mb-2" key={integration.id}>
                <Tooltip className="w-full" title="Go to linked accounts">
                  <Button
                    variant={ButtonVariant.TextBlack}
                    size="sm"
                    rightIcon={<ChevronRight size={12} />}
                    className="text-left hoverable-white items-center justify-between"
                    fullWidth
                    onClick={() => {
                      navigateToProductionLinkedAccountsTablePage(history, {
                        integration__name: integration.name,
                      });
                    }}
                  >
                    <div className="items-center font-normal small">
                      <img src={integration.square_image} className="w-5 h-5 mr-3 rounded-full" />
                      {integration.name}
                    </div>
                  </Button>
                </Tooltip>
              </div>
            ))
          ) : (
            <div className="h-full flex items-center text-gray-50 font-light">
              No linked integrations
            </div>
          )
        ) : (
          <div className="mb-2 w-full">
            <ContentLoader
              speed={1.4}
              width={100}
              height={15}
              viewBox="0 0 100 15"
              backgroundColor={spectrum.gray0}
              foregroundColor={spectrum.gray10}
            >
              <rect x="0" y="0" rx="3" ry="3" width="100" height="15" />
            </ContentLoader>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkedIntegrationsScreen;
