import { Clock4, Pencil, Star, Trash } from "lucide-react";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import type { ReadonlyLogFilter } from "../../../models/LogFilter";
import { palette, spectrum } from "../../../styles/theme";
import { SEARCH_BAR_PLAINTEXT } from "./SearchBarPlaintextRow";
import SearchPill from "./SearchPill";

interface Action {
  /**
   * Which kind of icon we show to denote the action
   */
  iconType: "edit" | "delete";

  /**
   * The actual action that happens upon click
   */
  action: () => void;
}

export interface Props {
  /**
   * Right now, only two icons are possible. Recent is a clock, and
   * saved is a filled star.
   */
  iconType: "recent" | "saved";

  /**
   * If present, rendered next to the icon in plain text
   */
  title?: string;

  /**
   * All the filters and their current values for showing in this row. They
   * will be readonly, as the search bar row isn't interactive for picking
   * different items.
   */
  filters: Array<ReadonlyLogFilter>;

  /**
   * If present, shows up as a list of small icons at the end that
   * have actions that happen on click.
   */
  actions?: Array<Action>;

  /**
   * If the row is currently highlighted, like from a mouse over
   */
  isHighlighted: boolean;
}

interface ComponentProps {
  /**
   * If the row itself is highlighted because of user action
   */
  $isHighlighted: boolean;
}

// In pixels, the size of all icons
const ICON_SIZE = 16;

const ACTION_ICON = css`
  flex: 0 0 auto;
  color: ${palette.placeholder};
  &:hover {
    fill: ${palette.blue};
    color: ${palette.blue};
  }
  cursor: pointer;
`;

const RecentIcon = styled(Clock4)<ComponentProps>`
  flex: 0 0 auto;
  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      color: ${palette.blue};
    `}
`;

const SavedIcon = styled(Star)`
  flex: 0 0 auto;
  color: ${palette.blue};
  fill: ${palette.blue};
`;

const EditIcon = styled(Pencil)`
  ${ACTION_ICON};
`;
const DeleteIcon = styled(Trash)`
  ${ACTION_ICON};
`;

// Simple text for the title matching the style of other plain text in the bar
const Title = styled.div`
  ${SEARCH_BAR_PLAINTEXT};
`;

const Container = styled.div.attrs({
  className: "d-flex align-items-center",
})<ComponentProps>`
  padding: 6px 12px;
  gap: 20px;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
  cursor: pointer;

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      background-color: ${spectrum.blue0};
    `}
`;

// Max-flexy + smaller gaps with a fade at the end
const Filters = styled.div.attrs({
  className: "d-flex align-items-center",
})<ComponentProps>`
  gap: 8px;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 16px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), ${palette.white});
  }

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      background-color: ${spectrum.blue0};
      &:after {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), ${spectrum.blue0} 80%);
      }
    `}
`;

// Rigid + big gaps
const Actions = styled.div.attrs({ className: "d-flex align-items-center" })`
  gap: 16px;
  flex: 0 0 auto;
`;

/**
 * Creates an action element (icon) from an Action
 */
const ActionElement = ({ iconType, action }: Action) => {
  const props = {
    onClick: (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      action();
    },
    size: ICON_SIZE,
  };
  switch (iconType) {
    case "edit":
      return <EditIcon {...props} />;
    case "delete":
      return <DeleteIcon {...props} />;
  }
};

/**
 * Creates one row of the search bar, which contains an icon, possible
 * title for the row, search pills, and possible actions at the end.
 */
const SearchBarRow = ({
  iconType,
  title,
  filters,
  actions,
  isHighlighted,
  ...props
}: Props & Omit<React.ComponentPropsWithoutRef<"div">, "className">) => {
  const mainIcon = useMemo(() => {
    switch (iconType) {
      case "recent":
        return <RecentIcon size={ICON_SIZE} $isHighlighted={isHighlighted} />;
      case "saved":
        return <SavedIcon size={ICON_SIZE} />;
    }
  }, [iconType, isHighlighted]);

  return (
    <Container $isHighlighted={isHighlighted} {...props}>
      {mainIcon}
      {title && <Title>{title}</Title>}
      <Filters $isHighlighted={isHighlighted}>
        {filters.map((filter, index) => (
          <SearchPill key={index} isHighlighted={isHighlighted} {...filter} />
        ))}
      </Filters>
      {actions && (
        <Actions>
          {actions.map((action) => (
            <ActionElement key={action.iconType} {...action} />
          ))}
        </Actions>
      )}
    </Container>
  );
};

export default SearchBarRow;
