import React from "react";
import { Card } from "@merge-api/merge-javascript-shared";

import {
  FieldMappingOptions,
  FieldMappingTarget,
  LinkedAccount,
} from "../../../../../../models/Entities";
import { Globe } from "lucide-react";
import { OrganizationAvatar, UserAvatarSize } from "../../../../../shared-components/MergeAvatars";
import {
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
  CONFIGURATION_COMMON_MODELS_PATH,
} from "../../../../../../router/RouterUtils";
import UnmappedTargetCard from "./UnmappedTargetCard";

type FieldMappingTargetsForModelProps = {
  commonModel: string;
  fieldMappingTargets: Array<FieldMappingTarget>;
  isRemoteDataEnabled: boolean;
  fieldMappingOptions: FieldMappingOptions;
  linkedAccount: LinkedAccount;
};

const FieldMappingTargetsForModel = ({
  commonModel,
  fieldMappingTargets,
  isRemoteDataEnabled,
  fieldMappingOptions,
  linkedAccount,
}: FieldMappingTargetsForModelProps) => {
  return (
    <Card className="pt-5 pb-6 ">
      <div className="font-semibold text-xl px-6 pb-5 border-b border-gray-20">{commonModel}</div>

      {isRemoteDataEnabled ? (
        fieldMappingTargets.length > 0 ? (
          <>
            <div className="grid grid-cols-11 px-6 mt-5">
              <div className="flex items-center col-start-1 col-end-6">
                <Globe size={16} className="text-orange-50" />
                <span className="ml-2 tet-base font-semibold">
                  {linkedAccount.organization.name}-wide target fields
                </span>
              </div>
              <div className="flex items-center col-start-7 col-end-11">
                <OrganizationAvatar
                  size={UserAvatarSize.xs}
                  imageURL={linkedAccount.integration.square_image}
                  isCircle
                  className="mr-3 d-flex"
                />
                <span className="ml-2 tet-base font-semibold">
                  {linkedAccount.integration.name} remote fields
                </span>
              </div>
            </div>
            <div className="px-6 pt-5 flex flex-col gap-6">
              {fieldMappingTargets.map(
                ({ field_key, field_description, id }: FieldMappingTarget) => {
                  return (
                    <UnmappedTargetCard
                      linkedAccount={linkedAccount}
                      fieldKey={field_key}
                      fieldDescription={field_description}
                      fieldMappingOptions={fieldMappingOptions}
                      commonModel={commonModel}
                      fieldMappingTargetId={id}
                    />
                  );
                },
              )}
            </div>
          </>
        ) : (
          <div className="pt-5 px-6">
            This Common Model has no{" "}
            <a href={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}>target fields</a>
          </div>
        )
      ) : (
        <div className="pt-5 px-6">
          Enable{" "}
          <a href={`${CONFIGURATION_COMMON_MODELS_PATH}/${linkedAccount.category}`}>Remote Data</a>{" "}
          to create Field Mappings for this Common Model
        </div>
      )}
    </Card>
  );
};

export default FieldMappingTargetsForModel;
