import React, { useMemo, useState, useContext } from "react";
import { Dropdown, Accordion, AccordionContext } from "react-bootstrap";
import {
  DataWarehouseImportConfigList,
  Destination,
  LinkedAccount,
} from "../../../../../../models/Entities";
import MergeTable from "../../../../../shared-components/MergeTable";
import EmptyStateWrapper from "../../../../../shared-components/EmptyStateWrapper";
import TableSkeletonLoader from "../../../../shared/TableSkeletonLoader";
import DataWarehouseSyncHistoryTableRow from "./DataWarehouseSyncHistoryTableRow";
import { Text } from "@merge-api/merge-javascript-shared";

export interface Props {
  linkedAccount: LinkedAccount;
  destinations: Destination[];
  connectionImportConfigData: DataWarehouseImportConfigList | undefined;
}

/**
 * Shows a table of the syncs that have happened in the past - automatically polls for new
 * data once every three seconds to keep it up to date.
 */
const DataWarehouseSyncHistoryTable = ({
  linkedAccount,
  destinations,
  connectionImportConfigData,
}: Props) => {
  const sortedConfigData = useMemo(() => {
    connectionImportConfigData?.import_configs.sort((a, b) =>
      a.destination_connection.name.localeCompare(b.destination_connection.name),
    );
    return connectionImportConfigData;
  }, [connectionImportConfigData]);

  const header = (
    <>
      <th className="pl-6 py-3" scope="col">
        Source
      </th>
      <th className="py-3" scope="col">
        Destination
      </th>
      <th className="py-3" scope="col">
        Status
      </th>
      <th className="py-3" scope="col">
        Last sync start
      </th>
      <th className="py-3" scope="col">
        Next sync start
      </th>
      <th className="py-3" scope="col">
        Type
      </th>
    </>
  );

  const importConfigRows = sortedConfigData?.import_configs.map((importConfig) => {
    return (
      <DataWarehouseSyncHistoryTableRow linkedAccount={linkedAccount} importConfig={importConfig} />
    );
  });

  const content =
    (linkedAccount && sortedConfigData === null) || sortedConfigData === undefined ? (
      <TableSkeletonLoader cols={6} />
    ) : (
      (!!connectionImportConfigData?.import_configs.length && importConfigRows) || (
        <tr>
          <td colSpan={12} className="p-0">
            <EmptyStateWrapper isTable title="No import configurations" />
          </td>
        </tr>
      )
    );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col mb-9 bg-white shadow-md rounded-[10px]">
        <div className="border-b-[1px] border-[#edf2f9] flex justify-content-between px-6 py-3 font-semibold ">
          <Text variant="h5">Data Sync</Text>
        </div>
        <Accordion>
          <MergeTable
            header={header}
            content={content}
            hasMarginBottom={false}
            noBoxShadow
            hover={false}
          />
        </Accordion>
      </div>
    </div>
  );
};

export default DataWarehouseSyncHistoryTable;
