import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StatusToggle } from "../../../shared-components/MergeToggles";
import { Shuffle, Star } from "lucide-react";
import { patchFieldMappingTarget } from "./utils/FieldMappingUtils";

const ConfigurationTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const ConfigurationSubtitle = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  margin-top: 8px;
`;

const ConfigurationLinkOptionsContainer = styled.div`
  margin-top: 24px;
`;

const OptionInfoContainer = styled.div`
  margin-left: 24px;
`;

const FieldOptionTitle = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  margin-left: 7.5px;
`;

const FieldOptionSubtitle = styled.div`
  font-size: 11px;
  line-height: 18px;

  color: #737982;
`;

const getLucideIconForOption = (iconName: String) => {
  switch (iconName) {
    case "shuffle":
      return <Shuffle size={12} />;
    case "star":
      return <Star size={12} />;
    default:
      return <Star size={12} />;
  }
};

type FieldMappingOptionProps = {
  title: String;
  subtitle: String;
  icon: String;
  isEnabled: boolean;
  disableSwitch?: boolean;
  handleChange: () => void;
  isDisabled: boolean;
};

const FieldMappingLinkOption = ({
  title,
  subtitle,
  icon,
  isEnabled,
  disableSwitch = false,
  handleChange,
  isDisabled,
}: FieldMappingOptionProps) => {
  return (
    <div className={`${isDisabled ? "opacity-50" : ""} d-flex mb-5 align-items-start`}>
      <StatusToggle
        className="field-mapping-overrides-toggle"
        isEnabled={isEnabled}
        onChange={handleChange}
        id={`test-${title}`}
        hideStatusText
        hideSwitch={false}
        disableSwitch={disableSwitch || isDisabled}
      />
      <OptionInfoContainer className="d-flex flex-column">
        <div className="d-flex align-items-center">
          {getLucideIconForOption(icon)}
          <FieldOptionTitle>{title}</FieldOptionTitle>
        </div>
        <FieldOptionSubtitle className="d-flex align-items-center">{subtitle}</FieldOptionSubtitle>
      </OptionInfoContainer>
    </div>
  );
};

type FieldMappingLinkConfigurationOptionsProps = {
  fieldMappingTarget: any;
  refreshFieldMappingsAndConfigurations: any;
  isDisabled?: boolean;
};

const FieldMappingLinkConfigurationOptions = ({
  fieldMappingTarget,
  refreshFieldMappingsAndConfigurations,
  isDisabled = false,
}: FieldMappingLinkConfigurationOptionsProps) => {
  const [isMappableInLink, setIsMappableInLink] = useState(fieldMappingTarget?.is_mappable_in_link);
  const [isPrioritizedInLink, setIsPrioritizedInLink] = useState(fieldMappingTarget?.weight > 0);

  useEffect(() => {
    patchFieldMappingTarget({
      body: {
        is_mappable_in_link: isMappableInLink,
        weight: isPrioritizedInLink ? 1 : 0,
      },
      fieldMappingTargetId: fieldMappingTarget.id,
      onResponse: () => {
        refreshFieldMappingsAndConfigurations();
      },
      onError: () => {},
    });
  }, [isMappableInLink, isPrioritizedInLink]);

  const FieldMappinginLinkConfigOptions = [
    {
      title: "Mappable in Merge Link",
      subtitle: "If enabled, this field will appear in Link for your end users to map to",
      icon: "shuffle",
      isEnabled: isMappableInLink,
      handleChange: () => {
        setIsMappableInLink(!isMappableInLink);
        if (isMappableInLink) {
          setIsPrioritizedInLink(false);
        }
      },
    },
    {
      title: "Prioritized in Merge Link",
      subtitle:
        "If enabled, this field will appear first in the Link mapping flow with other priority targets",
      icon: "star",
      isEnabled: isPrioritizedInLink,
      handleChange: () => {
        setIsPrioritizedInLink(!isPrioritizedInLink);
      },
    },
  ];

  return (
    <div className="d-flex flex-column">
      <ConfigurationTitle>Configure target field in Link</ConfigurationTitle>
      <ConfigurationSubtitle>
        Customize how this target field appears in Link for your end users.
      </ConfigurationSubtitle>
      <ConfigurationLinkOptionsContainer className="d-flex flex-column">
        {FieldMappinginLinkConfigOptions.map((option) => {
          const isPrioritizedInLink = option.title.includes("Prioritized");
          return (
            <FieldMappingLinkOption
              title={option.title}
              subtitle={option.subtitle}
              icon={option.icon}
              isEnabled={option.isEnabled}
              disableSwitch={isPrioritizedInLink && !isMappableInLink}
              handleChange={option.handleChange}
              isDisabled={isDisabled}
            />
          );
        })}
      </ConfigurationLinkOptionsContainer>
    </div>
  );
};

export default FieldMappingLinkConfigurationOptions;
