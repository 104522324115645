import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { palette } from "../../../styles/theme";
import MergeModal from "../../shared-components/MergeModal";
import cx from "classnames";
import { useController, useForm } from "react-hook-form";
import { fetchWithAuth, FormErrorData, MagicLinkResponse } from "../../../api-client/APIClient";
import { showErrorToast } from "../../shared-components/Toasts";
import { Button, APICategory, ButtonVariant } from "@merge-api/merge-javascript-shared";
import nodeHasTarget from "../utils/nodeHasTarget";
import DottedOutlineTextCard from "../../shared-components/DottedOutlineTextCard";
import { displayNameForAPICategory } from "../../../models/Helpers";
import DeprecatedH5 from "../../../deprecated/DeprecatedH5";
import { Link, Wand } from "lucide-react";
import { LinkedAccount } from "../../../models/Entities";

import clsx from "clsx";

const StyledWand = styled(Wand)`
  color: ${palette.black};
`;

const MinHeightDiv = styled.div`
  min-height: 610px;
`;

const StyledModal = styled(MergeModal)`
  .modal-content {
    min-height: 720px;
    width: 375px;
    padding: 24px;
    border-radius: 12px;
  }
  .modal-header {
    border-bottom: 0px;
    height: 32px;
    margin-bottom: 24px;
    align-items: flex-start;
    padding: 0px;
    .modal-title {
      font-size: 23px;
      line-height: 36px;
    }
  }

  .modal-body {
    padding: 0px;
    min-height: 610px;
  }
`;

const StyledSpan = styled.span`
  color: #000;
  font-size: 14px;
  font-family: Inter;
  line-height: 24px;
  margin-bottom: 9px;
`;

const Content = styled(Col)`
  max-width: 335px;
  font-size: 12px;
  line-height: 22px;

  p {
    color: ${palette.slate};
  }

  .expiration-label {
    color: ${palette.gray};
  }

  .form-label {
    font-weight: 600;
  }

  .dropdown-toggle {
    font-weight: 400;
    font-size: 14px;
    color: ${palette.placeholder};
  }

  .btn-link {
    color: ${palette.black};
    font-weight: 400;
  }

  .dashed-outline-rounded-container {
    background-image: none;
  }

  .dropup .dropdown-menu {
    width: unset;
  }

  .form-control.is-invalid {
    #categoriesToggle {
      border-color: var(--red);
    }
  }
`;

const MaxWidthForm = styled(Form)`
  max-width: 335px;
  display: flex;
  flex-direction: column;
  min-height: 578.5px;
  justify-content: space-between;
`;

interface RelinkProductionLinkedAccountButtonProps {
  linkedAccount: LinkedAccount;
}

const RelinkProductionLinkedAccountButton = ({
  linkedAccount,
}: RelinkProductionLinkedAccountButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formEndUserName, setFormEndUserName] = useState<string | undefined>(
    linkedAccount.end_user.organization_name,
  );
  const [formEndUserEmail, setFormEndUserEmail] = useState<string | undefined>(
    linkedAccount.end_user.email_address,
  );
  const [endUserOrganizationName, setEndUserOrganizationName] = useState<string | undefined>(
    linkedAccount.end_user.organization_name,
  );
  const [url, setURL] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, errors, control, reset } = useForm({
    mode: "onBlur",
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  // The whole dropdown element surrounding the menu + toggle
  const {
    field: { ref: dropdownRef, value: categories, onBlur, ...dropdownProps },
  } = useController({
    name: "categories",
    control,
    rules: {
      validate: (categories: Record<APICategory, boolean>) =>
        Object.values(categories).find((value) => value) || false,
    },
    defaultValue: [linkedAccount.category],
  });

  useEffect(() => {
    if (!isDropdownOpen) {
      return;
    }

    const closeDropdown = (event: MouseEvent) => {
      if (!nodeHasTarget(event.target, dropdownRef.current)) {
        setIsDropdownOpen(false);
      }
    };

    // HAS to be in a requestAnimationFrame or it immediately decides to close itself
    requestAnimationFrame(() => window.addEventListener("mousedown", closeDropdown));
    return () => window.removeEventListener("mousedown", closeDropdown);
  }, [isDropdownOpen]);

  const onSubmit = () => {
    setIsLoading(true);
    const formData = {
      end_user_origin_id: linkedAccount.end_user.origin_id,
      end_user_organization_name: formEndUserName,
      end_user_email_address: formEndUserEmail,
      categories: [linkedAccount.category],
      should_create_magic_link_url: true,
      link_expiry_mins: 10080,
    };

    fetchWithAuth({
      path: `/integrations/create-link-token-for-magic-link?is_relink=True`,
      method: "POST",
      body: formData,
      onResponse: (data: MagicLinkResponse) => {
        if (!data?.magic_link_url) {
          showErrorToast("An unkown error has occurred. Please try again later.");
        } else {
          setEndUserOrganizationName(formData.end_user_organization_name);
          setURL(data.magic_link_url);
        }
        setIsLoading(false);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((data: FormErrorData) => {
            let wasToastShown = false;
            if ("non_field_errors" in data) {
              showErrorToast(data.non_field_errors[0]);
              wasToastShown = true;
            }
            if (!wasToastShown) {
              showErrorToast("An unknown error has occurred. Please try again later.");
            }
          });
        } else {
          showErrorToast("A network error has occurred. Please try again.");
        }
        setIsLoading(false);
      },
    });
  };

  const form = !url && (
    <>
      <MinHeightDiv className="justify-content-between d-flex flex-column">
        <Row>
          <h5>Enter your end user's information:</h5>
        </Row>
        <Row>
          <MaxWidthForm>
            <div className="mt-5">
              <Form.Group controlId="organizationName" className="mb-6">
                <Form.Label>
                  <h6>Organization name</h6>
                </Form.Label>
                <Form.Control
                  as="input"
                  name="organizationName"
                  type="text"
                  onChange={(currentName) => setFormEndUserName(currentName.target.value)}
                  placeholder="Organization Name"
                  ref={register({ required: true, minLength: 1 })}
                  className={cx({
                    "is-invalid": errors.organizationName,
                  })}
                  defaultValue={linkedAccount.end_user.organization_name}
                />

                <Form.Control.Feedback type="invalid" className="position-absolute">
                  Please enter a valid organization name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email" className="mb-6">
                <Form.Label>
                  {" "}
                  <h6>Email</h6>
                </Form.Label>
                <Form.Text className="text-gray-50">
                  For identification purposes — this will not cause any emails to be sent.
                </Form.Text>
                <Form.Control
                  as="input"
                  name="email"
                  type="input"
                  placeholder="Email"
                  onChange={(currentEmail) => setFormEndUserEmail(currentEmail.target.value)}
                  defaultValue={linkedAccount.end_user.email_address}
                  ref={register({
                    required: true,
                    validate: (value) => {
                      const input = document.createElement("input");

                      input.type = "email";
                      input.required = true;
                      input.value = value;

                      const result =
                        typeof input.checkValidity === "function"
                          ? input.checkValidity() || input.validationMessage
                          : /\S+@\S+\.\S+/.test(value);
                      input.remove();
                      return result;
                    },
                  })}
                  className={cx({
                    "is-invalid": errors.email,
                  })}
                />

                <Form.Control.Feedback type="invalid" className="position-absolute">
                  Please enter a valid email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="categories" className="mb-6">
                <Form.Label>
                  <h6>Categories</h6>
                </Form.Label>
                <Form.Control
                  as="input"
                  name="categories"
                  type="text"
                  ref={register({ required: true, minLength: 1 })}
                  placeholder="Categories"
                  defaultValue={displayNameForAPICategory(linkedAccount.category)}
                  disabled
                  className={clsx(true && "opacity-[0.5] bg-gray-0 text-gray-80")}
                />
              </Form.Group>
              <Form.Group controlId="uniqueIdentifier" className="mb-6">
                <Form.Label>
                  <h6>End user origin ID</h6>
                </Form.Label>
                <Form.Text className="text-gray-50">
                  Uniquely identifies a Linked Account i.e. a specific customer of yours for a given
                  integration.{" "}
                  <a
                    href="https://help.merge.dev/en/articles/8032943-end-user-origin-id-best-practices"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                  .
                </Form.Text>
                <Form.Control
                  as="input"
                  name="uniqueIdentifier"
                  type="text"
                  ref={register({ required: true, minLength: 1 })}
                  placeholder="End user origin ID"
                  disabled
                  className={clsx(true && "opacity-[0.5] bg-gray-0 text-gray-80")}
                  defaultValue={linkedAccount.end_user.origin_id}
                />
              </Form.Group>
            </div>
            <Row className="d-flex justify-content-center">
              <Col>
                <Button onClick={onSubmit} loading={isLoading} fullWidth>
                  Generate URL
                </Button>
              </Col>
            </Row>
          </MaxWidthForm>
        </Row>
      </MinHeightDiv>
    </>
  );

  const urlDisplay = url && (
    <MinHeightDiv className="justify-content-between d-flex flex-column">
      <div>
        <Row className="mb-5">
          <StyledSpan>Send this URL to your user for them to relink their account.</StyledSpan>
        </Row>
        <Row>
          <DeprecatedH5 className="mt-2 mb-3">URL for {endUserOrganizationName}</DeprecatedH5>
        </Row>
        <Row>
          <DottedOutlineTextCard text={url} className="w-100" isUrl />
        </Row>
        <Row>
          <p className="expiration-label mt-3">
            This URL will be shown once and will expire after 7 days.
          </p>
        </Row>
      </div>
      <Row className="d-flex justify-content-center">
        <Button onClick={() => setIsModalOpen(false)} fullWidth>
          Finish
        </Button>
      </Row>
    </MinHeightDiv>
  );

  const modalContent = () => {
    return !url ? form : urlDisplay;
  };

  return (
    <>
      <StyledModal
        show={isModalOpen}
        title="Relink with Magic Link"
        onHide={() => setIsModalOpen(false)}
        dialogClassName=""
        fitContent
        backdrop={url ? "static" : true}
        iconLeft={<StyledWand size={24} />}
      >
        <Content>{modalContent()}</Content>
      </StyledModal>

      <Button
        size="sm"
        variant={ButtonVariant.TertiaryWhite}
        onClick={() => {
          setEndUserOrganizationName(undefined);
          setURL(undefined);
          reset();
          setIsModalOpen(true);
        }}
        leftIcon={<Link size={12} />}
        fullWidth
      >
        Relink with Magic Link
      </Button>
    </>
  );
};

export default RelinkProductionLinkedAccountButton;
