import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { capitalizeFirstLetter } from "@merge-api/merge-javascript-shared";
import styled, { css } from "styled-components";
import { EnabledAction } from "../../../models/CommonModel";
import { palette, spectrum } from "../../../styles/theme";
import MultiSwitch from "../../shared-components/MultiSwitch";
import CenteredVerticalLineCol, { VerticalLineConfig } from "./CenteredVerticalLineCol";
import AnimatedChevron from "./AnimatedChevron";
import MaybeDisable from "../../shared-components/MaybeDisable";
import classNames from "classnames";
import Markdown from "markdown-to-jsx";
import { Badge } from "@merge-api/merge-javascript-shared";
import { DeprecationStage } from "../../../constants";
import { getScopeOptions } from "./CommonModelToggle";
export enum Disableability {
  DISABLEABLE,
  NOT_DISABLEABLE,
  DISABLEABLE_WITH_UPGRADE,
}

export enum Enableability {
  ENABLEABLE,
  ENABLEABLE_WITH_UPGRADE,
}

export interface FieldToggleProps {
  displayName: string;
  isEnabled: boolean;
  type?: string;
  description?: string;
  children?: React.ReactNode;
  deprecationMessage?: React.ReactNode;
  deprecationStage?: DeprecationStage;
  showDeprecatedSunsetItems?: boolean;

  /** Defaults to "DISABLEABLE". */
  disableability?: Disableability;
  /** Defaults to "ENABLEABLE". */
  enableability?: Enableability;
  /** Defaults to 70.8. */
  iconColumnWidthInPixels?: number;
  showBorderAboveIcon?: boolean;
  showBorderAboveContent?: boolean;
  verticalLineConfig: VerticalLineConfig;
  showDescription?: boolean;
  setShowDescription?: (isExpanded: boolean) => void;
  changeState: (
    enabledActions: Array<EnabledAction>,
    isOptional: boolean,
    optionalScopes?: Array<EnabledAction> | undefined,
    nonOptionalScopes?: Array<EnabledAction> | undefined,
  ) => void;
  className?: string;
  isRemoteDataToggle?: boolean;
  isViewing: boolean;
  areOptionalScopesEnabled: boolean;
  fieldOverlayText?: string;
  isConfigurable?: boolean;
  isSubmodelScope?: boolean;
  isFieldParentModelEnabled?: boolean;
  canToggleOrgScopesOnly: boolean;
  isDefaultScopesOffFlag: boolean | undefined;
}

const TopBorder = css`
  border-top: 1px solid ${palette.border};
`;

export const BorderedCenteredVerticalLineCol = styled(CenteredVerticalLineCol)<{
  $showBorder?: boolean;
}>`
  ${({ $showBorder }) => $showBorder && TopBorder};
`;

export const XSIconContainer = styled.div.attrs({
  className: "avatar-xs avatar-title rounded-circle",
})<{
  $iconColumnWidthInPixels: number;
}>`
  margin: 0px ${({ $iconColumnWidthInPixels }) => ($iconColumnWidthInPixels / 2).toString() + "px"};
`;

export const mapTypeToFeatherIconClassName = (type: string | undefined) => {
  if (type?.endsWith("[]")) {
    return "fe-list";
  }
  switch (type) {
    case "Object":
      return "fe-list";
    case "Array":
      return "fe-menu";
    case "Number":
      return "fe-hash";
    default:
      return "fe-italic";
  }
};

const FieldRow = styled(Row).attrs({ className: "flex-nowrap" })`
  margin: 0px;
`;

const BorderedFieldRowContent = styled(Row)<{
  $showBorder: boolean;
}>`
  ${({ $showBorder }) => $showBorder && TopBorder}

  min-height: 60px;
  justify-content: flex-end;
`;

const ClickableRow = styled(Row)<{
  $isClickable: boolean;
}>`
  ${({ $isClickable }) => $isClickable && "cursor: pointer"};
`;

export const StyledMultiSwitch = styled(MultiSwitch)`
  margin-right: 8px;

  button {
    font-size: 12px;
    line-height: 16px;
  }
`;

const DeprecationMessage = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  color: ${spectrum.gray60};

  code {
    color: ${spectrum.gray60};
    padding: unset;
    border-radius: unset;
    background: unset;
    border: unset;
  }
`;

const DescriptionMarkdown = styled(Markdown)`
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  color: ${palette.slate};

  code {
    color: ${palette.black};
    padding: unset;
    border-radius: unset;
    background: unset;
    border: unset;
  }
`;

/**
 * Ensure elements can be interacted with even if negative margins are used
 */
const ChildrenRow = styled(Row)`
  position: relative;
`;

const getFieldScopeText = (
  isEnabled: boolean,
  isConfigurable: boolean,
  areOptionalScopesEnabled: boolean,
) => {
  if (isConfigurable && areOptionalScopesEnabled) {
    return <span className="text-yellow-50 "> Optional</span>;
  } else if (isEnabled) {
    return <span className="text-teal-50 "> Enabled</span>;
  } else {
    return <span className="opacity-35 ">Disabled</span>;
  }
};

const FieldToggle = ({
  displayName,
  isEnabled,
  type,
  description,
  children,
  disableability = Disableability.DISABLEABLE,
  enableability = Enableability.ENABLEABLE,
  iconColumnWidthInPixels = 70.8 /* width of IconContainer - width of avatar-xs */,
  showBorderAboveIcon,
  showBorderAboveContent,
  verticalLineConfig,
  showDescription,
  setShowDescription,
  changeState,
  className,
  deprecationStage,
  deprecationMessage,
  showDeprecatedSunsetItems,
  areOptionalScopesEnabled,
  isRemoteDataToggle = false,
  isViewing,
  fieldOverlayText,
  isConfigurable = false,
  isSubmodelScope = false,
  isFieldParentModelEnabled = false,
  canToggleOrgScopesOnly,
  isDefaultScopesOffFlag,
}: FieldToggleProps) => {
  // derived state
  const shouldShowChevron = !!(description && setShowDescription);
  const shouldShowDeprecationMessage =
    deprecationMessage &&
    (deprecationStage === DeprecationStage.DEPRECATED || showDeprecatedSunsetItems);
  const scopeOptions =
    isSubmodelScope || isRemoteDataToggle || !areOptionalScopesEnabled
      ? getScopeOptions(true).slice(0, 2)
      : getScopeOptions(true);

  return (
    <FieldRow className={className}>
      <BorderedCenteredVerticalLineCol
        className="col-auto px-0"
        $verticalLineConfig={verticalLineConfig}
        $showBorder={showBorderAboveIcon}
      >
        <Row className="mx-0 h-100">
          <Col className="px-0 my-auto">
            <XSIconContainer $iconColumnWidthInPixels={iconColumnWidthInPixels}>
              <i className={classNames("fe", mapTypeToFeatherIconClassName(type))} />
            </XSIconContainer>
          </Col>
        </Row>
      </BorderedCenteredVerticalLineCol>
      <Col className={`pr-0 ${isRemoteDataToggle ? "" : "mr-3"}`}>
        <BorderedFieldRowContent $showBorder={showBorderAboveContent} className="py-6 my-0">
          <Col className="pr-6 pl-0 my-auto">
            <Row className="mx-0">
              <Col className="px-0 col-auto">
                <ClickableRow
                  className="mx-0 flex-nowrap"
                  $isClickable={shouldShowChevron}
                  onClick={
                    shouldShowChevron ? () => setShowDescription(!showDescription) : undefined
                  }
                >
                  <div className="my-auto font-semibold text-base">{displayName}</div>
                  {shouldShowChevron && (
                    <AnimatedChevron className="ml-[3px] text-black" $isUp={!!showDescription} />
                  )}
                  {shouldShowDeprecationMessage ? (
                    <div className="ml-1.5">
                      <Badge color="yellow">Deprecated</Badge>
                    </div>
                  ) : (
                    type && (
                      <div className="my-auto mx-1.5 text-sm text-gray-60 ">
                        {capitalizeFirstLetter(type)}
                      </div>
                    )
                  )}
                </ClickableRow>
              </Col>
            </Row>
            {(description || shouldShowDeprecationMessage) && (
              <Accordion activeKey={showDescription ? "0" : undefined}>
                <Accordion.Collapse eventKey="0">
                  <div className="pt-2">
                    {shouldShowDeprecationMessage ? (
                      <DeprecationMessage>{deprecationMessage}</DeprecationMessage>
                    ) : (
                      <DescriptionMarkdown>{description}</DescriptionMarkdown>
                    )}
                  </div>
                </Accordion.Collapse>
              </Accordion>
            )}
          </Col>
          <Col className="pr-8 pl-0 col-auto my-auto">
            {isSubmodelScope && !isFieldParentModelEnabled ? null : isViewing ||
              (isDefaultScopesOffFlag && canToggleOrgScopesOnly) ? (
              <div className="font-semibold text-sm">
                {getFieldScopeText(isEnabled, isConfigurable, areOptionalScopesEnabled)}
              </div>
            ) : (
              <MaybeDisable
                hasPadding={false}
                disable={disableability === Disableability.NOT_DISABLEABLE}
              >
                <MultiSwitch
                  options={scopeOptions.map((option) => {
                    return {
                      ...option,
                      disable:
                        (option.id === "disabled" &&
                          disableability === Disableability.DISABLEABLE_WITH_UPGRADE) ||
                        (option.id === "enabled" &&
                          enableability === Enableability.ENABLEABLE_WITH_UPGRADE),
                    };
                  })}
                  textClass="text-sm"
                  buttonClass="pt-[1px] pb-[3px] px-3"
                  selectedID={
                    isConfigurable && areOptionalScopesEnabled
                      ? "optional"
                      : isEnabled
                      ? "enabled"
                      : "disabled"
                  }
                  onSelectOption={(option) => {
                    if (option.disable) {
                      return;
                    }
                    const isOptional = option.id == "optional";
                    const isEnabled = option.id == "enabled" || isOptional;

                    const optionalActionsToEnable = isOptional ? [EnabledAction.READ] : [];
                    const optionalActionsToDisable =
                      !isEnabled || (isEnabled && !isOptional) ? [EnabledAction.READ] : [];

                    changeState(
                      isOptional || isEnabled ? [EnabledAction.READ] : [],
                      isOptional,
                      optionalActionsToEnable,
                      optionalActionsToDisable,
                    );
                  }}
                  isRounded
                />
              </MaybeDisable>
            )}
          </Col>
        </BorderedFieldRowContent>
        {children && <ChildrenRow>{children}</ChildrenRow>}
      </Col>
    </FieldRow>
  );
};

export const RemoteDataToggle = styled(FieldToggle)`
  ${CenteredVerticalLineCol} {
    margin-left: -13px;
  }
  ${StyledMultiSwitch} {
    margin-right: -12px;
  }

  // Remove border to blend with RemoteFieldsToggle
  border-bottom: none;
  border-radius: 8px 8px 0 0; /* Round only top corners */
`;

export const RemoteFieldsToggle = styled(FieldToggle)`
  ${CenteredVerticalLineCol} {
    margin-left: -13px;
  }
  ${StyledMultiSwitch} {
    margin-right: -12px;
  }

  // Remove border radius for top to match the RemoteDataToggle
  border-radius: 0 0 8px 8px; /* Round only bottom corners */

  // Add right-aligned partial border at the bottom
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0; // Position the border at the bottom
    right: 0; // Align it to the right side
    width: 91%; // Set the width of the partial border (adjust as needed)
    height: 1px;
    background-color: ${palette.border}; // Use the same border color
  }
`;

export default FieldToggle;
