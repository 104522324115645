import { IssueDescription } from "../../constants";
import { EndUser, Integration, LinkedAccount, Organization, User } from "../../models/Entities";

// ANALYTICS

export enum ChartTypes {
  CHART_TYPE_BAR = "BAR",
  CHART_TYPE_LINE = "LINE",
}

export interface LinkedAccountAnalyticsChartData {
  title: string;
  chart_type: ChartTypes;
  data: Object;
}

// LOGS

export enum IntegrationLogEntryType {
  API_REQUEST_LOG_ENTRY_TYPE = "API_REQUEST_LOG_ENTRY_TYPE",
}

export type APIRequestLogEntryEndUser = Pick<EndUser, "organization_name" | "id">;
export type APIRequestLogEntryOrganization = Pick<Organization, "id" | "name" | "logo">;
export type APIRequestLogEntryIntegration = Pick<
  Integration,
  "id" | "name" | "abbreviated_name" | "light_grey_image" | "square_image"
>;
export interface APIRequestLogEntryLinkedAccount {
  id: string;
  integration: APIRequestLogEntryIntegration;
  organization: APIRequestLogEntryOrganization;
  end_user: APIRequestLogEntryEndUser;
}

export interface APIRequestLogEntry {
  id: string;
  created_at: string;
  direction: string;
  url: string;
  method: string;
  request_body: string;
  request_query_params: string;
  request_headers: string;
  response_body: string;
  response_code: number;
  response_headers: string;
  linked_account: APIRequestLogEntryLinkedAccount;
  status: string;
}

export interface WebhookLogEntry {
  id: string;
  created_at: string;
  direction: string;
  webhook: {
    type: string;
    event: string;
  };
  url: string;
  method: string;
  request_body?: string;
  request_query_params?: string;
  request_headers?: string;
  response_code: number;
  response_headers?: string;
  response_body: string;
  linked_account: LinkedAccount;
  status: string;
}

export enum ScraperRequestType {
  COMMON_MODEL_DATA = "COMMON_MODEL_DATA",
  AUTH_DATA = "AUTH_DATA",
}

export enum ScraperTriggerType {
  FORCE_SYNC = "FORCE_SYNC",
  INITIAL_SYNC = "INITIAL_SYNC",
  PERIODIC_SYNC = "PERIODIC_SYNC",
  INITIAL_LINK = "INITIAL_LINK",
}

export interface ScraperLogEntry {
  id: string;
  created_at: string;
  direction: string;
  url: string;
  method: string;
  request_type: ScraperRequestType;
  trigger_type: ScraperTriggerType;
  response_code: number;
  linked_account: LinkedAccount;
  status: string;
  runtime: string;
  last_fetch_time: string;
  completion_time: string;
  response_body: string;
  error_message?: string;
}

export type LogType = WebhookLogEntry | APIRequestLogEntry | ScraperLogEntry;

export const isWebhookLogEntry = (entry: LogType): entry is WebhookLogEntry => {
  return "webhook" in entry;
};

export const isScraperLogEntry = (entry: LogType): entry is ScraperLogEntry => {
  return entry && "runtime" in entry;
};

export interface APILogResponseBodyPayload {
  response_body: string;
  download_link?: string;
  file_size?: number;
}

// ISSUES

export enum IntegrationIssueStatus {
  ONGOING = "ONGOING",
  RESOLVED = "RESOLVED",
}

export enum IntegrationIssueActor {
  AUTO_ACTOR = "AUTO",
  USER_ACTOR = "USER",
}

export enum IntegrationIssueActionLogEntryAction {
  RESOLVE = "RESOLVE",
  NOTIFY = "NOTIFY",
  REOPEN = "REOPEN",
  INFO = "INFO",
}

export interface IntegrationIssueActionLogEntry {
  id: string;
  user: User;
  action_type: IntegrationIssueActionLogEntryAction;
  created_at: string;
}

export type IntegrationIssueChartData = Array<{
  hour: number;
  count: number;
}>;

export type CommonModelInformation = {
  missing_fields: string[];
  common_model_name: string;
};

export interface IntegrationIssueMetadata {
  action: IntegrationIssueActionLogEntryAction;
  actor_type: IntegrationIssueActor;
  user_name?: string;
  user_email?: string;
  user_facing_error_detail?: string;
  status_code: number;
  common_model_information: CommonModelInformation[];
  is_resolved: boolean;
}

export interface IntegrationIssue {
  id: string;
  status: IntegrationIssueStatus;
  error_description: IssueDescription;
  linked_account: LinkedAccount;
  last_action: IntegrationIssueActionLogEntry;
  chart_data: IntegrationIssueChartData;
  first_incident_time: string;
  last_incident_time: string;
  created_at: string;
  comment_count: number;
  recent_logs: Array<APIRequestLogEntry>;
  is_muted?: boolean;
  incidents_until_unmuted: number;
  muted_until?: string;
  metadata: Array<IntegrationIssueMetadata> | null;
  issue_generated_by?: string;
  has_user_seen?: boolean;
}

export interface IntegrationIssueLogs {
  recent_logs: APIRequestLogEntry[];
}
