import React, { useCallback, useEffect, useState } from "react";
import { fetchCurrentUser, fetchWithAuth } from "../../../api-client/APIClient";
import useAppContext from "../../context/useAppContext";
import { showErrorToast, showSuccessToast } from "../../shared-components/Toasts";
import { Card, Col, Row } from "react-bootstrap";
import { DisableTwoFactorAuthRequireModal } from "./DisableTwoFactorAuthRequireModal";
import { Toggle } from "@merge-api/merge-javascript-shared";

function OrganizationTwoFactorAuth() {
  const { user, setUser, isUserPrivileged } = useAppContext();
  const [isMfaRequired, setMfaRequired] = useState(false);
  const [showConfirmMsg, setShowConfirmMsg] = useState(false);

  useEffect(() => {
    setMfaRequired(user.organization?.is_mfa_required);
  }, [user]);

  const disableTwoFactorAuthentication = useCallback(() => {
    fetchWithAuth({
      path: "/organizations/me",
      method: "PATCH",
      body: { is_mfa_required: false },
      onResponse: () => {
        setMfaRequired(false);
        showSuccessToast("Successfully changed two-factor authentication for your organization.");
        fetchCurrentUser(setUser);
      },
      onError: () => {
        showErrorToast("Failed to disable two-factor authentication for your organization.");
        setMfaRequired(true);
      },
    });
    setShowConfirmMsg(false);
  }, [setShowConfirmMsg, setMfaRequired]);

  const handleConfirmMsg = useCallback(() => {
    if (isMfaRequired) {
      setShowConfirmMsg(true);
    } else {
      fetchWithAuth({
        path: "/organizations/me",
        method: "PATCH",
        body: { is_mfa_required: true },
        onResponse: () => {
          setMfaRequired(true);
          showSuccessToast("Successfully changed two-factor authentication for your organization.");
          fetchCurrentUser(setUser);
        },
        onError: () => {
          showErrorToast("Failed to enable two-factor authentication for your organization.");
          setMfaRequired(false);
        },
      });
    }
  }, [setShowConfirmMsg, setMfaRequired, isMfaRequired]);

  return (
    <>
      {isUserPrivileged && (
        <>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                <Col>
                  <span className="font-semibold mb-0">
                    Enforce two-factor authentication for all organization members
                  </span>
                </Col>
                <Col className="col-auto">
                  <Toggle
                    label={isMfaRequired ? "2FA enforced" : "Enforce 2FA"}
                    onChange={() => handleConfirmMsg()}
                    checked={isMfaRequired}
                    labelPlacement="left"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {showConfirmMsg && (
            <DisableTwoFactorAuthRequireModal
              onClickMethod={disableTwoFactorAuthentication}
              isShown={showConfirmMsg}
              onHide={() => setShowConfirmMsg(false)}
            />
          )}
        </>
      )}
    </>
  );
}

export default OrganizationTwoFactorAuth;
