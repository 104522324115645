import React from "react";
import { palette } from "../../../../styles/theme";
import { Tooltip } from "@merge-api/merge-javascript-shared";

/**
 * Creates a small (11 x 13 px) Merge logo mark with no text but with a tooltip. Has
 * a border around it (circular)
 */
const MergeLogoMark = () => (
  <Tooltip title="Merge">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.75" fill="white" stroke={palette.border} strokeWidth="0.5" />
      <path
        d="M5.41313 4.58436C5.24296 4.72409 5.20857 4.9625 5.31705 5.15412C5.59281 5.64123 5.90786 6.1058 6.23875 6.55693C6.81539 7.34312 8.01055 8.64928 9.10625 9.38406C10.0348 8.81441 10.8883 8.56521 11.826 8.30656C10.4199 7.49963 9.20489 6.39192 8.24606 5.08465C7.98998 4.73551 7.74692 4.37449 7.52967 3.99973C7.42163 3.81335 7.2129 3.70874 7.00506 3.76539C6.78629 3.82502 6.48509 3.92633 6.16202 4.09217C5.84304 4.2559 5.58826 4.44055 5.41313 4.58436Z"
        fill="url(#paint0_linear_258_53683)"
      />
      <path
        d="M12.2788 8.95613C11.1984 9.21526 10.175 9.6076 9.25187 10.1919C7.94233 11.0208 6.91611 12.2071 6.23687 13.1332C5.89122 13.6044 5.62154 14.0275 5.43696 14.3351C5.40277 14.3921 5.35864 14.4689 5.31491 14.546C5.2085 14.7337 5.23213 14.9676 5.39163 15.1129C5.5655 15.2713 5.82109 15.4744 6.13141 15.6328C6.45069 15.7958 6.77767 15.8899 7.01271 15.9421C7.21671 15.9875 7.41678 15.8824 7.52136 15.7015C7.74027 15.3227 7.98557 14.958 8.24419 14.6054C8.83986 13.7933 9.64939 12.8864 10.5832 12.2953C11.5101 11.7086 12.6259 11.3972 13.5593 11.2389C13.9383 11.1747 14.3219 11.1239 14.7062 11.1061C14.9442 11.0951 15.1461 10.9267 15.1808 10.691C15.2155 10.4557 15.25 10.1458 15.25 9.8449C15.25 9.55093 15.217 9.24837 15.1832 9.01517C15.1479 8.77178 14.9353 8.60044 14.6893 8.6007C13.8742 8.60156 13.0684 8.76673 12.2788 8.95613Z"
        fill="#080808"
      />
      <path
        d="M12.2788 8.95504C11.1984 9.21417 10.175 9.60651 9.25187 10.1908C7.94233 11.0197 6.91611 12.206 6.23687 13.1321C5.89122 13.6033 5.62154 14.0264 5.43696 14.3341C5.40277 14.391 5.35864 14.4678 5.31491 14.5449C5.2085 14.7326 5.23213 14.9665 5.39163 15.1119C5.5655 15.2703 5.82109 15.4733 6.13141 15.6317C6.45069 15.7947 6.77767 15.8888 7.01271 15.941C7.21671 15.9864 7.41678 15.8813 7.52136 15.7004C7.74027 15.3216 7.98557 14.9569 8.24419 14.6043C8.83986 13.7922 9.64939 12.8853 10.5832 12.2942C11.5101 11.7075 12.6259 11.3961 13.5593 11.2379C13.9383 11.1736 14.3219 11.1228 14.7062 11.105C14.9442 11.094 15.1461 10.9256 15.1808 10.6899C15.2155 10.4546 15.25 10.1447 15.25 9.84381C15.25 9.54984 15.217 9.24728 15.1832 9.01408C15.1479 8.7707 14.9353 8.59935 14.6893 8.59961C13.8742 8.60047 13.0684 8.76564 12.2788 8.95504Z"
        fill="#080808"
      />
      <defs>
        <linearGradient
          id="paint0_linear_258_53683"
          x1="15.5101"
          y1="5.6321"
          x2="4.99407"
          y2="14.5615"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#080808" />
          <stop offset="1" stopColor="#080808" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </Tooltip>
);

export default MergeLogoMark;
