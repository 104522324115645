import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { CommonModelBadge } from "../../../shared-components/MergeBadges";
import { displayNameForAPICategory } from "../../../../models/Helpers";
import { APICategory } from "../../../../models/Entities";
import { Globe } from "lucide-react";
import { Badge, Dropdown } from "react-bootstrap";
import DeleteFieldMappingModal from "../../../integrations-management/linked-accounts/detail-page/field-mappings/DeleteFieldMappingModal";
import { navigateToConfigurationFieldMappingTargets } from "../../../../router/RouterUtils";
import { useState } from "react";
import useAppContext from "../../../context/useAppContext";
import MoreHorizontalPopover from "../../../shared-components/MoreHorizontalPopover";

const FieldMappingKeyName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-top: 9px;
`;

const CommonModelNameBadge = styled(Badge)`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-right: 8px;
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  height: 36px;
  color: #000000;
  &:hover {
    background: var(--gray0, #f2f4f7);
  }
`;

type IntegrationFieldMappingsHeaderProps = {
  commonModel: String;
  title: String;
  category: APICategory;
  description: String;
  fieldMappingTargetId: string;
  refreshFieldMappingsAndConfigurations: () => void;
};
const IntegrationFieldMappingsHeader = ({
  fieldMappingTargetId,
  commonModel,
  title,
  category,
  description,
  refreshFieldMappingsAndConfigurations,
}: IntegrationFieldMappingsHeaderProps) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const { user } = useAppContext();

  return (
    <>
      <DeleteFieldMappingModal
        fieldMappingTargetId={fieldMappingTargetId}
        isFieldMappingTargetDelete
        handlePostDelete={() => {
          refreshFieldMappingsAndConfigurations();
          navigateToConfigurationFieldMappingTargets(history);
        }}
        showModal={showModal}
        onHide={() => setShowModal(false)}
        headerText="Delete target field"
        text="This will delete all Field Mappings associated with this target field."
      />

      <div className="h-2 bg-orange-20 rounded-t-lg"></div>
      <div className="bg-white shadow-md rounded-lg">
        <div className="d-flex pl-6 pr-6 pb-5 pt-3">
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex align-items-center">
              <div className="text-gray-90 mr-3 font-mono font-semibold text-[20px]">{title}</div>
            </div>
            <div className="mt-2">
              <Globe size={12} className="text-orange-40" />
              <span className="ml-1 text-[12px] font-semibold">Organization-wide target field</span>
              <CommonModelNameBadge
                className="ml-3"
                style={{ background: "#F2F4F7", color: "#50545B" }}
              >
                {commonModel}
              </CommonModelNameBadge>
              <CommonModelBadge commonModel={displayNameForAPICategory(category)} />
            </div>

            <div className="text-base mt-2 truncate max-w-[850px] text-gray-60 font-inter">
              {description}
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <MoreHorizontalPopover
              menuOptions={
                <>
                  <StyledDropdownItem
                    className="ellipses-dropdown-item dropdown-item text-red-50"
                    onSelect={() => {
                      setShowModal(true);
                    }}
                  >
                    Delete target field
                  </StyledDropdownItem>
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntegrationFieldMappingsHeader;
