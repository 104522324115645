import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { fetchLinkedAccountFieldMappingInstances } from "../../../../../api-client/APIClient";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingTargetsAndFieldMappingInstances,
  LinkedAccount,
} from "../../../../../models/Entities";
import { getFieldMappingConfigurations } from "../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";
import LinkedAccountFieldMappingsContextProvider from "./context/LinkedAccountFieldMappingsContextProvider";
import FieldMappings from "./FieldMappings";
import ReferenceMetadataPageLayout from "../../../shared/ReferenceMetadataPageLayout";

type Props = {
  linkedAccount: LinkedAccount;
};

/**
 * Shows existing and available custom mappings for a linked account
 */
const FieldMappingsPage = ({ linkedAccount }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fieldMappingTargetsAndInstances, setFieldMappingTargetsAndInstances] =
    useState<FieldMappingTargetsAndFieldMappingInstances>({
      field_mapping_instances: [],
      field_mapping_targets: [],
      overridden_common_model_instances: [],
      overridden_common_model_targets: [],
    });
  const [fieldMappingConfigurations, setFieldMappingConfigurations] = useState<
    FieldMappingCommonModelConfiguration[] | null
  >(null);

  const [fetchCounter, setFetchCounter] = useState<number>(0);
  const refreshFieldMappings = () => {
    setFetchCounter(fetchCounter + 1);
  };

  useEffect(() => {
    if (linkedAccount) {
      if (fetchCounter == 0) {
        setIsLoading(true);
      }
      fetchLinkedAccountFieldMappingInstances(
        linkedAccount.id,
        setFieldMappingTargetsAndInstances,
        () => {
          setIsLoading(false);
        },
      );
      getFieldMappingConfigurations({
        category: linkedAccount.category,
        onFetch: (response: FieldMappingCommonModelConfiguration[]) => {
          setFieldMappingConfigurations(response);
        },
        onError: () => {},
        linked_account_id: linkedAccount.id,
      });
    }
  }, [linkedAccount, fetchCounter]);

  return (
    <ReferenceMetadataPageLayout linkedAccount={linkedAccount}>
      {linkedAccount ? (
        <LinkedAccountFieldMappingsContextProvider
          refreshFieldMappings={refreshFieldMappings}
          setFieldMappingTargetAndInstances={setFieldMappingTargetsAndInstances}
        >
          <FieldMappings
            linkedAccount={linkedAccount}
            fieldMappingInstances={fieldMappingTargetsAndInstances.field_mapping_instances}
            fieldMappingTargets={fieldMappingTargetsAndInstances.field_mapping_targets}
            fieldMappingConfigurations={fieldMappingConfigurations}
            overriddenCommonModelTargets={
              fieldMappingTargetsAndInstances.overridden_common_model_targets
            }
            overriddenCommonModelInstances={
              fieldMappingTargetsAndInstances.overridden_common_model_instances
            }
            isLoading={isLoading}
          />
        </LinkedAccountFieldMappingsContextProvider>
      ) : (
        <></>
      )}
    </ReferenceMetadataPageLayout>
  );
};

export default FieldMappingsPage;
