import React from "react";
import ConfigurationSettingsCard from "../helpers/ConfigurationSettingsCard";
import useCustomizationContext from "../context/useCustomizationContext";
import { MergeLinkCustomizationStatus } from "../context/CustomizationContext";
import { Toggle } from "@merge-api/merge-javascript-shared";

const ConfigurationSelectiveSyncCard = () => {
  const { organizationCustomization, setOrganizationCustomization } = useCustomizationContext();
  const showSelectiveSync = organizationCustomization?.show_selective_sync_screen ?? false;

  const updateEnableSelectiveSync = (newValue: boolean) => {
    // either create new org customization value or update existing one
    if (organizationCustomization != undefined) {
      const newOrgCustomization = { ...organizationCustomization };
      newOrgCustomization.show_selective_sync_screen = newValue;
      setOrganizationCustomization(newOrgCustomization);
    } else {
      const currentOrganizationCustomization = {
        status: "PUBLISHED" as MergeLinkCustomizationStatus,
        show_selective_sync_screen: newValue,
      };
      setOrganizationCustomization(currentOrganizationCustomization);
    }
  };

  return (
    <ConfigurationSettingsCard title="Selective Sync" isBeta>
      <div className="flex flex-row justify-between">
        <span>Allow your users to select what data is synced from third-party platforms</span>
        <Toggle
          checked={showSelectiveSync}
          label={showSelectiveSync ? "Show in Merge Link" : " "}
          onChange={() => updateEnableSelectiveSync(!showSelectiveSync)}
        />
      </div>
    </ConfigurationSettingsCard>
  );
};

export default ConfigurationSelectiveSyncCard;
