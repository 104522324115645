import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import classNames from "classnames";
import useAppContext from "../../../context/useAppContext";
import { getAPIRequestPath } from "../../../../models/Helpers";
import { APIRequestLogEntry, IntegrationIssue } from "../../IntegrationsManagementEntities";
import { showErrorToast } from "../../../shared-components/Toasts";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import IndividualIssueLogActivity from "./IndividualIssueLogActivity";
import { Col } from "react-bootstrap";
import { LogSidePanelBody } from "../../logs/side-panel/shared-components/body/LogSidePanelBody";
import { LogSidePanelNotFound } from "../../logs/side-panel/shared-components/error-views/LogSidePanelNotFound";
import { LogSidePanelUnauthorized } from "../../logs/side-panel/shared-components/error-views/LogSidePanelUnauthorized";
import { LogSidePanelBanner } from "../../logs/side-panel/shared-components/banner/LogSidePanelBanner";
import { fetchLogEntryResponseBody } from "../../utils/IntegrationsManagementAPIClient";
import { CodeBlockFileDownloadInfo } from "../../../shared-components/MergeCodeBlock";
import {
  fetchLogEntryByID,
  fetchIntegrationIssueLogs,
} from "../../utils/IntegrationsManagementAPIClient";
import { APILogResponseBodyPayload } from "../../IntegrationsManagementEntities";
import { LogType, IntegrationIssueLogs } from "../../IntegrationsManagementEntities";
import useOnClickOutside from "use-onclickoutside";
import { palette } from "../../../../styles/theme";
import ContentLoader from "react-content-loader";
import { isBillingPlanFreeLaunch } from "../../../settings/billing/BillingUtils";
import { ResponseCodeBadge } from "../../../shared-components/MergeBadges";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import MergeCodeBlock from "../../../shared-components/MergeCodeBlock";
import EmptyStateWrapper from "../../../shared-components/EmptyStateWrapper";
import { Dialog } from "@merge-api/merge-javascript-shared";
const NoLogsContainer = styled.div`
  height: 150px;
  width: 100%;
`;

const LogsContainer = styled.div`
  border-radius: 8px;
  background: ${palette.white};
`;

const Section = ({ children }: { children: JSX.Element | (JSX.Element | undefined)[] }) => (
  <>
    <div className="mb-3">{children}</div>
    <hr />
  </>
);

const TextField = ({
  name,
  value,
  children,
}: {
  name: string;
  value: string | object | undefined | null;
  children?: JSX.Element;
}) => {
  const text =
    !value || (typeof value === "string" && value.length == 0)
      ? "None"
      : typeof value === "string" && !children
      ? value
      : children;
  return (
    <div className="d-flex text-left align-items-center justify-content-start mt-2">
      <DeprecatedH4 className="mb-0 text-start w-1/4">{name}</DeprecatedH4>
      <p className="mb-0 text-break w-3/4">{text}</p>
    </div>
  );
};

type RequestTestResultsModalProps = {
  log?: APIRequestLogEntry;
  responseCode: number | undefined;
  responseBody: string;
  isShown: boolean;
  setIsShown: (value: boolean) => void;
};

const RequestTestResultsModal = ({
  log,
  responseCode,
  responseBody,
  isShown,
  setIsShown,
}: RequestTestResultsModalProps) => {
  return (
    <Dialog
      open={isShown}
      footerButtonsHidden
      onClose={() => setIsShown(false)}
      title="Retry API request results"
      variant="lg"
    >
      {responseCode ? (
        <>
          <Section>
            <TextField name="URL" value={log?.url} />
            <TextField name="Direction" value={log?.direction} />
            <TextField name="Method" value={log?.method} />
          </Section>
          <Section>
            <TextField name="Headers" value={log?.request_headers} />
            <TextField name="Body" value={log?.request_body} />
            <TextField name="Status" value={undefined}>
              <ResponseCodeBadge responseCode={responseCode} />
            </TextField>
          </Section>
          <div className="text-[15px] mt-3 mb-3 font-semibold">Response</div>
          <MergeCodeBlock
            showLineNumbers
            customStyle={{
              lineHeight: "4px",
              height: "200px",
            }}
          >
            {responseBody || "The remote API returned an empty body."}
          </MergeCodeBlock>
        </>
      ) : (
        <EmptyStateWrapper isSpinner />
      )}
    </Dialog>
  );
};

type NoLogsComponentProps = {
  text: string;
};
const NoLogsComponent = ({ text }: NoLogsComponentProps) => {
  return (
    <NoLogsContainer className="d-flex justify-content-center align-items-center text-gray-30 ">
      <h5>{text}</h5>
    </NoLogsContainer>
  );
};

const LoadingLogsComponent = () => {
  const skeletonRows = Array.from({ length: 8 }).map((_, i) => {
    return (
      <BottomBorderDiv
        showBottomBorder={i !== 7}
        className="d-flex pt-3 pb-3 pr-4 pl-4 align-items-center"
      >
        <ContentLoader
          speed={2}
          width="100%"
          height={32}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          radius={16}
        >
          <rect x="0" y="0" rx="16" ry="16" width="100%" height="28" radius="8" />
        </ContentLoader>
      </BottomBorderDiv>
    );
  });

  return <>{skeletonRows}</>;
};

type IndividualIssueActivityV2Props = {
  integrationIssue: IntegrationIssue;
};

type BottomBorderDivProps = {
  showBottomBorder: boolean;
};

export const BottomBorderDiv = styled.div<BottomBorderDivProps>`
  border-bottom: ${(props) =>
    props.showBottomBorder ? " 0.5px solid var(--lm-gray-20, #dce2ea)" : ""};
`;
const IndividualIssueActivityV2 = ({ integrationIssue }: IndividualIssueActivityV2Props) => {
  const { isUserPrivileged } = useAppContext();
  const [isRequestTestResultsModalShown, setIsRequestTestResultsModalShown] =
    useState<boolean>(false);
  const [testedLog, setTestedLog] = useState<APIRequestLogEntry>();
  const [responseCode, setResponseCode] = useState<number>();
  const [responseBody, setResponseBody] = useState<string>("");
  const [isLogSidePanelShown, setIsLogSidePanelShown] = useState<boolean>(false);
  const [logEntry, setSelectedLogEntry] = useState<undefined | LogType>(undefined);
  const [logID, setSelectedLogId] = useState<string | undefined>(undefined);

  const [logResponseBody, setLogResponseBody] = useState<undefined | string>();
  const [logDoesNotExist, setLogDoesNotExist] = useState<boolean>(false);
  const [logResponseBodyDownloadInfo, setLogResponseBodyDownloadInfo] = useState<
    CodeBlockFileDownloadInfo | undefined
  >();
  const [issueLogs, setIssueLogs] = useState<undefined | APIRequestLogEntry[]>(undefined);
  const { user } = useAppContext();

  useEffect(() => {
    fetchIntegrationIssueLogs({
      integrationIssueID: integrationIssue.id,
      onSuccess: (result: IntegrationIssueLogs) => {
        setIssueLogs(result.recent_logs);
      },
    });
  }, []);

  const ref = useRef<HTMLDivElement>(null);

  const exitSidePanel = () => {
    setIsLogSidePanelShown(false);
    setSelectedLogId(undefined);
    setSelectedLogEntry(undefined);
  };

  useEffect(() => {
    if (logID != undefined) {
      fetchLogEntryByID({
        logID: logID,
        onSuccess: (data: any) => {
          setSelectedLogEntry(data);
          setIsLogSidePanelShown(true);
        },
        onError: () => {
          setLogDoesNotExist(true);
        },
      });
    }
  }, [logID]);

  useEffect(() => {
    if (logEntry && logID) {
      if (fetchLogEntryResponseBody !== undefined) {
        fetchLogEntryResponseBody({
          logID,
          onSuccess: (data: APILogResponseBodyPayload) => {
            setLogResponseBody(data.response_body);
            if (data.download_link !== undefined && data.file_size != undefined) {
              // Response Body is link, not Object
              const previewMessage = `Download full response as file (${(
                data.file_size / 1000000
              ).toFixed(1)} MB)`;
              setLogResponseBodyDownloadInfo({
                downloadLink: data.download_link,
                previewMessage: previewMessage,
              });
            }
          },
        });
      } else {
        setLogResponseBody(logEntry.response_body);
      }
    }
  }, [logEntry]);

  useOnClickOutside(ref, () => {
    exitSidePanel();
  });

  const testIssueIntegration = (log: APIRequestLogEntry, isDemo = false) => {
    if (integrationIssue && !isDemo) {
      const path = getAPIRequestPath(log.url, integrationIssue.linked_account.integration);
      setIsRequestTestResultsModalShown(true);
      setResponseBody("");
      setResponseCode(undefined);
      fetchWithAuth({
        path: `/integrations/linked-accounts/${log.linked_account.id}/test-request`,
        method: "POST",
        body: { method: log.method, endpoint: path, body: log.request_body },
        onResponse: (responseData: { status: string; status_code: number; body: string }) => {
          setResponseCode(responseData.status_code);
          setTestedLog(log);
          try {
            setResponseBody(JSON.stringify(JSON.parse(responseData.body), null, 2));
          } catch (err) {
            setResponseBody(responseData.body);
          }
        },
        onError: () => {
          showErrorToast("Oops! Looks like we encountered an error. Please try again.");
          setIsRequestTestResultsModalShown(false);
        },
      });
    }
    if (isDemo) {
      setResponseBody(`{ "demo": "Sample JSON response!" }`);
      setResponseCode(log.response_code);
      setTestedLog(log);
      setIsRequestTestResultsModalShown(true);
    }
  };
  return (
    <>
      <LogsContainer className="d-flex flex-column mt-6 mb-4 shadow-md">
        <BottomBorderDiv showBottomBorder>
          <div className="pt-3 pb-3 pl-4 pr-3 font-semibold text-lg">Logs</div>
        </BottomBorderDiv>
        <div className="d-flex flex-column">
          {issueLogs ? (
            issueLogs.length > 0 ? (
              issueLogs.map((issueLog, index: number, array: any[]) => {
                return (
                  <IndividualIssueLogActivity
                    key={index}
                    issueLog={issueLog}
                    testIssueIntegration={testIssueIntegration}
                    isLastRow={index == array.length - 1}
                    setSelectedLogEntry={setSelectedLogId}
                  />
                );
              })
            ) : (
              <NoLogsComponent
                text={
                  isUserPrivileged
                    ? `No recent activity. Logs are retained for ${
                        isBillingPlanFreeLaunch(
                          user.organization.organization_billing_plan?.billing_plan,
                        )
                          ? "6"
                          : "10"
                      }  days.`
                    : "You must be an admin or developer to view activity"
                }
              />
            )
          ) : (
            <LoadingLogsComponent />
          )}
        </div>
      </LogsContainer>
      <RequestTestResultsModal
        log={testedLog}
        responseCode={responseCode}
        responseBody={responseBody}
        isShown={isRequestTestResultsModalShown}
        setIsShown={setIsRequestTestResultsModalShown}
      />
      <>
        {isLogSidePanelShown && (
          <div>
            <div className="log-side-panel col-md-9 h-100 position-fixed" ref={ref}>
              <div className="mx-3">
                <Col>
                  {isUserPrivileged ? (
                    logEntry ? (
                      <>
                        <LogSidePanelBanner
                          title="API request log"
                          logEntry={logEntry}
                          onClickExit={() => exitSidePanel()}
                        />
                        <LogSidePanelBody
                          logEntry={logEntry}
                          hasDynamicResponseBody={fetchLogEntryResponseBody !== undefined}
                          responseBody={logResponseBody}
                          downloadInfo={logResponseBodyDownloadInfo}
                        />
                      </>
                    ) : (
                      logDoesNotExist && (
                        <LogSidePanelNotFound onClickExit={() => exitSidePanel()} />
                      )
                    )
                  ) : (
                    <>
                      <LogSidePanelUnauthorized onClickExit={() => exitSidePanel()} />
                    </>
                  )}
                </Col>
              </div>
            </div>
          </div>
        )}
      </>
      <div className={classNames("opaque-backdrop", isLogSidePanelShown ? "show" : "fade")} />
    </>
  );
};
export default IndividualIssueActivityV2;
