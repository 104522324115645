import React, { Dispatch, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { LinkedAccount } from "../../../../models/Entities";
import { PaddingLessCol } from "../helpers/LeftSideBarSpacing";
import SyncHistoryView from "./SyncHistoryView";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";

export interface Props {
  /**
   * The account we have linked or nothing if we have only an id
   */
  linkedAccount: LinkedAccount | null;
  linkedAccounts: LinkedAccount[] | null;
  setLinkedAccount: Dispatch<SetStateAction<LinkedAccount | null>>;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledH4 = styled(DeprecatedH4)`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #121314;
  margin-top: 43px;
  margin-bottom: 27px;
`;

const SyncOverview = ({ linkedAccount, linkedAccounts, setLinkedAccount }: Props) => {
  return (
    <>
      <PaddingLessCol>
        <MainContainer>
          <StyledH4>Sync overview</StyledH4>
          <Row>
            <Col xs={12}>
              <SyncHistoryView
                linkedAccount={linkedAccount}
                linkedAccounts={linkedAccounts}
                setLinkedAccount={setLinkedAccount}
              />
            </Col>
          </Row>
        </MainContainer>
      </PaddingLessCol>
    </>
  );
};

export default SyncOverview;
