import React from "react";
import { Link, useParams } from "react-router-dom";
import MergeTypeahead from "../../../../../shared-components/MergeTypeahead";
import SkeletonLoader from "../../../../../shared-components/SkeletonLoader";
import {
  DOCS_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
  getLinkedAccountDetailFieldMappingsPathForId,
} from "../../../../../../router/RouterUtils";
import FieldMappingTargetsForModel from "./FieldMappingTargetsForModel";
import { FieldMappingCommonModelConfiguration } from "../../../../../../models/Entities";
import { ArrowLeftRight, ArrowLeft } from "lucide-react";

import useFieldmappingTargetsPage from "./hooks/useFieldMappingTargetsPage";
const FieldMappingTargetsPage = () => {
  const { id } = useParams<{ id: string }>();
  const params = new URLSearchParams(window.location.search);
  const preSelectedCommonModel = params.get("commonModelName") ?? null;

  const {
    linkedAccount,
    fieldMappingInformation,
    fieldMappingConfigurations,
    availableFieldMappings,
    selectedCommonModels,
    setSelectedCommonModels,
  } = useFieldmappingTargetsPage({
    linkedAccountID: id,
    preSelectedCommonModel: preSelectedCommonModel,
  });

  const filterFieldMappingConfigs = () => {
    if (selectedCommonModels.length == 0) {
      return fieldMappingConfigurations;
    }

    return fieldMappingConfigurations!.filter((config: FieldMappingCommonModelConfiguration) => {
      return selectedCommonModels.includes(config.common_model_name);
    });
  };

  return (
    <div className="my-8 ml-5">
      {linkedAccount && (
        <Link
          to={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
          className="text-blue-40 font-medium flex items-center cursor:hover"
        >
          <ArrowLeft size={14} className="mr-1" />
          {linkedAccount?.organization.name}
          <i className="ml-1 mr-1">
            <ArrowLeftRight size={12} />
          </i>
          {linkedAccount?.integration.name}
        </Link>
      )}
      <div className="mt-4">
        {linkedAccount && fieldMappingInformation && fieldMappingConfigurations ? (
          <>
            <div className="text-xl font-semibold">
              Unmapped {linkedAccount.organization.name}-wide target fields
            </div>
            <div className="text-base mt-4">
              <p>
                Connect predefined target fields to new {linkedAccount.integration.name} remote
                fields. Any changes made here are automatically saved and will be updated in the
                next sync.
              </p>
              <p className="mt-0">
                Go to{" "}
                <a href="/configuration/field-mappings/target-fields">Advanced Configuration</a> to
                add more organization-wide target fields or set default integration-wide mappings.{" "}
                <a target="_blank" rel="noreferrer" href={DOCS_FIELD_MAPPINGS_TARGET_FIELDS_PATH}>
                  Learn more
                </a>
                .
              </p>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-4">
            <SkeletonLoader height={28} width={500} borderRadius={4} />
            <SkeletonLoader height={28} width={900} borderRadius={4} />
            <SkeletonLoader height={28} width={900} borderRadius={4} />
          </div>
        )}
      </div>
      {linkedAccount &&
      fieldMappingInformation &&
      availableFieldMappings &&
      fieldMappingConfigurations ? (
        <>
          <div className="mt-8">
            <div className="mb-4">
              <MergeTypeahead
                id="typeahead"
                multiple
                includeSearchIcon
                includeChevronDown={false}
                selected={selectedCommonModels}
                options={fieldMappingConfigurations.map(
                  (config: FieldMappingCommonModelConfiguration) => {
                    return config.common_model_name;
                  },
                )}
                inputProps={{ autoComplete: "none" }}
                placeholder="Search Common Models..."
                onChange={(selectedModelNames) => {
                  setSelectedCommonModels(selectedModelNames);
                }}
                isInvalid={false}
                error=""
              />
            </div>
          </div>
          <div className="flex flex-col gap-8 mt-8">
            {filterFieldMappingConfigs()!.map(
              (configuration: FieldMappingCommonModelConfiguration) => {
                return (
                  <FieldMappingTargetsForModel
                    commonModel={configuration.common_model_name}
                    fieldMappingTargets={fieldMappingInformation.field_mapping_targets.filter(
                      (target: { common_model_name: string }) => {
                        return target.common_model_name == configuration.common_model_name;
                      },
                    )}
                    isRemoteDataEnabled={configuration.has_remote_data_enabled}
                    fieldMappingOptions={availableFieldMappings}
                    linkedAccount={linkedAccount}
                  />
                );
              },
            )}
          </div>
        </>
      ) : (
        <div className="mt-8">
          <div className="mb-4 flex flex-col gap-8">
            <SkeletonLoader height={40} fullWidth borderRadius={4} />
            <SkeletonLoader height={300} fullWidth borderRadius={4} />
            <SkeletonLoader height={300} fullWidth borderRadius={4} />
            <SkeletonLoader height={300} fullWidth borderRadius={4} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FieldMappingTargetsPage;
