import { useCallback, useEffect, useState } from "react";
import { fetchWithAuth } from "../../../api-client/APIClient";

export enum MergeFlagFeature {
  MERGE_FLAG_ENABLE_REDACT_UNMAPPED_DATA_FRONTEND = "MERGE_FLAG_ENABLE_REDACT_UNMAPPED_DATA_FRONTEND", // TODO: remove https://app.asana.com/0/1205174382712375/1205328852055066/f
  MERGE_FLAG_ENABLE_DATA_WAREHOUSE = "MERGE_FLAG_ENABLE_DATA_WAREHOUSE", // TODO: remove https://app.asana.com/0/1205550558375762/1205671730434201/f
  MERGE_FLAG_DEFAULT_OFF_SCOPES = "MERGE_FLAG_DEFAULT_OFF_SCOPES", // TODO: remove https://app.asana.com/0/1205550558375762/1206769143809871/f
  MERGE_FLAG_SHOW_DEPRECATED_SUNSET_ITEMS = "MERGE_FLAG_SHOW_DEPRECATED_SUNSET_ITEMS", // never remove!
  ENABLE_SELECTIVE_SYNC = "ENABLE_SELECTIVE_SYNC", // TODO: remove https://app.asana.com/0/1206661594236943/1206670899981051/f
  MERGE_FLAG_ENABLE_COMMON_MODEL_SCOPE_DEPENDENCIES = "MERGE_FLAG_ENABLE_COMMON_MODEL_SCOPE_DEPENDENCIES", // TODO: remove https://app.asana.com/0/1205550558375762/1207232599957511/f
  FREE_ACCOUNT_GATING = "FREE_ACCOUNT_GATING", // TODO: remove free account gating changes
  MERGE_FLAG_ENABLE_ENDPOINT_USAGE = "MERGE_FLAG_ENABLE_ENDPOINT_USAGE", // TODO: remove https://app.asana.com/0/1207602234244110/1207847435589277/f
}

export type UseMergeFlagProps = {
  feature: MergeFlagFeature;
  organizationId?: string;
  linkedAccountId?: string;
  integrationId?: string;
};

/**
 * Hook for checking if merge flag is enabled. Requires one (and only one)
 * of organizationId, linkedAccountId, or integrationId
 *
 * If you just want to hide UI can use `<MergeFlagWrapper />`
 */
export const useMergeFlag = ({
  feature,
  linkedAccountId,
  organizationId,
  integrationId,
}: UseMergeFlagProps) => {
  // state
  const [enabled, setEnabled] = useState<boolean | undefined>(undefined);
  const [enabledForLinkedAccount, setEnabledForLinkedAccount] = useState<boolean | undefined>(
    undefined,
  );
  const [enabledForOrganization, setEnabledForOrganization] = useState<boolean | undefined>(
    undefined,
  );
  const [enabledForIntegration, setEnabledForIntegration] = useState<boolean | undefined>(
    undefined,
  );
  const [hasInitialized, setHasInitialized] = useState(false);

  // event handlers
  const loadMergeFlagPermissions = useCallback(() => {
    fetchWithAuth({
      path: `integrations/merge-flags/${feature}/enabled`,
      method: "POST",
      body: {
        organizationId,
        integrationId,
        linkedAccountId,
      },
      onResponse: (res: {
        feature_enabled_for_organization: boolean;
        feature_enabled_for_integration: boolean;
        feature_enabled_for_linked_account: boolean;
      }) => {
        if (
          (organizationId && res.feature_enabled_for_organization) ||
          (integrationId && res.feature_enabled_for_integration) ||
          (linkedAccountId && res.feature_enabled_for_linked_account)
        ) {
          setEnabled(true);
        } else {
          setEnabled(false);
        }
        setEnabledForLinkedAccount(res.feature_enabled_for_linked_account ?? undefined);
        setEnabledForOrganization(res.feature_enabled_for_organization ?? undefined);
        setEnabledForIntegration(res.feature_enabled_for_integration ?? undefined);
        setHasInitialized(true);
      },
      onError: (err) => {
        console.error(err);
        setHasInitialized(true);
      },
    });
  }, [integrationId, linkedAccountId, organizationId, feature]);

  // effects
  // initialize
  useEffect(() => {
    loadMergeFlagPermissions();
  }, [loadMergeFlagPermissions]);

  return {
    hasInitialized,
    enabled,
    enabledForLinkedAccount,
    enabledForOrganization,
    enabledForIntegration,
  };
};
