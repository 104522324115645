import { useState, useMemo } from "react";
import reduce from "lodash/reduce";
import { HTTPMethod, UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";
import {
  SyncConnectionIntegration,
  SyncConnectionAuthConfigAuthSchemaFieldType,
} from "../../../../../../../models/Entities";
import { fetchWithAuth } from "../../../../../../../api-client/APIClient";
import { isValidJSON } from "../../../../../../../services";

type UseCreateSyncConnectionProps = {
  integration: SyncConnectionIntegration;
  onNext: (error?: { message: string; third_party_error: boolean }) => void;
  authFields: {
    [key: string]: string;
  };
  name: string;
};

const useCreateSyncConnection = ({
  onNext,
  authFields,
  integration,
  name,
}: UseCreateSyncConnectionProps) => {
  // state
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  // derived state
  const requiredFields = useMemo(() => {
    const authSchema = integration.sync_connection_auth_config.destination!.auth_schema;
    return authSchema
      .flatMap(({ fields }) => fields)
      .filter(({ required }) => required)
      .map(({ key }) => key);
  }, [integration.sync_connection_auth_config.destination]);

  const jsonFields = useMemo(() => {
    const authSchema = integration.sync_connection_auth_config.destination!.auth_schema;
    return authSchema
      .flatMap(({ fields }) => fields)
      .filter(({ type }) => type === SyncConnectionAuthConfigAuthSchemaFieldType.JSON)
      .map(({ key }) => key);
  }, [integration.sync_connection_auth_config.destination]);

  const isSubmitDisabled = useMemo(
    () =>
      !name ||
      // all required fields exist
      requiredFields.some((key) => !authFields[key]) ||
      // all json fields are valid json (or do not exist, which is fine unless required and would be caught by above line)
      jsonFields.some((key) => authFields[key] && !isValidJSON(authFields[key])),
    [authFields, jsonFields, name, requiredFields],
  );

  const onSubmit = () => {
    setIsSubmitLoading(true);

    // flatten JSON
    // get list of all fields of type === JSON
    const jsonFields = integration.sync_connection_auth_config
      .destination!.auth_schema.flatMap(({ fields }) => fields)
      .filter(({ type }) => type === SyncConnectionAuthConfigAuthSchemaFieldType.JSON)
      .map(({ key }) => key);

    // flatten all JSON fields into authFields array
    const flattenedAuthFields = reduce(
      authFields,
      (acc, curr, key) => {
        if (jsonFields.includes(key) && isValidJSON(curr)) {
          const parsedJson = JSON.parse(curr);
          acc = { ...acc, ...parsedJson };
        } else {
          acc[key] = curr;
        }

        return acc;
      },
      {} as any,
    );

    fetchWithAuth({
      path: "integrations/destination",
      method: HTTPMethod.POST,
      body: {
        name,
        auth_fields: flattenedAuthFields,
        integration_id: integration.id,
        category: UnreleasedAPICategory.datawarehouse,
      },
      onResponse: () => {
        setIsSubmitLoading(false);
        onNext();
      },
      onError: async (e: any) => {
        setIsSubmitLoading(false);
        try {
          const body = await e.json();
          if ("message" in body) {
            onNext(body);
          } else {
            onNext({
              message: "Error connecting destination! Please try again.",
              third_party_error: false,
            });
          }
        } catch (e) {
          onNext({
            message: "Error connecting destination! Please try again.",
            third_party_error: false,
          });
        }
      },
    });
  };

  return { onSubmit, isSubmitLoading, isSubmitDisabled };
};

export default useCreateSyncConnection;
