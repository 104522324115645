import React from "react";
import { Card } from "react-bootstrap";
import useAppContext from "../../../context/useAppContext";
import { SectionHeaderWrapper, TitleHeading } from "../../../shared-components/MergeLayouts";
import { Button, ButtonVariant, UnreleasedAPICategory } from "@merge-api/merge-javascript-shared";
import { LinkedAccount } from "../../../../models/Entities";

type Props = {
  linkedAccount: LinkedAccount;
  onClick: () => void;
};

const LinkedAccountDeleteSwitch = ({ linkedAccount, onClick }: Props) => {
  const { user } = useAppContext();
  const deleteText =
    linkedAccount.category === UnreleasedAPICategory.datawarehouse
      ? "Synced data models cannot be deleted because Merge does not have DELETE permissions on destinations. "
      : "After deleting this Linked Account, you’ll be notified once deletion of all associated data - including synced data models - is complete.";

  return (
    <Card className="p-5">
      <SectionHeaderWrapper
        title="Delete Linked Account"
        titleHeading={TitleHeading.H2}
        style={{ marginBottom: "-8px" }}
      >
        <>
          <p>{deleteText}</p>
          <p className="mt-3 mb-6">Deletion is irreversible!</p>

          <Button size="md" variant={ButtonVariant.DangerFilled} onClick={onClick}>
            Delete Linked Account
          </Button>
        </>
      </SectionHeaderWrapper>
    </Card>
  );
};

export default LinkedAccountDeleteSwitch;
