import React from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import DeprecatedH5 from "../../../../deprecated/DeprecatedH5";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";
import { Button } from "@merge-api/merge-javascript-shared";
import { ChevronRight } from "lucide-react";

export interface TileWithImageProps {
  img: React.ReactElement;
  title: string;
  subtitle?: JSX.Element;
  buttonName: string;
  docsURL: string;
}

//used for the header in each of the individual step sections
const TitleText = styled(DeprecatedH5)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #111317;
  padding: 16px 20px;
  margin-bottom: 0px;
`;

const SubTitleText = styled(DeprecatedH6)`
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #080808;
  padding: 0px 20px;
  margin-bottom: 0px;
`;

// used for the primary description container
const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Image = styled.i`
  margin-right: 12px;
`;

const FinalRowContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 0px;
  margin-left: 0px;
`;

const SelectOneStep = ({ img, title, subtitle, buttonName, docsURL }: TileWithImageProps) => (
  <Container>
    <TitleText>
      <Image>{img}</Image>
      {title}
    </TitleText>
    <SubTitleText>{subtitle}</SubTitleText>
    <FinalRowContainer>
      <Button
        className="mr-5 mb-5 mt-3"
        size="sm"
        rightIcon={<ChevronRight size={16} />}
        onClick={() => window.open(docsURL, "_blank")}
      >
        {buttonName}
      </Button>
    </FinalRowContainer>
  </Container>
);

export default SelectOneStep;
