import React from "react";
import { Destination } from "../../../models/Entities";
import MergeTable from "../../shared-components/MergeTable";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import TableSkeletonLoader from "../../integrations-management/shared/TableSkeletonLoader";
import DestinationTableRows from "./DestinationTableRows";

type DestinationTableProps = {
  isLoading: boolean;
  destinations?: Destination[];
  setDestinationToDelete: (destination: Destination) => void;
};

function DestinationTable(props: DestinationTableProps) {
  const { isLoading, destinations, setDestinationToDelete } = props;

  const header = (
    <>
      <th scope="col">Name</th>
      <th scope="col">Integration</th>
      <th scope="col">Status</th>
      <th scope="col">Connection ID</th>
      <th />
    </>
  );

  const content = (
    <>
      {!isLoading && destinations ? (
        destinations.length ? (
          <DestinationTableRows
            destinations={destinations}
            setDestinationToDelete={setDestinationToDelete}
          />
        ) : (
          <tr>
            <td colSpan={5}>
              <EmptyStateWrapper
                isTable
                title="You have no Destinations. Click the button in the top right to add a Destination."
              />
            </td>
          </tr>
        )
      ) : (
        <TableSkeletonLoader cols={4} rows={5} size="large" />
      )}
    </>
  );

  return (
    <MergeTable hover={false} header={header} content={content} hasMorePaddingOnFirstElement />
  );
}
export default DestinationTable;
