import React from "react";

import { Badge } from "@merge-api/merge-javascript-shared";
import { AuditLogRole } from "../../../../../../models/Entities";
import { BaseColor } from "@merge-api/merge-javascript-shared/dist/designSystem/types";

const ROLE_COLOR: Record<AuditLogRole, BaseColor> = {
  [AuditLogRole.ADMIN]: "purple",
  [AuditLogRole.DEVELOPER]: "indigo",
  [AuditLogRole.MEMBER]: "blue",
  [AuditLogRole.MERGE_TEAM]: "gray",
  [AuditLogRole.SYSTEM]: "yellow",
  [AuditLogRole.API]: "pink",
};

const ROLE_NAME: Record<AuditLogRole, string> = {
  [AuditLogRole.ADMIN]: "Admin",
  [AuditLogRole.DEVELOPER]: "Developer",
  [AuditLogRole.MEMBER]: "Member",
  [AuditLogRole.MERGE_TEAM]: "Merge Support",
  [AuditLogRole.SYSTEM]: "System",
  [AuditLogRole.API]: "API",
};

type RoleCellProps = {
  role: AuditLogRole;
};

const RoleCell = ({ role }: RoleCellProps) => {
  const color = ROLE_COLOR[role];
  return (
    <td>
      <Badge color={color} size="md">
        {ROLE_NAME[role]}
      </Badge>
    </td>
  );
};

export default RoleCell;
