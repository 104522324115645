import React from "react";
import styled from "styled-components";
import { Integration } from "../../../models/Entities";
import { APIRequestLogEntryIntegration } from "../../integrations-management/IntegrationsManagementEntities";
import { OrganizationAvatar, UserAvatarSize } from "../../shared-components/MergeAvatars";
import { Text } from "@merge-api/merge-javascript-shared";

interface Props {
  textVariant?: string;
  customText?: string;
  textStyle?: string;
  integration: Integration | APIRequestLogEntryIntegration;
}

const NameAndLogoContainer = styled.div`
  min-width: 0;
`;

const EllipsisTextDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IntegrationNameAndLogo = ({ textVariant, integration, textStyle, customText }: Props) => {
  return (
    <NameAndLogoContainer className="d-flex align-items-center">
      <OrganizationAvatar
        size={UserAvatarSize.xs}
        imageURL={integration.square_image}
        organizationName={
          integration.abbreviated_name ? integration.abbreviated_name : integration.name
        }
        isCircle
        className="mr-3 d-flex"
      />
      <EllipsisTextDiv>
        {/*@ts-ignore need to input textVariant, but we dont have a type for this*/}
        <Text className={textStyle} variant={textVariant}>
          {customText || integration.abbreviated_name
            ? integration.abbreviated_name
            : integration.name}
        </Text>
      </EllipsisTextDiv>
    </NameAndLogoContainer>
  );
};

export default IntegrationNameAndLogo;
