import { formatDistanceToNowStrict } from "date-fns";
import { IntegrationIssue } from "../IntegrationsManagementEntities";
import isEmpty from "lodash/isEmpty";
import { replaceDistanceWithShortFormat } from "../../shared-components/utils/SharedComponentUtils";
import {
  IntegrationIssueMetadata,
  CommonModelInformation,
} from "../IntegrationsManagementEntities";

export const getFormattedEventDistanceFromNow = (timestamp: string) => {
  if (timestamp) {
    return replaceDistanceWithShortFormat(formatDistanceToNowStrict(new Date(timestamp))) + " ago";
  } else {
    return "-";
  }
};

export const getIssueCreatedAt = (issue: IntegrationIssue) => {
  return issue.first_incident_time || issue.created_at;
};

export const getIssueUpdatedAt = (issue: IntegrationIssue) => {
  if (!issue.last_action || isEmpty(issue?.last_action)) {
    return issue.last_incident_time;
  } else if (issue.last_action.created_at > issue.last_incident_time) {
    return issue.last_action.created_at;
  } else {
    return issue.last_incident_time;
  }
};

export const isIssueMuted = (issue: IntegrationIssue) => {
  const now = new Date();
  return (
    issue.incidents_until_unmuted > 0 ||
    issue.muted_until != undefined ||
    issue.is_muted ||
    (issue.muted_until && issue.muted_until < now)
  );
};

export const getCommonModelName = (commonModel: string) => {
  return commonModel.split(".")[1];
};

export const getImpactedModels = (issueMetadata: IntegrationIssueMetadata[]) => {
  let impactedModels = {};
  const impacedModelsErrorMessageLookup: { [key: string]: string[] } = {};
  issueMetadata.forEach((metadata: IntegrationIssueMetadata) => {
    if (metadata.action == "INFO" && metadata.common_model_information) {
      impactedModels = metadata.common_model_information.reduce(
        (
          acc: {
            [key: string]: number;
          },
          commonModelInfo: CommonModelInformation,
        ) => {
          const seenErrorMessagesForModel =
            impacedModelsErrorMessageLookup[commonModelInfo.common_model_name] || [];
          const userFacingErrorMessage = metadata.user_facing_error_detail || "";
          if (
            commonModelInfo.common_model_name in impactedModels &&
            !seenErrorMessagesForModel.includes(userFacingErrorMessage)
          ) {
            acc[commonModelInfo.common_model_name] += 1;
          } else {
            acc[commonModelInfo.common_model_name] = 1;
            seenErrorMessagesForModel.push(userFacingErrorMessage);
            impacedModelsErrorMessageLookup[commonModelInfo.common_model_name] =
              seenErrorMessagesForModel;
          }
          return acc;
        },
        impactedModels,
      );
    }
  });
  return impactedModels;
};
