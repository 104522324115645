import React, { useState } from "react";
import { deleteLinkedAccountFieldMapping } from "../../../../../api-client/APIClient";
import useLinkedAccountCustomMappingsContext from "./context/useLinkedAccountFieldMappingsContext";
import { deleteFieldMappingTarget } from "../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";
import { showErrorToast, showSuccessToast } from "../../../../shared-components/Toasts";
import { Dialog } from "@merge-api/merge-javascript-shared";

type Props = {
  headerText?: string;
  linkedAccountID?: string;
  fieldMappingID?: string;
  onHide: () => void;
  showModal: boolean;
  handlePostDelete?: () => void;
  text?: string;
  secondaryText?: string;
  isFieldMappingTargetDelete?: boolean;
  fieldMappingTargetId?: string;
  onSuccess?: (data: any) => void;
  skipFieldMappingRefresh?: boolean;
};

const DeleteFieldMappingModal = ({
  headerText,
  fieldMappingID,
  onHide,
  showModal,
  handlePostDelete,
  text,
  secondaryText,
  isFieldMappingTargetDelete = false,
  fieldMappingTargetId,
  onSuccess,
  skipFieldMappingRefresh = false,
}: Props) => {
  const { refreshFieldMappings: refreshCustomMappings } = useLinkedAccountCustomMappingsContext();

  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = () => {
    setIsDeleting(true);
    if (isFieldMappingTargetDelete) {
      deleteFieldMappingTarget({
        fieldMappingTargetId: fieldMappingTargetId!,
        onResponse: () => showSuccessToast("Successfully deleted target field!"),
        onError: () => showErrorToast("Could not delete target field!"),
      });
    } else {
      deleteLinkedAccountFieldMapping(
        fieldMappingID!,
        refreshCustomMappings,
        onSuccess,
        skipFieldMappingRefresh,
      );
    }
    setIsDeleting(false);
    if (handlePostDelete) {
      handlePostDelete();
    }
    onHide();
  };

  return (
    <Dialog
      open={showModal}
      onClose={onHide}
      onPrimaryButtonClick={onDelete}
      primaryButtonLoading={isDeleting}
      primaryButtonText={isFieldMappingTargetDelete ? "Delete target field" : "Delete mapping"}
      onSecondaryButtonClick={onHide}
      secondaryButtonText="Cancel"
      title={headerText || "Delete mapping"}
      variant="sm"
    >
      {text || "This action cannot be undone"}
      {secondaryText ? (
        <>
          <br /> <br /> {secondaryText}
        </>
      ) : (
        <></>
      )}
      <br />
      <br />
      Do you wish to continue?
    </Dialog>
  );
};

export default DeleteFieldMappingModal;
