import React, { useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { OrganizationAvatar, UserAvatarSize } from "../../../../../shared-components/MergeAvatars";
import { CodeBlockFileDownloadInfo } from "../../../../../shared-components/MergeCodeBlock";
import { LogSidePanelRequestSection } from "./LogSidePanelRequestSection";

import styled from "styled-components";
import { spectrum } from "../../../../../../styles/theme";
import RotatingChevronRight from "../../../../../shared-components/RotatingChevronRight";
import { LogSidePanelResponseSection } from "./LogSidePanelResponseSection";
import { LogType } from "../../../../IntegrationsManagementEntities";
import { Badge, Text } from "@merge-api/merge-javascript-shared";

const HoverableRow = styled(Row)`
  margin-left: 0px;
  margin-right: 0px;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${spectrum.gray0};
  }
`;

const ExpandedRow = styled(Row)`
  margin: 16px 30px 0px 30px;
`;

const HoverableCol = styled(Col)`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 10px;
`;

interface CollapsableSectionProps {
  title: React.ReactNode;
  numberOfElements: number | undefined;
  children: JSX.Element | JSX.Element[];
  isCollapsedByDefault?: boolean;
}

export const CollapsableSection = ({
  title,
  numberOfElements,
  children,
  isCollapsedByDefault,
}: CollapsableSectionProps) => {
  const [isClosed, setIsClosed] = useState(isCollapsedByDefault);

  return (
    <Accordion defaultActiveKey={!isClosed ? "0" : undefined}>
      <Accordion.Toggle
        onClick={() => setIsClosed(!isClosed)}
        as={Card.Body}
        className="clickable"
        eventKey="0"
      >
        <HoverableRow className="align-items-center clickable">
          <HoverableCol>
            <span className="flex flex-row items-center">
              <Text variant="h4">{title}</Text>
              <div>
                <Badge className="ml-2">{numberOfElements}</Badge>
              </div>
            </span>
          </HoverableCol>
          <HoverableCol className="col-auto">
            <RotatingChevronRight isRotatedDown={!isClosed} />
          </HoverableCol>
        </HoverableRow>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body className="mt-1">
          <ExpandedRow>{children}</ExpandedRow>
        </Card.Body>
      </Accordion.Collapse>
    </Accordion>
  );
};

export const FullWidthCard = styled(Card)`
  .card-body {
    padding: 0rem;
  }
`;

type Props = {
  logEntry: LogType;
  hasDynamicResponseBody: boolean;
  responseBody: string | undefined;
  downloadInfo?: CodeBlockFileDownloadInfo;
};

export const LogSidePanelBody = ({
  logEntry,
  hasDynamicResponseBody,
  responseBody,
  downloadInfo,
}: Props) => {
  const OrganizationBodyImage = () => (
    <OrganizationAvatar organizationName={logEntry.linked_account.end_user.organization_name} />
  );

  const IntegrationImage = () => (
    <OrganizationAvatar
      imageURL={logEntry.linked_account.integration.square_image}
      organizationName={logEntry.linked_account.integration.name}
    />
  );

  return (
    <Row className="p-6">
      <Col md="6">
        <LogSidePanelRequestSection
          logEntry={logEntry}
          organizationBodyImage={<OrganizationBodyImage />}
          integrationImage={<IntegrationImage />}
        />
      </Col>
      <Col md="6">
        <LogSidePanelResponseSection
          logEntry={logEntry}
          organizationBodyImage={<OrganizationBodyImage />}
          integrationImage={<IntegrationImage />}
          hasDynamicResponseBody={hasDynamicResponseBody}
          responseBody={responseBody}
          downloadInfo={downloadInfo}
        />
      </Col>
    </Row>
  );
};
