import { useEffect, useMemo, useState } from "react";
import { useRequest } from "../../../../shared-components/hooks/useRequest";
import {
  CalculatedTier,
  EndpointAndIntegrationsResponse,
  EndpointUsageDataResponse,
  EndpointUsageIntegration,
} from "../types";
import { APICategory } from "@merge-api/merge-javascript-shared";

const useEndpointUsageData = () => {
  // state
  const [selectedCategory, setSelectedCategory] = useState<APICategory>(APICategory.hris);
  const [selectedEndpoints, setSelectedEndpoints] = useState<string[]>([]);
  const [selectedIntegrations, setSelectedIntegrations] = useState<EndpointUsageIntegration[]>([]);
  const [hasLoadedIntegrationsAndEndpoints, setHasLoadedIntegrationsAndEndpoints] = useState(false);

  // derived state
  const path = useMemo(() => {
    let path = `integrations/endpoint-usage/${selectedCategory}?`;

    selectedEndpoints.forEach((endpoint) => {
      path += `endpoints=${encodeURIComponent(endpoint)}&`;
    });
    selectedIntegrations.forEach((integration) => {
      path += `integrations=${integration.id}&`;
    });

    return path;
  }, [selectedCategory, selectedEndpoints, selectedIntegrations]);

  // hooks
  const { data: endpointUsageData, loading: endpointUsageLoading } =
    useRequest<EndpointUsageDataResponse>({
      path,
      errorText: "Error loading endpoint usage data",
      skip: !hasLoadedIntegrationsAndEndpoints,
      shouldAbortRequests: true,
    });

  const { data: endpointsAndIntegrationsData, loading: endpointsAndIntegrationsLoading } =
    useRequest<EndpointAndIntegrationsResponse>({
      path: `integrations/endpoints/${selectedCategory}`,
      errorText: "Error loading endpoint usage filters",
      shouldAbortRequests: true,
    });

  // event handlers
  const onSelectedCategoryChange = (category: APICategory) => {
    // when we change category we also need to reset endpoints and integrations
    setSelectedCategory(category);
    setHasLoadedIntegrationsAndEndpoints(false);
    setSelectedEndpoints([]);
    setSelectedIntegrations([]);
  };

  // effects
  // auto filter by tier 1 integrations
  useEffect(() => {
    if (endpointsAndIntegrationsData?.integrations) {
      const tier1Integrations = endpointsAndIntegrationsData.integrations.filter(
        ({ calculated_tier }) => calculated_tier === CalculatedTier.TIER_1,
      );
      setSelectedIntegrations(tier1Integrations);
      setHasLoadedIntegrationsAndEndpoints(true);
    }
  }, [endpointsAndIntegrationsData?.integrations]);

  return {
    setSelectedCategory: onSelectedCategoryChange,
    integrations: endpointsAndIntegrationsData?.integrations,
    endpoints: endpointsAndIntegrationsData?.endpoints,
    selectedCategory,
    selectedIntegrations,
    setSelectedIntegrations,
    selectedEndpoints,
    setSelectedEndpoints,
    endpointUsageData,
    endpointUsageLoading,
    endpointsAndIntegrationsLoading,
  };
};

export default useEndpointUsageData;
