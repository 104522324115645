import React, { useState } from "react";
import { Dropdown, Card, Row, Col, FormControl, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { FormErrorData, fetchWithAuth } from "../../../api-client/APIClient";
import { showSuccessToast, showErrorToast } from "../../shared-components/Toasts";
import { UserType } from "../../../models/Entities";
import { REGEX_EMAIL_ADDRESS } from "../../shared-components/utils/SharedComponentUtils";
import { displayNameForUserType } from "../../../models/Helpers";
import useAppContext from "../../context/useAppContext";
import { Button } from "@merge-api/merge-javascript-shared";

type InviteTeamMemberButtonProps = {
  fetchPendingInvites: () => void;
};

function InviteTeamMemberButton(props: InviteTeamMemberButtonProps) {
  const { fetchPendingInvites } = props;

  const { register, handleSubmit, errors, reset } = useForm();
  const { user } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [inviteUserType, setInviteUserType] = useState<UserType>(UserType.admin_without_billing);
  const [showInviteTeamMemberMenu, setShowInviteTeamMemberMenu] = useState(false);

  const inviteCoworker = (data: { email: string }) => {
    setIsLoading(true);

    const formData = {
      email: data.email,
      user_type: inviteUserType,
    };

    fetchWithAuth({
      path: "/organizations/users/invite",
      method: "POST",
      body: formData,
      onResponse: () => {
        showSuccessToast(`Successfully invited ${data.email}!`);
        fetchPendingInvites();
        setIsLoading(false);
        setShowInviteTeamMemberMenu(false);
        reset();
      },
      onError: (error) => {
        if (error) {
          error.json().then((data: FormErrorData) => {
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                const fieldError = data[field_name][0];
                showErrorToast(fieldError);
                continue;
              }
            }
          });
        } else {
          showErrorToast(`Failed to invite ${data.email}.`);
        }

        setIsLoading(false);
      },
    });
  };

  return (
    <Dropdown show={showInviteTeamMemberMenu} hidden={!user.allowed_invite_roles.length}>
      <Dropdown.Toggle
        variant="primary"
        size="sm"
        id="invite-team-member"
        key="invite-team-member"
        onClick={() => setShowInviteTeamMemberMenu(!showInviteTeamMemberMenu)}
      >
        Invite team member
      </Dropdown.Toggle>
      <Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-right dropdown-menu-card">
        <Form onSubmit={handleSubmit(inviteCoworker)} autoComplete="off">
          <Card.Body>
            <Form.Group className="mb-3">
              <FormControl
                name="email"
                type="email"
                placeholder="Email address"
                className={classNames("form-control", {
                  "is-invalid": errors.email,
                })}
                ref={register({
                  required: true,
                  pattern: REGEX_EMAIL_ADDRESS,
                })}
              />
              {errors.email && (
                <div className="invalid-feedback">Please enter a valid email address.</div>
              )}
            </Form.Group>
            <Form.Group as={Row}>
              <Col className="my-auto">Add as:</Col>
              <Col className="text-right">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    id="invite-team-member-type"
                    size="sm"
                  >
                    {displayNameForUserType(inviteUserType)}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {user.allowed_invite_roles.map(
                      (type) =>
                        inviteUserType !== type && (
                          <Dropdown.Item
                            key={type}
                            className="small"
                            onClick={() => setInviteUserType(type)}
                          >
                            {displayNameForUserType(type)}
                          </Dropdown.Item>
                        ),
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Form.Group>
            <Button fullWidth size="sm" loading={isLoading} className="mt-3" type="submit">
              Invite team member
            </Button>
          </Card.Body>
        </Form>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default InviteTeamMemberButton;
