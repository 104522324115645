import React, { useState, useEffect } from "react";
import { Button, Card, Text, Chips, Alert, Link } from "@merge-api/merge-javascript-shared";
import { Elements } from "@stripe/react-stripe-js";
import { Info } from "lucide-react";
import { CardElement } from "@stripe/react-stripe-js";
import stripePromise from "./components/StripePromise";
import { BILLING_PATH } from "../../../router/RouterUtils";
import { paymentOptions } from "../../../constants";
import { getPaymentMethods, updateDefaultPaymentMethod } from "./BillingAPIClient";
import { LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT } from "./BillingModels";
import {
  BankAccount,
  BillingPlanTier,
  CreditCard,
  PaymentMethod,
  PaymentMethodTypes,
} from "./BillingModels";
import { Stripe } from "@stripe/stripe-js";
import Divider from "../../shared-components/Divider";
import BankAccountSetupForm from "./BankAccountSetupForm";
import CreditCardSetupForm from "./CreditCardSetupForm";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { useLocation } from "react-router-dom";
import { MergeFlagFeature, useMergeFlag } from "../../shared-components/hooks/useMergeFlag";
import useAppContext from "../../context/useAppContext";

export interface LinkedAccountProdCount {
  production_account_count: number;
}

type navigateState = {
  fromUpgradeButton?: boolean;
};

const AddPaymentMethod: React.FC = () => {
  const location = useLocation<navigateState>();
  const isFromUpgradeButton = location.state;
  const { user } = useAppContext();
  const freeAccountGated = user?.is_free_account_gating_enabled ?? false;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    PaymentMethodTypes.BANK_ACCOUNT,
  );

  const [stripe, setStripe] = useState<Stripe | null | undefined>(null);
  useEffect(() => {
    async function loadStripe() {
      const value = await stripePromise;
      setStripe(value);
    }
    loadStripe();
  }, []);

  const handleButtonClick = (value: string) => {
    setSelectedPaymentMethod(value);
  };

  const modifiedPaymentOptions = paymentOptions.map((option) => ({
    ...option,
    selected: selectedPaymentMethod === option.value,
    onClick: () => handleButtonClick(option.value),
  }));

  return (
    <div className="max-w-3xl mx-auto p-4 space-y-6">
      <div className="flex flex-col space-y-2 mt-6 mb-6 items-start">
        <Text variant="h6" className="font-semibold text-blue-40">
          <Link href={BILLING_PATH}>← Back to billing</Link>
        </Text>
        <Text variant="h2" className="font-semibold">
          {isFromUpgradeButton.fromUpgradeButton ? "Upgrade to Launch" : "Add payment method"}
        </Text>
      </div>

      {location?.state?.fromUpgradeButton && (
        <Card className="bg-white rounded-lg shadow-sm pt-4 py-5">
          <Text variant="h5" className="mb-4 px-6">
            Plan details
          </Text>
          <Divider />
          <div className="mb-4 px-5">
            <Text variant="h3">$650 / month</Text>
            <Text variant="md" className="text-gray-70">
              for 10 Production Linked Accounts
            </Text>
          </div>
          {user &&
            user?.organization?.static_production_linked_account_count &&
            user?.organization?.static_production_linked_account_count > 10 && (
              <>
                <div className="px-5 mb-4">
                  <Text variant="h3">
                    + $
                    {(user?.organization?.static_production_linked_account_count -
                      LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT) *
                      65}{" "}
                    / month
                  </Text>
                  <Text variant="md" className="text-gray-70">
                    for{" "}
                    {user?.organization?.static_production_linked_account_count -
                      LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT}{" "}
                    additional Production Linked Accounts
                  </Text>
                </div>
                <Alert
                  color="blue"
                  icon={<Info className="text-blue-50" size={16} />}
                  className="mx-5 mb-4"
                >
                  Interested in bulk discounts?<Link>Talk to our team</Link> for other options.
                </Alert>
              </>
            )}
          <div className="flex justify-between px-4 py-2 mx-5 bg-gray-0 rounded-lg mb-2">
            <Text variant="h6">Current total of Production Linked Accounts</Text>
            <Text variant="md">
              <Text className="text-blue-40" variant="md">
                {user?.organization?.static_production_linked_account_count}{" "}
              </Text>
              / 10
            </Text>
          </div>
          <div className="mx-5">
            <Text variant="sm" className="text-gray-70">
              Upgrading to Launch will start syncing any Linked Accounts that were connected beyond
              the Free plan limit of 3. If you don't want these Linked Accounts synced, you can
              delete them in{" "}
              <Text variant="sm" className="text-blue-40">
                Linked Accounts
              </Text>
              .
            </Text>
          </div>
        </Card>
      )}

      <Card className="bg-white rounded-lg shadow-sm pt-4 pb-5">
        <Text variant="h5" className="mb-4 px-6">
          Payment
        </Text>
        <Divider />
        <div className="px-6">
          <div>
            <Text variant="h6" className="mb-2">
              Select payment method
            </Text>
            <div className="flex flex-col">
              <Chips className="mb-2" options={modifiedPaymentOptions} />
              <Divider />
            </div>
          </div>
          {stripe ? (
            <Elements stripe={stripe}>
              {selectedPaymentMethod === PaymentMethodTypes.BANK_ACCOUNT && (
                <BankAccountSetupForm
                  isFromUpgrade={isFromUpgradeButton}
                  freeAccountGated={freeAccountGated}
                />
              )}
              {selectedPaymentMethod === PaymentMethodTypes.CREDIT_CARD && (
                <CreditCardSetupForm
                  isFromUpgrade={isFromUpgradeButton}
                  freeAccountGated={freeAccountGated}
                />
              )}
            </Elements>
          ) : (
            <Text className="pb-6">
              An unknown error occurred. Please try again later or contact us.
            </Text>
          )}
        </div>
      </Card>

      <div className="mt-4">
        <Text variant="sm" className="text-gray-60">
          By clicking Confirm, you authorize Merge to debit the bank account specified above for any
          amount owed for charges arising from your use of Merge's services and/or purchase of
          products from Merge, pursuant to Merge's website and terms, until this authorization is
          revoked. You may amend or cancel this authorization at any time by providing notice to
          Merge with 30 (thirty) days notice.
        </Text>
      </div>
    </div>
  );
};

export default AddPaymentMethod;
