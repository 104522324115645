import React from "react";
import { Col, Row } from "react-bootstrap";
import ClickableContainer from "../../../../../shared-components/ClickableContainer";
import EmptyStateWrapper from "../../../../../shared-components/EmptyStateWrapper";

export const LogSidePanelNotFound = ({ onClickExit }: { onClickExit: () => void }) => (
  <>
    <Row className="mt-6">
      <Col className="text-center">
        <ClickableContainer onClick={() => onClickExit()}>
          <span className="fe fe-x float-right" />
        </ClickableContainer>
      </Col>
    </Row>
    <Row>
      <Col className="m-6">
        <EmptyStateWrapper title="Log not found" />
      </Col>
    </Row>
  </>
);
