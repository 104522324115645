import React from "react";
import classNames from "classnames";
import { REDACTED_VALUE_TEXT } from "../../../../../../constants";
import { isValidJSON } from "../../../../../../services";
import MergeCodeBlock, {
  CodeBlockFileDownloadInfo,
} from "../../../../../shared-components/MergeCodeBlock";
import { CollapsableSection } from "./LogSidePanelBody";
import styled from "styled-components";
import { Spinner } from "@merge-api/merge-javascript-shared";

type Props = {
  title: string;
  data: string | undefined; // Query Params, headers, and body all string/json data
  isBody?: boolean; // Only true for Request Body
  downloadInfo?: CodeBlockFileDownloadInfo;
  isCollapsedByDefault?: boolean;
};

const MarginCodeBlock = styled(MergeCodeBlock)`
  margin-top: 14px;
  margin-bottom: 30px;
`;

export const LogSidePanelBodySection = ({
  title,
  data,
  isBody = false,
  downloadInfo,
  isCollapsedByDefault = false,
}: Props) => {
  let displayText =
    data !== undefined && isValidJSON(data)
      ? JSON.stringify(JSON.parse(data), null, 2)
      : data || "Empty";

  // For large response bodies, truncate to 21 lines
  if (downloadInfo !== undefined) {
    displayText = displayText.split("\n", 21).join("\n");
    displayText += "\n...";
  }

  return (
    <CollapsableSection
      title={title}
      isCollapsedByDefault={isCollapsedByDefault}
      numberOfElements={!isBody && data ? Object.entries(data).length : undefined}
      children={
        <div className="w-100 fs-mask">
          {data === undefined ? (
            <div className="mt-1 mb-2">
              <Spinner />
            </div>
          ) : isBody ? (
            <div className="mt-1">
              <MarginCodeBlock
                showLineNumbers
                customStyle={{
                  lineHeight: "4px",
                  maxHeight: "450px",
                  maxWidth: `100%`,
                }}
                textToCopy={data}
                downloadInfo={downloadInfo}
              >
                {displayText}
              </MarginCodeBlock>
            </div>
          ) : (
            Object.entries(data).map(([key, value]) => {
              const isRedacted = value === REDACTED_VALUE_TEXT;
              return (
                <div key={key}>
                  <div className="log-entry-section-key-text mb-0">{key}</div>
                  <p className={classNames("log-data-text", isRedacted ? "italic" : "")}>{value}</p>
                </div>
              );
            })
          )}
        </div>
      }
    />
  );
};
