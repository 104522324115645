import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingTarget,
  LinkedAccount,
  OverriddenCommonModelInstance,
  OverriddenCommonModelTarget,
  FieldMappingOptionsResponse,
  FieldMappingCreationAndEditDict,
} from "../../../../../models/Entities";
import MergeTypeahead from "../../../../shared-components/MergeTypeahead";
import LinkedAccountCommonModelFieldMappingOverview from "./LinkedAccountCommonModelFieldMappingOverview";
import { WarningCard } from "../../../../shared-components/NoticeCards";
import { SectionHeaderWrapper, TitleHeading } from "../../../../shared-components/MergeLayouts";
import { LinkedAccountStatuses } from "../../../../../constants";
import {
  getLinkedAccountFieldMappings,
  getFieldMappingMeta,
} from "../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";
import FieldMappingUpsellModal from "../../../../configuration/integrations/field-mappings/ConfigurationFieldMappingsUpsellModal";
import useProductRestrictions from "../../../../shared-components/hooks/useProductRestrictions";
import useAppContext from "../../../../context/useAppContext";
import FieldMappingSelectionModal from "./modal/FieldMappingSelectionModal";
import { MenuItem, Dropdown, Card } from "@merge-api/merge-javascript-shared";
import FieldMappingsExplainer from "./modal/field-mappings/fieldMappings.svg";
import RemoteFieldSearchBar from "./RemoteFieldsSearchBar";
import useCategoryCommonModels from "../hooks/useCategoryCommonModels";
import { FieldMappingsDocsButton } from "../../../../configuration/integrations/field-mappings/ConfigurationFieldMappingLinkedAccountTable";
import { Plus } from "lucide-react";

type FieldMappingsProps = {
  fieldMappingInstances: Array<FieldMappingInstance>;
  fieldMappingTargets: Array<FieldMappingTarget>;
  fieldMappingConfigurations: Array<FieldMappingCommonModelConfiguration> | null;
  overriddenCommonModelTargets: Array<OverriddenCommonModelTarget>;
  overriddenCommonModelInstances: Array<OverriddenCommonModelInstance>;
  linkedAccount: LinkedAccount;
  isLoading: boolean;
};

const FieldMappingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
`;

const FieldMappingsSubtitleText = styled.p`
  font-size: 14px;
  line-height: 24px;
`;

const MarginTopBottomWarningCard = styled(WarningCard)`
  margin: 0px;
`;

const MarginBottomDivContainerForTypeAhead = styled.div`
  margin-bottom: 16px;
`;

const MODAL_TOP_VALUE = "0px";

/**
 * Shows existing and available custom mappings for a linked account
 */
const FieldMappings = ({
  fieldMappingInstances,
  fieldMappingTargets,
  fieldMappingConfigurations,
  overriddenCommonModelTargets,
  overriddenCommonModelInstances,
  linkedAccount,
  isLoading,
}: FieldMappingsProps) => {
  // hooks
  const { productRestrictions } = useProductRestrictions();
  const { categoryCommonModels } = useCategoryCommonModels({ linkedAccount });

  // state
  const [customMappingMetaResponse, setCustomMappingMetaResponse] = useState<
    FieldMappingMetaResponse | undefined
  >();
  const [linkedAccountFieldMappingOptions, setLinkedAccountFieldMappingOptions] = useState<
    FieldMappingOptionsResponse | undefined
  >();
  const [showUpsellModal, setShowUpsellModal] = useState(
    !productRestrictions?.are_custom_fields_enabled,
  );
  const [selectedCommonModels, setSelectedCommonModels] = useState<Array<string>>([]);
  const [isFieldMappingCreationModalOpen, setIsFieldMappingCreationModalOpen] = useState(false);
  const [isCommonModelOverrideSelected, setIsCommonModelOverrideSelected] = useState(false);
  const [remoteFieldSelected, setRemoteFieldSelected] =
    useState<FieldMappingCreationAndEditDict | null>(null);

  // effects
  useEffect(() => {
    linkedAccount &&
      getFieldMappingMeta({
        linkedAccountID: linkedAccount.id,
        onFetch: (data) => {
          setCustomMappingMetaResponse(data);
        },
        onError: () => {},
      });
  }, [linkedAccount]);

  useEffect(() => {
    linkedAccount &&
      getLinkedAccountFieldMappings({
        linkedAccountID: linkedAccount.id,
        onFetch: (data) => {
          setLinkedAccountFieldMappingOptions(data);
        },
        onError: () => {},
      });
  }, [linkedAccount]);

  const fieldMappingsByCommonModel = [...fieldMappingInstances, ...fieldMappingTargets].reduce(
    (
      accumulatingObj: {
        [key: string]: Array<FieldMappingInstance | FieldMappingTarget>;
      },
      fieldMapping: FieldMappingInstance | FieldMappingTarget,
    ) => {
      const commonModelName = fieldMapping.common_model_name;
      accumulatingObj[commonModelName] = [
        ...(accumulatingObj[commonModelName] ?? []),
        fieldMapping,
      ];

      return accumulatingObj;
    },
    {},
  );

  const hasFieldMappingsLoaded = fieldMappingConfigurations !== null && !isLoading;

  const commonModelsToMappingConfigs = fieldMappingConfigurations
    ? fieldMappingConfigurations
        .sort((a, b) => a.common_model_name.localeCompare(b.common_model_name))
        .reduce(
          (
            accum: {
              [common_model_id: string]: FieldMappingCommonModelConfiguration;
            },
            mapping_target,
          ) => {
            accum[`${mapping_target.common_model_name}`] = mapping_target;
            return accum;
          },
          {},
        )
    : null;

  const filterCommonModels = (commonModelName: string) => {
    if (commonModelsToMappingConfigs === null) {
      return null;
    }

    return selectedCommonModels.length === 0
      ? Object.keys(commonModelsToMappingConfigs).includes(commonModelName)
      : selectedCommonModels.includes(commonModelName) &&
          Object.keys(commonModelsToMappingConfigs).includes(commonModelName);
  };

  if (
    linkedAccount.status === LinkedAccountStatuses.RELINK_NEEDED ||
    linkedAccount.status === LinkedAccountStatuses.INCOMPLETE
  ) {
    const warningText = `Field Mapping is not available when ${
      linkedAccount.status === LinkedAccountStatuses.RELINK_NEEDED
        ? "account relink is needed"
        : "the linked account is incomplete"
    }`;
    return (
      <FieldMappingContainer>
        <SectionHeaderWrapper
          className="mb-4"
          title="Field Mapping"
          titleHeading={TitleHeading.H1}
          headerRightHandContent={FieldMappingsDocsButton}
        />
        <>
          <MarginTopBottomWarningCard showsIcon>{warningText}</MarginTopBottomWarningCard>
        </>
      </FieldMappingContainer>
    );
  }

  const fieldMappingCards = hasFieldMappingsLoaded
    ? categoryCommonModels
        .filter(filterCommonModels)
        .filter((modelName) => {
          const fieldMappings = fieldMappingsByCommonModel[modelName] ?? [];
          const mappedTargets = fieldMappings.filter(
            (fieldMappingTarget: FieldMappingInstance | FieldMappingTarget) => {
              if ("field_traversal_path" in fieldMappingTarget) {
                return true;
              }

              return false;
            },
          );
          const filteredInstances = overriddenCommonModelInstances
            ? overriddenCommonModelInstances?.filter((ocmInstance) => {
                return ocmInstance.common_model_name == modelName;
              })
            : [];
          if (mappedTargets.length == 0 && filteredInstances.length == 0) {
            return false;
          }

          return true;
        })
        .map((modelName, index) => {
          return (
            <>
              <LinkedAccountCommonModelFieldMappingOverview
                key={index}
                commonModelName={modelName}
                linkedAccount={linkedAccount}
                fieldMappings={fieldMappingsByCommonModel[modelName] ?? []}
                fieldMappingConfig={commonModelsToMappingConfigs?.[modelName] ?? null}
                customMappingMetaResponse={customMappingMetaResponse}
                linkedAccountFieldMappingOptions={linkedAccountFieldMappingOptions}
                hasFieldMappingsLoaded={hasFieldMappingsLoaded}
                overriddenCommonModelInstances={overriddenCommonModelInstances?.filter(
                  (ocmInstance) => {
                    return ocmInstance.common_model_name == modelName;
                  },
                )}
              />
            </>
          );
        })
    : [];

  return (
    <>
      {!productRestrictions?.are_custom_fields_enabled && (
        <FieldMappingUpsellModal
          showUpsellModal={showUpsellModal}
          setShowUpsellModal={setShowUpsellModal}
          modalTopValue={MODAL_TOP_VALUE}
        />
      )}
      {isFieldMappingCreationModalOpen &&
        commonModelsToMappingConfigs &&
        customMappingMetaResponse && (
          <FieldMappingSelectionModal
            isOpen={isFieldMappingCreationModalOpen}
            onClose={() => {
              setIsFieldMappingCreationModalOpen(false);
              setIsCommonModelOverrideSelected(false);
              setRemoteFieldSelected(null);
            }}
            linkedAccount={linkedAccount}
            linkedAccountMappingInfo={customMappingMetaResponse}
            commonModelsForIntegration={categoryCommonModels}
            commonModelsToMappingConfigs={commonModelsToMappingConfigs}
            fieldMappingTargets={fieldMappingTargets}
            fieldMappingInstances={fieldMappingInstances}
            showCommonModelOverrideFlow={isCommonModelOverrideSelected}
            linkedAccountFieldMappingOptions={linkedAccountFieldMappingOptions}
            remoteFieldSelected={remoteFieldSelected}
          />
        )}
      <div className={showUpsellModal ? "opacity-50" : "opacity-100"}>
        <FieldMappingContainer>
          <SectionHeaderWrapper
            className="mb-4"
            title="Field Mapping"
            titleHeading={TitleHeading.H3}
            subtitle={
              <>
                <FieldMappingsSubtitleText className="text-base">
                  Map third-party <strong>remote fields</strong> to{" "}
                  <strong>new target fields</strong> or <strong>override existing fields</strong> on
                  your Common Models for this Linked Account. Go to{" "}
                  <a
                    href="/configuration/field-mappings/target-fields"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Advanced Configuration
                  </a>{" "}
                  to add a default integration-wide-mapping.{" "}
                  <a
                    href="https://docs.merge.dev/supplemental-data/field-mappings/overview/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                  .
                </FieldMappingsSubtitleText>
              </>
            }
          />
          <div className="flex">
            <div className="flex-1">
              <>
                <RemoteFieldSearchBar
                  linkedAccountFieldMappingOptions={linkedAccountFieldMappingOptions}
                  setRemoteFieldSelected={setRemoteFieldSelected}
                  remoteFieldSelected={remoteFieldSelected}
                  fieldMappingConfigurations={fieldMappingConfigurations}
                />
              </>
            </div>
            <div
              className={`ml-4 ${
                !customMappingMetaResponse ? "opacity-35 pointer-events-none" : ""
              }`}
            >
              <Dropdown
                menuPlacement="bottom-end"
                ButtonProps={{
                  children: "Mapping",
                  leftIcon: <Plus size={16} />,
                  disabled: true,
                }}
              >
                <>
                  <MenuItem
                    onClick={() => {
                      setIsFieldMappingCreationModalOpen(true);
                    }}
                  >
                    Map a new target field
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setIsCommonModelOverrideSelected(true);
                      setIsFieldMappingCreationModalOpen(true);
                    }}
                  >
                    Override an existing Merge field
                  </MenuItem>
                </>
              </Dropdown>
            </div>
          </div>
          <div className="flex flex-col gap-8 mt-4 mb-6">
            {hasFieldMappingsLoaded && fieldMappingCards.length == 0 && (
              <Card>
                <div className="px-6 border-b border-gray-20 pb-[18px] pt-5 flex items-center">
                  <div className="text-lg font-semibold">Learn about Merge Field Mapping</div>
                </div>
                <div className="w-100">
                  <img className="pr-6 pl-6 pb-5 pt-6 w-100" src={FieldMappingsExplainer}></img>
                </div>
              </Card>
            )}

            {hasFieldMappingsLoaded
              ? fieldMappingCards
              : categoryCommonModels.map((modelName, index) => (
                  <>
                    <LinkedAccountCommonModelFieldMappingOverview
                      key={index}
                      commonModelName={modelName}
                      linkedAccount={linkedAccount}
                      fieldMappings={fieldMappingsByCommonModel[modelName] ?? []}
                      fieldMappingConfig={commonModelsToMappingConfigs?.[modelName] ?? null}
                      customMappingMetaResponse={customMappingMetaResponse}
                      hasFieldMappingsLoaded={hasFieldMappingsLoaded}
                      overriddenCommonModelInstances={[]}
                      linkedAccountFieldMappingOptions={linkedAccountFieldMappingOptions}
                    />
                  </>
                ))}
          </div>
        </FieldMappingContainer>
      </div>
    </>
  );
};

export default FieldMappings;
