import {
  IntegrationCustomization,
  OrganizationCustomization,
} from "../context/CustomizationContext";

export const themeCustomizationKeys = [
  "primary_button_color",
  "primary_button_text_color",
  "font",
  "logo",
];
export const featuresCustomizationKeys = ["show_selective_sync_screen", "show_scopes_screen"];
export const configurationCustomizationKeys = [
  "auto_enable_new_integrations",
  "preexisting_production_linked_account_preference",
];

export const customNotEquals = (
  value1: string | boolean | null | undefined,
  value2: string | boolean | null | undefined,
): boolean => {
  if (value1 == null && value2 == null) {
    return false;
  } else if ((value1 == null && value2 === "") || (value2 == null && value1 === "")) {
    return false;
  } else {
    return value1 !== value2;
  }
};

export const getOrganizationCustomizationsCounts = (
  keyList: string[],
  organizationCustomization: OrganizationCustomization | null,
  originalOrganizationCustomization: OrganizationCustomization | null,
) => {
  if (organizationCustomization == null && originalOrganizationCustomization == null) {
    return 0;
  } else if (originalOrganizationCustomization == null || organizationCustomization == null) {
    return keyList.length;
  } else {
    let count = 0;
    keyList.forEach((k) => {
      const typedKey = k as keyof OrganizationCustomization;
      if (
        customNotEquals(
          originalOrganizationCustomization[typedKey],
          organizationCustomization[typedKey],
        )
      ) {
        count++;
      }
    });
    return count;
  }
};

export const getIntegrationCustomizationCounts = (
  integrationCustomizations: IntegrationCustomization[] | null,
  originalIntegrationCustomizations: IntegrationCustomization[] | null,
) => {
  if (integrationCustomizations == null && originalIntegrationCustomizations == null) {
    return 0;
  } else if (originalIntegrationCustomizations == null || integrationCustomizations == null) {
    const filteredIntegrationCustomizations = integrationCustomizations?.filter(
      (integObj) =>
        customNotEquals(integObj.help_center_authentication_message, "") ||
        customNotEquals(integObj.help_center_authentication_url, "") ||
        customNotEquals(integObj.help_center_validation_url, ""),
    );
    return Math.max(
      originalIntegrationCustomizations?.length ?? 0,
      filteredIntegrationCustomizations?.length ?? 0,
    );
  } else {
    let count = 0;
    integrationCustomizations.forEach((integrationCustomObj) => {
      const integID = integrationCustomObj.integration;
      const originalValue = originalIntegrationCustomizations?.find(
        (origIntegrationCustomObj) =>
          origIntegrationCustomObj.integration === integID &&
          origIntegrationCustomObj.category === integrationCustomObj.category,
      );
      if (originalValue == null) {
        if (
          customNotEquals(integrationCustomObj?.help_center_authentication_message, "") ||
          customNotEquals(integrationCustomObj?.help_center_authentication_url, "") ||
          customNotEquals(integrationCustomObj?.help_center_validation_url, "")
        ) {
          count++;
        }
      } else {
        if (
          customNotEquals(
            originalValue.help_center_authentication_message,
            integrationCustomObj.help_center_authentication_message,
          ) ||
          customNotEquals(
            originalValue.help_center_authentication_url,
            integrationCustomObj.help_center_authentication_url,
          ) ||
          customNotEquals(
            originalValue.help_center_validation_url,
            integrationCustomObj.help_center_validation_url,
          ) ||
          customNotEquals(originalValue.status, integrationCustomObj.status)
        ) {
          count++;
        }
      }
    });
    return count;
  }
};

export const webSafeFonts = [
  "Merge default (Inter)",
  "Arial",
  "Arial Black",
  "Comic Sans MS",
  "Courier New",
  "Georgia",
  "Helvetica",
  "Lucida Console",
  "Lucida Sans",
  "Palatino",
  "Roboto",
  "Tahoma",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
];
