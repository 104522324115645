import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { Plus } from "lucide-react";
import { useHistory } from "react-router-dom";
import { Card, Col, Collapse, Row, Table } from "react-bootstrap";
import { PaginatedAPIResponse } from "../../../api-client/APIClient";
import { Text, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { UserType } from "../../../models/Entities";
import { CheckCircle2 } from "lucide-react";
import { palette } from "../../../styles/theme";
import useAppContext from "../../context/useAppContext";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import { MergeChargeStatusBadge } from "../../shared-components/MergeBadges";
import { CardHeaderTitle, SmallTextMutedParagraph } from "../../shared-components/MergeText";
import PaginationFooter from "../../shared-components/PaginationFooter";
import Divider from "../../shared-components/Divider";
import RotatingChevronRight from "../../shared-components/RotatingChevronRight";
import SpinnerButton from "../../shared-components/SpinnerButton";
import { showErrorToast, showSuccessToast } from "../../shared-components/Toasts";
import {
  getPaymentMethods,
  getInvoices,
  updateDefaultPaymentMethod,
  getInvoicePdf,
} from "./BillingAPIClient";
import { GET_IN_TOUCH_WITH_TRACKING_PATH } from "../../../router/RouterUtils";
import {
  BankAccount,
  CreditCard,
  MergeInvoice,
  PaymentMethod,
  BillingPlanTier,
  PAYABLE_INVOICE_STATUSES,
  FREE_PLAN_LINKED_ACCOUNT_LIMIT,
  LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT,
} from "./BillingModels";
import {
  formatCentsNearestCent,
  getBillingPlanTitle,
  isBillingPlanFreePlan,
  isBillingPlanLaunchPlan,
  parseCategoryChargesFromInvoice,
  /**
   * Shared CSS elements between old and new Billing Pages.
   */
  CurrentModelUsageCard,
  CurrentModelUsageCardBody,
  /**
   * Shared Functions between old and new Billing Pages.
   */
  getFormattedDateFromISO,
  getThirtyDaysLater,
  getDefaultPaymentMethod,
} from "./BillingUtils";
import AddPaymentMethodModal from "./components/AddPaymentMethodModal";
import PaymentMethodRow from "./components/BillingPaymentMethodRow";
import CommonModelChargeTable from "./components/CommonModelChargeTable";
import PayInvoiceModal from "./components/PayInvoiceModal";
import AddBankAccountModal from "./components/AddBankAccountModal";
import DeletePaymentMethodModal from "./components/DeletePaymentMethodModal";
import InvoiceBreakdown from "./components/InvoiceBreakdown";
import {
  MERGE_BILLING_SELF_SERVE_HELP_ARTICLE_PATH,
  navigateToAddPaymentPage,
} from "../../../router/RouterUtils";
import DeprecatedH2 from "../../../deprecated/DeprecatedH2";
import DeprecatedH6 from "../../../deprecated/DeprecatedH6";
import { Button, Tooltip, Link } from "@merge-api/merge-javascript-shared";
import styled from "styled-components";
import useLoadOrganizationBillingPlan from "./hooks/useLoadOrganizationBillingPlan";

function OldBillingPage() {
  const [showingAddPaymentMethodModal, setShowingAddPaymentMethodModal] = useState(false);
  const [paymentMethodDeletionTarget, setPaymentMethodDeletionTarget] = useState<
    PaymentMethod | undefined
  >();
  const [payInvoiceModalTarget, setPayInvoiceModalTarget] = useState<MergeInvoice | undefined>();
  const [paymentMethods, setPaymentMethods] = useState<null | PaymentMethod[]>(null);
  const [isLoadingCreditCards, setIsLoadingCreditCards] = useState(false);
  const [invoices, setInvoices] = useState<null | MergeInvoice[]>(null);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [expandedInvoice, setExpandedInvoice] = useState<null | MergeInvoice>(null);
  const [defaultPaymentMethodBeingSet, setDefaultPaymentMethodBeingSet] =
    useState<null | PaymentMethod>(null);
  const [previousPageURL, setPreviousPageURL] = useState<string | null>(null);
  const [nextPageURL, setNextPageURL] = useState<string | null>(null);
  const history = useHistory();

  // hooks
  const { user } = useAppContext();
  const { organizationBillingPlan, setOrganizationBillingPlan } = useLoadOrganizationBillingPlan();
  const freeAccountGated = user?.is_free_account_gating_enabled ?? false;
  const StyledP = styled.p`
    @media (min-width: 992px) {
      padding-bottom: 0;
    }
  `;

  useEffect(() => {
    if (user.type === UserType.admin_with_billing) {
      fetchPaymentMethods(false);
      fetchInvoices();
    }
  }, []);

  const organizePaymentMethods = (
    bankAccounts: BankAccount[],
    creditCards: CreditCard[],
  ): PaymentMethod[] => {
    const arr: PaymentMethod[] = [...bankAccounts, ...creditCards]
      .sort((a, b) => a.created_at.localeCompare(b.created_at))
      .sort((a, _) => (a.is_default_method ? -1 : 1));
    return arr;
  };

  const fetchPaymentMethods = (forceRefreshFromStripe: boolean) => {
    setIsLoadingCreditCards(true);
    getPaymentMethods({
      forceRefreshStripe: forceRefreshFromStripe,
      onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => {
        const paymentMethods = organizePaymentMethods(data.bank_accounts, data.credit_cards);
        setPaymentMethods(paymentMethods);
        setIsLoadingCreditCards(false);
      },
    });
  };

  const setDefaultPaymentMethod = (paymentMethod: PaymentMethod) => {
    setDefaultPaymentMethodBeingSet(paymentMethod);
    updateDefaultPaymentMethod({
      paymentMethod: paymentMethod,
      onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => {
        const paymentMethods = organizePaymentMethods(data.bank_accounts, data.credit_cards);
        setPaymentMethods(paymentMethods);
        setDefaultPaymentMethodBeingSet(null);
        showSuccessToast("Successfully updated your default payment method!");
      },
    });
  };

  const fetchInvoices = (cursorURL?: string) => {
    setIsLoadingInvoices(true);
    getInvoices({
      cursorURL,
      onSuccess: (data: PaginatedAPIResponse<MergeInvoice>) => {
        setInvoices(data.results);
        setPreviousPageURL(data.previous);
        setNextPageURL(data.next);
        setIsLoadingInvoices(false);
      },
      onError: () => {
        setInvoices(null);
        setPreviousPageURL(null);
        setNextPageURL(null);
        showErrorToast("Failed to load invoices, please try again later or contact support");
        setIsLoadingInvoices(false);
      },
    });
  };

  const formatInvoiceDetails = (
    invoice: MergeInvoice,
  ): { period: string; dueDate: string; amount: string } => {
    if (invoice.created_post_billing_overhaul) {
      return {
        period: `${getFormattedDateFromISO(invoice.issue_date)} - ${getFormattedDateFromISO(
          invoice.due_date,
        )}`,
        dueDate: getFormattedDateFromISO(invoice.due_date),
        amount: invoice.formatted_total,
      };
    } else {
      return {
        period: `${getFormattedDateFromISO(
          invoice.charge_period_start_time,
        )} - ${getFormattedDateFromISO(invoice.charge_period_end_time)}`,
        dueDate: getThirtyDaysLater(invoice.charge_period_start_time),
        amount: formatCentsNearestCent(invoice.total_charge_amount),
      };
    }
  };

  const fetchInvoicePdf = (invoice: MergeInvoice) => {
    const win = window.open("about:blank", "_blank");
    if (win) {
      getInvoicePdf({
        invoiceId: invoice.id,
        onSuccess: (data: { invoice_url: string }) => {
          win.location.href = data.invoice_url;
          win.focus();
        },
        onError: () => {
          showErrorToast("Failed to load invoice PDF");
          win.close();
        },
      });
    } else {
      showErrorToast("Failed to load invoice PDF");
    }
  };
  const isFreePlan = isBillingPlanFreePlan(organizationBillingPlan?.billing_plan);
  const isLaunchPlan = isBillingPlanLaunchPlan(organizationBillingPlan?.billing_plan);
  const averageProductionLinkedAccountCount =
    user.organization.average_production_linked_account_count || 0;
  const testLinkedAccountCount = user.organization.test_linked_account_count || 0;

  function getPlanSubtitle() {
    switch (organizationBillingPlan?.billing_plan?.plan_tier) {
      case BillingPlanTier.BILLING_PLAN_TIER_FREE:
        return `You can only connect 3 production and 3 test Linked Accounts on this plan. Any additional accounts you connect will not be synced. Upgrade your plan to link more accounts.`;
      case BillingPlanTier.BILLING_PLAN_TIER_LAUNCH:
        return (
          <>
            {`Up to ${LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT} production Linked Accounts for
            $650/month. You will automatically incur a $65/month prorated fee for every production
            Linked Account past ${LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT}. `}
            <a href={MERGE_BILLING_SELF_SERVE_HELP_ARTICLE_PATH} rel="noreferrer" target="_blank">
              Learn more
            </a>
            .
          </>
        );
      case BillingPlanTier.BILLING_PLAN_TIER_GROW:
        return "Premium";
      case BillingPlanTier.BILLING_PLAN_TIER_EXPAND:
        return "Enterprise";
    }
    return "";
  }

  return (
    <>
      {isFreePlan || isLaunchPlan ? (
        <AddPaymentMethodModal
          show={showingAddPaymentMethodModal}
          //remove after testing :avirathtib
          freeAccountGated={freeAccountGated}
          onHide={() => {
            setShowingAddPaymentMethodModal(false);
            fetchPaymentMethods(true);
          }}
        />
      ) : (
        <AddBankAccountModal
          show={showingAddPaymentMethodModal}
          //remove after testing :avirathtib
          freeAccountGated={freeAccountGated}
          onHide={() => {
            setShowingAddPaymentMethodModal(false);
            fetchPaymentMethods(true);
          }}
        />
      )}
      <PayInvoiceModal
        onHide={() => {
          setPayInvoiceModalTarget(undefined);
          fetchInvoices();
        }}
        invoice={payInvoiceModalTarget}
        defaultPaymentMethod={getDefaultPaymentMethod(paymentMethods)}
      />
      <DeletePaymentMethodModal
        paymentMethod={paymentMethodDeletionTarget}
        onHide={() => {
          setPaymentMethodDeletionTarget(undefined);
          fetchPaymentMethods(true);
        }}
      />
      <Card>
        <Card.Body>
          <Row className="align-items-center">
            <Col xl="12" className="pr-5">
              <Text variant="md" className="text-gray-70 mb-2">
                Current plan
              </Text>
              <div className="mb-2">
                <Text variant="h3" className="font-semibold">
                  {organizationBillingPlan ? getBillingPlanTitle(organizationBillingPlan) : "-"}
                </Text>
              </div>
              <div>
                <Text variant="md" className="mb-0 text-gray-70">
                  {getPlanSubtitle()}
                </Text>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {freeAccountGated ? (
        <>
          <Row className="mt-4">
            <Col lg="4" className="flex">
              <Card className="flex-fill flex flex-column">
                <Card.Body className="text-center px-5 flex flex-column">
                  <div>
                    <Text variant="h3">Launch</Text>
                    <p className="gray">
                      For development teams that want self-serve integrations in production
                    </p>
                    <Divider />
                    <ul className="text-left list-unstyled">
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">
                          Get 3 production Linked Accounts for free
                        </Text>
                      </li>
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">
                          Pay $650/month for up to 10 total production Linked Accounts
                        </Text>
                      </li>
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">
                          Pay $65/month prorated fee for every production Linked Account past 10
                        </Text>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-auto mt-10">
                    <Button
                      variant={ButtonVariant.SecondaryBlue}
                      fullWidth
                      onClick={() => {
                        navigateToAddPaymentPage(history, { fromUpgradeButton: true });
                      }}
                    >
                      Upgrade to Launch
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg="4" className="flex">
              <Card className="flex-fill flex flex-column">
                <Card.Body className="text-center px-5 flex flex-column">
                  <div>
                    <Text className="" variant="h3">
                      Professional
                    </Text>
                    <p className="gray">
                      For companies that want to power deep integration use cases
                    </p>
                    <Divider />
                    <ul className="text-left list-unstyled mb-10">
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">Access custom fields</Text>
                      </li>
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">
                          Field-level scopes for enhanced data minimization
                        </Text>
                      </li>
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">Custom sync frequencies</Text>
                      </li>
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">
                          60 day access to developer sandboxes for third-party platforms
                        </Text>
                      </li>
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">Access to go-live support packages</Text>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-auto">
                    <Link href={GET_IN_TOUCH_WITH_TRACKING_PATH} target="_blank">
                      <a className="w-full">
                        <Button variant={ButtonVariant.SecondaryBlue} fullWidth>
                          <span className="text-blue-40 font-semibold">Contact us</span>
                        </Button>
                      </a>
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col lg="4" className="flex">
              <Card className="flex-fill flex flex-column">
                <Card.Body className="text-center px-5 flex flex-column">
                  <div>
                    <Text className="" variant="h3">
                      Enterprise
                    </Text>
                    <p className="gray">
                      For large companies that want white-glove security, support, and
                      infrastructure
                    </p>
                    <Divider />
                    <ul className="text-left list-unstyled mb-10">
                      <li className="d-flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">
                          Enterprise security features like Audit Trail
                        </Text>
                      </li>
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">Premium go-live support</Text>
                      </li>
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">60 day access to sandboxes</Text>
                      </li>
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">
                          Dedicated account manager with shared Slack channel
                        </Text>
                      </li>
                      <li className="flex mb-4">
                        <CheckCircle2 size={16} className="mr-2 flex-shrink-0 mt-1" />
                        <Text className="flex-grow-1">Support and uptime SLAs</Text>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-auto">
                    <Link href={GET_IN_TOUCH_WITH_TRACKING_PATH} target="_blank">
                      <a className="w-full">
                        <Button variant={ButtonVariant.SecondaryBlue} fullWidth>
                          <span className="text-blue-40 font-semibold">Contact us</span>
                        </Button>
                      </a>
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {invoices ? (
            <>
              <Card>
                <Card.Header>
                  <Row className="align-items-center">
                    <Col>
                      <CardHeaderTitle>Payment methods</CardHeaderTitle>
                    </Col>
                    <Col className="col-auto">
                      {user.type === UserType.admin_with_billing ? (
                        <Button
                          onClick={() => setShowingAddPaymentMethodModal(true)}
                          leftIcon={<Plus size={12} />}
                          variant={ButtonVariant.TertiaryWhite}
                        >
                          Add payment method
                        </Button>
                      ) : (
                        <Tooltip title="You must be an admin to add payment methods">
                          <Button
                            variant={ButtonVariant.TertiaryWhite}
                            leftIcon={<Plus size={12} />}
                          >
                            Add payment method
                          </Button>
                        </Tooltip>
                      )}
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <div className="list-group list-group-flush my-n3">
                    {user.type !== UserType.admin_with_billing ? (
                      <EmptyStateWrapper title="You must be an admin to view payment methods" />
                    ) : isLoadingCreditCards ? (
                      <EmptyStateWrapper isSpinner />
                    ) : paymentMethods && paymentMethods?.length > 0 ? (
                      paymentMethods.map((paymentMethod) => (
                        <PaymentMethodRow
                          key={paymentMethod.id}
                          paymentMethod={paymentMethod}
                          billingPlan={organizationBillingPlan}
                          updateBillingPlan={setOrganizationBillingPlan}
                          onClickSetDefault={() => setDefaultPaymentMethod(paymentMethod)}
                          settingDefault={defaultPaymentMethodBeingSet === paymentMethod}
                          setPaymentMethodDeletionTarget={setPaymentMethodDeletionTarget}
                        />
                      ))
                    ) : (
                      <EmptyStateWrapper title="No payment methods" />
                    )}
                  </div>
                </Card.Body>
              </Card>

              <Card>
                <Card.Header>
                  <CardHeaderTitle>Invoices</CardHeaderTitle>
                </Card.Header>
                <div className="table-responsive rounded-table-corner">
                  <Table size="sm" className="table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>Invoice date</th>
                        <th>Billing period</th>
                        <th>Amount</th>
                        <th>Due date</th>
                        <th>Status</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody className="font-size-base">
                      {user.type !== UserType.admin_with_billing ? (
                        <tr>
                          <td colSpan={6}>
                            <EmptyStateWrapper
                              isTable
                              title="You must be an admin to view invoices"
                            />
                          </td>
                        </tr>
                      ) : isLoadingInvoices ? (
                        <tr>
                          <td colSpan={6}>
                            <EmptyStateWrapper isTable isSpinner />
                          </td>
                        </tr>
                      ) : isEmpty(invoices) ? (
                        <tr>
                          <td colSpan={6}>
                            <EmptyStateWrapper isTable title="No invoices" />
                          </td>
                        </tr>
                      ) : (
                        invoices.map((invoice: MergeInvoice) => {
                          const { period, dueDate, amount } = formatInvoiceDetails(invoice);
                          return (
                            <React.Fragment key={invoice.id}>
                              <tr
                                className={classNames(
                                  "table-link",
                                  expandedInvoice?.id === invoice.id ? "bg-lighter" : "",
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setExpandedInvoice(
                                    expandedInvoice?.id === invoice.id ? null : invoice,
                                  );
                                }}
                                style={{ borderBottomWidth: 0 }}
                              >
                                <td>
                                  <strong>
                                    {getFormattedDateFromISO(invoice.charge_period_start_time)}
                                  </strong>
                                </td>
                                <td className="text-gray-50">{period}</td>
                                <td className="text-gray-50">{amount}</td>
                                <td className="text-gray-50">{dueDate}</td>
                                <td className="text-gray-50">
                                  <MergeChargeStatusBadge status={invoice.status} />
                                </td>
                                <td className="d-flex justify-content-end">
                                  <div className="d-flex">
                                    {PAYABLE_INVOICE_STATUSES.includes(invoice.status) && (
                                      <SpinnerButton
                                        text="Pay now"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setPayInvoiceModalTarget(invoice);
                                        }}
                                        isLoading={false}
                                        className="btn-outline-secondary btn-sm"
                                        type="button"
                                      />
                                    )}
                                    {invoice.created_post_billing_overhaul && (
                                      <Button
                                        variant={ButtonVariant.IconOnly}
                                        className="ml-3"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          fetchInvoicePdf(invoice);
                                        }}
                                      >
                                        <i className="fe fe-file" />
                                      </Button>
                                    )}
                                  </div>
                                  <Button
                                    className="ml-3"
                                    variant={ButtonVariant.IconOnly}
                                    onClick={() => setExpandedInvoice(invoice)}
                                  >
                                    <RotatingChevronRight
                                      isRotatedDown={invoice.id === expandedInvoice?.id}
                                    />
                                  </Button>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={6}
                                  className={classNames(
                                    "py-0 bg-lighter",
                                    expandedInvoice?.id === invoice.id
                                      ? ""
                                      : "linked-account-table-row-border",
                                  )}
                                  style={{
                                    borderTop: "0px",
                                    borderBottom: `1px solid ${palette.border}`,
                                  }}
                                >
                                  <Collapse in={invoice.id === expandedInvoice?.id}>
                                    <div>
                                      <tr></tr>
                                      {invoice.created_post_billing_overhaul ? (
                                        <InvoiceBreakdown invoice={invoice} />
                                      ) : (
                                        <CommonModelChargeTable
                                          keyPrefix={`${invoice.id}-`}
                                          organizationBillingPlan={organizationBillingPlan}
                                          commonModelCharges={parseCategoryChargesFromInvoice(
                                            invoice,
                                          )}
                                          isInvoice
                                          isLaunchPlan
                                        />
                                      )}
                                    </div>
                                  </Collapse>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card>
              {(previousPageURL || nextPageURL) && (
                <PaginationFooter
                  hasPrevious={!!previousPageURL}
                  hasNext={!!nextPageURL}
                  onPreviousClick={() => {
                    fetchInvoices(previousPageURL ?? undefined);
                  }}
                  onNextClick={() => {
                    fetchInvoices(nextPageURL ?? undefined);
                  }}
                />
              )}
            </>
          ) : (
            <div></div>
          )}
        </>
      )}

      <SmallTextMutedParagraph className="text-center mb-9">
        Tired of painless integrations?{" "}
        <a href="mailto:hello@merge.dev" className="red">
          Contact us to cancel your plan
        </a>
      </SmallTextMutedParagraph>
    </>
  );
}

export default OldBillingPage;
