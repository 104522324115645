import React, { useMemo } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { OrganizationConfigStatusField } from "../../../models/Entities";
import { snakeCaseToSpacedCapitalized } from "../../../services";
import { spectrum } from "../../../styles/theme";
import ClickableContainer from "../../shared-components/ClickableContainer";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";
import { Tooltip } from "@merge-api/merge-javascript-shared";

export interface Props {
  /**
   * The field we're showing in this row
   */
  fieldConfigStatus: OrganizationConfigStatusField;

  /**
   * Which integration this corresponds to
   */
  integrationName: string;

  /**
   * First row has some different UI (namely, no upper border)
   */
  isFirstRow: boolean;

  /**
   * Id for the overall integration's settings
   */
  organizationIntegrationSettingsId: string;

  /**
   * A function that opens UI to modify the field itself
   */
  modifyField: (field: OrganizationConfigStatusField) => void;
}

// Items are right aligned on mobile only
const RightAlignedCol = styled(Col)`
  display: flex;
  align-content: center;
  justify-content: flex-end;
  @media (max-width: 767.96px) {
    display: initial;
  }
`;

const ConfigurationParameterContainer = styled(Card.Body)<{
  $isFirstRow: boolean;
}>`
  ${({ $isFirstRow }) =>
    $isFirstRow
      ? css`
          padding: 4px 0 10px 0;
        `
      : css`
          padding: 10px 0;
        `};
  margin-left: 40px;
  margin-right: 20px;
`;

const ParameterName = styled(DeprecatedH4)`
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  color: ${spectrum.gray60};
`;

const Status = styled.span`
  font-size: 13px;
  line-height: 24px;
`;

const EditButton = styled.span`
  font-size: 12px;
  line-height: 16px;
`;

/**
 * Shows a single config parameter for the settings for a particular integration.
 * Has the name of the parameter, some data about it, and allows editing it in a
 * modal.
 */
const IntegrationSettingsConfigParameterRow = ({
  fieldConfigStatus,
  integrationName,
  organizationIntegrationSettingsId,
  isFirstRow,
  modifyField,
}: Props) => {
  const { field_name: fieldName, description, completed: isCompleted } = fieldConfigStatus;
  const isOptional = !fieldConfigStatus.required;
  const editField = () => modifyField(fieldConfigStatus);

  const accordionKey = `collapse-${organizationIntegrationSettingsId}-${fieldName}`;
  const humanFormattedFieldName =
    snakeCaseToSpacedCapitalized(fieldName) + (isOptional ? " (Optional)" : "");

  return (
    <Accordion.Collapse eventKey="0" key={accordionKey} className="bg-light-blue">
      <ConfigurationParameterContainer
        $isFirstRow={isFirstRow}
        className={isFirstRow ? "" : "border-top"}
      >
        <Row>
          <Col sm={12} md={9}>
            <Tooltip
              title={
                <>
                  {description}
                  <br />
                  <br />
                  <i>
                    This field is required. {integrationName} will not appear in the linking flow
                    until you set a value.
                  </i>
                </>
              }
            >
              <ParameterName className="my-0 font-medium">
                {humanFormattedFieldName}
                <span
                  className={`ml-2 small fe fe-${
                    isCompleted
                      ? "check green"
                      : isOptional
                      ? "alert-triangle yellow"
                      : "alert-circle red"
                  }`}
                />
              </ParameterName>
            </Tooltip>
          </Col>
          <RightAlignedCol sm={12} md={3}>
            <Status className="text-gray-50">{isCompleted ? "Complete" : "None"}</Status>
            <ClickableContainer onClick={editField}>
              <EditButton className="ml-3 blue-text font-medium">Edit</EditButton>
            </ClickableContainer>
          </RightAlignedCol>
        </Row>
      </ConfigurationParameterContainer>
    </Accordion.Collapse>
  );
};

export default IntegrationSettingsConfigParameterRow;
