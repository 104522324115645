import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, Table } from "react-bootstrap";
import DottedOutlineTextCard from "../../shared-components/DottedOutlineTextCard";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { showErrorToast, showSuccessToast } from "../../shared-components/Toasts";
import { DOCS_AUTHENTICATION_PATH, TEST_LINKED_ACCOUNTS_PATH } from "../../../router/RouterUtils";
import styled from "styled-components";
import { Button, ButtonVariant, Dialog, TextField } from "@merge-api/merge-javascript-shared";
import { Plus, Trash } from "lucide-react";
import { SectionHeaderWrapper, TitleHeading } from "../../shared-components/MergeLayouts";
import { RefreshCw } from "lucide-react";
import IconGrayTextCard from "../../shared-components/IconGrayTextCard";
import { formatDate } from "../../../models/Helpers";
import PortalPageHeaderWrapper from "../../portal/PortalPageHeaderWrapper";

type APIKeyInfo = {
  id: string;
  key_type: string;
  created_at: string;
};

type APIKey = APIKeyInfo & {
  key: string;
};

type RemoteAPIKeyInfo = APIKeyInfo & {
  name: string;
  is_test: boolean;
};

type APIKeyData = [RemoteAPIKeyInfo];

const CustomHeader = styled.div`
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
`;

const RemoteKeyName = styled.div`
  font-size: 14px;
  line-height: 24px;
`;

const StyledTable = styled.div`
  border-width: 0.5px;
  border-style: solid;
  font-size: 12px;
  border-color: var(--lm-gray-20, #dce2ea);

  && tr:first-child {
    border-top-width: 0;
  }
  && tr {
    font-size: 12px;
    line-height: 22px;
    border-style: solid;
    border-top-width: 0.5px;
    border-color: var(--lm-gray-20, #dce2ea);
  }
  && td {
    border: 0;
  }
`;

const ConfigurationAPIKeysPage = () => {
  const [remoteAPIKeyData, setRemoteAPIKeyData] = useState<APIKeyData | undefined>();
  const [keyBeingRegenerated, setKeyBeingRegenerated] = useState<"production" | undefined>();
  const [regenerateConfirmInputText, setRegenerateConfirmInputText] = useState("");
  const [isLoadingRegenerateKey, setIsLoadingRegenerateKey] = useState(false);
  const [isLoadingNewRemoteKey, setIsLoadingNewRemoteKey] = useState(false);
  const [isLoadingDeleteKey, setIsLoadingDeleteKey] = useState(false);
  const [generatedAPIKey, setGeneratedAPIKey] = useState<APIKey | undefined>();
  const [keyBeingDeleted, setKeyBeingDeleted] = useState<APIKeyInfo | undefined>();
  const [isShowingAddRemoteKeyModal, setIsShowingAddRemoteKeyModal] = useState(false);
  const [isShowingConfirmDeleteModal, setIsShowingConfirmDeleteModal] = useState(false);
  const [newRemoteKeyName, setNewRemoteKeyName] = useState("");
  const [newRemoteKeyIsTest, setNewRemoteKeyIsTest] = useState(false);
  const [newRemoteKeyIsProd, setNewRemoteKeyIsProd] = useState(false);

  useEffect(() => {
    fetchWithAuth({
      path: "/integrations/remote-keys",
      method: "GET",
      onResponse: (data) => {
        setRemoteAPIKeyData(data);
      },
    });
  }, []);

  const regenerateAPIKey = useCallback(() => {
    setIsLoadingRegenerateKey(true);
    setNewRemoteKeyIsProd(true);
    fetchWithAuth({
      path: `/integrations/keys/regenerate-production-key`,
      method: "POST",
      onResponse: (generatedProdAPIKey: APIKey) => {
        setGeneratedAPIKey(generatedProdAPIKey);
        setIsLoadingRegenerateKey(false);
        setNewRemoteKeyName("");
      },
      onError: () => {
        showErrorToast(
          `Failed to regenerate your organization's ${keyBeingRegenerated} key. Please try again.`,
        );
        setIsLoadingRegenerateKey(false);
        setKeyBeingRegenerated(undefined);
        setNewRemoteKeyName("");
      },
    });
  }, [keyBeingRegenerated, remoteAPIKeyData]);

  const createRemoteKey = useCallback(
    (e: any) => {
      e.preventDefault();
      setIsLoadingNewRemoteKey(true);
      fetchWithAuth({
        path: "/integrations/remote-key",
        method: "POST",
        body: { name: newRemoteKeyName, is_test: newRemoteKeyIsTest },
        onResponse: (generatedRemoteAPIKey: APIKey) => {
          setGeneratedAPIKey(generatedRemoteAPIKey);
          fetchWithAuth({
            path: "/integrations/remote-keys",
            method: "GET",
            onResponse: (data) => {
              setIsLoadingNewRemoteKey(false);
              setRemoteAPIKeyData(data);
              setNewRemoteKeyName("");
            },
          });
        },
        onError: (response: Response | undefined) => {
          // We catch the specific issue where we get a 409 Conflict error
          // and display a specific error toast for it.
          if (response && response.status === 409) {
            showErrorToast(
              `A key named ${newRemoteKeyName} already exists. Please select a different key name.`,
            );
          } else {
            showErrorToast(
              `Failed to generate your organization's ${newRemoteKeyName} key. Please try again.`,
            );
          }
          setIsShowingAddRemoteKeyModal(false);
          setIsLoadingNewRemoteKey(false);
        },
      });
    },
    [newRemoteKeyName, newRemoteKeyIsTest],
  );

  const emptyState = (isTest: boolean) => {
    return (
      <tr key="remote-key-0">
        <td>
          <RemoteKeyName className="pt-0.5 pb-0.5">
            You have no {isTest ? "test" : "remote production"} access keys. Add an access key by
            clicking the button below.
          </RemoteKeyName>
        </td>
      </tr>
    );
  };

  const getKeyRows = (isTest: boolean) => {
    const keyData = remoteAPIKeyData?.filter((remote_key) => remote_key.is_test == isTest) ?? [];
    if (keyData.length > 0) {
      return keyData.map((remote_key, index) => {
        return (
          <tr key={`remote-key-${index}`}>
            <td style={{ width: "80%" }} className="font-semibold">
              <RemoteKeyName>{remote_key.name}</RemoteKeyName>
            </td>
            <td>
              <>
                <span className="text-gray-50 mr-2">Created</span>
                {formatDate(remote_key.created_at, "MMM DD, YYYY", false)}
              </>
            </td>
            <td>
              <Button
                variant={ButtonVariant.IconOnly}
                size="sm"
                onClick={() => {
                  setKeyBeingDeleted(remote_key);
                  setIsShowingConfirmDeleteModal(true);
                }}
              >
                <Trash size={16} />
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      return emptyState(isTest);
    }
  };

  const getKeyTable = (isTest: boolean) => {
    return (
      <StyledTable className="rounded-table-corner table-responsive mb-4">
        <Table className="table-nowrap mb-0">
          <tbody>{getKeyRows(isTest)}</tbody>
        </Table>
      </StyledTable>
    );
  };

  const deleteRemoteKey = () => {
    if (!keyBeingDeleted) {
      return;
    }
    setIsLoadingDeleteKey(true);
    fetchWithAuth({
      path: `/integrations/remote-key/${keyBeingDeleted.id}`,
      method: "DELETE",
      onResponse: () => {
        setKeyBeingDeleted(undefined);
        setIsShowingConfirmDeleteModal(false);
        fetchWithAuth({
          path: "/integrations/remote-keys",
          method: "GET",
          onResponse: (data) => {
            setRemoteAPIKeyData(data);
            showSuccessToast("Successfully deleted key!");
            setIsLoadingDeleteKey(false);
          },
        });
      },
    });
  };

  const getGeneratedKey = (
    <>
      <p>Copy your access key below and record it somewhere safe.</p>
      <p className="font-bold">
        You will <span className="red">not</span> be able to recover this key after closing this
        window.
      </p>
      <DottedOutlineTextCard
        className="my-6"
        text={generatedAPIKey?.key ?? "Loading..."}
        isSecret={!!generatedAPIKey}
        isOutlined={false}
        iconSize={16}
      />
    </>
  );

  const keyType = newRemoteKeyIsTest ? "test" : newRemoteKeyIsProd ? "production" : "remote";
  const modalTitle = keyBeingRegenerated
    ? `Regenerate ${keyBeingRegenerated} access token`
    : `${isShowingAddRemoteKeyModal ? "Add" : "New"} ${keyType} access key`;

  const onCloseAddRemoteKeyModal = () => {
    setIsShowingAddRemoteKeyModal(false);
    setGeneratedAPIKey(undefined);
    setNewRemoteKeyIsProd(false);
    setNewRemoteKeyIsTest(false);
  };

  return (
    <PortalPageHeaderWrapper
      title="API keys"
      subtitle="Access your production and development keys"
    >
      <>
        <Dialog
          open={isShowingAddRemoteKeyModal}
          onClose={onCloseAddRemoteKeyModal}
          onPrimaryButtonClick={(e) => createRemoteKey(e)}
          primaryButtonDisabled={newRemoteKeyName === ""}
          primaryButtonLoading={isLoadingNewRemoteKey}
          primaryButtonVariant={ButtonVariant.PrimaryBlue}
          primaryButtonText="Create"
          onSecondaryButtonClick={onCloseAddRemoteKeyModal}
          secondaryButtonText="Cancel"
          footerButtonsHidden={!!generatedAPIKey}
          title={modalTitle}
          variant="md"
        >
          <>
            {generatedAPIKey ? (
              <>{getGeneratedKey}</>
            ) : (
              <>
                <p>
                  This key only provides access to{" "}
                  <b>{newRemoteKeyIsTest ? "test" : "production"} Linked Accounts</b> in your Merge
                  instance
                </p>
                <TextField
                  placeholder="Key name"
                  className="my-6"
                  onChange={(e) => setNewRemoteKeyName(e.target.value)}
                  variant="bordered"
                />
              </>
            )}
          </>
        </Dialog>

        <Dialog
          open={!!keyBeingRegenerated}
          onClose={() => {
            setKeyBeingRegenerated(undefined);
            setGeneratedAPIKey(undefined);
          }}
          onPrimaryButtonClick={regenerateAPIKey}
          primaryButtonDisabled={regenerateConfirmInputText !== keyBeingRegenerated}
          primaryButtonLoading={isLoadingRegenerateKey}
          primaryButtonText="Delete and regenerate token"
          onSecondaryButtonClick={() => {
            setKeyBeingRegenerated(undefined);
            setGeneratedAPIKey(undefined);
          }}
          secondaryButtonText="Cancel"
          footerButtonsHidden={!!generatedAPIKey}
          title={modalTitle}
          variant="md"
        >
          {generatedAPIKey ? (
            <>{getGeneratedKey}</>
          ) : (
            <>
              <p>
                Regenerating will permanently delete any existing {keyBeingRegenerated} tokens
                currently in use.
              </p>
              <p className="font-bold">
                Type "<span className="red">{keyBeingRegenerated}</span>" below to confirm.
              </p>

              <TextField
                placeholder={keyBeingRegenerated}
                className="my-6"
                onChange={(e) => setRegenerateConfirmInputText(e.currentTarget.value)}
                variant="bordered"
              />
            </>
          )}
        </Dialog>

        <Dialog
          open={isShowingConfirmDeleteModal}
          onClose={() => setIsShowingConfirmDeleteModal(false)}
          onPrimaryButtonClick={() => {
            deleteRemoteKey();
          }}
          onSecondaryButtonClick={() => {
            setIsShowingConfirmDeleteModal(false);
          }}
          primaryButtonText="Delete key"
          primaryButtonLoading={isLoadingDeleteKey}
          secondaryButtonText="Cancel"
          title="Delete access key"
          variant="sm"
        >
          <p>This action cannot be undone.</p>

          <p>Do you wish to continue?</p>
        </Dialog>
        <Row className="mb-5">
          <Col>
            <SectionHeaderWrapper
              title="Organization secrets"
              subtitle={
                <>
                  <p className="mb-3">
                    Use your access keys below to authenticate requests to Merge's APIs.
                    <br />
                  </p>{" "}
                  <p className="mb-3">
                    Never put plaintext secrets in your codebase. We highly suggest using a secrets
                    manager to load in secrets at runtime to your application.
                  </p>
                </>
              }
              titleHeading={TitleHeading.H1}
              headerRightHandContent={
                <a
                  href={DOCS_AUTHENTICATION_PATH}
                  className="text-primary font-medium small d-flex align-items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn about authentication
                  <i className="fe fe-chevron-right" style={{ marginLeft: 1 }} />
                </a>
              }
            />
          </Col>
        </Row>
        <Row className="mb-9">
          <Col>
            <Card className="mb-8">
              <Card.Body>
                <Row className="pl-3 align-items-center d-flex w-100 justify-content-between">
                  <Col>
                    <Row>
                      <CustomHeader>Production access key</CustomHeader>
                      <IconGrayTextCard className="ml-sm-n3" textLength={20} />
                    </Row>
                    <Row>
                      <p className="mt-3 mb-0 pb-2">
                        Use this key when interacting with the Merge API from your production
                        environment.
                      </p>
                    </Row>
                  </Col>
                  <Col className="col-xl-auto col-sm-12">
                    <Button
                      variant={ButtonVariant.TertiaryWhite}
                      size="sm"
                      className="ml-sm-n3"
                      leftIcon={<RefreshCw size={12} />}
                      onClick={() => setKeyBeingRegenerated("production")}
                    >
                      Regenerate token
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="w-100 mb-8">
              <Card.Body>
                <div className="d-flex">
                  <div style={{ flex: 1 }}>
                    <div className="d-flex">
                      <CustomHeader>Remote production access keys</CustomHeader>
                    </div>
                    <p className="mt-3 mb-0">
                      These keys give full access to production data, and are typically used for
                      on-premise deploys.
                    </p>
                    <p className="mt-3 mb-0">
                      They are different from your production access key since they cannot be used
                      to generate additional remote production access keys.
                    </p>
                    <div className="flex w-full flex-row justify-between">
                      <p className="mt-3 mb-0">Most organizations will not need these.</p>
                      <Button
                        variant={ButtonVariant.TertiaryWhite}
                        size="sm"
                        leftIcon={<Plus size={12} />}
                        onClick={() => {
                          setNewRemoteKeyIsTest(false);
                          setIsShowingAddRemoteKeyModal(true);
                        }}
                      >
                        Remote key
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="mt-5">{getKeyTable(false)}</div>
              </Card.Body>
            </Card>
            <Card className="w-100">
              <Card.Body>
                <div>
                  <CustomHeader>Test access keys</CustomHeader>
                </div>
                <div className="flex w-full flex-row justify-between">
                  <p className="mt-3 mb-0">
                    Using these keys, you can communicate with the Merge API for data related to{" "}
                    <Link to={TEST_LINKED_ACCOUNTS_PATH}>test Linked Accounts</Link>.
                  </p>
                  <Button
                    leftIcon={<Plus size={16} />}
                    onClick={() => {
                      setNewRemoteKeyIsTest(true);
                      setIsShowingAddRemoteKeyModal(true);
                    }}
                  >
                    Test key
                  </Button>
                </div>
                <div className="mt-5">{getKeyTable(true)}</div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    </PortalPageHeaderWrapper>
  );
};

export default ConfigurationAPIKeysPage;
